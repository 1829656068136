import { useMemo } from "react";
import {
  IFilters,
  useFilters,
} from "SRC/redux/slices/summary/hooks/useFilters";
import { useMinistries } from "SRC/redux/slices/summary/hooks/useMinistries";
import {
  TPremier,
  usePremiers,
} from "SRC/redux/slices/summary/hooks/usePremiers";
import {
  TProgram,
  usePrograms,
} from "SRC/redux/slices/summary/hooks/usePrograms";

import {
  IMinistriesTableItem,
  IPremierTableItem,
  IProgramTableItem,
} from "./interfaces";
import {
  createMinistriesTableData,
  createPremierRow,
  createPremiersTableData,
  createProgramRow,
  createProgramsTableData,
} from "./utils";

interface IUseTableData {
  programsData: IProgramTableItem[];
  premiersData: IPremierTableItem[];
  premiersTotals: IPremierTableItem;
  ministriesData: IMinistriesTableItem[];
}

export const useTableData = (): IUseTableData => {
  const {
    filteredPrograms,
    filteredVPs,
    filteredMinistries,
    calculationMethod,
  }: IFilters = useFilters();
  const { items: programs } = usePrograms();
  const { items: premiers, totals } = usePremiers();
  const { items: ministries } = useMinistries();

  const programsTableData = useMemo(
    () => createProgramsTableData(programs, calculationMethod),
    [programs, calculationMethod]
  );

  const programsData = useMemo(
    () =>
      programsTableData
        .filter((item) => filteredPrograms.includes(item.id))
        .sort((a, b) => Number(a.id) - Number(b.id)),
    [programsTableData, filteredPrograms]
  );

  const premiersTableData = useMemo(
    () => createPremiersTableData(premiers, calculationMethod),
    [premiers, calculationMethod]
  );

  const premiersData = useMemo(
    () =>
      premiersTableData
        .filter((item) => filteredVPs.includes(item.code))
        .map((item) => ({
          ...item,
          subRows: programsTableData.filter(
            (program) => program.vp === item.code
          ),
        }))
        .sort((a, b) => Number(a.id) - Number(b.id)),
    [premiersTableData, filteredVPs, programsTableData]
  );

  const premiersTotals = useMemo(
    () => createPremierRow({ data: totals } as TPremier, calculationMethod, 0),
    [totals, calculationMethod]
  );

  const ministriesTableData = useMemo(
    () => createMinistriesTableData(ministries, calculationMethod),
    [ministries, calculationMethod]
  );
  const ministriesData = useMemo(
    () =>
      ministriesTableData
        // .filter((item) => filteredMinistries.includes(item.id))
        .sort((a, b) => Number(a.id) - Number(b.id))
        .map((ministry) => ({
          ...ministry,
          subRows: programs
            .filter((it: TProgram) => it["Министерство"] === ministry.name)
            .map((el) => createProgramRow(el, calculationMethod)),
        })),
    [ministriesTableData, filteredMinistries, programs]
  );

  return {
    programsData,
    premiersData,
    premiersTotals,
    ministriesData,
  };
};
