import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { AgnosticRouteMatch } from "@remix-run/router/dist/utils";
import * as React from "react";
import { matchRoutes, useHref, useLocation } from "react-router-dom";
import { IRoute, MAIN, routeList } from "SRC/urls";

import { css } from "./Breadcrumbs.styled";

const Crumbs: React.FC = () => {
  const location = useLocation();
  const match: AgnosticRouteMatch<string, IRoute>[] | null = matchRoutes(
    routeList,
    location.pathname
  );

  const links = {
    main: useHref(MAIN),
  };

  if (!match?.length || match[0].route.nav) return null;

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="text.onAccent"
      href={links.main}
      sx={css.crumb}
    >
      Главная
    </Link>,
    <Typography sx={css.crumb} key="3" color="text.primary">
      {match[0].route.title}
    </Typography>,
  ];

  return (
    <Grid container sx={css.container}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="medium" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Grid>
  );
};

export default Crumbs;
