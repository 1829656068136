import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  title: (theme: Theme) => ({
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  }),
  rating: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
  },
};
