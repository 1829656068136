import { useMemo } from "react";
import { useStructureElementsIndicators } from "SRC/redux/slices/gosprogram/hooks";

import { createSeIndicatorsTableData, getIndicatorStatuses } from "../utils";

type TSEIndicatorsTableData = {
  indicatorsStatuses: any;
  indicatorsTableData: any;
};

export const useSEIndicatorsTableData = (): TSEIndicatorsTableData => {
  const { items } = useStructureElementsIndicators();

  const { indicatorsTableData, indicatorsStatuses } = useMemo(() => {
    const tableData = createSeIndicatorsTableData(items);

    return {
      indicatorsTableData: tableData,
      indicatorsStatuses: getIndicatorStatuses(tableData),
    };
  }, [items]);

  return {
    indicatorsStatuses,
    indicatorsTableData,
  };
};
