import { Box, useTheme } from "@mui/material";
import React from "react";

import { css } from "./StatusPoint.styled";

interface IStatusPointProps {
  value: number;
}

export const StatusPoint = ({ value }: IStatusPointProps) => {
  const theme = useTheme();
  return <Box sx={css.icon(theme, value)} />;
};
