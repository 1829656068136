import { Box, Button, SxProps, Tooltip, Typography } from "@mui/material";
import React from "react";
import { ECalculationMethod } from "SRC/constants/globals";
import { useMethods } from "SRC/redux/slices/main/hooks/useMethods";

import { css } from "./MethodSwitcher.styled";

const EFFECT = "Эффективность";
const RESULT = "Результативность";

interface IMethodSwitcherProps {
  value: ECalculationMethod;
  onChange: (value: ECalculationMethod) => void;
  isHidden?: boolean;
}
const MethodSwitcher = ({
  value,
  onChange,
  isHidden = true,
}: IMethodSwitcherProps) => {
  const { items: methods } = useMethods();

  const getBtnActive = (item: Record<string, string>) => {
    if (Object.keys(item)[0] === EFFECT) {
      return value === ECalculationMethod.EFFECT;
    }
    if (Object.keys(item)[0] === RESULT) {
      return value === ECalculationMethod.RESULT;
    }
  };

  const handleMethod = (item: Record<string, string>) => {
    if (Object.keys(item)[0] === EFFECT) {
      onChange(ECalculationMethod.EFFECT);
    }
    if (Object.keys(item)[0] === RESULT) {
      onChange(ECalculationMethod.RESULT);
    }
  };

  if (isHidden) {
    return null;
  }

  return (
    <Box sx={css.switch as SxProps}>
      {methods?.data?.map((item, i) => {
        return (
          <Tooltip
            key={i}
            componentsProps={{
              tooltip: {
                sx: css.hintContainer as SxProps,
              },
            }}
            title={
              <Typography sx={css.hintText as SxProps}>
                {Object.values(item)}
              </Typography>
            }
          >
            <Button
              sx={
                css.methodButton(
                  getBtnActive(item),
                  Object.keys(item)[0] === EFFECT
                ) as SxProps
              }
              onClick={() => {
                if (Object.keys(item)[0] !== EFFECT) {
                  handleMethod(item);
                }
              }}
            >
              {Object.keys(item)[0]}
            </Button>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default MethodSwitcher;
