export const css = {
  carouselContainer: {
    overflow: "hidden",
    height: "100%",
    width: "100%",
  },
  innerContainer: {
    whiteSpace: "nowrap",
    transition: "transform 0.3s",
    height: "100%",
    display: "inline-flex",
    width: "calc(100% + 1rem)",
  },
  carouselItemContainer: (itemWidth: number) => ({
    display: "inline-flex",
    height: "100%",
    width: `${itemWidth}%`,
    minWidth: `${itemWidth}%`,
    maxWidth: `${itemWidth}%`,
  }),
};
