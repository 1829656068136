import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { IObject } from "SRC/pages/Event/interfaces";
import { getListOfObjects } from "SRC/redux/slices/event/api/client";
import { IEventParams } from "SRC/redux/slices/event/slices/filters";

export interface IListOfObjectsState {
  items: IObject[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "listOfObjects/get",
    async (params: IEventParams) => await getListOfObjects(params)
  ),
};

const initialState: IListOfObjectsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "listOfObjects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IListOfObjectsState, action) => {
          const data = action.payload.data[0]?.data?.[0]?.data as IObject[];
          state.items = isContainedArray(data) ? data : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
