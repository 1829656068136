import { Box, Button, SxProps, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Column, TableInstance } from "react-table";
// import { EventCardModal, TOpenCardFn } from "SRC/components/CardModals";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import Table from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import TableStatusCardNew from "SRC/components/TableStatusCardNew/TableStatusCardNew";
import useTableExportParams from "SRC/hooks/useTableExportParams";
import { getEventColumns } from "SRC/pages/Program/common/EventsSection/util/columns";
import { TableSearch } from "SRC/pages/Program/common/EventsTab/TableSearch/TableSearch";
import { FilterFeatures } from "SRC/pages/Program/common/filters/FilterFeatures";
import { FilterTypeStructure } from "SRC/pages/Program/common/filters/FilterTypeStructure";
import { IStatus } from "SRC/pages/Program/common/ProgramSummary/interfaces";
import { useEventsTableData } from "SRC/pages/Program/tableData/useEventsTableData";
import { useEvents } from "SRC/redux/slices/gosprogram/hooks/useEvents";
import { useFilters } from "SRC/redux/slices/gosprogram/hooks/useFilters";
import { EVENT, setIdToUrl } from "SRC/urls";

import { css } from "./EventsTab.styled";

interface IEventsTabProps {
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  infoRows: Record<string, string>[][];
}

const Statuses = () => {
  const { eventsStatuses: statuses } = useEventsTableData();
  const { selectedStatus, setSelectedStatus } = useFilters();

  const handleClick = useCallback(
    ({ amount, status }: IStatus) =>
      () => {
        if (amount) {
          setSelectedStatus(status);
        }
      },
    [setSelectedStatus]
  );

  const statusesNodes: JSX.Element[] = useMemo(
    () =>
      statuses.map((item: IStatus) => (
        <TableStatusCardNew
          key={item.status}
          status={item.status}
          amount={item.amount}
          onClick={handleClick(item)}
          selected={selectedStatus.includes(item.status)}
        />
      )),
    [statuses, handleClick, selectedStatus]
  );

  return <Box sx={css.statusCardContainer}>{statusesNodes}</Box>;
};

const sortedColumns = ["План", "Факт", "Выполнение", "Влияние", "CЭ"];

const RenderTable: React.FC<IEventsTabProps> = ({
  isTableModalOpen,
}: IEventsTabProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const filterStatus = searchParams.get("filter");
  // const [onViewEvent, setOnViewEvent] = useState<TOpenCardFn | null>(null);

  const [instance, setInstance] = useState<TableInstance | undefined>(
    undefined
  );

  const { fetching, fetched: eventsDataFetched } = useEvents();
  const { eventsData: data } = useEventsTableData();
  const columns: Column[] = useMemo(() => getEventColumns(), []);

  const {
    setSelectedEventFeature,
    setSelectedStructureElementType,
    setSelectedStatus,
    clearSelectedStatus,
  } = useFilters();

  const selectStyle = useMemo(() => css.selector(theme), [theme]);
  const handleClearFilters = () => {
    setSelectedStructureElementType("");
    setSelectedEventFeature("");
    instance?.setGlobalFilter(undefined);
    clearSelectedStatus();
  };

  useEffect(() => {
    if (filterStatus && eventsDataFetched) {
      setSelectedStatus(filterStatus);
    }
  }, [filterStatus, eventsDataFetched]);

  const handleDoubleClick = (id: number | string) => {
    navigate(setIdToUrl(EVENT, id));
    // TODO: если нужна модалка, то оставить этот вариант
    // onViewEvent?.(id);
  };

  return (
    <Box sx={css.modalContainer}>
      <Box sx={css.searchSelectContainer}>
        <Box sx={{ flex: "1", minWidth: "20rem" }}>
          <TableSearch instance={instance} />
        </Box>
        <FilterFeatures
          label="Признак мероприятия"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedEventFeature}
        />
        <FilterTypeStructure
          label="Тип структурного элемента"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedStructureElementType}
        />
        <Button sx={css.filterButton as SxProps} onClick={handleClearFilters}>
          Сбросить фильтры
        </Button>
      </Box>
      <Statuses />
      <Table
        data={data}
        columns={columns}
        loading={fetching}
        sortedColumns={sortedColumns}
        tableHeight={!isTableModalOpen ? "70vh" : undefined}
        noDataCondition={data?.length === 0}
        getInstance={setInstance}
        onDoubleClick={handleDoubleClick}
      />
      {/* <EventCardModal opener={(call) => setOnViewEvent(() => call)} /> */}
    </Box>
  );
};

export const EventsTab: React.FC<IEventsTabProps> = (props) => {
  const { isTableModalOpen, setTableModalOpen, infoRows } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  const { eventsExports: downloadData } = useEventsTableData();
  const exports = useTableExportParams(downloadData || [], "events");

  if (!match && isTableModalOpen) {
    return (
      <TableModal
        open={isTableModalOpen}
        setOpen={setTableModalOpen}
        tableName="Мероприятия"
      >
        {downloadData && (
          <TableActionsButtons
            {...exports}
            open={isTableModalOpen}
            setOpen={setTableModalOpen}
            downloadData={downloadData}
            fileName="Мероприятия"
            infoRows={infoRows}
          />
        )}
        <RenderTable {...props} />
      </TableModal>
    );
  }

  return <RenderTable {...props} />;
};
