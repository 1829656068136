import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import UnitSelect from "SRC/components/UnitSelect/UnitSelect";
import { WIPTag } from "SRC/components/WIP";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { Overlay } from "SRC/pages/App.styled";
import { IDataSideBar } from "SRC/pages/Event/interfaces";
import { useEventData } from "SRC/redux/slices/event/hooks/useEventData";
import { useFilters } from "SRC/redux/slices/event/hooks/useFilters";
import { IEventData } from "SRC/redux/slices/event/slices/data";

import { css } from "./Sidebar.styled";

const digits: Record<string, number> = {
  млрд: 1000000,
  млн: 1000,
  тыс: 1,
};

const convertByDigit = (value?: number, unit: string = "") =>
  !value ? 0 : roundNumbersToFixed((value * 1000000) / (digits[unit] || 1), 2);

const prepareData = (
  event: IEventData | null,
  selectedFinanceUnit: string
): IDataSideBar => ({
  achievementLevel:
    roundNumbersToFixed(
      event?.data.find((it) => it.code === "1359")?.sum || 0,
      2
    ) || 0,
  corrections: {
    value: {
      worse: event?.data.find((it) => it.code === "1825")?.sum || 0,
      better: event?.data.find((it) => it.code === "1823")?.sum || 0,
    },
    time: {
      worse: event?.data.find((it) => it.code === "1826")?.sum || 0,
      better: event?.data.find((it) => it.code === "1824")?.sum || 0,
    },
  },
  event: {
    plan:
      roundNumbersToFixed(
        event?.data.find((it) => it.code === "1352")?.sum || 0,
        2
      ) || "-",
    fact:
      roundNumbersToFixed(
        event?.data.find((it) => it.code === "1358")?.sum || 0,
        2
      ) || "-",
    atRisk: event?.data.find((it) => it.code === "1471")?.sum || 0,
    forecast:
      roundNumbersToFixed(
        event?.data.find((it) => it.code === "1827")?.sum || 0,
        2
      ) || "-",
  },
  realization: {
    plan: event?.data.find((it) => it.code === "1366")?.sum || "-",
    fact: event?.data.find((it) => it.code === "1367")?.text || "-",
  },
  financing: {
    plan: convertByDigit(
      event?.data.find((it) => it.code === "1360")?.sum,
      selectedFinanceUnit
    ),
    fact: convertByDigit(
      event?.data.find((it) => it.code === "1361")?.sum,
      selectedFinanceUnit
    ),
  },
});

export const Sidebar = () => {
  const { items: event, fetching } = useEventData();
  const { selectedFinanceUnit } = useFilters();

  const data = useMemo<IDataSideBar>(
    () => prepareData(event, selectedFinanceUnit),
    [selectedFinanceUnit, event]
  );

  if (fetching) {
    return (
      <Overlay>
        <CircularProgress />
      </Overlay>
    );
  }

  return (
    <Box sx={css.container}>
      <Stack sx={css.content}>
        <Box sx={css.row}>
          <Box sx={css.achievement}>
            <Typography sx={css.title}>Уровень достижения,</Typography>
            <Typography sx={css.achievementLevelText} className="percent">
              &nbsp;%
            </Typography>
            <WIPTag
              sx={{
                display: "inline-block",
                marginLeft: "0.5rem",
                flexWrap: "nowrap",
              }}
            />
          </Box>
          <Box sx={css.achievement}>
            <Typography sx={css.achievementLevelText} className="big">
              {String(data.achievementLevel).split(".")[0]}
            </Typography>
            <Typography sx={css.achievementLevelText} className="small">
              {String(data.achievementLevel).split(".")[1] &&
                `,${String(data.achievementLevel).split(".")[1]}`}
            </Typography>
          </Box>
        </Box>

        <hr />

        <Box sx={css.valuesBox}>
          <Typography sx={css.title}>
            Количество корректировок
            <WIPTag
              sx={{
                display: "inline-block",
                marginLeft: "0.5rem",
                flexWrap: "nowrap",
              }}
            />
          </Typography>
          <Box sx={css.row}>
            <Typography sx={css.label} className="lineHeight">
              ухудшилось значение / срок
            </Typography>
            <Box sx={css.valueRow}>
              <Typography sx={css.value} className="red">
                {data.corrections.value.worse}
              </Typography>
              &nbsp;/&nbsp;
              <Typography sx={css.value} className="red">
                {data.corrections.time.worse}
              </Typography>
            </Box>
          </Box>

          <Box sx={css.row}>
            <Typography sx={css.label}>улучшилось значение / срок</Typography>
            <Box sx={css.valueRow}>
              <Typography sx={css.value} className="green">
                {data.corrections.value.better}
              </Typography>
              &nbsp;/&nbsp;
              <Typography sx={css.value} className="green">
                {data.corrections.time.better}
              </Typography>
            </Box>
          </Box>
        </Box>

        <hr />

        <Box sx={css.valuesBox}>
          <Typography sx={css.title}>
            Значение мероприятия
            <WIPTag
              sx={{
                display: "inline-block",
                marginLeft: "0.5rem",
                flexWrap: "nowrap",
              }}
            />
          </Typography>
          <Box sx={css.row}>
            <Typography sx={css.label}>план / факт / прогноз</Typography>
            <Box sx={css.valueRow}>
              <Typography sx={css.value} className="white">
                {data.event.plan}
              </Typography>
              &nbsp;/&nbsp;
              <Typography sx={css.value} className="white">
                {data.event.fact}
              </Typography>
              &nbsp;/&nbsp;
              <Typography sx={css.value} className="white">
                {data.event.forecast}
              </Typography>
            </Box>
          </Box>
        </Box>

        <hr />

        <Box sx={css.valuesBox}>
          <Typography sx={css.title}>
            Срок реализации
            <WIPTag
              sx={{
                display: "inline-block",
                marginLeft: "0.5rem",
                flexWrap: "nowrap",
              }}
            />
          </Typography>
          <Box sx={css.row}>
            <Typography sx={css.label}>план / факт</Typography>
            <Box sx={css.valueRow}>
              <Typography sx={css.value} className="white">
                {data.realization.plan}
              </Typography>
              &nbsp;/&nbsp;
              <Typography sx={css.value} className="white">
                {data.realization.fact}
              </Typography>
            </Box>
          </Box>
        </Box>

        <hr />

        <Box sx={css.valuesBox}>
          <Box sx={css.row}>
            <Typography sx={css.title}>
              Финансирование
              <WIPTag
                sx={{
                  display: "inline-block",
                  marginLeft: "0.5rem",
                  flexWrap: "nowrap",
                }}
              />
            </Typography>
            <UnitSelect />
          </Box>
          <Box sx={css.row}>
            <Typography sx={css.label}>план / факт</Typography>
            <Box sx={css.valueRow}>
              <Typography sx={css.value} className="white">
                {data.financing.plan}
              </Typography>
              &nbsp;/&nbsp;
              <Typography sx={css.value} className="white">
                {data.financing.fact}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
