import { SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { EFinanceUnit } from "SRC/constants/globals";
import { useFilters } from "SRC/redux/slices/event/hooks/useFilters";

const UnitSelect = () => {
  const { selectedFinanceUnit, setSelectedFinanceUnit } = useFilters();

  return (
    <Select
      value={selectedFinanceUnit}
      defaultValue={EFinanceUnit.BILLION}
      onChange={(event: SelectChangeEvent) =>
        setSelectedFinanceUnit(event.target.value)
      }
      inputProps={{ "aria-label": "Without label" }}
      style={{ height: "2rem" }}
    >
      <MenuItem value={EFinanceUnit.BILLION}>
        {EFinanceUnit.BILLION} &#8381;
      </MenuItem>
      <MenuItem value={EFinanceUnit.MILLION}>
        {EFinanceUnit.MILLION} &#8381;
      </MenuItem>
      <MenuItem value={EFinanceUnit.THOUSAND}>
        {EFinanceUnit.THOUSAND} &#8381;
      </MenuItem>
    </Select>
  );
};

export default UnitSelect;
