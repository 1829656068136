import { Theme } from "@mui/material";

export const css = {
  uiProgressBarStyle: (progressColor?: string) => ({
    backgroundColor: (theme: Theme) => theme.palette.background.default,
    borderRadius: 10,
    margin: "auto",
    maxWidth: "100%",
    width: "100%",
    display: "flex",
    overflow: "hidden",
  }),
  progressLineFirst: (value: number, progressColor?: string) => ({
    height: 8,
    width: `${value}%`,
    borderRadius: 4,
    backgroundColor: (theme: Theme) =>
      progressColor ? progressColor : theme.palette.success.light,
  }),
  progressLineSecond: (value: number, progressColor?: string) => ({
    height: 8,
    width: `${value}%`,
    borderRadius: 4,
    backgroundColor: (theme: Theme) =>
      progressColor ? progressColor : theme.palette.error.light,
  }),
  labelsContainer: {
    display: "flex",
    gap: "0.2rem",
    width: "100%",
    maxWidth: "100%",
    overflow: "hidden",
    marginTop: "0.2rem",

    span: {
      color: (theme: Theme) => theme.palette.charts.whiteTransparent,
    },
  },
  firstLabel: (value: number) => ({
    flex: `1 1 ${value}%`,
    maxWidth: `${value}%`,
    minWidth: "fit-content",
  }),
  secondLabel: {
    flex: 1,
    textAlign: "left",
  },
  totalLabel: {
    flex: "0 0 auto",
    minWidth: "fit-content",
  },
};
