import { useCallback } from "react";
import { useDispatch } from "react-redux";
import slice, {
  IProgramFiltersState,
} from "SRC/redux/slices/gosprogram/slices/filters";

import { useAppSelector } from "../../../hooks";

export interface IFilters extends IProgramFiltersState {
  clear(): void;
  setSelectedStatus(status: string): void;
  setSelectedStructureId(id: string | number): void;
  setSelectedStructureRow(id: string | number): void;
  setSelectedStructureCodes(codes: string[]): void;
  setSelectedEventFeature(feature: string): void;
  setSelectedStructureElementType(type: string): void;
  setSelectedStructureElementName(name: string): void;
  clearSelectedStatus(): void;
}

export const useFilters = (): IFilters => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.gosprogram.filters);

  const setSelectedStatus = useCallback(
    (status: string) => {
      return dispatch(slice.actions.selectStatus(status));
    },
    [dispatch]
  );

  const clearSelectedStatus = useCallback(() => {
    return dispatch(slice.actions.clearStatus());
  }, [dispatch]);

  const setSelectedStructureRow = (id: string) =>
    dispatch(slice.actions.selectStructureRow(id));

  const setSelectedStructureId = (id: string) =>
    dispatch(slice.actions.selectStructureId(id));

  const setSelectedStructureCodes = (codes: string[]) =>
    dispatch(slice.actions.selectStructureCodes(codes));

  const setSelectedEventFeature = (feature: string) =>
    dispatch(slice.actions.selectEventFeature(feature));

  const setSelectedStructureElementType = (type: string) =>
    dispatch(slice.actions.selectStructureElementType(type));

  const setSelectedStructureElementName = (name: string) =>
    dispatch(slice.actions.selectStructureElementName(name));

  const clear = useCallback(() => dispatch(slice.actions.clear()), [dispatch]);

  return {
    ...filters,
    setSelectedStatus,
    setSelectedStructureRow,
    setSelectedStructureId,
    setSelectedStructureCodes,
    setSelectedEventFeature,
    setSelectedStructureElementType,
    setSelectedStructureElementName,
    clear,
    clearSelectedStatus,
  };
};
