import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { IDataCodeEventCard } from "SRC/pages/Event/interfaces";
import { getEventData } from "SRC/redux/slices/event/api/client";
import { IEventParams } from "SRC/redux/slices/event/slices/filters";

export interface IEventData {
  NAME: string;
  R_ID: string;
  SortOrder: string;
  code: string;
  data: IDataCodeEventCard[];
  Денежный: string | boolean;
  "Ед. измерения бюджета": string;
  "Ед. измерения результата": string;
  "Наименование проекта": string;
  "Ответственный исполнитель": string;
  "Тип мероприятия": string;
  "Характеристика результата": string;
}

export interface IEventDataState {
  items: IEventData | null;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "data/get",
    async (params: IEventParams) => await getEventData(params)
  ),
};

const initialState: IEventDataState = {
  items: null,
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = null;
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IEventDataState, action) => {
        const data = action.payload.data as unknown as IEventData[];
        state.items = isContainedArray(data) ? data[0] : null;
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
