import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { IDataCodeHeader } from "SRC/pages/Event/interfaces";
import { getCashExecution } from "SRC/redux/slices/event/api/client";
import { IEventParams } from "SRC/redux/slices/event/slices/filters";

export interface ICashExecution {
  NAME: string;
  R_ID: string;
  SortOrder: string;
  code: string;
  data: IDataCodeHeader[];
  Денежный: string | boolean;
  "Ед. измерения бюджета": string;
  "Ед. измерения результата": string;
  "Наименование проекта": string;
  "Ответственный исполнитель": string;
  "Тип мероприятия": string;
  "Характеристика результата": string;
}

export interface ICashExecutionState {
  items: ICashExecution | null;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "cashExecution/get",
    async (params: IEventParams) => await getCashExecution(params)
  ),
};

const initialState: ICashExecutionState = {
  items: null,
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "cashExecution",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = null;
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: ICashExecutionState, action) => {
          const data = action.payload.data as unknown as ICashExecution[];
          state.items = isContainedArray(data) ? data[0] : null;
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
