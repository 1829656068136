import { Box, CircularProgress, SxProps } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { ProgressDoughnutPale } from "SRC/components/Charts";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import { getDataSumByCodeGP } from "SRC/constants/dataCodes";
import { ECalculationMethod } from "SRC/constants/globals";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { Overlay } from "SRC/pages/App.styled";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  useSpheres,
  useSumSpheres,
} from "SRC/redux/slices/main/hooks/useSpheres";
import { ISphere } from "SRC/redux/slices/main/slices/spheres";
import theme from "SRC/theme";

import { css } from "./Effectiveness.styled";

export const Effectiveness = () => {
  const { calculationMethod, setSelectedSphere, sphere }: IFilters =
    useFilters();
  const { items: spheres, fetching } = useSpheres();
  const { items: sumSpheres, fetching: fetchingSum } = useSumSpheres();
  const dataSumSphere = useMemo(
    () =>
      calculationMethod === ECalculationMethod.EFFECT
        ? roundNumbersToFixed(
            getDataSumByCodeGP(sumSpheres[0] || [], 1753) || 0,
            1
          )
        : roundNumbersToFixed(
            getDataSumByCodeGP(sumSpheres[0] || [], 1796) || 0,
            1
          ),
    [sumSpheres, calculationMethod]
  );

  const handleActiveSphere = useCallback(
    (sphereName: string) => {
      setSelectedSphere(sphere === sphereName ? "" : sphereName);
    },
    [sphere, setSelectedSphere]
  );

  const effectItems = useMemo(
    (): JSX.Element[] =>
      spheres?.map((item: ISphere) => {
        const progress =
          calculationMethod === ECalculationMethod.EFFECT
            ? roundNumbersToFixed(getDataSumByCodeGP(item, 1753) || 0, 0)
            : roundNumbersToFixed(getDataSumByCodeGP(item, 1796) || 0, 0);
        return (
          <Box
            key={item.code}
            sx={css.effectItem(sphere ? sphere === item.code : true) as SxProps}
            onClick={() => handleActiveSphere(item.code)}
          >
            <Box sx={css.itemTop}>
              <Box sx={css.title}>{item.code}</Box>
              <Box sx={css.num}>{getDataSumByCodeGP(item, 1837) || 0}</Box>
            </Box>
            <Box sx={css.itemBottom}>
              <Box sx={css.barContainer}>
                <Box sx={css.barTitle}>Уровень достижения</Box>
                <Box sx={css.barUnit}>
                  {progress}
                  <span>%</span>
                </Box>
              </Box>
              <ProgressBar
                value={progress}
                progressColor={theme.palette.pale.green}
              />
            </Box>
          </Box>
        );
      }),
    [calculationMethod, sphere, spheres]
  );

  return (
    <Box sx={css.container}>
      <Box sx={css.generalEffect as SxProps}>
        <Box sx={css.title}>Уровень достижения по открытым госпрограммам</Box>
        {fetchingSum ? (
          <Overlay>
            <CircularProgress />
          </Overlay>
        ) : (
          <Box sx={css.chart}>
            <ProgressDoughnutPale total={100} value={dataSumSphere} />
          </Box>
        )}
      </Box>
      <Box sx={css.effectsContainer}>
        {fetching ? (
          <Overlay>
            <CircularProgress />
          </Overlay>
        ) : (
          effectItems
        )}
      </Box>
    </Box>
  );
};
