import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    flex: 1,
    flexWrap: "nowrap",
    borderRadius: "1rem",
    padding: "1.5rem 2rem",
    backgroundColor: (theme: Theme) =>
      theme.palette.complementary.darkBlueLight,
  },
};
