import { actions as goals } from "./slices/goals";
import { actions as methods } from "./slices/methods";
import { actions as ministries } from "./slices/ministries";
import { actions as premiers } from "./slices/premiers";
import { actions as programs } from "./slices/programs";
import { actions as spheres } from "./slices/spheres";
import { actions2 as sumSpheres } from "./slices/spheres";
import { actions as stats } from "./slices/stats";

export interface IMainActions {
  ministries: typeof ministries;
  programs: typeof programs;
  goals: typeof goals;
  premiers: typeof premiers;
  stats: typeof stats;
  spheres: typeof spheres;
  sumSpheres: typeof sumSpheres;
  methods: typeof methods;
}

const actions: IMainActions = {
  ministries,
  programs,
  goals,
  premiers,
  stats,
  spheres,
  sumSpheres,
  methods,
};

export default actions;
