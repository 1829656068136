import { olapDimensionRequestData } from "CORE/api/core";
import { getDataFiltersOperands } from "SRC/redux/utils";
import { TStructureElementsGpRequest } from "SRC/types";

const DATA_FILTERS = {
  gp_code: (gp_code: string[]) => {
    return {
      type: "CONTAINS",
      version: 2,
      operands: [
        {
          type: "DIM",
          version: 2,
          id: "5219",
          attributeCode: "StateProgramCode",
        },
        {
          type: "TEXT",
          version: 2,
          values: gp_code,
        },
      ],
    };
  },
  se_type: (se_type: string[]) => {
    return {
      type: "EQ",
      version: 1,
      operands: [
        {
          type: "DIM",
          version: 2,
          id: "5219",
          attributeCode: "SE_TYPE",
        },
        {
          type: "TEXT",
          version: 2,
          values: se_type,
        },
      ],
    };
  },
};

const GET_GP_STRUCTURE_ELEMENTS = "get-gp-structure-elements";

// Структурные элементы для ГП
export const getStructureElementsGp = (params: TStructureElementsGpRequest) => {
  const operands = getDataFiltersOperands(params, DATA_FILTERS);

  return olapDimensionRequestData(
    [
      {
        requestId: "83548830-1a34-4cc7-b8a9-819914f98eb8",
        type: "dimension_data",
        params: {
          modelUuid: "dbd43734-dc29-4fc4-a16a-144efa1a7d62",
          dimensionId: "5219",
          dataFilter: {
            type: "AND",
            version: 1,
            operands,
          },
          includeAttributesByCodes: [
            "NAME",
            "SHORT_NAME",
            "SORT_ORDER",
            "SE_CODE",
          ],
        },
      },
    ],
    GET_GP_STRUCTURE_ELEMENTS
  );
};
