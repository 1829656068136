import { useMediaQuery, useTheme } from "@mui/material";
import React, { FC, Fragment, useEffect } from "react";
import { useParams } from "react-router";
import { LayoutNew } from "SRC/layouts";
import { useEvents } from "SRC/redux/slices/gosprogram/hooks/useEvents";
import { useGosprogram } from "SRC/redux/slices/gosprogram/hooks/useGosprogram";
import { useProgram } from "SRC/redux/slices/gosprogram/hooks/useProgram";
import {
  IFilters,
  useFilters as useMainFilters,
} from "SRC/redux/slices/main/hooks/useFilters";
import themeConfig from "SRC/theme";

import { CenterLayout, LeftLayout, RightLayout } from "./layouts";

export const Program: FC = () => {
  const theme = useTheme<typeof themeConfig>();
  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));

  const { init } = useGosprogram();
  const { id } = useParams();
  const { items: events } = useEvents();
  const { fetching } = useProgram();
  const { period }: IFilters = useMainFilters();

  useEffect(() => {
    if (id && !fetching) {
      init({ id });
    }
  }, [id, period]);

  return (
    <Fragment>
      <LayoutNew
        left={<LeftLayout />}
        center={<CenterLayout isExtraLarge={isExtraLarge} />}
        right={
          isExtraLarge && <RightLayout programGoals={[]} events={events} />
        }
        isProgramLayout
      />
    </Fragment>
  );
};
