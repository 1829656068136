import { Theme } from "@mui/material";

const $activeGradient =
  "linear-gradient(90deg, rgba(46, 54, 74, 0) 0%, rgba(160, 176, 208, 0.3) 100%)";
const $lightRow = (theme: Theme) => theme.palette.complementary.darkGrayBlue;
const $darkRow = (theme: Theme) => theme.palette.background.paper;
const $bgc = (theme: Theme) => theme.palette.main.darkBlue;

const mixins = {
  headFoot: {
    position: "sticky",
    zIndex: 9,
    width: "fit-content",
    minWidth: "100%",
    borderRadius: "1rem",
    flex: "0 0 auto",
    padding: "0.5rem 0",
  },
  row: {
    position: "relative",
    display: "flex",
    width: "fit-content",
    minWidth: "100% !important",
    borderRadius: "1rem",
  },
  cell: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    minWidth: "1rem",
    padding: "0 1rem",
    fontSize: "1em",

    ":first-of-type": {
      borderRadius: "1.5rem 0 0 1.5rem",
    },
    ":last-child": {
      borderRadius: "0 1.5rem 1.5rem 0",
    },
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const cssSortingArrows = (isSortedDesc: boolean) => ({
  ...mixins.flexCenter,
  flex: "0 0 auto",
  flexDirection: "column",
  gap: "0.5rem",
  transform: !isSortedDesc ? "scaleY(-1)" : null,
});

export const css = {
  wrapper: (tableHeight?: string | number) => ({
    position: "relative",
    flex: "1",
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    height: tableHeight || "auto",
    maxHeight: tableHeight || "100%",
    maxWidth: "100%",
  }),

  table: (scrollHide: boolean | undefined) => ({
    minWidth: "fit-content",
    width: "calc(100% + 1rem)",
    height: "100%",
    maxHeight: "100%",
    marginRight: "-1rem",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",

    "::-webkit-scrollbar": {
      display: scrollHide ? "none" : "",
    },

    "&.sticky": {
      "[data-sticky-td]": {
        position: "sticky",
      },

      "[data-sticky-last-left-td]": {
        boxShadow: `rgb(40 40 40 / 17%) 1px 0px, rgb(30 30 30 / 10%) 3px 0px, rgb(30 30 30 / 3%) 5px 0px`,
      },

      "[data-sticky-first-right-td]": {
        boxShadow: `rgb(40 40 40 / 17%) -1px 0px, rgb(30 30 30 / 10%) -3px 0px, rgb(30 30 30 / 3%) -5px 0px`,
      },
    },
  }),

  tr: {
    ...mixins.row,
    minHeight: "3rem",
  },

  thead: {
    ...mixins.headFoot,
    top: 0,
    backgroundColor: $lightRow,
  },
  th: (
    sorted: boolean,
    align: "center" | "left" | "right",
    width: number | string = "auto"
  ) => ({
    ...mixins.cell,
    textAlign: align || "left",
    fontSize: "1.2em",
    lineHeight: "1.2em",
    fontWeight: 400,
    cursor: sorted && "pointer",
    color: (theme: Theme) => theme.palette.main.gray,
    backgroundColor: $lightRow,
    flex:
      width === "auto"
        ? 1
        : `0 0 ${typeof width === "number" ? `${width}px` : width} !important`,
  }),
  thContent: (upper: boolean) => ({
    display: "flex",
    alignItems: "inherit",
    columnGap: "0.5rem",
    position: "relative",
    bottom: upper ? "100%" : "unset",
  }),

  tbody: {
    width: "fit-content",
    minWidth: "100%",
    position: "relative",
    zIndex: 4,
    flex: 1,
  },
  tableRow: (
    theme: Theme,
    withClickableRow: undefined | boolean,
    isSelected: boolean,
    defaultSelection: boolean
  ) => ({
    ...mixins.row,
    borderRadius: "1rem",
    position: "relative",
    margin: "0.5rem 0",
    minHeight: "3rem",
    background: isSelected
      ? `${$activeGradient}, rgba(46, 54, 74, 0.4) !important`
      : `linear-gradient(270deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.paperGray} 100%)`,
    cursor: !withClickableRow ? "default" : "pointer",
    opacity: defaultSelection || isSelected ? 1 : 0.5,

    "&:hover": {
      background: `${$activeGradient}, rgba(46, 54, 74, 0.4) !important`,

      "[data-sticky-last-left-td]": {
        "&:after": {
          background: `${$activeGradient}, ${theme.palette.background.paperGray} !important`,
        },
      },
    },

    // "@media (minWidth: 3800px)": {
    //   height: withBigRows ? "8rem" : "6rem",
    // },
  }),
  td: (width: number | string, stickyWidth: number) => ({
    ...mixins.cell,
    flex:
      width === "auto"
        ? 1
        : `0 0 ${typeof width === "number" ? `${width}px` : width} !important`,

    ".cellWrapper": {
      marginTop: "0rem",
      display: "flex",
      alignItems: "center",
      height: "100%",
      textAlign: "left",
    },

    "&[data-sticky-last-left-td]": {
      zIndex: "2 !important",
      boxShadow: (theme: Theme) =>
        `5px 2px 10px -4px ${theme.palette.main.darkBlue}`,

      "&:after": {
        content: '""',
        zIndex: -1,
        position: "absolute",
        width: stickyWidth + "px",
        height: "100%",
        top: 0,
        right: 0,
        borderRadius: "1rem 0 0 1rem",
        background: (theme: Theme) =>
          `linear-gradient(270deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.paperGray} 100%)`,
      },
    },
  }),

  tfoot: (theme: Theme) => ({
    ...mixins.headFoot,
    bottom: 0,
    boxShadow: `0px -3px 3px ${$bgc(theme)}`,
    background: $darkRow,

    ":after": {
      width: "calc(100% + 2rem)",
      height: "100%",
      bottom: 0,
      position: "absolute",
      background: $bgc,
      zIndex: 5,
      boxShadow: `0px -10px 5px 5px ${theme.palette.main.darkBlue}`,

      [theme.breakpoints.up("xl")]: {
        height: "5rem",
      },
    },
  }),
  tf: {
    ...mixins.cell,
    height: "4rem",
    padding: "0.5rem 1rem",
    background: $darkRow,
  },

  emptyTable: {
    minHeight: "10rem",
    flex: 1,
    position: "sticky",
    left: 0,
    fontSize: "1rem",
    fontWeight: "bold",
    color: (theme: Theme) => theme.palette.main.gray,
  },

  resetSelection: (theme: Theme) => ({
    position: "absolute",
    top: "50%",
    right: 5,
    // TODO [добавить]: translate(50%, -50%), убрано из-за проблемы с контейнером
    transform: "translateY(-50%)",
    cursor: "pointer",
    border: `1px solid ${theme.palette.background.paper}`,
    borderRadius: "50%",
    background: theme.palette.primary.main,
    width: "2.4rem",
    height: "2.4rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),

  // TODO [Добить стили]: что мог забыть? ===========================================
  headerBackHideRiskIcon: {
    width: "calc(100% - 2rem)",
    height: "5.5rem",
    top: "-0.5rem",
    left: "-0.5rem",
    position: "absolute",
    background: "transparent",
    zIndex: 1,

    "&.hideHeaderIcon": {
      background: (theme: Theme) => theme.palette.main.darkBlue,
      width: "2rem",
      height: "6rem",
    },
    "&.auxBlock": {
      background: "transparent",
      width: "100%",
    },
  },
  footerBackHideRiskIcon: {
    width: "calc(100% - 2rem)",
    height: "5.5rem",
    top: "2rem",
    left: "-1rem",
    position: "absolute",
    background: "transparent",
    zIndex: 1,

    "&.hideFooterIcon": {
      background: (theme: Theme) => theme.palette.main.darkBlue,
      width: "2rem",
      height: "5rem",
      top: "-2rem",
    },
  },
};
