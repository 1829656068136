import { Box, SxProps } from "@mui/material";
import { CSSProperties } from "@mui/styles";
import React, { FC } from "react";
import { css } from "SRC/components/CardsAnalitic/CardsAnalitic.styled";
import { WIPTag } from "SRC/components/WIP/WIP";

interface ICardsAnalitic {
  sx?: SxProps;
  headerLeft?: string;
  headerRight?: string | number | React.ReactNode;
  style?: CSSProperties | undefined;
  isSmall?: boolean;
  inDevelopment?: boolean;
}

export const CardsAnalitic: FC<ICardsAnalitic> = ({
  children,
  headerLeft,
  headerRight,
  style,
  isSmall,
  inDevelopment,
}) => {
  return (
    <Box sx={css.container(isSmall) as SxProps}>
      {(headerLeft || headerRight) && (
        <Box sx={css.header}>
          <Box sx={css.headerLeftContainer}>
            <Box sx={css.headerLeft}>{headerLeft}</Box>
            {inDevelopment && <WIPTag />}
          </Box>
          <Box>{headerRight}</Box>
        </Box>
      )}
      <Box sx={style}>{children}</Box>
    </Box>
  );
};
