import { Theme } from "@mui/material";

export const css = {
  container: {
    margin: "auto",
    maxWidth: "1900px",
    height: "100%",
    maxHeight: "100%",
    minHeight: "100%",
    width: "100%",
    flex: 1,
    gap: "1rem",
  },
  row: {
    display: "flex",
    flex: "0 1 auto",
    minHeight: "4rem",
  },
  content: {
    minHeight: "100px",
    display: "flex",
    flex: 1,
    gap: "1rem",
  },
  col: {
    display: "flex",
    minHeight: "4rem",
    flex: "0 1 30rem",
  },
  full: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
  },
  field: {
    display: "flex",
    flex: 1,
    minWidth: "4rem",
    width: "100%",
    maxWidth: "100%",
    borderRadius: "1rem",
    backgroundColor: (theme: Theme) => theme.palette.background.paper,
  },
};
