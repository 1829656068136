import { Box, styled } from "@mui/material";

export const RootContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.background.default,
  position: "relative",
  overflow: "hidden",
  padding: "4.5rem 0 0",

  [theme.breakpoints.up("xl")]: {
    overflow: "hidden",
    padding: "2rem 0 2rem",
  },
}));

// @ts-ignore
export const Content = styled("div")(({ theme }) => ({
  flex: "1 1 auto",
  height: "100%",
  overflowY: "overlay",
  zIndex: 1,
}));

export const Overlay = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  padding: "1rem",
});

export const PageContent = styled("main")(({ theme }) => ({
  height: "100%",
  padding: "1rem 2rem",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up("xl")]: {
    overflow: "hidden",
    padding: "0 2rem 0",
  },
}));
