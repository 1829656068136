import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getObjectPhotos } from "SRC/redux/slices/event/api/client";
import { IEventParams } from "SRC/redux/slices/event/slices/filters";

export interface IObjectPhoto {
  data: Record<string, any>;
  requestId: string;
  type: string;
}

export interface IObjectPhotosState {
  items: IObjectPhoto[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "objectPhotos/get",
    async (params: IEventParams) => await getObjectPhotos(params)
  ),
};

const initialState: IObjectPhotosState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "objectPhotos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IObjectPhotosState, action) => {
          state.items = (
            isContainedArray(action.payload.data) ? action.payload.data : []
          ) as IObjectPhoto[];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
