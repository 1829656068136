import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useMemo,
} from "react";
import { Column, TableInstance } from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import Table, { ColumnsController } from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import useTableExportParams from "SRC/hooks/useTableExportParams";
import { RatingTabs } from "SRC/pages/Summary/common/Rating/RatingTabs/RatingTabs";
import { GeneralInfoTabFilters } from "SRC/pages/Summary/common/SummaryComplexGrid/GeneralFullInfoTab/GeneralInfoTabFilters/GeneralInfoTabFilters";
import { getGeneralInfoColumns } from "SRC/pages/Summary/common/SummaryComplexGrid/GeneralFullInfoTab/tableColumns";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";
import {
  IGeneralInfoDataItem,
  IGeneralInfoDataItemWithoutIcon,
} from "SRC/pages/Summary/interfaces";
import { useRatingData } from "SRC/pages/Summary/useRatingData";

interface IGeneralInfoTabProps {
  posTable?: string;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  tableData: IGeneralInfoDataItem[];
  program: string;
  setSelectedProgram: (code: string) => void;
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  generalInfoVicePremiersFilter: string;
  setGeneralInfoVicePremiersFilter: (value: string) => void;
  generalInfoFoivFilter: string;
  setGeneralInfoFoivFilter: (value: string) => void;
  generalInfoVicePremiersFilterValues: (string | number | undefined)[];
  generalInfoFoivFilterValues: (string | undefined)[];
  downloadData: IGeneralInfoDataItemWithoutIcon[];
  infoRows: Record<string, string>[][];
  loading?: boolean;
  instance: TableInstance | undefined;
  setInstance: Dispatch<SetStateAction<TableInstance<{}> | undefined>>;
}

const staticColumns = [
  "status-icon",
  "id",
  "icon",
  "govProgram",
  "vicePremier",
  "foiv",
];
const sortedColumns = [
  "№",
  "Госпрограмма",
  "Факт",
  "Уровень достижения, %",
  "Факт, %",
  "План, млрд ₽",
  "План",
  "Факт",
  "Не выполнено",
];

const RenderTable: React.FC<IGeneralInfoTabProps> = ({
  forwardRef,
  onScroll,
  tableData,
  program,
  isTableModalOpen,
  setTableModalOpen,
  setSelectedProgram,
  downloadData,
  infoRows,
  loading,
  instance,
  setInstance,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const columns: Column[] = useMemo(
    () => getGeneralInfoColumns(theme),
    [theme]
  );

  const { headings, mergeCells, wscols } = useTableExportParams(
    downloadData,
    "summaryPrograms"
  );

  const { allColumns } = instance || {};

  const tableHeight: string | undefined = useMemo(() => {
    if (isTableModalOpen) return;
    return matches ? css.tableHeightLong : css.tableHeight;
  }, [isTableModalOpen, matches]);

  return (
    <>
      {isTableModalOpen && (
        <Box sx={css.modalActionButtons}>
          {(matches || isTableModalOpen) && <GeneralInfoTabFilters />}
          <Box sx={css.actionButtonsContainer}>
            <TableActionsButtons
              open={isTableModalOpen}
              setOpen={setTableModalOpen}
              downloadData={downloadData}
              fileName="Сводная таблица. Госпрограммы"
              wscols={wscols}
              headings={headings}
              mergeCells={mergeCells}
              infoRows={infoRows}
            />
            {allColumns && (
              <ColumnsController
                allColumns={allColumns}
                statics={staticColumns}
              />
            )}
          </Box>
        </Box>
      )}
      <Box sx={css.tablesWrapper} style={{ rowGap: "5rem" }}>
        <Table
          data={tableData}
          columns={columns}
          loading={loading}
          sortedColumns={sortedColumns}
          tableHeight={tableHeight}
          selectedRow={program}
          setSelectedRow={setSelectedProgram}
          forwardRef={forwardRef}
          onScroll={onScroll}
          noDataCondition={tableData?.length === 0}
          sticky
          getInstance={setInstance}
          classes={{ tr: "table-row" }}
        />
      </Box>
    </>
  );
};

export const GeneralFullInfoTab = (props: IGeneralInfoTabProps) => {
  const { isTableModalOpen, setTableModalOpen } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { bestGPData, worstGPData, bestGPFetching, worstGPFetching } =
    useRatingData();

  return (
    <>
      <Box sx={{ pb: "2rem" }}>
        <RatingTabs
          dataBest={bestGPData}
          dataWorst={worstGPData}
          bestFetching={bestGPFetching}
          worstFetching={worstGPFetching}
        />
      </Box>
      <RenderTable {...props} />
      {!matches && (
        <TableModal
          open={isTableModalOpen}
          setOpen={setTableModalOpen}
          tableName="Сводная таблица. Госпрограммы"
        >
          <RenderTable {...props} />
        </TableModal>
      )}
    </>
  );
};
