import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { IArrayCords } from "SRC/pages/Program/common/GoalsTab/common/NationalGoalsConnect/interfaces";
import {
  ESelectedType,
  ICordsItemLeft,
  ICordsItemRight,
  ISelected,
  TCheckForActiveFn,
} from "SRC/pages/Program/common/GoalsTab/common/NationalGoalsConnect/NationalGoalsConnect";

interface IConnectorLineProps {
  cordsLeft: ICordsItemLeft[];
  cordsRight: ICordsItemRight[];
  size: DOMRect | undefined;
  selected: ISelected | null;
  hiddenCountItemsRef: MutableRefObject<number[]>;
  checkActive: TCheckForActiveFn;
}

export const ConnectorLine = ({
  cordsLeft,
  cordsRight,
  size,
  selected,
  hiddenCountItemsRef,
  checkActive,
}: IConnectorLineProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const arrCoordinates = useMemo<IArrayCords[]>(() => {
    let arrayCords: IArrayCords[] = [];
    cordsLeft.forEach((itemLeft: ICordsItemLeft) => {
      cordsRight.forEach((itemRight: ICordsItemRight) => {
        if (itemLeft.dataInfo.goalsCode.includes(itemRight.dataInfo.id)) {
          itemRight.dataInfo.factors.forEach((factor) => {
            if (factor?.programId?.includes(itemLeft?.dataInfo?.id)) {
              arrayCords.push({
                programGoalId: itemLeft.dataInfo.id,
                goalCode: itemRight.dataInfo.id,
                goalFactor: factor.id,
                cordOut: {
                  x: itemLeft.x,
                  y: itemLeft.y,
                },
                cordIn: {
                  x: itemRight.x,
                  y: itemRight.y,
                },
                itemHeightRight: itemRight?.heightItem,
                itemHeightLeft: itemLeft?.heightItem,
              });
            }
          });
        }
      });
    });
    return arrayCords;
  }, [cordsRight, cordsLeft]);

  const filteredArrayCords = useMemo(
    () =>
      arrCoordinates.filter((item: IArrayCords) => {
        if (!selected) return true;
        const checkByGoal = checkActive(ESelectedType.GOAL, item.goalCode);
        const checkByInd = checkActive(ESelectedType.IND, item.programGoalId);
        const checkByFactor = checkActive(
          ESelectedType.FACTOR,
          item.goalFactor
        );
        return checkByGoal && checkByFactor && checkByInd;
      }),
    [arrCoordinates, checkActive, selected]
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas && size) {
      canvas.width = size.width;
      canvas.height = size.height;
    }
    if (canvas === null) return;
    const ctx = canvas.getContext("2d");
    if (ctx === null) return;
    ctx.fillStyle = "#212735";
    ctx.fillRect(0, 0, ctx.canvas.width, canvas.height);

    ctx.beginPath();

    if (size) {
      filteredArrayCords.forEach((item: IArrayCords) => {
        let pointLeftX = 0;
        let pointLeftY = 0;
        let cp1 = {
          x: size.width / 2,
          y: item?.cordOut?.y + item?.itemHeightLeft / 2,
        };
        let cp2 = {
          x: size.width / 2,
          y: item?.cordIn?.y + item?.itemHeightRight / 2,
        };
        if (item?.cordOut?.y < 0) cp1 = { x: size.width / 2, y: 0 };
        else if (item?.cordOut?.y > ctx?.canvas?.height - 60) {
          pointLeftY = ctx?.canvas?.height;
          cp1 = {
            x: size.width / 2,
            y: ctx?.canvas?.height,
          };
        } else pointLeftY = item?.cordOut?.y + item?.itemHeightLeft / 2;
        ctx.moveTo(pointLeftX, pointLeftY);
        ctx.bezierCurveTo(
          cp1.x,
          cp1.y,
          cp2.x,
          cp2.y,
          ctx?.canvas?.width,
          item?.cordIn?.y + item?.itemHeightRight / 2
        );
      });
    }
    ctx.strokeStyle = "white";
    ctx.lineWidth = 2;
    ctx.stroke();
  }, [size, filteredArrayCords, selected]);

  hiddenCountItemsRef.current = useMemo(() => {
    let counter: number[] = [0, 0];
    const canvas = canvasRef.current;
    if (canvas === null) return counter;
    const ctx = canvas.getContext("2d");
    if (ctx === null) return counter;
    cordsLeft.forEach((item: ICordsItemLeft) => {
      if (item?.y < 0) counter[0] = counter[0] + 1;
      else if (item?.y > ctx.canvas.height - 60) counter[1] = counter[1] + 1;
    });
    return counter;
  }, [cordsLeft]);

  return <canvas id="canvas" ref={canvasRef} />;
};
