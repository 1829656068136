import { Theme } from "@mui/material";

export const css = {
  item: {
    display: "flex",
    position: "relative",
    justifyContent: "end",
    transition: "0.5s ease-in",
    flex: "0 0 auto",
    marginLeft: "auto",
    minHeight: "8.5rem",
    maxHeight: "8.5rem",
  },
  itemContainer: {
    display: "flex",
    border: (theme: Theme) =>
      `0.25rem solid ${theme.palette.complementary.darkBlue}`,
    borderRadius: "1.5rem",
    padding: "1rem",
    maxHeight: "8.875rem",
    maxWidth: "38rem",
    minWidth: "38rem",
    backgroundColor: (theme: Theme) => theme.palette.main.darkBlue,
    zIndex: 1,
    cursor: "pointer",
  },
  rightImg: {
    display: "flex",
    "& img": {
      borderRadius: "0.5rem",
    },
  },
  rightName: (active: boolean) => ({
    display: "flex",
    alignItems: "center",
    fontSize: "1.375rem",
    fontWeight: 700,
    lineHeight: "140%",
    color: (theme: Theme) =>
      active
        ? theme.palette.primary.contrastText
        : theme.palette.complementary.grayBlue,
    transition: "0.5s ease-in",
  }),
  borderFactor: (active: boolean) => ({
    height: "100%",
    background: (theme: Theme) => theme.palette.complementary.grayBorder,
    padding: "0.1rem",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    opacity: active ? 1 : 0,
    transition: "0.5s ease-in",
    transform: `translateX(${active ? 0 : "16rem"})`,
  }),
  borderRound: {
    borderRadius: "50%",
    width: "1rem",
    height: "1rem",
    background: (theme: Theme) => theme.palette.primary.contrastText,
    border: (theme: Theme) =>
      `0.25rem solid ${theme.palette.complementary.grayBorder}`,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  factorContainer: (active: boolean) => ({
    display: "flex",
    alignItems: "start",
    flexDirection: "row",
    gap: "0.2rem",
    justifyContent: "center",
    position: "static",
    top: 0,
    bottom: 0,
    transition: "0.5s ease-in",
    width: !active ? 0 : "23rem",
  }),
  factorsList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    gap: "0.3rem",
    overflow: "hidden",
  },
  factor: (active: boolean) => ({
    display: "flex",
    color: (theme: Theme) =>
      active
        ? theme.palette.primary.contrastText
        : theme.palette.complementary.grayBlue,
    background: (theme: Theme) =>
      active ? "rgba(255,255,255,0.3)" : theme.palette.complementary.darkBlue,
    borderRadius: "0.5rem",
    border: (theme: Theme) =>
      `0.15rem solid ${
        active ? "rgba(255,255,255,0.3)" : theme.palette.complementary.darkBlue
      }`,
    minWidth: "22rem",
    position: "relative",
    paddingLeft: "1rem",
    gap: "0.5rem",
    transition: "0.5s ease-in",
    cursor: "pointer",
    transform: `translateX(${active ? "1rem" : "19rem"})`,
  }),
  factorName: {
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  factorPoint: {
    borderRight: "0.15rem solid black",
    pr: "2rem",
    maxWidth: "3rem",
    minWidth: "3rem",
    fontWeight: 700,
  },
  programCounter: (active: boolean) => ({
    opacity: active ? "0" : "1",
    display: "flex",
    alignItems: "center",
    // transform: "translateX(20rem)",
    width: active ? 0 : "auto",
  }),
  counter: (active: boolean) => ({
    background: "rgba(255, 255, 255, 0.15)",
    right: 0,
    borderRadius: "50%",
    fontSize: "1.5rem",
    fontWeight: 700,
    maxWidth: !active ? "0" : "1rem",
    maxHeight: "1rem",
    padding: "1.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid rgba(255, 255, 255, 0.3)`,
  }),
  hintContainer: {
    padding: "1.5rem",
    background: (theme: Theme) => theme.palette.complementary.grayBlue,
    borderRadius: "1rem",
  },
  hintText: {
    fontSize: "1rem",
    lineHeight: "1em",
    color: (theme: Theme) => theme.palette.main.gray,
    whiteSpace: "break-spaces",
  },
};
