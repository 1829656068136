import { Box, Typography } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import {
  iconCss,
  StatusIconContainer,
} from "SRC/components/Table/assets/icons";
import { css } from "SRC/components/Table/Table.styled";
import theme from "SRC/theme";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const subjectColumns = [
  {
    Header: " ",
    Footer: "",
    width: "30%",
    accessor: "nameSubject",
    columns: [
      {
        Header: "Название субъекта",
        accessor: "nameSubject",
        width: "30%",
        Cell: (tableProps: CustomTableProps) => (
          <div style={{ position: "relative", padding: "0 20px" }}>
            <StatusIconContainer>
              <Box
                sx={iconCss.statusIconImage(
                  theme,
                  tableProps.row.original.statusLabel
                )}
              />
            </StatusIconContainer>
            <Box
              sx={css.tableCellText}
              className="with-right-border withMarginLeft"
            >
              <Typography sx={css.tableCellText}>
                {tableProps.row.original.nameSubject}
              </Typography>
            </Box>
          </div>
        ),
      },
    ],
  },
  {
    Header: "Сроки",
    width: "30%",
    columns: [
      {
        Header: "План",
        accessor: "timePlan",
        width: "15%",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme)}
            className="bigText"
            align="left"
          >
            {tableProps.row.original.timePlan}
          </Typography>
        ),
      },
      {
        Header: "Факт",
        width: "15%",
        accessor: "timeFact",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme, tableProps.row.original.statusLabel)}
            className="bigText with-right-border"
            align="left"
          >
            {tableProps.row.original.timeFact}
          </Typography>
        ),
      },
    ],
  },
  {
    Header: "Значение",
    width: "30%",
    columns: [
      {
        Header: "План",
        width: "15%",
        accessor: "countObjectPlan",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme)}
            className="bigText"
            align="left"
          >
            {tableProps.row.original.countObjectPlan}
          </Typography>
        ),
      },
      {
        Header: "Факт",
        width: "15%",
        accessor: "countObjectFact",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(
              theme,
              tableProps.row.original.statusLabel,
              true
            )}
            className="bigText"
            align="left"
          >
            {tableProps.row.original.countObjectFact}
          </Typography>
        ),
      },
    ],
  },
];

export { subjectColumns };
