export const getDataFiltersOperands = (
  params: Record<any, any>,
  DATA_FILTERS: Record<any, any>
) => {
  const operands: Record<any, any>[] = [];

  Object.entries(params).forEach(([key, value]) => {
    const getFilter = DATA_FILTERS[key as keyof typeof DATA_FILTERS];
    const filterValue = Array.isArray(value) ? value : [value];

    if (getFilter && value) {
      operands.push(getFilter(filterValue));
    }
  });

  return operands;
};
