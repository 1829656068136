import React, { useCallback } from "react";
import { useNavigate } from "react-router";

import { Wrapper } from "./NavigateCell.styles";

interface INavigateCellProps {
  url: string;
  children: React.ReactNode;
}
export const NavigateCell = ({ url, children }: INavigateCellProps) => {
  const navigate = useNavigate();

  const handleDoubleClick = useCallback(
    (e) => {
      e.stopPropagation();
      navigate(url);
    },
    [navigate, url]
  );

  return <Wrapper onDoubleClick={handleDoubleClick}>{children}</Wrapper>;
};
