import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useMemo,
} from "react";
import { Column, TableInstance } from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import Table, { ColumnsController } from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import useTableExportParams from "SRC/hooks/useTableExportParams";
import { RatingTabs } from "SRC/pages/Summary/common/Rating/RatingTabs/RatingTabs";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";
import {
  IMinistriesTableItem,
  IMinistriesTableItemWithoutId,
} from "SRC/pages/Summary/interfaces";
import { useRatingData } from "SRC/pages/Summary/useRatingData";

import { getGeneralInfoMinistriesColumns } from "./tableColumns";

interface IGeneralInfoMinistriesTab {
  posTable?: string;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  tableData: IMinistriesTableItem[];
  ministry: string;
  setSelectedMinistry: (code: string) => void;
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  downloadData: IMinistriesTableItemWithoutId[];
  infoRows: Record<string, string>[][];
  loading?: boolean;
  instance: TableInstance | undefined;
  setInstance: Dispatch<SetStateAction<TableInstance<{}> | undefined>>;
}

const staticColumns = ["id", "ministriesIcon", "ministry", "numOfPrograms"];
const sortedColumns = [
  "№",
  "Министерство",
  "Кол-во госпрограмм",
  "Уровень достижения, %",
  "План, млрд ₽",
  "Факт, %",
  "План",
  "Факт",
  "Не выполнено",
];

const RenderTable: React.FC<IGeneralInfoMinistriesTab> = ({
  forwardRef,
  onScroll,
  tableData,
  ministry,
  setSelectedMinistry,
  isTableModalOpen,
  setTableModalOpen,
  downloadData,
  infoRows,
  loading,
  instance,
  setInstance,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const columns: Column[] = useMemo(
    () => getGeneralInfoMinistriesColumns(theme),
    [theme]
  );

  const { headings, mergeCells, wscols } = useTableExportParams(
    downloadData,
    "summaryMinistries"
  );

  const { allColumns } = instance || {};

  const tableHeight: string | undefined = useMemo(() => {
    if (isTableModalOpen) return;
    return matches ? css.tableHeightLong : css.tableHeight;
  }, [isTableModalOpen, matches]);

  return (
    <>
      {isTableModalOpen && (
        <Box sx={css.modalActionButtons}>
          <TableActionsButtons
            open={isTableModalOpen}
            setOpen={setTableModalOpen}
            downloadData={downloadData}
            fileName="Сводная таблица. Министерства"
            wscols={wscols}
            headings={headings}
            mergeCells={mergeCells}
            infoRows={infoRows}
          />
          {allColumns && (
            <ColumnsController
              allColumns={allColumns}
              statics={staticColumns}
            />
          )}
        </Box>
      )}
      <Box sx={css.tablesWrapper}>
        <Table
          loading={loading}
          columns={columns}
          data={tableData}
          sortedColumns={sortedColumns}
          tableHeight={tableHeight}
          selectedRow={ministry}
          setSelectedRow={setSelectedMinistry}
          forwardRef={forwardRef}
          onScroll={onScroll}
          noDataCondition={tableData?.length === 0}
          getInstance={setInstance}
          sticky
          expandable
        />
      </Box>
    </>
  );
};

export const GeneralFullInfoMinistriesTab = (
  props: IGeneralInfoMinistriesTab
) => {
  const { isTableModalOpen, setTableModalOpen } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const {
    bestMinistriesData,
    worstMinistriesData,
    bestMinistriesFetching,
    worstMinistriesFetching,
  } = useRatingData();

  return (
    <>
      <Box sx={{ pb: "2rem" }}>
        <RatingTabs
          dataBest={bestMinistriesData}
          dataWorst={worstMinistriesData}
          bestFetching={bestMinistriesFetching}
          worstFetching={worstMinistriesFetching}
        />
      </Box>
      <RenderTable {...props} />
      {!matches && (
        <TableModal
          open={isTableModalOpen}
          setOpen={setTableModalOpen}
          tableName="Сводная таблица. Министерства"
        >
          <RenderTable {...props} />
        </TableModal>
      )}
    </>
  );
};
