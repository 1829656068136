import { Box, CircularProgress, SxProps, useTheme } from "@mui/material";
import { SortIcon, SortIconDefault } from "ASSETS/svg";
import { SmallCloseGray } from "ASSETS/svg/close";
import React, {
  BaseSyntheticEvent,
  CSSProperties,
  MutableRefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Cell,
  Column,
  ColumnInstance,
  FooterProps,
  HeaderGroup,
  Renderer,
  Row,
  SortingRule,
  TableCellProps,
  TableInstance,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  UseSortByColumnProps,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { Overlay } from "SRC/pages/App.styled";

import { css, cssSortingArrows } from "../Base/Base.styled";

interface IDefaultData {
  id?: string | number;
}

type TDefaultData<D> = IDefaultData & D & object;

interface IStickyTableCell extends TableCellProps {
  "data-sticky-last-left-td": boolean;
}

enum ETableEl {
  table = "table",
  thead = "thead",
  tbody = "tbody",
  tfoot = "tfoot",
  tr = "tr",
  th = "th",
  td = "td",
  tf = "tf",
}

type TClasses = Partial<Record<keyof typeof ETableEl, string>>;

// @ts-ignore
interface IProps<DATA extends object> extends Partial<TableInstance<DATA>> {
  data: DATA[];
  columns: ReadonlyArray<Column<DATA>>;
  loading?: boolean;
  noDataCondition?: boolean;

  sortedColumns?: string[];
  tableHeight?: string | number;
  onClick?(id: number | string | undefined): void;
  onDoubleClick?(id: number | string): void;
  selectable?: boolean;
  selectedRow?: string | number;
  setSelectedRow?(id: number | string | undefined): void;
  scrollHide?: boolean;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: BaseSyntheticEvent) => void;

  getInstance?(tableInstance: TableInstance<DATA>): void;
  setSorting?: (sortBy: SortingRule<DATA>) => void;
  sticky?: boolean;
  expandable?: boolean;
  classes?: TClasses;
}

const getStickyWidth = (cell: IStickyTableCell): number => {
  const lastStickyStyles: CSSProperties | false | undefined =
    cell["data-sticky-last-left-td"] && cell.style;
  return lastStickyStyles
    ? ["left", "width"].reduce(
        (acc: number, style: string) =>
          // @ts-ignore
          (+lastStickyStyles[style]?.split("px")[0] || 0) + acc,
        0
      )
    : 0;
};

interface TTestCol<D extends object> {
  Footer?: Renderer<FooterProps<D>>;
  columns?: TTestCol<D>[];
}

const testOnFooter = <D extends object>({
  Footer,
  columns,
}: TTestCol<D>): boolean =>
  Boolean(Footer) || Boolean(columns?.some(testOnFooter));

interface ISortingArrows {
  isSorted: boolean;
  isSortedDesc: boolean;
}

const equalParentHead = (column: ColumnInstance): boolean => {
  const { parent } = column;
  return !!parent && !parent.Header && column.id === parent.id;
};

const SortingIcons: React.FC<ISortingArrows> = ({ isSorted, isSortedDesc }) => (
  <Box sx={cssSortingArrows(isSortedDesc)}>
    {isSorted ? <SortIcon /> : <SortIconDefault />}
  </Box>
);

const Base = <D extends object>({
  columns,
  data,
  loading,
  noDataCondition,

  sortedColumns,
  tableHeight,
  onClick,
  onDoubleClick,
  selectedRow,
  setSelectedRow,
  selectable = false,
  scrollHide,
  onScroll,
  forwardRef,

  setSorting,
  getInstance,
  sticky = false,
  expandable = false,
  classes,
}: IProps<D>): JSX.Element => {
  const theme = useTheme();
  const table = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    useFlexLayout,
    sticky ? useSticky : () => null,
    expandable ? useExpanded : () => null
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = table;

  useEffect(() => {
    getInstance?.(table);
  }, [table, getInstance]);

  const hasFooter = useMemo(() => columns.some(testOnFooter), [columns]);

  const [selected, setSelected] = useState<undefined | string | number>("");
  const setLocalSelection = useCallback(
    (v: string | number = ""): void => {
      setSelected(selectable ? v : "");
    },
    [selectable, setSelected]
  );

  useEffect(() => {
    if (typeof selectedRow !== undefined) setSelected(selectedRow);
  }, [selectedRow]);

  const handleMultiSortBy = useCallback(
    (column: UseSortByColumnProps<D> & Column) => () => {
      if (column && sortedColumns?.includes(String(column?.Header))) {
        if (column.isSorted && !column.isSortedDesc) {
          return column.clearSortBy();
        }
        column.toggleSortBy(!column.isSortedDesc);
      }
    },
    [sortedColumns]
  );

  const handleDoubleClick = (id: number | string) => () => {
    onDoubleClick?.(id);
  };

  const handleClick = (id: number | string) => () => {
    onClick?.(id);
    if (setSelectedRow) setSelectedRow(id);
    else setLocalSelection(id);
  };

  const handleResetSelection = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (setSelectedRow) setSelectedRow("");
    else setLocalSelection("");
  };

  const headers = useMemo(
    () =>
      headerGroups.map((headerGroup: HeaderGroup<D>) => (
        <Box
          className={`${ETableEl.tr} ${classes?.tr}`}
          {...headerGroup.getHeaderGroupProps()}
          sx={css.tr as SxProps}
        >
          {/* TODO: any !!!!!!!!!!!!!!!!! */}
          {headerGroup?.headers.map((column: any, index: number) => (
            <Box
              {...column.getHeaderProps(column.getSortByToggleProps())}
              sx={css.th(
                !!sortedColumns?.includes(column.Header),
                column.align,
                column.width
              )}
              width={column.width || "auto"}
              key={index}
              onClick={handleMultiSortBy(column)}
              className={`${ETableEl.th} ${classes?.th}`}
            >
              <Box sx={css.thContent(equalParentHead(column))}>
                <Box sx={{ flex: 1 }}>{column.render("Header")}</Box>
                {sortedColumns?.includes(column.Header) && (
                  <SortingIcons {...column} />
                )}
              </Box>
            </Box>
          ))}
        </Box>
      )),
    [headerGroups, classes, sortedColumns, handleMultiSortBy, sortBy]
  );

  const footer = useMemo(
    () =>
      footerGroups
        ?.filter(({ headers }) => headers.some((item) => Boolean(item.Footer)))
        ?.map((group: HeaderGroup<D>) => (
          <Box
            className={`${ETableEl.tr} ${classes?.tr}`}
            sx={css.tr as SxProps}
            {...group?.getFooterGroupProps()}
          >
            {group?.headers.map((column) => (
              <Box
                className={`${ETableEl.tf} ${classes?.tf}`}
                sx={css.tf}
                {...column.getHeaderProps()}
              >
                {column.render("Footer")}
              </Box>
            ))}
          </Box>
        )),
    [footerGroups, classes]
  );

  return (
    <Box sx={css.wrapper(tableHeight) as SxProps}>
      <Box
        className={`${ETableEl.table} sticky ${classes?.table}`}
        {...getTableProps()}
        sx={css.table(scrollHide) as SxProps}
        ref={forwardRef}
        onScroll={onScroll}
      >
        <Box
          className={`${ETableEl.thead} ${classes?.thead}`}
          sx={css.thead as SxProps}
        >
          {headers}
        </Box>

        {loading ? (
          <Box sx={css.emptyTable as SxProps}>
            <Overlay>
              <CircularProgress />
            </Overlay>
          </Box>
        ) : !data.length && noDataCondition ? (
          <Box sx={css.emptyTable as SxProps}>
            <Overlay>Нет данных</Overlay>
          </Box>
        ) : (
          <Box
            className={`${ETableEl.tbody} ${classes?.tbody}`}
            {...getTableBodyProps()}
            sx={css.tbody as SxProps}
          >
            {rows.map((row: Row<TDefaultData<D>>) => {
              prepareRow(row);
              return (
                <Box
                  {...row.getRowProps()}
                  className={`${ETableEl.tr} ${classes?.tr}`}
                  sx={
                    css.tableRow(
                      theme,
                      Boolean(onClick) ||
                        Boolean(onDoubleClick) ||
                        Boolean(setSelectedRow),
                      row.original?.id === selected,
                      !selected
                    ) as SxProps
                  }
                  onClick={handleClick(row.original.id || "")}
                  onDoubleClick={handleDoubleClick(row.original.id || "")}
                >
                  {row.cells.map((cell: Cell<D>, index: number) => {
                    const props: TableCellProps = cell.getCellProps();
                    return (
                      <Box
                        {...props}
                        className={`${ETableEl.td} ${classes?.td}`}
                        sx={
                          css.td(
                            cell.column.width || "auto",
                            getStickyWidth(props as IStickyTableCell)
                          ) as SxProps
                        }
                      >
                        {cell.render("Cell")}
                        {row.original.id &&
                          row.original.id === selected &&
                          index === row.cells.length - 1 && (
                            <Box
                              sx={css.resetSelection}
                              onClick={handleResetSelection}
                            >
                              <SmallCloseGray />
                            </Box>
                          )}
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        )}
        {hasFooter && (
          <Box
            className={`${ETableEl.tfoot} ${classes?.tfoot}`}
            sx={css.tfoot as SxProps}
          >
            {footer}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Base;
