import moment from "moment";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { getDataTextByCodeGP } from "SRC/constants/dataCodes";
import { ECalculationMethod, EDateType } from "SRC/constants/globals";
import { IGoals, useGoals } from "SRC/redux/slices/main/hooks/useGoals";
import {
  IPremiers,
  usePremiers,
} from "SRC/redux/slices/main/hooks/usePremiers";
import {
  IPrograms,
  usePrograms,
} from "SRC/redux/slices/main/hooks/usePrograms";
import slice, { IFiltersState } from "SRC/redux/slices/main/slices/filters";
import { IProgram } from "SRC/redux/slices/main/slices/programs";

import { useAppSelector } from "../../../hooks";
import { IMinistries, useMinistries } from "./useMInistries";

export interface IFilters extends IFiltersState {
  toggleChart(viewId: number): void;
  toggleSecret(secret: boolean): void;
  clear(): void;
  setSelectedProgram(code: string): void;
  setSelectedVP(code: string): void;
  setSelectedMinistry(code: string): void;
  setSelectedSphere(sphere: string): void;
  setSelectedGoal(code: string): void;
  setSelectedFoiv(code: string): void;
  setSelectedPeriod(period: null | Date, view?: EDateType): void;
  filteredGoals: (string | number)[];
  filteredVPs: (string | number)[];
  filteredMinistries: (string | number)[];
  filteredPrograms: (string | number)[];
  filteredType: (string | number)[];
  setSelectedCenterTab(tab: string): void;
  setSelectedComplexGridTab(tab: string): void;
  setCalculationMethod(method: ECalculationMethod): void;
}

export const useFilters = (): IFilters => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.main.filters);
  const { goal, vp, typeProgram, program, ministry, sphere, withSecret } =
    filters;
  const { items: premiers }: IPremiers = usePremiers();
  const { items: programs }: IPrograms = usePrograms();
  const { items: ministries }: IMinistries = useMinistries();
  const { items: goals, getItemByCode: getGoalByCode }: IGoals = useGoals();
  // TODO: переделать на поиск внутри вице-премьера, когда будет список целей
  const currentGoal = getGoalByCode(goal);

  // TODO: сделать выбор ФОИВ'ов
  const setSelectedFoiv = useCallback(
    (id: string) => {
      dispatch(slice.actions.selectFoiv(id));
    },
    [dispatch]
  );

  const filteredVPs = useMemo(
    () =>
      premiers
        .filter(
          (premier) =>
            !currentGoal || currentGoal?.VP_LIST.includes(premier.VP_CODE)
        )
        .map((premier) => premier.VP_CODE),
    [premiers, currentGoal]
  );
  const filteredMinistries: string[] = useMemo(
    () =>
      ministries
        .filter((ministry) => !program || ministry.NAME === program)
        .map((ministry) => ministry.code),
    [ministries, program]
  );

  const filteredGoals = useMemo(
    () =>
      goals
        .filter((goal) => !vp || goal.VP_LIST.includes(vp))
        .map((goal) => goal.NT_CODE),
    [goals, vp]
  );

  const filteredPrograms = useMemo(
    () =>
      programs
        .filter((program) => {
          const isGoal =
            !goal ||
            getDataTextByCodeGP(program, 1769)?.split(";")?.includes(goal);
          const isSphere = !sphere || program["Сфера"] === sphere;
          const isMinistry = !ministry || program["Министерство"] === ministry;
          const isVp = !vp || program["Вице-премьер, код"] === vp;
          const isSecretProgram = withSecret
            ? program
            : !Number(program["Секретная"]) && program;

          return isSecretProgram && isGoal && isSphere && isMinistry && isVp;
        })
        .map((program) => program.gp_code),
    [programs, vp, goal, ministry, sphere, withSecret]
  );

  const filteredType = useMemo(
    () =>
      programs
        .filter(
          (program) =>
            (!typeProgram && program.hasOwnProperty(typeProgram)) ||
            Number(program[typeProgram as keyof IProgram])
        )
        .filter((program) => filteredPrograms.includes(program.gp_code))
        .map((program) => program.gp_code),
    [typeProgram, programs, filteredPrograms]
  );

  const setSelectedProgram = (id: string) =>
    dispatch(slice.actions.selectProgram(id));

  const setSelectedVP = (id: string) => {
    dispatch(slice.actions.selectVP(id));
    if (id) {
      setSelectedProgram("");
      if (currentGoal && !currentGoal.VP_LIST.includes(id)) {
        dispatch(slice.actions.selectGoal(""));
      }
    }
  };

  const setSelectedMinistry = (id: string) => {
    dispatch(slice.actions.selectMinistry(id));
    if (id) {
      setSelectedProgram("");
      // dispatch(slice.actions.selectMinistry(""));
    }
  };

  const setSelectedSphere = (sphere: string) => {
    dispatch(slice.actions.selectSphere(sphere));
  };

  const setSelectedGoal = (id: string) => {
    dispatch(slice.actions.selectGoal(id));
    if (id) {
      setSelectedProgram("");
      if (!getGoalByCode(id)?.VP_LIST.includes(vp)) {
        dispatch(slice.actions.selectVP(""));
      }
    }
  };

  const setSelectedPeriod = (
    value: null | Date = new Date(),
    view: EDateType = EDateType.YEAR
  ) => {
    const details = {
      [EDateType.YEAR]: `${moment(value).year()}`,
      [EDateType.QUARTER]: moment(value).format("Q"),
      [EDateType.MONTH]: moment(value).format("MM"),
    };

    const filterValue = `${details[EDateType.YEAR]}${
      view === EDateType.YEAR ? "00" : details[view]
    }`;

    const apiV2 = `${details[EDateType.YEAR]}${
      view === EDateType.YEAR ? "" : details[view]
    }`;

    const period = {
      value,
      filterValue,
      apiV2,
    };

    dispatch(slice.actions.selectPeriod(period));
  };

  const clear = useCallback(() => dispatch(slice.actions.clear()), [dispatch]);
  const toggleChart = (activeId: number) =>
    dispatch(slice.actions.toggleChart(activeId));

  const toggleSecret = (activeSecret: boolean) =>
    dispatch(slice.actions.toggleSecret(activeSecret));

  const setSelectedCenterTab = (tab: string) =>
    dispatch(slice.actions.setCenterTab(tab));

  const setSelectedComplexGridTab = (tab: string) => {
    dispatch(slice.actions.setComplexGridTab(tab));
  };

  const setCalculationMethod = (method: ECalculationMethod) =>
    dispatch(slice.actions.setCalculationMethod(method));

  return {
    ...filters,
    setSelectedProgram,
    setSelectedMinistry,
    setSelectedSphere,
    setSelectedVP,
    setSelectedGoal,
    setSelectedPeriod,
    clear,
    toggleChart,
    toggleSecret,
    filteredVPs,
    filteredMinistries,
    filteredGoals,
    filteredPrograms,
    filteredType,
    setSelectedFoiv,
    setSelectedCenterTab,
    setSelectedComplexGridTab,
    setCalculationMethod,
  };
};
