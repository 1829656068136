import React, { useState } from "react";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import { RatingTab } from "SRC/pages/Summary/common/Rating/RatingTab/RatingTab";
import { IRatingTopData } from "SRC/pages/Summary/interfaces";

interface IRatingTabsProps {
  dataBest: IRatingTopData[];
  dataWorst: IRatingTopData[];
  bestFetching: boolean;
  worstFetching: boolean;
}

export const RatingTabs = ({
  dataBest,
  dataWorst,
  bestFetching,
  worstFetching,
}: IRatingTabsProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabs: ITab<number>[] = [
    {
      value: 0,
      label: "Лидирующие",
      component: (
        <RatingTab typeTop="best" data={dataBest} fetching={bestFetching} />
      ),
    },
    {
      value: 1,
      label: "Отстающие",
      component: (
        <RatingTab typeTop="worst" data={dataWorst} fetching={worstFetching} />
      ),
    },
  ];

  const onChangeTab = (value: number): void => setActiveTab(value);

  return (
    <ExtendedTabs
      value={activeTab}
      onChange={onChangeTab}
      tabs={tabs}
      contained
      style={{ fontWeight: 500 }}
    />
  );
};
