import { Theme } from "@mui/material";

export const css = {
  switch: (theme: Theme) => ({
    background: theme.palette.background.paper,
    borderRadius: "0.5rem",
    padding: "0.25rem",
    flex: "0 0 auto",
    width: "fit-content",
    position: "relative",
    height: "2.5rem",

    [theme.breakpoints.up("lg")]: {
      display: "flex",
      gap: "1rem",
      padding: "0",
      paddingLeft: "1rem",
      marginRight: "-0.5rem",
      border: "none",
      borderRadius: 0,
      borderLeft: `4px solid ${theme.palette.background.default}`,
    },
  }),
  methodButton: (active?: boolean, disabled?: boolean) => (theme: Theme) => ({
    backgroundColor: !active ? "transparent" : theme.palette.primary.main,
    color: "text.onAccent",
    borderRadius: "0.5rem",
    height: "2rem",
    padding: "0.5rem 1rem 0.5rem 1rem !important",
    flex: "0 0 auto",
    fontSize: "1rem",
    textTransform: "capitalize",
    fontWeight: "normal",
    cursor: disabled ? "default" : "pointer",
    opacity: disabled ? 0.6 : 1,

    [theme.breakpoints.up("lg")]: {
      height: "2.7rem",
      backgroundColor: theme.palette.background.default,
      borderRadius: "50%",
    },
  }),
  hintContainer: {
    background: (theme: Theme) => theme.palette.complementary.grayBlue,
    padding: "1.5rem",
    borderRadius: "1rem",
  },
  hintText: {
    fontSize: "1rem",
    lineHeight: "1em",
    color: (theme: Theme) => theme.palette.main.gray,
    whiteSpace: "break-spaces",
  },
};
