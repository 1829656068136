import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "SRC/redux/hooks";
import actions from "SRC/redux/slices/gosprogram/actions";
import { TStateDefault, TStructureElementsIndicatorsRequest } from "SRC/types";

export interface IStructureElementsIndicators extends TStateDefault {
  items: any;
  load(params: TStructureElementsIndicatorsRequest): void;
}

export const useStructureElementsIndicators =
  (): IStructureElementsIndicators => {
    const dispatch = useAppDispatch();
    const { items, fetching, fetched, error } = useAppSelector((state) => {
      return state.gosprogram.structureElementsIndicators;
    });

    const load = useCallback(
      (params: TStructureElementsIndicatorsRequest) => {
        if (!fetching) {
          dispatch(actions.structureElementsIndicators.get(params));
        }
      },
      [dispatch, fetching]
    );

    return {
      items,
      fetching,
      fetched,
      error,
      load,
    };
  };
