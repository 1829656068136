import { SxProps } from "@mui/material";
import React, { useMemo } from "react";
import SelectWithSearch from "SRC/components/SelectWithSearch/SelectWithSearch";
import { useFilters } from "SRC/redux/slices/gosprogram/hooks/useFilters";

type TOptionValue = string | number;

interface IOption {
  value: TOptionValue;
  label: string;
  disabled?: boolean;
}

interface IStyle {
  labelStyle?: SxProps;
  selectStyle?: SxProps;
  optionStyle?: SxProps;
}

interface IFilterFeatureProps {
  onChange(value: TOptionValue): void;
  label?: string;
  staticLabel?: boolean;
  colorizeActiveOption?: boolean;
  style: IStyle;
}

const selectTypeProjects = [
  {
    value: "Федеральные проекты",
    label: "Федеральные проекты",
  },
  {
    value: "Ведомственные проекты",
    label: "Ведомственные проекты",
  },
  {
    value: "Комплексы процессных мероприятий",
    label: "Комплексы процессных мероприятий",
  },
];

export const FilterTypeStructure = ({
  onChange,
  staticLabel = true,
  label,
  colorizeActiveOption = true,
  style,
}: IFilterFeatureProps) => {
  const { selectedStructureElementType } = useFilters();
  const customOptions = useMemo(
    () => [
      {
        value: "",
        label: label || "Все",
      },
      ...selectTypeProjects,
    ],
    [label, selectTypeProjects]
  );

  return (
    <SelectWithSearch
      onChange={onChange}
      options={customOptions}
      getOptionLabel={(option: IOption) => option.label}
      staticLabel={staticLabel}
      label={label}
      colorizeActiveOption={colorizeActiveOption}
      style={style}
      hasCloseIcon={Boolean(selectedStructureElementType)}
      value={selectedStructureElementType}
    />
  );
};
