import { Box, SxProps } from "@mui/material";
import { SecretProgram } from "ASSETS/svg";
import { WhiteArrowRight } from "ASSETS/svg/arrow";
import React, { BaseSyntheticEvent, useCallback } from "react";
import { useNavigate } from "react-router";
import { getDataSumByCodeGP } from "SRC/constants/dataCodes";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { TProgram } from "SRC/redux/slices/main/hooks/usePrograms";
import { PROGRAM_DETAIL, setIdToUrl } from "SRC/urls";

import { css } from "./Tiles.styled";

interface ITilesItemElement extends TProgram {
  secretProgram: boolean;
  onClick(): void;
}

export default function TilesItemElement({
  gp_icon_url,
  gp_short_name,
  gp_code,
  secretProgram,
  data,
}: ITilesItemElement) {
  const navigate = useNavigate();
  const { calculationMethod }: IFilters = useFilters();

  const onNavigate = useCallback(
    (e: BaseSyntheticEvent): void => {
      e.stopPropagation();
      navigate(setIdToUrl(PROGRAM_DETAIL, gp_code));
    },
    [gp_code]
  );

  const value =
    calculationMethod === "effect"
      ? getDataSumByCodeGP({ data }, 1753) || 0
      : getDataSumByCodeGP({ data }, 1796) || 0;

  return (
    <Box sx={css.item}>
      <Box sx={css.icon as SxProps}>
        <span>{gp_code}</span>
        <img src={gp_icon_url} aria-hidden alt="Title Item Image." />
      </Box>
      <Box sx={css.title(value)}>
        <Box sx={css.shortName}>{gp_short_name}</Box>
        <Box sx={css.secretContainer as SxProps}>
          {secretProgram ? <SecretProgram /> : null}
        </Box>
      </Box>
      <Box sx={css.indicator(value) as SxProps}>
        <span>%</span>
        <p>{(value && roundNumbersToFixed(value, 1)) || 0}</p>
      </Box>
      <Box sx={css.rightArrowContainer as SxProps} onClick={onNavigate}>
        <WhiteArrowRight />
      </Box>
    </Box>
  );
}
