import { Box, SxProps, Theme, Typography } from "@mui/material";
import { SecretProgram, WarningTriangle } from "ASSETS/svg";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { StatusPoint } from "SRC/components/StatusPoint/StatusPoint";
import { NavigateCell } from "SRC/components/Table/commons/NavigateCell";
import { css } from "SRC/components/Table/Table.styled";
import { WIPTag } from "SRC/components/WIP";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { EVENT_STATUS, PROGRAM_TABS } from "SRC/types";
import { createProgramCardUrl, PROGRAM_DETAIL_FULL } from "SRC/urls";

import { IProgramTableItem } from "../../../interfaces";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<IProgramTableItem>;
  rows?: UseTableRowProps<IProgramTableItem>[];
}

const getGeneralInfoColumns = (theme: Theme) => [
  {
    Header: "",
    accessor: "status-icon",
    sticky: "left",
    width: 15,
    sorted: true,
    columns: [
      {
        Header: "",
        sticky: "left",
        width: 15,
        accessor: "status-icon",
        Cell: (tableProps: CustomTableProps) => {
          const { secretProgram, atRisk } = tableProps.row.original;
          return (
            <Box sx={css.statusIcon as SxProps}>
              {Boolean(secretProgram) && <SecretProgram />}
              {Boolean(atRisk) && <WarningTriangle />}
            </Box>
          );
        },
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    sticky: "left",
    accessor: "id",
    columns: [
      {
        Header: "№",
        width: 50,
        sticky: "left",
        accessor: "id",
        Cell: (tableProps: CustomTableProps) => (
          <Typography sx={css.tableCellText} align="left" className="colorGray">
            {tableProps.row.original.id}
          </Typography>
        ),
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "icon",
    sticky: "left",
    columns: [
      {
        Header: "",
        width: 65,
        sticky: "left",
        accessor: "icon",
        Cell: (tableProps: CustomTableProps) => (
          <Box sx={css.centered}>
            <img
              style={css.contained}
              src={tableProps.row.original.icon}
              alt="govProgram"
            />
          </Box>
        ),
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    sticky: "left",
    accessor: "govProgram",
    columns: [
      {
        Header: "Госпрограмма",
        width: 250,
        sticky: "left",
        accessor: "govProgram",
        Cell: (tableProps: CustomTableProps) => (
          <Typography sx={css.tableCellText} align="left">
            {tableProps.row.original.govProgram}
          </Typography>
        ),
        Footer: <Typography sx={css.tableCellText}>Итого</Typography>,
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    sticky: "left",
    accessor: "vicePremier",
    columns: [
      {
        Header: "Вице-премьер",
        width: 200,
        sticky: "left",
        accessor: "vicePremier",
        Cell: (tableProps: CustomTableProps) => (
          <Typography sx={css.tableCellText} align="left">
            {tableProps.row.original.vicePremier}
          </Typography>
        ),
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    sticky: "left",
    accessor: "foiv",
    columns: [
      {
        Header: "Министерства",
        width: 200,
        sticky: "left",
        accessor: "foiv",
        Cell: (tableProps: CustomTableProps) => (
          <Typography sx={css.tableCellText} align="left">
            {tableProps.row.original.foiv}
          </Typography>
        ),
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    width: 260,
    accessor: "performance",
    columns: [
      {
        Header: "Уровень достижения, %",
        width: 260,
        accessor: "performance",
        Cell: (tableProps: CustomTableProps) => {
          const performance = roundNumbersToFixed(
            tableProps.row.original.performance || 0,
            1
          );

          return (
            <Typography
              align="left"
              sx={{
                ...css.tableCellText(theme),
                display: "flex",
                gap: "0.75rem",
                alignItems: "center",
              }}
            >
              <StatusPoint value={performance} />
              {performance}
            </Typography>
          );
        },
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return (
              roundNumbersToFixed(
                tableProps?.rows?.[0]?.original.performanceTotal || 0,
                1
              ) || 0
            );
          }, [tableProps.rows]);

          return (
            <Typography
              align="left"
              sx={{
                ...css.tableCellText(theme),
                display: "flex",
                gap: "0.75rem",
                alignItems: "center",
              }}
            >
              <StatusPoint value={total} />
              {total}
            </Typography>
          );
        },
      },
    ],
    Footer: "",
  },
  {
    Header: "Кассовое исполнение",
    accessor: "cash",
    columns: [
      {
        Header: "План, млрд ₽",
        width: 170,
        accessor: "cashPlan",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme, tableProps.row.original.statusLabel)}
            align="left"
          >
            {tableProps.row.original.cashPlan}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return (
              roundNumbersToFixed(
                tableProps?.rows?.[0]?.original.cashPlanTotal || 0,
                2
              ) || 0
            );
          }, [tableProps.rows]);

          return (
            <Typography sx={css.tableCellText(theme)} align="left">
              {total}
            </Typography>
          );
        },
      },
      {
        Header: "Факт, %",
        width: 150,
        accessor: "cashFact",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme, tableProps.row.original.statusLabel)}
            align="left"
          >
            {roundNumbersToFixed(tableProps.row.original.cashFact || 0, 2)}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return (
              roundNumbersToFixed(
                tableProps?.rows?.[0]?.original.cashFactTotal || 0,
                2
              ) || 0
            );
          }, [tableProps.rows]);

          return (
            <Typography sx={css.tableCellText(theme)} align="left">
              {total}
            </Typography>
          );
        },
      },
    ],
    Footer: "",
  },
  {
    Header: "Показатели ГП",
    accessor: "indicators",
    columns: [
      {
        Header: "План",
        width: 150,
        accessor: "indicatorsPlan",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme)}
            className="colorWhite"
            align="left"
          >
            {tableProps.row.original.indicatorsPlan}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.indicatorsPlanTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography sx={css.tableCellText(theme)} align="left">
              {total}
            </Typography>
          );
        },
      },
      {
        Header: (
          <div>
            Факт
            <WIPTag sx={{ display: "inline-block", marginLeft: "0.5rem" }} />
          </div>
        ),
        width: 180,
        accessor: "indicatorsDone",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme)}
            className="colorGreen"
            align="left"
          >
            {tableProps.row.original.indicatorsDone}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.indicatorsDoneTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorGreen"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
      {
        Header: "Не выполнено",
        width: 180,
        accessor: "indicatorsFail",
        Cell: (tableProps: CustomTableProps) => (
          <NavigateCell
            url={createProgramCardUrl(
              PROGRAM_DETAIL_FULL,
              String(tableProps.row.original.id),
              PROGRAM_TABS.INDICATORS
            )}
          >
            <Typography
              sx={css.tableCellText(theme)}
              className="colorRed"
              align="left"
            >
              {tableProps.row.original.indicatorsFail}
            </Typography>
          </NavigateCell>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.indicatorsFailTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorRed"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
    ],
    Footer: "",
  },
  {
    Header: "Структурные элементы",
    accessor: "structure",
    columns: [
      {
        Header: "План",
        width: 150,
        accessor: "structurePlan",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme)}
            className="colorWhite"
            align="left"
          >
            {tableProps.row.original.structurePlan}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.structurePlanTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorWhite"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
      {
        Header: (
          <div>
            Факт
            <WIPTag sx={{ display: "inline-block", marginLeft: "0.5rem" }} />
          </div>
        ),
        width: 180,
        accessor: "structureDone",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme)}
            className="colorGreen"
            align="left"
          >
            {tableProps.row.original.structureDone}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.structureDoneTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorGreen"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
      {
        Header: "Не выполнено",
        width: 180,
        accessor: "structureFail",
        Cell: (tableProps: CustomTableProps) => (
          <NavigateCell
            url={createProgramCardUrl(
              PROGRAM_DETAIL_FULL,
              String(tableProps.row.original.id),
              PROGRAM_TABS.STRUCTURE,
              EVENT_STATUS.NOT_DONE
            )}
          >
            <Typography
              sx={css.tableCellText(theme)}
              className="colorRed"
              align="left"
            >
              {tableProps.row.original.structureFail}
            </Typography>
          </NavigateCell>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.structureFailTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorRed"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
    ],
    Footer: "",
  },
  {
    Header: "Мероприятия",
    accessor: "events",
    columns: [
      {
        Header: "План",
        width: 180,
        accessor: "eventsPlan",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme)}
            className="colorWhite"
            align="left"
          >
            {tableProps.row.original.eventsPlan}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.eventsPlanTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorWhite"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
      {
        Header: "Факт",
        width: 180,
        accessor: "eventsDone",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme)}
            className="colorGreen"
            align="left"
          >
            {tableProps.row.original.eventsDone}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.eventsDoneTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorGreen"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
      {
        Header: "Не выполнено",
        width: 180,
        accessor: "eventsFail",
        Cell: (tableProps: CustomTableProps) => (
          <NavigateCell
            url={createProgramCardUrl(
              PROGRAM_DETAIL_FULL,
              tableProps.row.original.id,
              PROGRAM_TABS.EVENTS,
              EVENT_STATUS.NOT_DONE
            )}
          >
            <Typography
              sx={css.tableCellText(theme)}
              className="colorRed"
              align="left"
            >
              {tableProps.row.original.eventsFail}
            </Typography>
          </NavigateCell>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.eventsFailTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorRed"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
    ],
    Footer: "",
  },
  {
    Header: "Контрольные точки",
    accessor: "checkpoints",
    columns: [
      {
        Header: "План",
        width: 180,
        accessor: "checkpointsPlan",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme)}
            className="colorWhite"
            align="left"
          >
            {tableProps.row.original.checkpointsPlan}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.checkpointsPlanTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorWhite"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
      {
        Header: "Факт",
        width: 180,
        accessor: "checkpointsDone",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme)}
            className="colorGreen"
            align="left"
          >
            {tableProps.row.original.checkpointsDone}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.checkpointsDoneTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorGreen"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
      {
        Header: "Не выполнено",
        width: 180,
        accessor: "checkpointsFail",
        Cell: (tableProps: CustomTableProps) => (
          <NavigateCell
            url={createProgramCardUrl(
              PROGRAM_DETAIL_FULL,
              tableProps.row.original.id,
              PROGRAM_TABS.EVENTS,
              EVENT_STATUS.NOT_DONE
            )}
          >
            <Typography
              sx={css.tableCellText(theme)}
              className="colorRed"
              align="left"
            >
              {tableProps.row.original.checkpointsFail}
            </Typography>
          </NavigateCell>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.checkpointsFailTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorRed"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
    ],
    Footer: "",
  },
];

export { getGeneralInfoColumns };
