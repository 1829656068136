import { Box, Grid } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Column } from "react-table";
import Table from "SRC/components/Table";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { ISubjectItem } from "SRC/pages/Event/interfaces";
import { useSubjects } from "SRC/redux/slices/event/hooks/useSubjects";

import { css } from "./SubjectTab.styled";

export interface ISubjectTableData {
  id: string;
  statusLabel: string;
  nameSubject: string;
  timePlan: string;
  timeFact: string;
  countObjectPlan: number;
  countObjectFact: number;
}

interface IProps {
  columns: Column[];
}

export const SubjectTab = ({ columns }: IProps) => {
  const { items, fetching } = useSubjects();
  const [filterValue, setFilterValue] = useState<string>("");

  const data = useMemo<ISubjectTableData[]>(
    () =>
      items.map((item: ISubjectItem) => ({
        id: item.code,
        statusLabel: "completed",
        nameSubject: item.NAME,
        timePlan: item.data.find((el) => el.code === "1423")?.text || "",
        timeFact: item.data.find((el) => el.code === "1424")?.text || "",
        countObjectPlan: (() => {
          const el = item.data.find((el) => el.code === "1421");
          return roundNumbersToFixed(Number(el?.sum || el?.text) || 0, 0);
        })(),
        countObjectFact: roundNumbersToFixed(
          item.data.find((el) => el.code === "1422")?.sum || 0,
          0
        ),
      })),
    [items]
  );

  const totalItems = data?.length > 0 ? 55 : 0;
  const doneItems = data?.length > 0 ? 48 : 0;
  const notDoneItems = data?.length > 0 ? 5 : 0;
  const noDataItems = data?.length > 0 ? 2 : 0;

  return (
    <Box sx={css.wrapper}>
      <Grid container sx={{ gap: "1rem", marginBottom: "0.5rem" }}>
        <Grid item>
          <Box
            onClick={() => setFilterValue("")}
            sx={css.flexContainer(filterValue === "")}
          >
            <span className="grayText">Всего </span>
            <span>{totalItems}</span>
          </Box>
        </Grid>
        <Grid item>
          <Box
            onClick={() => setFilterValue("done")}
            sx={css.flexContainer(filterValue === "done")}
          >
            <Box sx={css.styledDot} className="green" />
            <span className="grayText">Выполнено </span>
            <span>{doneItems}</span>
          </Box>
        </Grid>
        <Grid item>
          <Box
            onClick={() => setFilterValue("notDone")}
            sx={css.flexContainer(filterValue === "notDone")}
          >
            <Box sx={css.styledDot} className="red" />
            <span className="grayText">Не выполнено </span>
            <span>{notDoneItems}</span>
          </Box>
        </Grid>
        <Grid item>
          <Box
            onClick={() => setFilterValue("noData")}
            sx={css.flexContainer(filterValue === "noData")}
          >
            <Box sx={css.styledDot} className="blue" />
            <span className="grayText">Не представлена отчетность </span>
            <span>{noDataItems}</span>
          </Box>
        </Grid>
      </Grid>
      <Table
        columns={columns}
        data={data}
        loading={fetching}
        sortedColumns={["План", "Факт", "Название субъекта"]}
        tableHeight={"31rem"}
        noDataCondition={!fetching && data?.length === 0}
      />
    </Box>
  );
};
