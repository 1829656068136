import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import EventChart from "SRC/components/Charts/EventChart/EventChart";
import UnitSelect from "SRC/components/UnitSelect/UnitSelect";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { Overlay } from "SRC/pages/App.styled";
import {
  IDataCodeEventCard,
  IDataCodeHeader,
} from "SRC/pages/Event/interfaces";
import {
  ICashExecutions,
  useCashExecution,
} from "SRC/redux/slices/event/hooks/useCashExecution";
import { useFilters } from "SRC/redux/slices/event/hooks/useFilters";
import { useFilters as useMainFilters } from "SRC/redux/slices/main/hooks/useFilters";

import { css } from "./CashTab.styled";

interface ICashExecutionItem {
  NAME: string;
  SHORT_NAME: string;
  SORT_ORDER: string;
  code: string;
  data: IDataCodeEventCard[];
}

interface ICashItem {
  month: string;
  plan: any;
  fact: any;
}

const emptyArray = new Array(12).fill(0);

const digits: Record<string, number> = {
  млрд: 1000000,
  млн: 1000,
  тыс: 1,
};

const convertByDigit = (value?: number, unit: string = "") =>
  !value ? 0 : roundNumbersToFixed((value * 1000000) / (digits[unit] || 1), 2);

export const CashTab = () => {
  const { items: cashExecution, fetching }: ICashExecutions =
    useCashExecution();
  const [cashTabData, setCashTabData] = useState<IDataCodeHeader[]>([]);

  const { selectedFinanceUnit } = useFilters();
  const { period } = useMainFilters();

  useEffect(() => {
    if (cashExecution) {
      setCashTabData(cashExecution.data || []);
    }
  }, [cashExecution]);

  const sortedCash = useMemo<ICashItem[]>(
    () =>
      cashTabData?.map((item: ICashExecutionItem) => ({
        month: item.code,
        plan: convertByDigit(
          Number(item.data.find((el) => el.code === "1442")?.sum),
          selectedFinanceUnit
        ),
        fact: convertByDigit(
          Number(item.data.find((el) => el.code === "1443")?.sum),
          selectedFinanceUnit
        ),
      })),
    [selectedFinanceUnit, cashTabData]
  );

  const planData = useMemo<number[]>(
    () => sortedCash?.map((item) => item.plan),
    [sortedCash]
  );

  const factData = useMemo<number[]>(() => {
    const isCurrentYear = moment().year() === moment(period.value).year();
    const currentMonth = moment().month();

    return sortedCash.map((item, monthIndex) => {
      if (isCurrentYear && monthIndex >= currentMonth) {
        return null;
      }

      return item.fact;
    });
  }, [sortedCash, period]);

  if (fetching) {
    return (
      <Box sx={css.cashTabWrapper}>
        <Overlay>
          <CircularProgress />
        </Overlay>
      </Box>
    );
  }

  return (
    <Box sx={css.cashTabWrapper}>
      <Grid container sx={css.cashTabContainer} justifyContent="space-between">
        <Grid item sx={css.legendContainer}>
          <Typography sx={css.mainText}>Кассовое исполнение</Typography>
          <UnitSelect />
        </Grid>

        <Grid item sx={css.legendContainer}>
          <Grid item sx={css.legendItemContainer} className="center">
            <Box sx={css.legendItem} className="white" />
            <p>План </p>
          </Grid>
          <Grid item sx={css.legendItemContainer} className="center">
            <Box sx={css.legendItem} className="blue" />
            <p>Факт </p>
          </Grid>
          <Grid item sx={css.legendItemContainer}>
            <Box sx={css.styledPoint} className="green" />
            <p>Превышение плана </p>
          </Grid>
          <Grid item sx={css.legendItemContainer}>
            <Box sx={css.styledPoint} className="red" />
            <p>Не достижение плана </p>
          </Grid>
        </Grid>
      </Grid>
      <EventChart
        planData={planData || emptyArray}
        factData={factData || emptyArray}
      />
    </Box>
  );
};
