import {
  Box,
  CircularProgress,
  SxProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { WhiteArrowLeft, WhiteArrowRight } from "ASSETS/svg/arrow";
import React, { useEffect, useMemo, useState } from "react";
import Carousel, { CarouselItem } from "SRC/components/Carousel/Carousel";
import { Overlay } from "SRC/pages/App.styled";
import GoalItem from "SRC/pages/Program/common/GoalsTab/GoalItem/GoalItem";
import { useProgramGoals } from "SRC/redux/slices/gosprogram/hooks/useProgramGoals";
import themeConfig from "SRC/theme";
import { IProgramGoal } from "SRC/types";

import { css } from "./Indicators.styled";

export const Indicators = () => {
  const theme = useTheme<typeof themeConfig>();
  const { items, fetching } = useProgramGoals();

  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPrevBtnDisabled, setPrevBtnDisabled] = useState(false);
  const [isNextBtnDisabled, setNextBtnDisabled] = useState(false);

  const perChunk = isExtraLarge ? 9 : 6;
  const inputArray = [...items];

  const goalsChunksArray = inputArray.reduce(
    (resultArray: any[], item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);

      return resultArray;
    },
    []
  );
  useEffect(() => {
    setPrevBtnDisabled(activeIndex === 0);
    setNextBtnDisabled(goalsChunksArray.length - activeIndex === 1);
  }, [activeIndex, goalsChunksArray.length]);

  const goalsChunks = useMemo(
    (): JSX.Element[] =>
      goalsChunksArray.map((array: IProgramGoal[], index: number) => (
        <CarouselItem key={index}>
          <Box sx={css.carouselItem}>
            {array.map((item: IProgramGoal) => (
              <GoalItem
                key={item["Код показателя"]}
                item={item}
                chunkLength={
                  index === goalsChunksArray.length - 1 && array.length
                }
              />
            ))}
          </Box>
        </CarouselItem>
      )),
    [goalsChunksArray]
  );

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = goalsChunksArray.length - 1;
    } else if (newIndex >= goalsChunksArray.length) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  const onPrevClick = () => {
    if (!isPrevBtnDisabled) {
      updateIndex(activeIndex - 1);
    }
  };
  const onNextClick = () => {
    if (!isNextBtnDisabled) {
      updateIndex(activeIndex + 1);
    }
  };

  if (fetching)
    return (
      <Overlay>
        <CircularProgress />
      </Overlay>
    );

  return (
    <Box sx={css.wrapper(goalsChunksArray.length > 1, theme) as SxProps}>
      <Box sx={css.carouselContainer}>
        {goalsChunksArray.length > 1 && (
          <Box
            onClick={onPrevClick}
            sx={css.button(isPrevBtnDisabled) as SxProps}
          >
            <WhiteArrowLeft style={css.arrow} />
          </Box>
        )}
        <Box sx={css.carousel}>
          <Carousel visibleItems={1} activeIndex={activeIndex} withoutMargin>
            {goalsChunks}
          </Carousel>
        </Box>
        {goalsChunksArray.length > 1 && (
          <Box
            onClick={onNextClick}
            sx={css.button(isNextBtnDisabled) as SxProps}
            className="nextBtn"
          >
            <WhiteArrowRight style={css.arrow} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
