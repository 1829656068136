import {
  Box,
  Button,
  CircularProgress,
  Grid,
  SxProps,
  useTheme,
} from "@mui/material";
import { CheckpointArrow } from "ASSETS/svg/arrow";
import { useMemo, useState } from "react";
import React from "react";
import { Overlay } from "SRC/pages/App.styled";
import { StepComponent } from "SRC/pages/Event/common/Checkpoints/Step/Step";
import { IDataCheckpoints, IStepsInfo } from "SRC/pages/Event/interfaces";
import { useControlPoints } from "SRC/redux/slices/event/hooks/useControlPoints";
import themeConfig from "SRC/theme";

import { BoxConnector, BoxStepper, css } from "./Checkpoint.styled";

export const Checkpoints = () => {
  const theme = useTheme<typeof themeConfig>();
  const { items: controlPoints, fetching } = useControlPoints();

  const checkpointsData = useMemo<IStepsInfo[]>(
    () =>
      (controlPoints?.data || [])
        .map((item: IDataCheckpoints) => ({
          datePlan: String(
            item.data.find((el) => el.code === "1380")?.sum || " - "
          ),
          dateFinish: String(
            item.data.find((el) => el.code === "1381")?.text ||
              item.data.find((el) => el.code === "1381")?.sum ||
              " - "
          ),
          namePoint: item.NAME,
          statusPoint: String(
            item.data.find((el) => el.code === "1504")?.sum || "default"
          ),
          SORT_ORDER: Number(item.SORT_ORDER),
          deviation: String(
            item.data.find((el) => el.code === "1830")?.sum || 0
          ),
          deviationColor: String(
            item.data.find((el) => el.code === "1831")?.sum || ""
          ),
        }))
        .sort((a, b) => a.SORT_ORDER - b.SORT_ORDER),
    [controlPoints]
  );

  const [currentStep, setCurrenStep] = useState(0);
  const [openName, setOpenName] = useState<boolean>(false);

  const handleNext = () => {
    if (currentStep < checkpointsData.length)
      setCurrenStep((state) => state + 1);
  };
  const handleBack = () => {
    if (currentStep > 0) setCurrenStep((state) => state - 1);
  };
  const steps = useMemo(
    () =>
      checkpointsData.map((label, index) => (
        <StepComponent
          key={index}
          label={label}
          openName={openName}
          setOpenName={setOpenName}
        />
      )),
    [checkpointsData, openName]
  );
  const isPrevBtnDisabled = currentStep === 0;
  const isNextBtnDisabled = currentStep === checkpointsData.length;

  if (fetching) {
    return (
      <Overlay>
        <CircularProgress />
      </Overlay>
    );
  }

  return (
    <Box sx={css.wrapper as SxProps}>
      <Box sx={css.stepperContainer(theme, openName) as SxProps}>
        <BoxStepper
          activeStep={4}
          connector={
            <Box
              sx={css.connectorWrapper as SxProps}
              className="connectorWrapper"
            >
              <BoxConnector />
            </Box>
          }
          sx={{ left: `${currentStep * -35}rem` }}
          alternativeLabel
        >
          {steps}
        </BoxStepper>
        <Button
          sx={css.button as SxProps}
          onClick={handleBack}
          style={{ left: "1rem" }}
          disabled={isPrevBtnDisabled}
        >
          <CheckpointArrow style={css.arrowLeft(isPrevBtnDisabled)} />
        </Button>
        <Button
          sx={css.button as SxProps}
          onClick={handleNext}
          style={{ right: "1rem" }}
          disabled={isNextBtnDisabled}
        >
          <CheckpointArrow style={css.arrowRight(isNextBtnDisabled)} />
        </Button>
        <Box sx={css.checkpointStepperLine} />
      </Box>
      <Grid container sx={css.legendContainer}>
        <Grid item sx={{ display: "flex", gap: "1rem" }}>
          <p>Всего </p>
          <p className="white">{checkpointsData.length}</p>
        </Grid>
        <Grid item sx={css.legendItemContainer}>
          <Box sx={css.styledPoint} className="green" />
          <p>Выполнено </p>
          <p className="white">
            {checkpointsData.filter((el) => el.statusPoint === "G").length}
          </p>
        </Grid>
        <Grid item sx={css.legendItemContainer}>
          <Box sx={css.styledPoint} className="red" />
          <p>Не выполнено </p>
          <p className="white">
            {checkpointsData.filter((el) => el.statusPoint === "R").length}
          </p>
        </Grid>
        <Grid item sx={css.legendItemContainer}>
          <Box sx={css.styledPoint} className="gray" />
          <p>В реализации </p>
          <p className="white">
            {checkpointsData.filter((el) => el.statusPoint === "Gr").length}
          </p>
        </Grid>
        <Grid item sx={css.legendItemContainer}>
          <Box sx={css.styledPoint} className="dark" />
          <p>Не представлена отчетность </p>
          <p className="white">
            {
              checkpointsData.filter((el) => el.statusPoint === "default")
                .length
            }
          </p>
        </Grid>
      </Grid>
    </Box>
  );
};
