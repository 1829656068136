import { Box, Grid, SxProps, useTheme } from "@mui/material";
import React from "react";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";

import { css } from "./LineProgress.styled";

export interface ILineProgressProps {
  value: number;
  percent: number;
  isMain?: boolean;
  nameValue: string;
}

export const LineProgress = ({
  value,
  percent,
  isMain = false,
  nameValue,
}: ILineProgressProps) => {
  const theme = useTheme();
  return (
    <Box sx={css.progressBar}>
      <Box sx={css.nameAndPercent}>
        <Box sx={css.name(isMain)}>{nameValue}</Box>
        <Box sx={css.percent(isMain)}>{value}</Box>
      </Box>
      <Box sx={css.bar}>
        <ProgressBar
          value={percent}
          progressColor={
            percent < 100 ? theme.palette.pale.gray : theme.palette.pale.green
          }
          withLabel={true}
          bottomLeftLabel={0}
          bottomRightLabel={100}
          circleLabel={percent}
          percentOnCircle={false}
          style={{
            marginTop: "1.25rem",
            background: theme.palette.background.progressDarkBlue,
          }}
        />
      </Box>
    </Box>
  );
};
