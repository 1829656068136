import { Box } from "@mui/material";
import React, { Fragment } from "react";
import { ExtendedTabs } from "SRC/components/ExtendedTabs/ExtendedTabs";
import { Analitics } from "SRC/pages/Main/common/Analitics/Analitics";
import GovPrograms from "SRC/pages/Main/common/GovPrograms/GovPrograms";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";

import { NationalGoalsTable } from "../../common/NationalGoalsTable/NationalGoalsTable";
import { css } from "./center-layout.styled";

interface ICenterLayoutProps {
  isLargeSize: boolean;
}
export const CenterLayout = ({ isLargeSize }: ICenterLayoutProps) => {
  const { setSelectedCenterTab, centerTab } = useFilters();
  const onChangeTab = (value: IFilters["centerTab"]): void =>
    setSelectedCenterTab(value);

  return (
    <Fragment>
      <GovPrograms />
      {!isLargeSize && (
        <Box sx={css.tabs}>
          <ExtendedTabs
            value={centerTab}
            onChange={onChangeTab}
            tabs={[
              {
                value: "analytics",
                label: "Сводная аналитика",
                component: <Analitics />,
              },
              {
                value: "goals",
                label: "Национальные цели",
                component: <NationalGoalsTable />,
              },
            ]}
            borderStyle="dotted"
            style={{ fontSize: "2rem", fontWeight: "bold" }}
          />
        </Box>
      )}
      {isLargeSize && <Analitics />}
    </Fragment>
  );
};
