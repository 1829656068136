import { Box, Grid, Typography } from "@mui/material";
import { RatingTop } from "SRC/components/RatingTop/RatingTop";
import { IRatingTopData } from "SRC/pages/Summary/interfaces";

import { css } from "./RatingTab.styled";

interface IRatingTabProps {
  withDarkBgr?: boolean | undefined;
  typeTop: "best" | "worst";
  data: IRatingTopData[];
  fetching: boolean;
}

const awaitArray = new Array(5).fill({});

export const RatingTab = ({
  withDarkBgr,
  typeTop,
  data,
  fetching,
}: IRatingTabProps) => {
  const topData = fetching ? awaitArray : data;
  return (
    <Grid sx={css.container}>
      {withDarkBgr && (
        <Box>
          <Typography component="h3" sx={css.title}>
            {typeTop === "best" ? "Лидирующие" : "Отстающие"}
          </Typography>
        </Box>
      )}
      <Box sx={css.rating}>
        {topData.map((item, key) => (
          <RatingTop
            data={item}
            withDarkBgr={withDarkBgr}
            key={key}
            fetching={fetching}
          />
        ))}
      </Box>
    </Grid>
  );
};
