import { useCallback } from "react";
import { ECalculationMethod } from "SRC/constants/globals";
import { useProgram } from "SRC/redux/slices/gosprogram/hooks/useProgram";
import { useFilters as useMainFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useMethods } from "SRC/redux/slices/main/hooks/useMethods";

import {
  useStructureElements,
  useStructureElementsIndicators,
} from "./structureElements";
import { useEvents } from "./useEvents";
import { useFilters } from "./useFilters";
import { useGoalConnect } from "./useGoalConnect";
import { useIndicators } from "./useIndicators";
import { useProgramGoals } from "./useProgramGoals";
import { useStatistics } from "./useStatistics";

interface IDefaultParams {
  id: string;
  fp_code?: string | null;
  dp_code?: string | null;
  cpm_code?: string | null;
}

export interface IMainState {
  loading: boolean;
  loaded: boolean;
  init(params: IDefaultParams): void;
  hasError: any;
}

const defaultParams = {
  id: "",
  fp_code: "*",
  dp_code: "*",
  cpm_code: "*",
};

export const useGosprogram = (): IMainState => {
  const { period, setCalculationMethod } = useMainFilters();
  const {
    fetching: loadingProgram,
    load: loadProgram,
    fetched: loadedProgram,
    error: errorProgram,
  } = useProgram();
  const {
    fetching: loadingGoals,
    load: loadProgramGoals,
    fetched: loadedGoals,
    error: errorProgramGoals,
  } = useProgramGoals();
  const {
    fetching: loadingEvents,
    load: loadProgramEvents,
    fetched: loadedEvents,
    error: errorEvents,
  } = useEvents();
  const {
    fetching: loadingIndicators,
    load: loadProgramIndicators,
    fetched: loadedIndicators,
    error: errorIndicators,
  } = useIndicators();
  const {
    fetching: loadingStatistics,
    load: loadStatistics,
    fetched: loadedStatistics,
    error: errorStatistics,
  } = useStatistics();
  const {
    fetching: loadingGoalConnect,
    load: loadGoalConnect,
    fetched: loadedGoalConnect,
    error: errorGoalConnect,
  } = useGoalConnect();
  const {
    fetching: loadingStructureElements,
    load: loadStructureElements,
    fetched: loadedStructureElements,
    error: errorStructureElements,
  } = useStructureElements();
  const {
    fetching: loadingMethods,
    load: loadMethods,
    fetched: loadedMethods,
    error: errorMethods,
  } = useMethods();
  const { setSelectedEventFeature, clearSelectedStatus } = useFilters();
  const {
    fetching: loadingStructureElementsIndicators,
    load: loadStructureElementsIndicators,
    fetched: loadedStructureElementsIndicators,
    error: errorStructureElementsIndicators,
  } = useStructureElementsIndicators();

  const loading =
    loadingProgram ||
    loadingGoals ||
    loadingEvents ||
    loadingIndicators ||
    loadingStatistics ||
    loadingGoalConnect ||
    loadingStructureElements ||
    loadingStructureElementsIndicators;
  const loaded =
    loadedProgram &&
    loadedGoals &&
    loadedEvents &&
    loadedIndicators &&
    loadedStatistics &&
    loadedGoalConnect &&
    loadedStructureElements &&
    loadedStructureElementsIndicators;
  const hasError =
    errorProgram ||
    errorEvents ||
    errorIndicators ||
    errorProgramGoals ||
    errorStatistics ||
    errorGoalConnect ||
    errorStructureElements ||
    errorStructureElementsIndicators;
  const init = useCallback(
    (params: IDefaultParams) => {
      const { id } = params;
      const loadParams = {
        ...defaultParams,
        ...params,
        gp_code: id,
        period,
        period_code: period.filterValue,
      };

      if (!loading) {
        loadProgram(loadParams);
        loadProgramGoals(loadParams);
        loadProgramEvents(loadParams);
        loadProgramIndicators(loadParams);
        loadStatistics(loadParams);
        loadGoalConnect(loadParams);
        loadStructureElements(id);
        loadStructureElementsIndicators({
          ...loadParams,
          period: "2022",
        });
        loadMethods();
      }

      clearSelectedStatus();
      setSelectedEventFeature("");
      setCalculationMethod(ECalculationMethod.RESULT);
    },
    [
      loading,
      loadProgramGoals,
      loadProgramEvents,
      loadProgramEvents,
      loadGoalConnect,
      loadMethods,
      period,
    ]
  );

  return {
    loading,
    loaded,
    init,
    hasError,
  };
};
