import "moment/locale/ru";

import { CircularProgress, Theme } from "@mui/material";
import { setAuthHeader } from "CORE/api/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import Breadcrumbs from "SRC/components/Breadcrumbs/Breadcrumbs";
import { Header } from "SRC/components/Header/Header";
import { Event } from "SRC/pages/Event/Event";
import { Main } from "SRC/pages/Main/Main";
import { Program } from "SRC/pages/Program/Program";
import { ProgramsBoard } from "SRC/pages/ProgramsBoard/ProgramsBoard";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  EVENT,
  GOVPROGRAMS,
  MAIN,
  PROGRAM,
  PROGRAM_DETAIL,
  PROGRAM_DETAIL_FULL,
  SUMMARY,
} from "SRC/urls";

import usePrevious from "../hooks/usePrevious";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { actions as tokenActions } from "../redux/slices/auth/tokenSlice";
import { actions as userActions } from "../redux/slices/auth/userSlice";
import { Content, Overlay, PageContent, RootContainer } from "./App.styled";
import { Summary } from "./Summary/Summary";

moment.locale("ru");

const App = () => {
  const dispatch = useAppDispatch();
  const [isInit, setIsInit] = useState(false);
  const { fetching: tokenFetching, access_token } = useAppSelector(
    (state) => state.auth.token
  );
  const { fetching: userFetching, user } = useAppSelector(
    (state) => state.auth.user
  );
  const prevTokenFetching = usePrevious(tokenFetching);
  const prevUserFetching = usePrevious(userFetching);

  let location = useLocation();

  const { clear }: IFilters = useFilters();

  useEffect(() => {
    clear();
  }, [location, clear]);

  const tokenInfo = localStorage.getItem("tokenInfo");

  useEffect(() => {
    const username = user?.login || "";

    if (tokenInfo && username) {
      const { refresh_token, access_token } = JSON.parse(tokenInfo);

      const accessTokenInfo = atob(access_token.split(".")[1]);
      const { exp } = JSON.parse(accessTokenInfo);
      const expDate = new Date(exp * 1000);

      const updateTimeOffset = expDate.getTime() - Date.now();

      setTimeout(
        () => dispatch(tokenActions.refreshToken({ username, refresh_token })),
        updateTimeOffset
      );
    } else {
      dispatch(tokenActions.getToken());
    }
  }, [dispatch, tokenInfo]);

  useEffect(() => {
    if (prevTokenFetching && !tokenFetching) {
      if (access_token) {
        setAuthHeader(access_token);
      }

      if (!user) {
        dispatch(userActions.getAuthorized());
      } else {
        setIsInit(true);
      }
    }
  }, [access_token, dispatch, tokenFetching, prevTokenFetching, user]);

  useEffect(() => {
    if (prevUserFetching && !userFetching) {
      setIsInit(true);
    }
  }, [prevUserFetching, userFetching]);

  if (tokenFetching || userFetching || !isInit) {
    return (
      <RootContainer
        sx={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <Header />
        <Content>
          <Overlay>
            <CircularProgress />
          </Overlay>
        </Content>
      </RootContainer>
    );
  }

  return (
    <RootContainer
      sx={(theme: Theme) => ({
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.up("xl")]: {
          width: "100vw",
          height: "19vw",
        },
      })}
    >
      <Header />
      <Content>
        {user ? (
          <PageContent>
            <Breadcrumbs />

            <Routes>
              <Route path={MAIN} element={<Main />} />
              <Route path={GOVPROGRAMS} element={<ProgramsBoard />} />
              <Route path={PROGRAM}>
                <Route path={PROGRAM_DETAIL} element={<Program />} />
                <Route path={PROGRAM_DETAIL_FULL} element={<Program />} />
              </Route>
              <Route path={SUMMARY} element={<Summary />} />
              <Route path={EVENT} element={<Event />} />
              <Route path="*" element={<Navigate to={MAIN} />} />
            </Routes>
          </PageContent>
        ) : (
          <Overlay sx={{ position: "fixed", height: "100vh" }}>
            Ошибка авторизации
          </Overlay>
        )}
      </Content>
    </RootContainer>
  );
};

export default App;
