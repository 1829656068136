import { Theme } from "@mui/material";

export const css = {
  buttonsContainer: {
    display: "flex",
    gap: "0.5rem",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "fit-content",
  },
  button: (active?: boolean) => ({
    backgroundColor: (theme: Theme) =>
      active
        ? theme.palette.main.blue
        : theme.palette.complementary.darkGrayBlue,
    width: "2.5rem",
    height: "2.5rem",
    minWidth: "auto",
    borderRadius: "8px",
    padding: 0,

    svg: {
      width: "1rem",
    },
  }),
};
