import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  Column,
  FilterValue,
  HeaderGroup,
  Row,
  SortingRule,
  TableBodyPropGetter,
  TableBodyProps,
  TablePropGetter,
  TableProps,
} from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { BaseTableNew } from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import useTableExportParams from "SRC/hooks/useTableExportParams";
import { VicePremiersFilters } from "SRC/pages/Main/common/ComplexGrid/filterComponents/VicePremiersFilters";
import { IVicePremiersDataItem } from "SRC/pages/Main/common/ComplexGrid/util/interfaces";

interface IVicePremiersTabProps {
  globalFilter: any;
  setGlobalFilter: (filterValue: FilterValue) => void;
  vicePremiersTableColumns: Column[];
  vicePremiersTableData: IVicePremiersDataItem[];
  getTableProps: (propGetter?: TablePropGetter<any>) => TableProps;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any>) => TableBodyProps;
  headerGroups: Array<HeaderGroup<any>>;
  rows: Array<Row<any>>;
  prepareRow: (row: Row<any>) => void;
  setSortBy: (sortBy: Array<SortingRule<any>>) => void;
  vp: string;
  setSelectedVP: (code: string) => void;
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  infoRows: Record<string, string>[][];
}

export const VicePremiersTab = ({
  globalFilter,
  setGlobalFilter,
  vicePremiersTableColumns,
  vicePremiersTableData,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  setSortBy,
  vp,
  setSelectedVP,
  isTableModalOpen,
  setTableModalOpen,
  infoRows,
}: IVicePremiersTabProps) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  const { headings, mergeCells, wscols } = useTableExportParams(
    vicePremiersTableData,
    "vicePremiers"
  );

  const noDataCondition = vicePremiersTableData?.length === 0;

  const RenderTable = () => {
    return (
      <Box>
        <Box
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          {(match || isTableModalOpen) && (
            <VicePremiersFilters
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
          {isTableModalOpen && (
            <TableActionsButtons
              open={isTableModalOpen}
              setOpen={setTableModalOpen}
              downloadData={vicePremiersTableData}
              fileName="Вице-премьеры"
              wscols={wscols}
              headings={headings}
              mergeCells={mergeCells}
              infoRows={infoRows}
            />
          )}
        </Box>
        <BaseTableNew
          columns={vicePremiersTableColumns}
          data={vicePremiersTableData}
          sortedColumns={[
            "Госпрограммы",
            "План",
            "Факт",
            "Выполнено",
            "Под риском",
            "Уровень достижения",
            "Количество госпрограмм",
          ]}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          setSortBy={setSortBy}
          isSmall
          tableHeight={isTableModalOpen ? "calc(100vh - 15rem)" : "26rem"}
          selectedRow={vp}
          setSelectedRow={setSelectedVP}
          noDataCondition={noDataCondition}
          withExpandingRows
        />
      </Box>
    );
  };

  return (
    <>
      <RenderTable />
      {!match && (
        <TableModal
          open={isTableModalOpen}
          setOpen={setTableModalOpen}
          tableName="Вице-премьеры"
        >
          <RenderTable />
        </TableModal>
      )}
    </>
  );
};
