import { Box, CircularProgress, SxProps } from "@mui/material";
import React, { Fragment, ReactNode, useMemo } from "react";
import { Overlay } from "SRC/pages/App.styled";
import { EffectiveInfo } from "SRC/pages/Program/common/GovProgramInfoWidget/common/EffectiveInfo/EffectiveInfo";
import { FinancingInfo } from "SRC/pages/Program/common/GovProgramInfoWidget/common/FinancingInfo/FinancingInfo";
import { HeaderInfoWidget } from "SRC/pages/Program/common/GovProgramInfoWidget/common/HeaderInfoWidget/HeaderInfoWidget";
import {
  getHeaderData,
  getProgramIconUrl,
  getProgramType,
} from "SRC/pages/Program/utils";
import { getCurrentProgram } from "SRC/pages/Program/utils";
import { useProgram } from "SRC/redux/slices/gosprogram/hooks/useProgram";

import { EventsInfo } from "./common/EventsInfo/EventsInfo";
import { css } from "./GovProgramInfoWidget.styled";

interface IHeaderProps {
  isFetched: boolean;
  children: ReactNode;
}

const Header = ({ isFetched, children }: IHeaderProps) => {
  if (!isFetched) {
    return (
      <Box sx={css.container as SxProps}>
        <Overlay>
          <CircularProgress />
        </Overlay>
      </Box>
    );
  }

  return <Fragment>{children}</Fragment>;
};

export const GovProgramInfoWidget = () => {
  const { items: program, fetching } = useProgram();

  const { currentProgram, programIconUrl, programType } = useMemo(() => {
    const currentProgram = getCurrentProgram(program);

    return {
      currentProgram,
      programIconUrl: getProgramIconUrl(currentProgram),
      programType: getProgramType(currentProgram),
    };
  }, [program]);
  const headerData = useMemo(() => {
    return getHeaderData(currentProgram, programType, programIconUrl);
  }, [currentProgram, programType]);

  return (
    <Box sx={css.infoWidgetContainer as SxProps}>
      <Header isFetched={!fetching}>
        <Box sx={css.programIcon as SxProps}>
          <img src={programIconUrl} alt="icon" />
        </Box>
        <HeaderInfoWidget headerData={headerData} />
      </Header>
      <Box sx={css.bodyInfoWidget}>
        <EffectiveInfo />
        <EventsInfo />
        <FinancingInfo />
      </Box>
    </Box>
  );
};
