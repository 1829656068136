import { Box, CircularProgress, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { Overlay } from "SRC/pages/App.styled";
import { ObjectTab } from "SRC/pages/Event/common/EventTabs/ObjectTab/ObjectTab";
import { IObjectDataItem } from "SRC/pages/Event/common/EventTabs/ObjectTab/util/interfaces";
import { IObject } from "SRC/pages/Event/interfaces";
import { useListOfObjects } from "SRC/redux/slices/event/hooks/useListOfObjects";

export const ObjectTabWrapper = () => {
  const theme = useTheme();
  const { items: listOfObjects, fetching } = useListOfObjects();

  const data = useMemo<IObjectDataItem[]>(
    () =>
      listOfObjects
        .map((item: IObject) => ({
          id: item["Код ОКС"],
          nameObject: item.NAME,
          nameRegion: item["Местонахождение объекта"],
          countObjects: item.Мощность,
          ready: roundNumbersToFixed(
            Number(
              (Number(item.data[0].data.find((it) => it.code === "1404")?.sum) /
                Number(
                  item.data[0].data.find((it) => it.code === "1403")?.sum
                )) *
                100 || 0
            ),
            0
          ),
          datePlan: [item["Дата начала"], item["Дата окончания"]] as [
            string,
            string
          ],
          hasPhoto: Boolean(
            item.data[0].data.find((it) => it.code === "1455")?.sum
          ),
          hasVideo: Boolean(
            item.data[0].data.find((it) => it.code === "1456")?.sum
          ),
          SORT_ORDER: Number(item.SORT_ORDER),
        }))
        .sort(
          (a: IObjectDataItem, b: IObjectDataItem) =>
            Number(a.SORT_ORDER) - Number(b.SORT_ORDER)
        ),
    [listOfObjects]
  );

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        padding: "0.5rem 2.5rem",
      }}
    >
      {fetching ? (
        <Box sx={{ width: "100%", height: "33rem" }}>
          <Overlay>
            <CircularProgress />
          </Overlay>
        </Box>
      ) : (
        <ObjectTab data={data} />
      )}
    </Box>
  );
};
