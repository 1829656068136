import { Box, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { Column, TableProps, UseTableRowProps } from "react-table";
import { css } from "SRC/components/Table/Table.styled";
import { statusIcons } from "SRC/constants/globals";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
  theme?: Theme;
}

export const columns = [
  {
    Header: " ",
    accessor: "status",
    width: css.statusIcon.width,
    Cell: (tableProps: CustomTableProps) => (
      <Box sx={css.statusIcon as SxProps}>
        {statusIcons[tableProps.row.original.status]}
      </Box>
    ),
  },
  {
    Header: "№",
    width: 50,
    accessor: "index",
    Cell: (tableProps: CustomTableProps) => (
      <Box sx={css.centered}>
        <Typography sx={css.tableCellText} align="center" className="colorGray">
          {tableProps.row.original.index}
        </Typography>
      </Box>
    ),
  },
  {
    Header: "ID индикатора СЭ",
    accessor: "se_indicator",
    width: 140,
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText}>
        {tableProps.row.original.se_indicator}
      </Typography>
    ),
  },
  {
    Header: "Тип CЭ",
    accessor: "structureElement",
    width: 100,
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText}>
        {tableProps.row.original.se_gp_type}
      </Typography>
    ),
  },
  {
    Header: "Наименование показателя",
    accessor: "se_gp",
    width: "auto",
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText}>
        {tableProps.row.original.se_gp}
      </Typography>
    ),
  },
  {
    Header: "Ед.изм.",
    accessor: "units",
    width: 100,
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText}>
        {tableProps.row.original.units}
      </Typography>
    ),
  },
  {
    Header: "Период достижения",
    accessor: "period",
    width: 150,
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText}>
        {tableProps.row.original.period}
      </Typography>
    ),
  },
  {
    Header: "Факт",
    accessor: "fact",
    width: 100,
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText}>
        {tableProps.row.original.fact}
      </Typography>
    ),
  },
  {
    Header: "План",
    accessor: "plan",
    width: 100,
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText}>
        {tableProps.row.original.plan}
      </Typography>
    ),
  },
  {
    Header: "План на год",
    accessor: "yearPlan",
    width: 150,
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText}>
        {tableProps.row.original.yearPlan}
      </Typography>
    ),
  },
  {
    Header: "Уровень достижения, %",
    accessor: "done",
    width: 200,
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText}>
        {tableProps.row.original.done}
      </Typography>
    ),
  },
];
