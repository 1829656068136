import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TableInstance } from "react-table";
import {
  IExportData,
  OpenerButton,
  TableExportButton,
} from "SRC/components/Buttons";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import ProgramsCounter from "SRC/components/ProgramsCounter/ProgramsCounter";
import Select, { IOption } from "SRC/components/Select/Select";
import { ColumnsController } from "SRC/components/Table";
import {
  getInfoRowsSummaryMinistries,
  getInfoRowsSummaryPrograms,
  getInfoRowsSummaryVicePremiers,
} from "SRC/helpers/getInfoRows";
import useTableExportParams from "SRC/hooks/useTableExportParams";
import { GeneralInfoTab } from "SRC/pages/Summary/common/SummaryComplexGrid/GeneralInfoTab/GeneralInfoTab";
import {
  IGeneralInfoDataItemWithoutIcon,
  IGeneralInfoPremiersDataItemWithoutId,
  IMinistriesTableItemWithoutId,
} from "SRC/pages/Summary/interfaces";
import { useTableData } from "SRC/pages/Summary/useTableData";
import { actions as tableTypeAction } from "SRC/redux/slices/gosprogram/slices/tableType";
import {
  EGridTabs,
  IFilters,
  useFilters,
} from "SRC/redux/slices/summary/hooks/useFilters";
import { useMinistries } from "SRC/redux/slices/summary/hooks/useMinistries";
import { usePremiers } from "SRC/redux/slices/summary/hooks/usePremiers";
import { usePrograms } from "SRC/redux/slices/summary/hooks/usePrograms";
import { dispatch } from "SRC/redux/store";
import themeConfig from "SRC/theme";

import { Filters } from "../Filters/Filters";
import { GeneralFullInfoMinistriesTab } from "./GeneralFullInfoMinistriesTab/GeneralFullInfoMinistriesTab";
import { GeneralFullInfoPremiersTab } from "./GeneralFullInfoPremiersTab/GeneralFullInfoPremiersTab";
import { GeneralFullInfoTab } from "./GeneralFullInfoTab/GeneralFullInfoTab";
import { GeneralInfoPremiersTab } from "./GeneralInfoPremiersTab/GeneralInfoPremiersTab";
import { css } from "./SummaryComplexGrid.styled";

interface ISummaryComplexGrid {
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  scrollX?: boolean;
  setSortBy: (sortBy: any) => void;
}
type TDData =
  | IGeneralInfoDataItemWithoutIcon
  | IGeneralInfoPremiersDataItemWithoutId
  | IMinistriesTableItemWithoutId;

type TExportData<T> = {
  [key: string]: IExportData<T>;
};

const staticGovProgramsColumns = [
  "status-icon",
  "id",
  "icon",
  "govProgram",
  "vicePremier",
  "foiv",
];
const staticVicePremiersColumns = [
  "id",
  "vicePremierPhoto",
  "vicePremier",
  "govPrograms",
];
const staticMinistriesColumns = [
  "id",
  "ministriesIcon",
  "ministry",
  "numOfPrograms",
];

export const SummaryComplexGrid = ({
  forwardRef,
  onScroll,
  setSortBy,
}: ISummaryComplexGrid) => {
  const theme = useTheme<typeof themeConfig>();
  const {
    program,
    vp,
    ministry,
    setSelectedProgram,
    setSelectedVP,
    setSelectedMinistry,
    clear,
    complexGridTab,
    setSelectedComplexGridTab,
  }: IFilters = useFilters();
  const { programsData, premiersData, ministriesData } = useTableData();
  const { fetching: fetchPR } = usePrograms();
  const { fetching: fetchVP } = usePremiers();
  const { fetching: fetchMinistries } = useMinistries();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const [isGovProgramsModalOpen, setGovProgramsModalOpen] = useState(false);
  const [isVicePremiersModalOpen, setVicePremiersModalOpen] = useState(false);
  const [isMinistriesModalOpen, setMinistriesModalOpen] = useState(false);

  const [govProgramsInstance, setGovProgramsInstance] = useState<
    TableInstance | undefined
  >(undefined);
  const [vicePremiersInstance, setVicePremiersInstance] = useState<
    TableInstance | undefined
  >(undefined);
  const [ministriesInstance, setMinistriesInstance] = useState<
    TableInstance | undefined
  >(undefined);

  const tabsHandler = (value: string) => setSelectedComplexGridTab(value);

  useEffect(() => {
    dispatch(tableTypeAction.changeTableType(complexGridTab));
  }, [complexGridTab]);
  const [generalInfoVicePremiersFilter, setGeneralInfoVicePremiersFilter] =
    useState<string>("");
  const [generalInfoFoivFilter, setGeneralInfoFoivFilter] =
    useState<string>("");

  const summaryProgramsTableDataFiltered = useMemo(
    () =>
      programsData
        .filter(
          (item) =>
            !generalInfoVicePremiersFilter ||
            item.vicePremier === generalInfoVicePremiersFilter
        )
        .filter(
          (item) =>
            !generalInfoFoivFilter || item.foiv === generalInfoFoivFilter
        ),
    [generalInfoVicePremiersFilter, generalInfoFoivFilter, programsData]
  );

  const components = (complexGridTab: number | string) => {
    if (complexGridTab === EGridTabs.PREMIERS && matches) {
      return [
        <GeneralInfoPremiersTab
          setSortBy={setSortBy}
          forwardRef={forwardRef}
          onScroll={onScroll}
          tableData={programsData}
        />,
      ];
    } else {
      return [
        <GeneralInfoTab
          setSortBy={setSortBy}
          forwardRef={forwardRef}
          onScroll={onScroll}
          tableData={programsData}
        />,
      ];
    }
  };

  const onlyUnique = (
    value: string | number | undefined,
    index: number,
    arr: any
  ) => {
    return arr.indexOf(value) === index;
  };

  const generalInfoVicePremiersFilterValues = useMemo<
    (string | number | undefined)[]
  >(
    () =>
      summaryProgramsTableDataFiltered
        .map((item) => (item.vicePremier !== -1 ? item.vicePremier : ""))
        .filter(Boolean)
        .filter(onlyUnique),
    [summaryProgramsTableDataFiltered]
  );
  const generalInfoFoivFilterValues = useMemo<(string | undefined)[]>(
    () =>
      summaryProgramsTableDataFiltered
        .map((item) => item.foiv)
        .filter(Boolean)
        .filter(onlyUnique),
    [summaryProgramsTableDataFiltered]
  );

  const summaryProgramsTableDataExport = useMemo(
    () =>
      summaryProgramsTableDataFiltered.map(
        ({ icon, secretProgram, ...item }) => item
      ),
    [summaryProgramsTableDataFiltered]
  );

  const summaryVicePremiersTableDataExport = useMemo(
    () => premiersData.map(({ id, ...item }) => item),
    [premiersData]
  );

  const summaryMinistriesTableDataExport = useMemo(
    () => ministriesData.map(({ id, ...item }) => item),
    [ministriesData]
  );

  const {
    headings: summaryProgramsHeadings,
    mergeCells: summaryProgramsMergeCells,
    wscols: summaryProgramsWscols,
  } = useTableExportParams(summaryProgramsTableDataExport, "summaryPrograms");
  const {
    headings: summaryVicePremiersHeadings,
    mergeCells: summaryVicePremiersMergeCells,
    wscols: summaryVicePremiersWscols,
  } = useTableExportParams(
    summaryVicePremiersTableDataExport,
    "summaryVicePremiers"
  );
  const {
    headings: summaryMinistriesHeadings,
    mergeCells: summaryMinistriesMergeCells,
    wscols: summaryMinistriesWscols,
  } = useTableExportParams(
    summaryMinistriesTableDataExport,
    "summaryMinistries"
  );

  const gpExportFilter = useMemo(() => {
    if (!generalInfoVicePremiersFilter && !generalInfoFoivFilter) return "Все";

    const values: string[] = [
      generalInfoVicePremiersFilter &&
        `вице-премьера ${generalInfoVicePremiersFilter}`,
      generalInfoFoivFilter && `министерства ${generalInfoFoivFilter}`,
    ].filter(Boolean);

    return `Госпрограммы ${values.join(", ")}`;
  }, [generalInfoVicePremiersFilter, generalInfoFoivFilter]);

  const infoRowsSummaryPrograms = useMemo(
    () => getInfoRowsSummaryPrograms(gpExportFilter),
    [summaryProgramsTableDataExport]
  );
  const infoRowsSummaryVicePremiers = useMemo(
    () => getInfoRowsSummaryVicePremiers(),
    [summaryVicePremiersTableDataExport]
  );
  const infoRowsSummaryMinistries = useMemo(
    () => getInfoRowsSummaryMinistries(),
    [summaryMinistriesTableDataExport]
  );

  const tabExportData: IExportData<TDData> = useMemo(() => {
    const exportData: TExportData<TDData> = {
      [EGridTabs.PROGRAMS]: {
        csvData: summaryProgramsTableDataExport,
        fileName: "Сводная таблица. Госпрограммы",
        wscols: summaryProgramsWscols,
        headings: summaryProgramsHeadings,
        mergeCells: summaryProgramsMergeCells,
        infoRows: infoRowsSummaryPrograms,
      },
      [EGridTabs.PREMIERS]: {
        csvData: summaryVicePremiersTableDataExport,
        fileName: "Сводная таблица. Вице-премьеры",
        wscols: summaryVicePremiersWscols,
        headings: summaryVicePremiersHeadings,
        mergeCells: summaryVicePremiersMergeCells,
        infoRows: infoRowsSummaryVicePremiers,
      },
      [EGridTabs.MINISTRIES]: {
        csvData: summaryMinistriesTableDataExport,
        fileName: "Сводная таблица. Министерства",
        wscols: summaryMinistriesWscols,
        headings: summaryMinistriesHeadings,
        mergeCells: summaryMinistriesMergeCells,
        infoRows: infoRowsSummaryMinistries,
      },
    };
    return exportData[complexGridTab];
  }, [complexGridTab, summaryProgramsTableDataFiltered, premiersData]);

  const tabs: ITab<EGridTabs>[] = [
    {
      label: "Госпрограммы",
      value: EGridTabs.PROGRAMS,
      component: (
        <GeneralFullInfoTab
          loading={fetchPR}
          forwardRef={forwardRef}
          onScroll={onScroll}
          tableData={summaryProgramsTableDataFiltered}
          program={program}
          setSelectedProgram={setSelectedProgram}
          isTableModalOpen={isGovProgramsModalOpen}
          setTableModalOpen={setGovProgramsModalOpen}
          generalInfoVicePremiersFilter={generalInfoVicePremiersFilter}
          setGeneralInfoVicePremiersFilter={setGeneralInfoVicePremiersFilter}
          generalInfoFoivFilter={generalInfoFoivFilter}
          setGeneralInfoFoivFilter={setGeneralInfoFoivFilter}
          generalInfoVicePremiersFilterValues={
            generalInfoVicePremiersFilterValues
          }
          generalInfoFoivFilterValues={generalInfoFoivFilterValues}
          downloadData={summaryProgramsTableDataExport}
          infoRows={infoRowsSummaryPrograms}
          instance={govProgramsInstance}
          setInstance={setGovProgramsInstance}
        />
      ),
    },
    {
      label: "Вице-премьеры",
      value: EGridTabs.PREMIERS,
      component: (
        <GeneralFullInfoPremiersTab
          loading={fetchVP}
          forwardRef={forwardRef}
          onScroll={onScroll}
          tableData={premiersData}
          vp={vp}
          setSelectedVP={setSelectedVP}
          isTableModalOpen={isVicePremiersModalOpen}
          setTableModalOpen={setVicePremiersModalOpen}
          downloadData={summaryVicePremiersTableDataExport}
          infoRows={infoRowsSummaryVicePremiers}
          instance={vicePremiersInstance}
          setInstance={setVicePremiersInstance}
        />
      ),
    },
    {
      label: "Министерства",
      value: EGridTabs.MINISTRIES,
      wip: true,
      component: (
        <GeneralFullInfoMinistriesTab
          loading={fetchMinistries}
          forwardRef={forwardRef}
          onScroll={onScroll}
          tableData={ministriesData}
          ministry={ministry}
          setSelectedMinistry={setSelectedMinistry}
          isTableModalOpen={isMinistriesModalOpen}
          setTableModalOpen={setMinistriesModalOpen}
          downloadData={summaryMinistriesTableDataExport}
          infoRows={infoRowsSummaryMinistries}
          instance={ministriesInstance}
          setInstance={setMinistriesInstance}
        />
      ),
    },
  ];

  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  // TODO [поправить]: привести к нормальному виду
  const opened: boolean = useMemo(
    () =>
      complexGridTab === EGridTabs.PROGRAMS
        ? isGovProgramsModalOpen
        : complexGridTab === EGridTabs.PREMIERS
        ? isVicePremiersModalOpen
        : isMinistriesModalOpen,
    [
      complexGridTab,
      isGovProgramsModalOpen,
      isVicePremiersModalOpen,
      isMinistriesModalOpen,
    ]
  );

  const setOpen = useCallback(() => {
    const callback =
      complexGridTab === EGridTabs.PROGRAMS
        ? setGovProgramsModalOpen
        : complexGridTab === EGridTabs.PREMIERS
        ? setVicePremiersModalOpen
        : setMinistriesModalOpen;
    return callback(() => true);
  }, [complexGridTab]);

  const tableInstance = useMemo(
    () =>
      complexGridTab === EGridTabs.PROGRAMS
        ? govProgramsInstance
        : complexGridTab === EGridTabs.PREMIERS
        ? vicePremiersInstance
        : ministriesInstance,
    [
      complexGridTab,
      govProgramsInstance,
      vicePremiersInstance,
      ministriesInstance,
    ]
  );

  const staticColumns = useMemo(
    () =>
      complexGridTab === EGridTabs.PROGRAMS
        ? staticGovProgramsColumns
        : complexGridTab === EGridTabs.PREMIERS
        ? staticVicePremiersColumns
        : staticMinistriesColumns,
    [complexGridTab]
  );

  return (
    <Box>
      {matches ? (
        <>
          <Box sx={css.tableHeaderWrapper}>
            <Paper
              sx={css.tableTitle}
              component="h1"
              onClick={clear}
              elevation={0}
            >
              Сводная таблица
            </Paper>
            <ProgramsCounter />
          </Box>
          <Box sx={css.divider} />
          <Box sx={css.selectorsWrapper}>
            <Select
              value={String(complexGridTab)}
              onChange={setSelectedComplexGridTab}
              options={tabs as IOption[]}
              label="Вид"
              hasAllOption={false}
              colorizeActiveOption={false}
              style={selectStyle}
            />
            <Box sx={css.filters}>
              <Filters withCounter={false} />
            </Box>
          </Box>
        </>
      ) : (
        <ExtendedTabs
          value={complexGridTab}
          onChange={tabsHandler}
          tabs={tabs}
          style={{ fontSize: "1.5rem", fontWeight: "bold" }}
          actions={[
            tableInstance && (
              <ColumnsController {...tableInstance} statics={staticColumns} />
            ),
            <TableExportButton {...tabExportData} />,
            <OpenerButton open={opened} setOpen={setOpen} />,
          ]}
        />
      )}
      {matches && components(complexGridTab)}
    </Box>
  );
};
