import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getProgram } from "SRC/redux/slices/gosprogram/api/client";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";
import { ICodeItem } from "SRC/types/data";

export interface IProgramParams {
  period: IPeriod;
  gp_code: string;
}

export interface IProgram {
  code: string;
  "Вице-премьер": string | number;
  "Вице-премьер, фото": string;
  Отраслевая: string;
  Сфера: string;
  Комплексная: string;
  Секретная: string;
  "Вице-премьер, код": string;
  gp_code: string;
  gp_short_name: string;
  gp_icon_url: string;
  Министерство: string;
  gp_name: string;
  data: ICodeItem[];
}

export interface IProgramState {
  items: IProgram[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "govprogram/get",
    async ({ period, gp_code }: IProgramParams) =>
      await getProgram(period, gp_code)
  ),
};

const initialState: IProgramState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

export const slice = createSlice({
  name: "govprogram",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IProgramState, action) => {
        const { data } = action.payload;
        // TODO [пофиксить]: проблема с интерфейсами запросов
        // @ts-ignore
        state.items = (isContainedArray(data) ? data : []) as IProgram[];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
