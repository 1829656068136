import { Box, Button, SxProps, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TableInstance } from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import Table from "SRC/components/Table";
import TableStatusCardNew from "SRC/components/TableStatusCardNew/TableStatusCardNew";
import { TableSearch } from "SRC/pages/Program/common/EventsTab/TableSearch/TableSearch";
import { FilterNameStructure } from "SRC/pages/Program/common/filters/FilterNameStructure";
import { FilterTypeStructure } from "SRC/pages/Program/common/filters/FilterTypeStructure";
import { IStatus } from "SRC/pages/Program/common/ProgramSummary/interfaces";
import { useSEIndicatorsTableData } from "SRC/pages/Program/tableData";
import { useFilters, useIndicators } from "SRC/redux/slices/gosprogram/hooks";

import { css } from "./IndicatorsTab.styled";
import { columns } from "./tableColumns";

interface IIndicatorsTabProps {
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  infoRows: Record<string, string>[][];
}

const Statuses = () => {
  const { indicatorsStatuses: statuses } = useSEIndicatorsTableData();
  const { selectedStatus, setSelectedStatus, clearSelectedStatus } =
    useFilters();

  useEffect(() => () => clearSelectedStatus(), [clearSelectedStatus]);

  const handleClick =
    ({ amount, status }: IStatus) =>
    () => {
      if (amount) {
        setSelectedStatus(status);
      }
    };

  const statusesNodes: JSX.Element[] = useMemo(
    () =>
      statuses.map((item: IStatus) => (
        <TableStatusCardNew
          key={item.status}
          status={item.status}
          amount={item.amount}
          onClick={handleClick(item)}
          selected={selectedStatus.includes(item.status)}
        />
      )),
    [statuses, selectedStatus]
  );

  return <Box sx={css.statusCardContainer}>{statusesNodes}</Box>;
};

const RenderTable: React.FC<IIndicatorsTabProps> = ({
  isTableModalOpen,
}: IIndicatorsTabProps) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const filterStatus = searchParams.get("filter");

  const [instance, setInstance] = useState<TableInstance | undefined>(
    undefined
  );

  const { fetching: indicatorsDataFetched } = useIndicators();
  const { indicatorsTableData } = useSEIndicatorsTableData();

  const { setGlobalFilter } = instance || {};

  const {
    setSelectedStructureElementName,
    setSelectedStructureElementType,
    setSelectedStatus,
    clearSelectedStatus,
  } = useFilters();

  const selectStyle = useMemo(() => css.selector(theme), [theme]);
  const handleClearFilters = () => {
    setSelectedStructureElementType("");
    setSelectedStructureElementName("");
    setGlobalFilter?.("");
    clearSelectedStatus();
  };

  useEffect(() => {
    if (filterStatus && indicatorsDataFetched) {
      setSelectedStatus(filterStatus);
    }
  }, [filterStatus, indicatorsDataFetched]);

  return (
    <Box sx={css.modalContainer}>
      <Box sx={css.filtersContainer(isTableModalOpen) as SxProps}>
        <Box sx={css.searchSelectContainer}>
          <Box sx={{ flex: "1", minWidth: "20rem" }}>
            <TableSearch instance={instance} />
          </Box>
          <FilterTypeStructure
            label="Тип структурного элемента"
            staticLabel={false}
            style={selectStyle}
            onChange={setSelectedStructureElementType}
          />
          <FilterNameStructure
            label="Наименование структурного элемента"
            staticLabel={false}
            style={selectStyle}
            onChange={setSelectedStructureElementName}
          />
          <Button sx={css.filterButton as SxProps} onClick={handleClearFilters}>
            Сбросить фильтры
          </Button>
        </Box>
        <Statuses />
      </Box>
      <Table
        loading={indicatorsDataFetched}
        // @ts-ignore
        columns={columns}
        data={indicatorsTableData}
        getInstance={setInstance}
        sortedColumns={[
          "Период достижения",
          "Факт",
          "План",
          "План на год",
          "Уровень достижения, %",
        ]}
        tableHeight={!isTableModalOpen ? "47.5rem" : undefined}
        selectable={false}
      />
    </Box>
  );
};

export const IndicatorsTab: React.FC<IIndicatorsTabProps> = (props) => {
  const { isTableModalOpen, setTableModalOpen } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  if (!match && isTableModalOpen) {
    return (
      <TableModal
        open={isTableModalOpen}
        setOpen={setTableModalOpen}
        tableName="Показатели СЭ"
      >
        <RenderTable {...props} />
      </TableModal>
    );
  }

  return <RenderTable {...props} />;
};
