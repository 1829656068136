import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getDataSumByCodeGP } from "SRC/constants/dataCodes";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";
import { IProgram } from "SRC/redux/slices/main/slices/programs";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export type TProgram = IProgram;

interface IStats {
  total: number;
  risk: number;
  secret: number;
}

export interface IPrograms {
  fetching: boolean;
  fetched: boolean;
  items: TProgram[];
  load(period: IPeriod): void;
  getItemByCode(code: string): TProgram | undefined;
  stats: IStats;
  error: SerializedError | null;
}

export const usePrograms = (): IPrograms => {
  const [programs, setPrograms] = useState<TProgram[]>([]);
  const dispatch = useAppDispatch();
  const {
    fetching,
    items = [],
    fetched,
  } = useAppSelector((state) => state.main.programs);
  const [error, setError] = useState<SerializedError | null>(null);
  useEffect(() => {
    if (fetched) {
      const sortedByGpCode = [...items];
      sortedByGpCode.sort(({ gp_code: gp_code_a }, { gp_code: gp_code_b }) =>
        Number(gp_code_a) < Number(gp_code_b) ? -1 : 1
      );

      setPrograms(sortedByGpCode);
    }
  }, [items, fetched]);

  const load = useCallback(
    (params) => {
      if (!fetching) {
        dispatch(actions.programs.get(params)).then((action) => {
          if (actions.programs.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  const getItemByCode = (code: string): TProgram | undefined =>
    programs.find((program) => program.gp_code === code);

  const stats: IStats = useMemo(
    () =>
      programs.reduce(
        (acc, pr) => ({
          ...acc,
          risk: acc.risk + (getDataSumByCodeGP(pr, 1843) || 0),
          secret: acc.secret + +pr["Секретная"],
        }),
        {
          total: programs.length,
          risk: 0,
          secret: 0,
        }
      ),
    [programs]
  );

  return {
    fetching,
    fetched,
    items: programs,
    getItemByCode,
    stats,
    load,
    error,
  };
};
