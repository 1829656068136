import { Theme } from "@mui/material";

export const css = {
  item: (theme: Theme) => ({
    background: theme.palette.complementary.darkBlueLight,
    border: `0.25rem solid ${theme.palette.complementary.darkBlue}`,
    padding: "1.5rem 2rem",
    borderRadius: "1.5rem",
    fontSize: "1.375rem",
    fontWeight: 700,
    lineHeight: "130%",
    position: "relative",
    textAlign: "left",
    minHeight: "7.875rem",
    maxHeight: "7.875rem",
    marginRight: "0.5rem",
    cursor: "pointer",
  }),
  name: (active: boolean) => ({
    color: (theme: Theme) =>
      active
        ? theme.palette.primary.contrastText
        : theme.palette.complementary.grayBlue,
    fontWeight: 700,
    lineHeight: "1.2em",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    transition: "0.2s ease-in",
  }),
  unit: {
    color: (theme: Theme) => theme.palette.complementary.grayBlue,
  },
  point: (active: boolean) => ({
    backgroundColor: (theme: Theme) =>
      active
        ? theme.palette.primary.contrastText
        : theme.palette.complementary.grayBlue,
    display: "block",
    width: "1rem",
    height: "1rem",
    border: (theme: Theme) =>
      `0.25rem solid ${theme.palette.complementary.darkBlue}`,
    borderRadius: "50%",
    position: "absolute",
    right: "-0.6rem",
    top: "50%",
    transform: "translateY(-50%)",
    transition: "0.2s ease-in",
  }),
};
