import executor from "ASSETS/non-usage/executors/Ministery_RF.svg";
import { isSecret } from "SRC/helpers/dataTesters";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { IEventItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import { IndicatorItem } from "SRC/pages/Program/common/IndicatorsTab/interface";
import { IStatus } from "SRC/pages/Program/common/ProgramSummary/interfaces";
import { IProgramEvent } from "SRC/redux/slices/gosprogram/slices/events";
import { IProgramIndicator } from "SRC/redux/slices/gosprogram/slices/indicators";
import { IProgram } from "SRC/redux/slices/gosprogram/slices/program";
import {
  EVENT_CODE,
  EVENT_STATUS,
  EVENT_STATUSES_DEFAULT,
  ICodeItem,
  IEventStatuses,
  INDICATOR_STATUSES_DEFAULT,
  TEventStatus,
  TSEIndicatorStatus,
} from "SRC/types";

export const isStatusMatch = (selectedStatus: string[], item: IEventItem) => {
  return selectedStatus.every((selectedStatus) => {
    return item.status[selectedStatus as TEventStatus];
  });
};

export const getEventStatuses = (eventData: ICodeItem[]) => {
  return Object.entries(EVENT_CODE).reduce(
    (acc: IEventStatuses, [key, code]) => {
      const keySum = eventData.find((item) => item.code === code)?.sum ?? 0;

      return {
        ...acc,
        [EVENT_STATUS[key as keyof typeof EVENT_STATUS]]: keySum,
      };
    },
    EVENT_STATUSES_DEFAULT
  );
};

const features = {
  34: "Признак_основные",
  35: "Признак_не основные",
  36: "Признак_ОКС онлайн",
  37: "Признак_Реализация в субъектах",
  38: "Признак_СЗП",
  39: "Признак_ФХД",
  40: "Признак_КТ",
  41: "Признак_ОКС",
  42: "Признак_ЛЮГ",
  43: "Признак_СМНП",
};

export const createEventsTableData = (data: IProgramEvent[]): IEventItem[] =>
  data.map((item: IProgramEvent, index) => ({
    id: item.code,
    index: index + 1,
    name: item.NAME,
    units: item["Ед. измерения результата"],
    structureElement: item.data.find((it) => Number(it.code) === 120)?.text,
    structureElementName: item["Наименование проекта"],
    plan: roundNumbersToFixed(
      Number(item.data.find((it) => Number(it.code) === 0)?.sum),
      0
    ),
    fact: roundNumbersToFixed(
      Number(item.data.find((it) => Number(it.code) === 1)?.sum),
      0
    ),
    done: roundNumbersToFixed(
      Number(item.data.find((it) => Number(it.code) === 2)?.sum),
      0
    ),
    effect: roundNumbersToFixed(
      Number(item.data.find((it) => Number(it.code) === 29)?.sum),
      0
    ),
    atRisk: item.data.find((it) => Number(it.code) === 17)?.sum || 0,
    status: getEventStatuses(item.data),
    features: Object.entries(features)
      .map(([code, feat]) =>
        item.data.find((it) => Number(it.code) === +code)?.sum ? feat : ""
      )
      .filter(Boolean),
  }));

// TODO [заполнить]: подставлены тестовые данные
export const createIndicatorsTableData = (
  data: IProgramIndicator[]
): IndicatorItem[] =>
  data.map((item: IProgramIndicator, index) => ({
    id: item.code,
    index: index + 1,
    name: item.NAME,
    units: item["Ед. измерения результата"],
    structureElement: "Тип",
    structureElementName: item["Наименование проекта"],
    plan: 0,
    yearPlan: 0,
    fact: 0,
    done: 0,
    period: 0,
    status: EVENT_STATUS.NOT_DONE,
  }));

export const getProgramIconUrl = (currentProgram: IProgram): string => {
  return currentProgram?.gp_icon_url;
};

export const getProgramType = (currentProgram: IProgram): string => {
  return Number(currentProgram["Комплексная"])
    ? "Комплексная"
    : Number(currentProgram["Отраслевая"])
    ? "Отраслевая"
    : isSecret(currentProgram)
    ? "Секретная"
    : "";
};

export const getHeaderData = (
  currentProgram: IProgram,
  programType: string,
  programIconUrl: string
) => {
  return {
    header: {
      id: currentProgram.gp_code,
      name: currentProgram.gp_name,
      programType: programType,
      icon: programIconUrl,
      programSecret: isSecret(currentProgram),
    },
    curator: {
      name: currentProgram["Вице-премьер"],
      photo: currentProgram["Вице-премьер, фото"],
      executorPhoto: executor, //TODO получить урл фото исполнителя госпрограммы
      executorName: currentProgram["Министерство"],
    },
  };
};

export const getCurrentProgram = (program: IProgram[]) => program[0] || {};

const indicatorKeys = {
  // Период
  5211: "period",
  // Статус показателя
  5214: "status",
  // Структурный элемент ГП
  5219: "se_gp",
  // Тип структурного элемента ГП
  5224: "se_gp_type",
  // Задача структурного элемента ГП
  5228: "se_task",
  // Показатель структурного элемента ГП
  5234: "se_indicator",
  // План
  5240: "plan",
  // Факт
  5241: "fact",
  // Факт, цвет
  5242: "fact_color",
  // План на год
  5243: "plan_year",
  // Уровень достижения
  5244: "achievement",
  // Уровень достижения, цвет
  5245: "achievement_color",
  // Показатели
  5239: "indicators",
};

export const getIndicatorStatuses = (tableData: any[]) => {
  const totalByStatus = tableData.reduce(
    (acc: Partial<Record<TSEIndicatorStatus, IStatus>>, item) => {
      const status = item.status as TSEIndicatorStatus;

      return {
        ...acc,
        [status]: {
          status,
          amount: (acc[status]?.amount || 0) + 1,
        },
      };
    },
    INDICATOR_STATUSES_DEFAULT
  );

  return Object.values(totalByStatus);
};

const parseIndicators = (indicatorVals: any) => {
  return Object.entries(indicatorVals || {}).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [indicatorKeys[key as unknown as keyof typeof indicatorKeys]]:
        (value as Record<any, any>).sum || 0,
    };
  }, {});
};

const getDictionaryItemAttributeName = (dictionaryItem: any) => {
  return dictionaryItem.attributeVals.NAME;
};

const getDictionaryItemCode = (dictionaryItem: any) => {
  return dictionaryItem.code;
};

const parseDims = (dims: any, dictionary: any) => {
  return Object.entries(dims || {}).reduce((acc, [key, value]) => {
    const indicatorKey = key as unknown as keyof typeof indicatorKeys;
    const dictionaryKey = key as keyof typeof dictionary;

    let dimValue;

    if (Number(key) === 5234) {
      dimValue = getDictionaryItemCode(
        dictionary[dictionaryKey][value as string]
      );
    } else {
      dimValue = getDictionaryItemAttributeName(
        dictionary[dictionaryKey][value as string]
      );
    }

    return {
      ...acc,
      [indicatorKeys[indicatorKey]]: dimValue,
    };
  }, {});
};

export const createSeIndicatorsTableData = (items: any) => {
  const { data = [], dimensionItems: dictionary } = items || {};

  return data.map((item: any, index: number) => {
    const { indicatorVals, dims } = item;

    return {
      index: index + 1,
      ...parseIndicators(indicatorVals),
      ...parseDims(dims, dictionary),
    };
  });
};
