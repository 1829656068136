import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { ISubjectItem } from "SRC/pages/Event/interfaces";
import { IEventParams } from "SRC/redux/slices/event/slices/filters";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../../event/actions";

export interface ISubjects {
  fetching: boolean;
  fetched: boolean;
  items: ISubjectItem[];
  load(params: IEventParams): void;
  error: SerializedError | null;
}

export const useSubjects = (): ISubjects => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: subjects = [],
    fetched,
  } = useAppSelector((state) => state.event.subjects);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (params) => {
      if (!fetching) {
        dispatch(actions.subjects.get(params)).then((action) => {
          if (actions.subjects.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: subjects,
    load,
    error,
  };
};
