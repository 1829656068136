import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { ECalculationMethod } from "SRC/constants/globals";

export interface IPeriod {
  value: null | Date;
  filterValue: string;
  apiV2: string;
}

export interface IFiltersState {
  program: string;
  vp: string;
  goal: string;
  foiv: string;
  ministry: string;
  sphere: string;
  period: IPeriod;
  viewChart: number;
  centerTab: string;
  complexGridTab: string;
  typeProgram: string;
  calculationMethod: ECalculationMethod;
  withSecret: boolean;
}

const initialState: IFiltersState = {
  program: "",
  vp: "",
  goal: "",
  foiv: "",
  ministry: "",
  sphere: "",
  period: {
    value: new Date(),
    filterValue: `${moment().year()}00`,
    apiV2: `${moment().year()}`,
  },
  viewChart: 1,
  centerTab: "analytics",
  complexGridTab: "govPrograms",
  typeProgram: "",
  calculationMethod: ECalculationMethod.RESULT,
  withSecret: true,
};

const slice = createSlice({
  name: "filtersMain",
  initialState,
  reducers: {
    clear: (state) => {
      const { period, withSecret } = state;

      return {
        ...initialState,
        period,
        withSecret,
      };
    },
    selectProgram: (state, action: { payload: string }) => {
      state.program = action.payload;
    },
    selectVP: (state, action: { payload: string }) => {
      state.vp = action.payload;
    },
    selectMinistry: (state, action: { payload: string }) => {
      state.ministry = action.payload;
    },
    selectSphere: (state, action: { payload: string }) => {
      state.sphere = action.payload;
    },
    selectGoal: (state, action: { payload: string }) => {
      state.goal = action.payload;
    },
    selectFoiv: (state, action: { payload: string }) => {
      state.foiv = action.payload;
    },
    selectTypeProgram: (state, action: { payload: string }) => {
      state.typeProgram = action.payload;
    },
    selectPeriod: (state, action: { payload: IPeriod }) => {
      state.period = action.payload;
    },
    toggleChart: (state, action: { payload: number }) => {
      state.viewChart = action.payload;
    },
    setCenterTab: (state, action: { payload: string }) => {
      state.centerTab = action.payload;
    },
    setComplexGridTab: (state, action: { payload: string }) => {
      state.complexGridTab = action.payload;
    },
    setCalculationMethod: (state, action: { payload: ECalculationMethod }) => {
      state.calculationMethod = action.payload;
    },
    toggleSecret: (state, action: { payload: boolean }) => {
      state.withSecret = action.payload;
    },
  },
});

export default slice;
