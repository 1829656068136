import { olapRequestData3, olapRequestData4 } from "CORE/api/core";
import { getEventIndicators } from "SRC/constants/dataCodes";
import { IEventParams } from "SRC/redux/slices/gosprogram/slices/filters";

const GET_CONTROL_POINTS = "get-control-points";
const GET_CASH_EXECUTION = "get-cash-execution";
const GET_SUBJECTS = "get-subjects";
const GET_LIST_OF_OBJECTS = "get-list-of-objects";
const GET_EVENT_DATA = "get-event-data";
const GET_OBJECT_PHOTOS = "get-object-photos";
const GET_OBJECT_VIDEOS = "get-object-videos";

export const getControlPoints = (params: IEventParams) => {
  return olapRequestData3(
    [
      {
        requestId: "4bdb46ae-2ba5-48ad-9156-3cec83a0a86f",
        type: "data2",
        params: {
          modelUuid: "9470732b-770c-4da1-b53e-da810caeb9fb",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1369",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1374",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1379",
            items: [
              {
                id: "1380",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1381",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1504",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1505",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1729",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1830",
                aggregationFunction: "sum",
              },
              {
                id: "1831",
                stringAggregation: true,
                separator: ";",
              },
            ],
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1369",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1374",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1369",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [params.period.apiV2],
                  },
                ],
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 2,
                    id: "1374",
                    attributeCode: "R_ID",
                  },
                  {
                    type: "TEXT",
                    version: 2,
                    values: [params.id],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_CONTROL_POINTS
  );
};

export const getCashExecution = (params: IEventParams) => {
  return olapRequestData4(
    [
      {
        requestId: "d9ad9f1b-2287-49e9-9b55-abda2f0bc2a8",
        type: "data2",
        params: {
          modelUuid: "45c0a0bb-d4ed-4541-ba6f-088aef4e5511",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1427",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1432",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1436",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1441",
            items: [
              {
                id: "1442",
                aggregationFunction: "sum",
              },
              {
                id: "1443",
                aggregationFunction: "sum",
              },
              {
                id: "1677",
                aggregationFunction: "sum",
              },
            ],
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1427",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1432",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1436",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1436",
              },
              {
                type: "AND",
                version: 1,
                operands: [
                  {
                    type: "EQ",
                    version: 1,
                    operands: [
                      {
                        type: "DIM",
                        version: 1,
                        id: "1427",
                      },
                      {
                        type: "CONST",
                        version: 1,
                        values: [params.period.apiV2],
                      },
                    ],
                  },
                  {
                    type: "EQ",
                    version: 1,
                    operands: [
                      {
                        type: "DIM",
                        version: 2,
                        id: "1432",
                        attributeCode: "R_ID",
                      },
                      {
                        type: "TEXT",
                        version: 2,
                        values: [params.id],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_CASH_EXECUTION
  );
};

export const getSubjects = (params: IEventParams) => {
  return olapRequestData3(
    [
      {
        requestId: "ae874083-032a-4010-a5c7-81c19792d3e6",
        type: "data2",
        params: {
          modelUuid: "46a04ab2-5e97-49b8-b42d-8844ece88440",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1405",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1410",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1414",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1420",
            items: [
              {
                id: "1421",
                aggregationFunction: "sum",
              },
              {
                id: "1422",
                aggregationFunction: "sum",
              },
              {
                id: "1423",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1424",
                stringAggregation: true,
                separator: ";",
              },
            ],
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1405",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1410",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1414",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1414",
              },
              {
                type: "AND",
                version: 1,
                operands: [
                  {
                    type: "EQ",
                    version: 1,
                    operands: [
                      {
                        type: "DIM",
                        version: 1,
                        id: "1405",
                      },
                      {
                        type: "CONST",
                        version: 1,
                        values: [params.period.apiV2],
                      },
                    ],
                  },
                  {
                    type: "EQ",
                    version: 1,
                    operands: [
                      {
                        type: "DIM",
                        version: 2,
                        id: "1410",
                        attributeCode: "R_ID",
                      },
                      {
                        type: "TEXT",
                        version: 2,
                        values: [params.id],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_SUBJECTS
  );
};

export const getListOfObjects = (params: IEventParams) => {
  return olapRequestData3(
    [
      {
        requestId: "a44d854c-f0b5-4ca5-88f7-6af47f4dcbf1",
        type: "data2",
        params: {
          modelUuid: "6f061c05-5d00-42e9-a92c-d1b59b2bb718",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1382",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1387",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1662",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1402",
            items: [
              {
                id: "1403",
                aggregationFunction: "sum",
              },
              {
                id: "1404",
                aggregationFunction: "sum",
              },
              {
                id: "1455",
                aggregationFunction: "sum",
              },
              {
                id: "1456",
                aggregationFunction: "sum",
              },
              {
                id: "1462",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1463",
                stringAggregation: true,
                separator: ";",
              },
            ],
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1382",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1387",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1662",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1387",
              },
              {
                type: "AND",
                version: 1,
                operands: [
                  {
                    type: "EQ",
                    version: 1,
                    operands: [
                      {
                        type: "DIM",
                        version: 1,
                        id: "1382",
                      },
                      {
                        type: "CONST",
                        version: 1,
                        values: [params.period.apiV2],
                      },
                    ],
                  },
                  {
                    type: "EQ",
                    version: 1,
                    operands: [
                      {
                        type: "DIM",
                        version: 2,
                        id: "1662",
                        attributeCode: "R_ID",
                      },
                      {
                        type: "TEXT",
                        version: 2,
                        values: [params.id],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_LIST_OF_OBJECTS
  );
};

export const getEventData = (params: IEventParams) => {
  return olapRequestData4(
    [
      {
        requestId: "20983682-8107-4261-9f13-c7a107412af4",
        type: "data2",
        params: {
          modelUuid: "f0c00f6b-d2fa-4a9a-9f57-258a0e88806d",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1344",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1348",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "Ед. измерения результата",
                "Ед. измерения бюджета",
                "SortOrder",
                "Наименование проекта",
              ],
            },
          ],
          indicators: {
            id: "1351",
            items: getEventIndicators(
              1352,
              1358,
              1359,
              1360,
              1361,
              1362,
              1363,
              1364,
              1365,
              1366,
              1367,
              1471,
              1750,
              1823,
              1824,
              1825,
              1826,
              1827
            ),
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1344",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1348",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1344",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [params.period.apiV2],
                  },
                ],
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 2,
                    id: "1348",
                    attributeCode: "R_ID",
                  },
                  {
                    type: "TEXT",
                    version: 2,
                    values: [params.id],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_EVENT_DATA
  );
};

export const getObjectPhotos = (params: IEventParams) => {
  return olapRequestData3(
    [
      {
        requestId: "0cce9e0d-f40b-44d5-a627-2fe7d20deac9",
        type: "data2",
        params: {
          modelUuid: "6f061c05-5d00-42e9-a92c-d1b59b2bb718",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1382",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1387",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1402",
            items: [
              {
                id: "1457",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1458",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1459",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1850",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1851",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1852",
                stringAggregation: true,
                separator: ";",
              },
            ],
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1382",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1387",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1382",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [params.period.apiV2],
                  },
                ],
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 2,
                    id: "1387",
                    attributeCode: "Код ОКС",
                  },
                  {
                    type: "TEXT",
                    version: 2,
                    values: [params.id],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_OBJECT_PHOTOS
  );
};

export const getObjectVideos = (params: IEventParams) => {
  return olapRequestData3(
    [
      {
        requestId: "e629b294-3951-45b2-b762-6e0b2f455dbf",
        type: "data2",
        params: {
          modelUuid: "6f061c05-5d00-42e9-a92c-d1b59b2bb718",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1382",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1387",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1402",
            items: [
              {
                id: "1456",
                aggregationFunction: "sum",
              },
              {
                id: "1467",
                aggregationFunction: "sum",
              },
            ],
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1382",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1387",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 2,
                    id: "1387",
                    attributeCode: "Код ОКС",
                  },
                  {
                    type: "TEXT",
                    version: 2,
                    values: [params.id],
                  },
                ],
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1382",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [params.period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_OBJECT_VIDEOS
  );
};
