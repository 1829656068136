import { CircularProgress, SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { Overlay } from "SRC/pages/App.styled";
import { useStatistics } from "SRC/redux/slices/gosprogram/hooks/useStatistics";

import { LineProgressEvents } from "../LineProgressEvents/LineProgressEvents";
import { css } from "./EventsInfo.styled";

interface IEventsData {
  events: {
    name: string;
    total: number;
    done: number;
    atRisk: number;
    units: string;
    percentage: number;
  };
  objectsCreated: {
    name: string;
    total: number;
    done: number;
    atRisk?: number;
    units: string;
    percentage: number;
  };
  npa: {
    name: string;
    total: number;
    done: number;
    atRisk: number;
    units: string;
    percentage: number;
  };
}

export const EventsInfo = () => {
  const { items: statistics, fetching } = useStatistics();
  const { events, objectsCreated, npa }: IEventsData = useMemo(
    () => ({
      events: {
        name: "Мероприятия",
        total: statistics["Мероприятий всего"],
        done: statistics["Мероприятий выполнено"],
        atRisk: statistics["Мероприятий под риском"],
        units: "ед.",
        percentage: roundNumbersToFixed(
          statistics["Мероприятий выполнено, %"],
          0
        ),
      },
      objectsCreated: {
        name: "Создано объектов",
        total: statistics["Создано объектов, план"],
        done: statistics["Создано объектов, факт"],
        units: "ед.",
        percentage: roundNumbersToFixed(statistics["Создано объектов, %"], 0),
      },
      npa: {
        name: "НПА",
        total: statistics["Нормативно-правовые акты, план"],
        done: statistics["Нормативно-правовые акты, факт"],
        atRisk: statistics["Нормативно правовые акты, под риском"],
        units: "ед.",
        percentage: roundNumbersToFixed(
          statistics["Нормативно правовые акты, %"],
          0
        ),
      },
    }),
    [statistics]
  );

  if (fetching)
    return (
      <Box sx={css.container as SxProps}>
        <Box sx={css.container as SxProps}>
          <Overlay>
            <CircularProgress />
          </Overlay>
        </Box>
      </Box>
    );

  return (
    <Box sx={css.container as SxProps}>
      <LineProgressEvents
        nameValue={events.name}
        unit={events.units}
        percentage={events.percentage}
        value={events.done}
        total={events.total}
      />
      <LineProgressEvents
        nameValue={objectsCreated.name}
        unit={objectsCreated.units}
        percentage={objectsCreated.percentage}
        value={objectsCreated.done}
        total={objectsCreated.total}
      />
      <LineProgressEvents
        nameValue={npa.name}
        unit={npa.units}
        percentage={npa.percentage}
        value={npa.done}
        total={npa.total}
      />
    </Box>
  );
};
