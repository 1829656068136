import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  textContainer: {
    fontSize: "1rem",

    "p.grayBlueText": {
      color: (theme: Theme) => theme.palette.text.primary,
    },
    "p.whiteText": {
      color: (theme: Theme) => theme.palette.text.onAccent,
    },
    "p.bigText": {
      fontSize: "1.5rem",
    },
  },
  progressbarHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  legend: {
    display: "flex",
    gap: "0.4rem",
    alignItems: "baseline",

    "p.legendText": {
      fontSize: "0.8rem",
    },
  },
  dot: {
    width: "0.5rem",
    height: "0.5rem",
    borderRadius: "50%",

    "&.green": {
      background: (theme: Theme) => theme.palette.success.light,
    },
    "&.red": {
      background: (theme: Theme) => theme.palette.error.light,
    },
  },
};
