import { Box, Typography } from "@mui/material";
import React, { Fragment, MutableRefObject } from "react";
import MethodSwitcher from "SRC/components/MethodSwitcher/MethodSwitcher";
import { SummaryComplexGrid } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid";
import { useFilters } from "SRC/redux/slices/summary/hooks/useFilters";

interface ICenterLayoutProps {
  isLargeSize: boolean;
  forwardRef: MutableRefObject<HTMLElement | undefined> | undefined;
  onScroll?: ((scroll: any) => void) | undefined;
  setSortBy: (sortBy: any) => void;
}
export const LeftLayout = ({
  isLargeSize,
  forwardRef,
  onScroll,
  setSortBy,
}: ICenterLayoutProps) => {
  const { setCalculationMethod, calculationMethod } = useFilters();
  return (
    <Fragment>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h1" component={"h1"}>
          Сводная таблица
        </Typography>
        <MethodSwitcher
          value={calculationMethod}
          onChange={setCalculationMethod}
        />
      </Box>
      <SummaryComplexGrid
        setSortBy={setSortBy}
        forwardRef={forwardRef}
        onScroll={onScroll}
      />
    </Fragment>
  );
};
