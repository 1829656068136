import {
  Box,
  Button,
  Checkbox,
  Grid,
  Modal,
  SxProps,
  Typography,
} from "@mui/material";
import { Close } from "ASSETS/svg/close";
import React, { useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { ColumnInstance } from "react-table";

import { css } from "./ColumnsController.styled";

interface ITableModalProps<D extends object = {}> {
  allColumns: ColumnInstance<D>[];
  statics?: string[];
}

const DraggableModal: any = Draggable;

const ColumnsController = <T extends object>({
  allColumns,
  statics,
}: ITableModalProps<T>) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [columns, setColumns] = useState<ColumnInstance<T>[]>([]);

  useEffect(() => {
    setColumns(allColumns);
  }, [allColumns]);

  const chooseAll = () => {
    setColumns(allColumns);
  };
  const resetAll = () => {
    setColumns(allColumns.filter((item) => statics?.includes(item.id)));
  };

  const submit = () => {
    allColumns.forEach((item) => item.toggleHidden(!columns.includes(item)));
    setOpen(false);
  };

  const onCheckboxChange = (column: ColumnInstance<T>) => {
    if (columns.includes(column)) {
      setColumns(columns.filter((el) => el.id !== column.id));
    } else {
      setColumns([...columns, column]);
    }
  };

  const renderOptions = useMemo(() => {
    return allColumns
      .filter((item) => !statics?.includes(item.id))
      .map((column) => {
        return (
          <Box key={column.id} sx={css.checkBoxContainer}>
            <Checkbox
              checked={Boolean(columns.includes(column))}
              onChange={() => onCheckboxChange(column)}
            />
            <Typography sx={css.label}>
              {[column.parent?.Header, column.Header]
                .filter(Boolean)
                .join(": ")}
            </Typography>
          </Box>
        );
      });
  }, [allColumns, columns]);

  return (
    <>
      <Button sx={css.columnsButton as SxProps} onClick={handleOpen}>
        Столбцы
      </Button>
      <DraggableModal>
        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          componentsProps={{
            backdrop: {
              style: css.backdrop,
            },
          }}
        >
          <Box sx={css.modalBox as SxProps}>
            <Grid container sx={css.modalHeader}>
              <Grid item>
                <Typography sx={css.modalName}>Столбцы</Typography>
              </Grid>
              <Grid item>
                <Box sx={css.closeButton} onClick={handleClose}>
                  <Close />
                </Box>
              </Grid>
            </Grid>
            <Box sx={css.checkboxes}>{renderOptions}</Box>
            <Grid container sx={css.modalFooter}>
              <Grid item xs={3}>
                <Button sx={css.button as SxProps} onClick={chooseAll}>
                  Выбрать все
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button sx={css.button as SxProps} onClick={resetAll}>
                  Снять все выделения
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button sx={css.outlinedButton as SxProps} onClick={submit}>
                  Применить
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </DraggableModal>
    </>
  );
};

export default ColumnsController;
