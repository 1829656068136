import { styled } from "@mui/material";

export const NationalGoalsTableWrapper = styled("div")(({ theme }) => ({
  width: "100%",

  [theme.breakpoints.up("lg")]: {
    marginTop: "1rem",
  },
}));

export const NationalGoalsTableContainer = styled("div")(({ theme }) => ({
  maxWidth: theme.breakpoints.values.md,
  width: "100%",
  margin: "auto",
}));

export const css = {
  programListContainer: {
    maxHeight: "16.5rem",
    "overflow-y": "scroll",
    margin: "0 -1rem -1rem -1rem",
    height: "100%",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  achievementContainer: {
    padding: "0 1rem",
    width: "60%",
    margin: "auto",
  },
};
