import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IMethod } from "SRC/redux/slices/main/slices/methods";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export type TMethod = IMethod;
export interface IMethods {
  fetching: boolean;
  fetched: boolean;
  items: TMethod;
  load(): void;
  error: SerializedError | null;
}

export const useMethods = (): IMethods => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: methods = {},
    fetched,
  } = useAppSelector((state) => state.main.methods);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(() => {
    if (!fetching) {
      dispatch(actions.methods.get()).then((action) => {
        if (actions.methods.get.rejected.match(action)) {
          setError(action.error);
        }
        return action;
      });
    }
  }, [dispatch, fetching]);

  return {
    fetching,
    fetched,
    items: methods,
    load,
    error,
  };
};
