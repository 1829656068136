import { Button } from "@mui/material";
import { Download } from "ASSETS/svg";
import FileSaver from "file-saver";
import React from "react";
import { Range } from "xlsx";
import * as XLSX from "xlsx";

import { css } from "./Buttons.styles";

export interface IExportData<CSV> {
  csvData: CSV[];
  fileName: string;
  wscols: Record<string, number>[];
  headings: Record<string, string | object>[];
  mergeCells?: Range[] | undefined;
  infoRows?: Record<string, string>[][];
}

export const TableExportButton = <T extends object>({
  csvData,
  fileName,
  wscols,
  headings,
  mergeCells,
  infoRows,
}: IExportData<T>) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (
    csvData: T[],
    fileName: string,
    wscols: Record<string, number>[]
  ) => {
    const ws = XLSX.utils.json_to_sheet(headings, {
      skipHeader: true,
    });
    ws["!cols"] = wscols;
    ws["!merges"] = mergeCells;

    if (infoRows) {
      for (let i = 0; i < infoRows[0].length; i++) {
        ws[`B${i + 1}`] = infoRows[0][i];
        ws[`C${i + 1}`] = infoRows[1][i];
      }
    }

    XLSX.utils.sheet_add_json(ws, csvData, {
      skipHeader: true,
      origin: "A11",
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      sx={css.button()}
      onClick={() => exportToCSV(csvData, fileName, wscols)}
    >
      <Download />
    </Button>
  );
};

export default TableExportButton;
