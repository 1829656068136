import { Theme } from "@mui/material";
import { selectorMixin } from "SRC/components/FilterForms/MainHeaderFilter/MainHeaderFilter.styled";

export const css = {
  selector: selectorMixin,
  filtersContainer: (isTableModalOpen?: boolean) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    marginTop: isTableModalOpen ? "1rem" : "0rem",
  }),
  searchSelectContainer: {
    display: "flex",
    gap: "1rem",
  },
  statusCardContainer: {
    display: "flex",
    height: "100%",
    gap: "1rem",
    flex: 1,
  },
  filterButton: {
    color: (theme: Theme) => theme.palette.primary.contrastText,
    background: (theme: Theme) => theme.palette.complementary.darkGrayBlue,
    borderRadius: "0.5rem",
    fontSize: "1rem !important",
    fontWeight: "400",
    textTransform: "none",
    height: "2.5rem",
    padding: "0.6rem 1.125rem",
  },

  modalContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "hidden",
    overflowX: "visible",
    padding: "0 1rem",
    gap: "0.5rem",
  },
};
