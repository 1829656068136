import { Box, Typography } from "@mui/material";
import React, { Fragment, useMemo } from "react";
import { isSecret } from "SRC/helpers/dataTesters";
import GoalsTab from "SRC/pages/Program/common/GoalsTab/GoalsTab";
import { GovProgramInfoWidget } from "SRC/pages/Program/common/GovProgramInfoWidget/GovProgramInfoWidget";
import { ProgramSummary } from "SRC/pages/Program/common/ProgramSummary";
import { getCurrentProgram } from "SRC/pages/Program/utils";
import { useProgram } from "SRC/redux/slices/gosprogram/hooks/useProgram";
import { useProgramGoals } from "SRC/redux/slices/gosprogram/hooks/useProgramGoals";

import { css } from "./center-layout.styled";

interface ICenterLayoutProps {
  isExtraLarge: boolean;
}
export const CenterLayout = ({ isExtraLarge }: ICenterLayoutProps) => {
  const { items: programGoals } = useProgramGoals();
  const { items: program } = useProgram();

  const isSecretProgram = useMemo(() => {
    const currentProgram = getCurrentProgram(program);

    return isSecret(currentProgram);
  }, [program]);

  return (
    <Box sx={css.centerContentWrapper}>
      {isSecretProgram && (
        <Box sx={css.isSecret}>Секретная Государственная Программа</Box>
      )}
      {!isSecretProgram && (
        <Fragment>
          <Box className="program-card">
            <GovProgramInfoWidget />
          </Box>
          <Box className="program-state">
            {isExtraLarge && (
              <Box sx={css.goalsContainer}>
                <Box sx={css.goalsHeaderContainer}>
                  <Box sx={css.goalsCount}>{programGoals.length}</Box>
                  <Typography sx={css.goalsTitle}>Цели</Typography>
                </Box>
                <GoalsTab />
              </Box>
            )}
            {!isExtraLarge && <ProgramSummary />}
          </Box>
        </Fragment>
      )}
    </Box>
  );
};
