import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
  },
  carouselContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "relative",
  },
  carousel: {
    // margin: "0 1rem",
    display: "flex",
    width: "100%",
  },
  button: (isPrevBtnDisabled?: boolean, isNextBtnDisabled?: boolean) => ({
    position: "absolute",
    top: "50%",
    left: "-1.5rem",
    transform: "translateY(-50%)",
    padding: { sm: "1.2rem 0.7rem", xl: "1rem 0.7rem" },
    borderRadius: "8px",
    background: (theme: Theme) => theme.palette.complementary.grayBlue,
    zIndex: 1,
    display: "flex",
    cursor: isPrevBtnDisabled || isNextBtnDisabled ? "unset" : "pointer",
    opacity: isPrevBtnDisabled || isNextBtnDisabled ? 0 : 1,
    transition: "0.3s ease-in",
    "&:hover": {
      background: (theme: Theme) =>
        isPrevBtnDisabled || isNextBtnDisabled
          ? theme.palette.complementary.grayBlue
          : theme.palette.primary.main,
    },
    "&.nextBtn": {
      right: "-1.5rem",
      left: "auto",
    },
  }),
  defaultItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  programListContainer: {
    maxHeight: "16.5rem",
    overflowY: "scroll",
    margin: "0 -1rem -1rem -1rem",
    height: "100%",
  },
  arrow: {
    height: "0.75rem",
    width: "0.375rem",
  },
  carouselItem: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
    width: "100%",
  },
  titleRightContainer: {
    display: "flex",
    gap: "1rem",
  },
  titleRight: {
    display: "flex",
    alignItems: "center",
    gap: "0.25rem",
  },
  title: {
    fontSize: "1.25rem",
    display: "flex",
    alignItems: "end",
  },
  unit: {
    fontSize: "0.875rem",
    color: (theme: Theme) => theme.palette.complementary.lightWhite,
    display: "flex",
    alignItems: "end",
    mb: "0.1rem",
  },
  detailBtn: (isActiveButton: boolean | undefined) => ({
    display: "flex",
    background: (theme: Theme) => theme.palette.background.buttonDarkBlue,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "0.35rem 0.6rem",
    borderRadius: "0.2rem",
    cursor: "pointer",
    "& svg": {
      width: "0.5rem",
      height: "0.875rem",
    },
    pointerEvents: !isActiveButton ? "none" : "",
  }),
};
