import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMethods } from "SRC/redux/slices/main/api/client";

export interface IMethod {
  custom_function?: string;
  data?: Record<string, string>[];
  dataTotal?: number;
  debug_info?: Record<string, string | number>;
  modelId?: string;
  modelUuid?: string;
  np_transform?: boolean;
}

export interface IMethodsState {
  items: IMethod;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk("methods/get", async () => await getMethods()),
};

const initialState: IMethodsState = {
  items: {},
  fetching: false,
  fetched: false,
  error: "",
};

export const slice = createSlice({
  name: "methods",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = {};
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IMethodsState, action) => {
        const { data } = action.payload;
        // TODO [пофиксить]: проблема с интерфейсами запросов
        // @ts-ignore
        state.items = data;
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
