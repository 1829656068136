import { olapDimensionRequestData } from "CORE/api/core";
import { getDataFiltersOperands } from "SRC/redux/utils";
import { TStructureElementsIndicatorsRequest } from "SRC/types";

const DATA_FILTERS = {
  gp_code: (gp_code: string[]) => {
    return {
      type: "CONTAINS",
      version: 2,
      operands: [
        {
          type: "DIM",
          version: 2,
          id: "5219",
          attributeCode: "StateProgramCode",
        },
        {
          type: "TEXT",
          version: 2,
          values: gp_code,
        },
      ],
    };
  },
  se_code: (se_code: string[]) => {
    return {
      type: "EQ",
      version: 1,
      operands: [
        {
          type: "DIM",
          version: 2,
          id: "5219",
          attributeCode: "SE_CODE",
        },
        {
          type: "TEXT",
          version: 2,
          values: se_code,
        },
      ],
    };
  },
  se_task_code: (se_task_code: string[]) => {
    return {
      type: "EQ",
      version: 1,
      operands: [
        {
          type: "DIM",
          version: 2,
          id: "5228",
          attributeCode: "SE_TASK_CODE",
        },
        {
          type: "TEXT",
          version: 2,
          values: se_task_code,
        },
      ],
    };
  },
  se_type: (se_type: string[]) => {
    return {
      type: "EQ",
      version: 1,
      operands: [
        {
          type: "DIM",
          version: 2,
          id: "5219",
          attributeCode: "SE_TYPE",
        },
        {
          type: "TEXT",
          version: 2,
          values: se_type,
        },
      ],
    };
  },
  period: (period: string[]) => {
    return {
      type: "EQ",
      version: 1,
      operands: [
        {
          type: "DIM",
          version: 2,
          id: "5211",
          attributeCode: "PERIOD2",
        },
        {
          type: "DATE",
          version: 1,
          values: period,
        },
      ],
    };
  },
};
const GET_STRUCTURE_ELEMENTS_INDICATORS = "get-structure-elements-indicators";

// Показатели СЭ
export const getStructureElementsIndicators = (
  params: TStructureElementsIndicatorsRequest
) => {
  const operands = getDataFiltersOperands(params, DATA_FILTERS);
  const request = [
    {
      requestId: "0f99a128-c9c9-4934-a695-3fb2d85dd4fe",
      type: "data2",
      params: {
        modelUuid: "dbd43734-dc29-4fc4-a16a-144efa1a7d62",
        dashboardUuid: "e9453e1c-4e0d-40f9-9751-060c1fc6be2d",
        includeGaps: false,
        dimensions: [
          {
            id: "5214",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "5219",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "5224",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "5234",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "UNIT",
              "IMPLEMENTATION_PERIOD",
            ],
          },
        ],
        indicators: {
          id: "5239",
          items: [
            {
              id: "5240",
              aggregationFunction: "sum",
            },
            {
              id: "5241",
              aggregationFunction: "sum",
            },
            {
              id: "5244",
              aggregationFunction: "sum",
            },
          ],
        },
        dataRequestCaching: true,
        dataFilter: {
          type: "AND",
          version: 1,
          operands,
        },
      },
    },
  ];

  return olapDimensionRequestData(request, GET_STRUCTURE_ELEMENTS_INDICATORS);
};
