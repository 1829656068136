import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useEffect } from "react";
import { useFilters as useMainFilters } from "SRC/redux/slices/main/hooks/useFilters";

import { usePrograms } from "./usePrograms";
import { useSummary } from "./useSummary";

export interface IProgramsBoard {
  loading: boolean;
  loaded: boolean;
  init(): void;
  hasError: SerializedError | null;
}

export const useProgramsBoard = (): IProgramsBoard => {
  const { period } = useMainFilters();
  const {
    fetching: loadingSummary,
    load: loadSummary,
    fetched: loadedSummary,
    error: errorHeaderProgram,
  } = useSummary();
  const {
    fetching: loadingPrograms,
    load: loadPrograms,
    fetched: loadedPrograms,
    error: errorProgramms,
  } = usePrograms();

  const loading = loadingPrograms || loadingSummary;
  const loaded = loadedPrograms || loadedSummary;
  const hasError = errorProgramms || errorHeaderProgram;
  const init = useCallback(() => {
    if (!loading) {
      loadPrograms(period);
      loadSummary(period);
    }
  }, [loading, loadPrograms, loadSummary, period]);

  useEffect(() => {
    init();
  }, [period]);

  return {
    loading,
    loaded,
    init,
    hasError,
  };
};
