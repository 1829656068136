import { Box } from "@mui/material";
import React, { useMemo, useState } from "react";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import Counter from "SRC/components/ProgramsCounter/ProgramsCounter";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { Event } from "SRC/pages/Event/Event";
import { EventsSection } from "SRC/pages/Program/common/EventsSection/EventsSection";
import { IEventItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import { StructureCard } from "SRC/pages/Program/common/StructureCard/StructureCard";
import { getEventStatuses } from "SRC/pages/Program/utils";
import { isStatusMatch } from "SRC/pages/Program/utils";
import { useStructureElements } from "SRC/redux/slices/gosprogram/hooks/structureElements";
import { useFilters } from "SRC/redux/slices/gosprogram/hooks/useFilters";
import { IProgramEvent } from "SRC/redux/slices/gosprogram/slices/events";
import { EVENT_STATUS, PROGRAM_TABS } from "SRC/types";

import {
  IProgramSummaryProps,
  IStatus,
} from "../../common/ProgramSummary/interfaces";

export const RightLayout = ({ events }: IProgramSummaryProps) => {
  const [activeTab, setActiveTab] = useState(1);
  const [openEventCard, setOpenEventCard] = useState(false);

  const { items: structures } = useStructureElements();

  const { selectedStatus } = useFilters();

  const eventsTableData = useMemo<IEventItem[] | undefined>(() => {
    if (events && events.length > 0) {
      return events.map((item: IProgramEvent) => ({
        id: item.code,
        name: item.NAME,
        units: item["Ед. измерения результата"],
        structureElement: item.data.find((it) => Number(it.code) === 120)?.text,
        structureElementName: item["Наименование проекта"],
        plan: roundNumbersToFixed(
          Number(item.data.find((it) => Number(it.code) === 0)?.sum),
          0
        ),
        fact: roundNumbersToFixed(
          Number(item.data.find((it) => Number(it.code) === 1)?.sum),
          0
        ),
        done: roundNumbersToFixed(
          Number(item.data.find((it) => Number(it.code) === 2)?.sum),
          0
        ),
        effect: roundNumbersToFixed(
          Number(item.data.find((it) => Number(it.code) === 29)?.sum),
          0
        ),
        status: getEventStatuses(item.data),
      }));
    }
  }, [events]);

  const eventsTableDataFiltered = useMemo<IEventItem[] | undefined>(() => {
    if (selectedStatus) {
      return eventsTableData?.filter((it) => isStatusMatch(selectedStatus, it));
    }

    return eventsTableData;
  }, [eventsTableData, selectedStatus]);

  const statuses = useMemo<IStatus[]>(
    () => [
      {
        status: "Выполнено",
        amount:
          eventsTableData?.filter((it) => it.status[EVENT_STATUS.DONE])
            .length || 0,
      },
      {
        status: "В реализации",
        amount:
          eventsTableData?.filter((it) => it.status[EVENT_STATUS.IN_PROGRESS])
            .length || 0,
      },
      {
        status: "Не начато",
        amount:
          eventsTableData?.filter((it) => it.status[EVENT_STATUS.NOT_STARTED])
            .length || 0,
      },
      {
        status: "Под риском",
        amount:
          eventsTableData?.filter((it) => it.status[EVENT_STATUS.AT_RISK])
            .length || 0,
      },
      {
        status: "ЧС",
        amount:
          eventsTableData?.filter((it) => it.status[EVENT_STATUS.CRITICAL])
            .length || 0,
      },
    ],
    [eventsTableData]
  );

  const tabs: ITab<PROGRAM_TABS>[] = useMemo(() => {
    const tabs: ITab<PROGRAM_TABS>[] = [];

    if (structures && structures.length > 0) {
      tabs.push({
        value: PROGRAM_TABS.STRUCTURE,
        label: "Структурные элементы",
        component: (
          <StructureCard structures={structures} statuses={statuses} />
        ),
      });
    }

    if (eventsTableDataFiltered) {
      tabs.push({
        value: PROGRAM_TABS.EVENTS,
        label: "Мероприятия",
        component: (
          <Box sx={{ marginTop: "-1rem" }}>
            {openEventCard ? (
              <>
                <Event />
              </>
            ) : (
              <EventsSection
                items={eventsTableDataFiltered}
                withoutButton
                setOpenEventCard={setOpenEventCard}
                statuses={statuses}
              />
            )}
          </Box>
        ),
      });
    }

    return tabs;
  }, [events, structures, openEventCard, selectedStatus]);

  const handleChangeTab = (value: number) => setActiveTab(value);

  return (
    <ExtendedTabs
      value={activeTab}
      onChange={handleChangeTab}
      tabs={tabs}
      withBgr
      actions={[
        <Counter
          totalDataLength={eventsTableData?.length}
          tableDataLength={eventsTableDataFiltered?.length}
          isBig
        />,
      ]}
    />
  );
};
