import { Theme } from "@mui/material";
import smallPointsLine from "ASSETS/svg/line/smallPointsLine.svg";

export const css = {
  itemWrapper: (
    isLastChunkStyle: number | boolean | undefined,
    theme: Theme
  ) => ({
    position: "relative",
    flex: isLastChunkStyle ? 0 : 1,
    minWidth: "calc(50% - 1rem)",
    height: "16.85rem",

    "@media(min-width: 1400px)": {
      minWidth: isLastChunkStyle ? "calc(33.5% - 1rem)" : "calc(33% - 1rem)",
      flex: isLastChunkStyle ? 0 : 1,
    },

    [theme.breakpoints.up("lg")]: {
      minWidth: "calc(50% - 1rem)",
      flex: isLastChunkStyle ? 0 : 1,
    },

    [theme.breakpoints.up("xl")]: {
      minWidth: isLastChunkStyle ? "calc(33.5% - 1rem)" : "calc(33% - 1rem)",
      flex: isLastChunkStyle ? 0 : 1,
      maxHeight: "350px",
    },
  }),
  itemContainer: (isFrontSide: boolean, chartViewIndex: number | null) => ({
    background: (theme: Theme) => theme.palette.complementary.darkBlue,
    boxShadow: (theme: Theme) =>
      `0px 40px 20px -20px ${theme.palette.background.defaultTransparent}`,
    borderRadius: "1rem",
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    transition: "transform 0.5s linear",
    backfaceVisibility: "hidden",
    transform:
      chartViewIndex === null
        ? isFrontSide
          ? "perspective(1000px) rotateY(0deg)"
          : "perspective(1000px) rotateY(180deg)"
        : isFrontSide
        ? "perspective(1000px) rotateY(-180deg)"
        : "perspective(1000px) rotateY(0deg)",
  }),
  progressContainer: (value: number) => ({
    padding: "1.5rem",
    flex: "0 0 40%",
    position: "relative",
    background: (theme: Theme) =>
      value < 100
        ? `linear-gradient(180deg, ${theme.palette.complementary.darkRedTransparent} 0%, ${theme.palette.complementary.darkViolet} 100%)`
        : `linear-gradient(180deg, ${theme.palette.complementary.darkGreenTransparent} 0%, ${theme.palette.complementary.grayGreen} 100%)`,
    borderTopLeftRadius: "1rem",
    borderBottomLeftRadius: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: (theme: Theme) =>
      `10px 0px 15px 0px ${theme.palette.complementary.darkBlueLight}`,
    overflow: "hidden",
  }),
  progressBgr: (value: number) => ({
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    background: (theme: Theme) => theme.palette.complementary.darkBlue,
    height: `${100 - value}%`,
  }),
  progressBgrBorder: {
    width: "97%",
    position: "absolute",
    top: 0,
    right: 0,
    background: (theme: Theme) =>
      theme.palette.complementary.darkBlueTransparent,
    borderTopLeftRadius: "1rem",
    borderBottomLeftRadius: "1rem",
    height: "100%",
  },
  indicatorContainer: (theme: Theme) => ({
    padding: "1.5rem",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",

    [theme.breakpoints.up("xl")]: {
      padding: "1rem 1.5rem",
      overflow: "hidden",
    },
  }),
  indicatorLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
    justifyContent: "space-between",
  },
  dotsLine: {
    background: `url(${smallPointsLine}) repeat`,
    height: "4px",
    width: "33%",
  },
  carouselContainer: {
    height: "100%",
  },
  smallText: {
    fontSize: "1.25rem",
    lineHeight: "1.25rem",
    color: (theme: Theme) => theme.palette.complementary.grayBlue,
  },
  indicatorLabelText: (theme: Theme) => ({
    fontSize: "1.25rem",
    lineHeight: "1.25rem",
    color: theme.palette.complementary.grayBlue,
    marginBottom: 0,
    marginRight: "0.62rem",

    [theme.breakpoints.up("xl")]: {
      fontSize: "1rem",
      lineHeight: "1rem",
    },
  }),
  mainText: {
    fontSize: "1.13rem",
    lineHeight: "1.3rem",
    color: (theme: Theme) => theme.palette.primary.contrastText,
    whiteSpace: "normal",
  },
  countText: {
    fontSize: "2.25rem",
    lineHeight: "1.3rem",
    color: (theme: Theme) => theme.palette.success.main,
    fontWeight: "bold",
    "& .percent": {
      color: (theme: Theme) => theme.palette.complementary.grayBlue,
      fontWeight: "normal",
    },
  },
  percentBigText: {
    fontWeight: "bold",
    fontSize: "2.75rem",
    lineHeight: "2.63rem",
    color: (theme: Theme) => theme.palette.primary.contrastText,
    display: "flex",
    flexDirection: "row",
  },
  percentSmallText: {
    fontSize: "1.25rem",
    lineHeight: "1.25rem",
    color: (theme: Theme) => theme.palette.complementary.grayBlue,
  },
  indicatorName: (theme: Theme) => ({
    fontSize: "1.13rem",
    lineHeight: "1.3rem",
    color: (theme: Theme) => theme.palette.primary.contrastText,
    whiteSpace: "normal",
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "&.chartViewName": {
      marginTop: 10,
      marginBottom: 10,
      marginRight: 10,
    },

    [theme.breakpoints.up("xl")]: {
      fontSize: "1rem",
      lineHeight: "1.2rem",
    },
  }),
  factText: (theme: Theme) => ({
    fontWeight: "bold",
    fontSize: "2rem",
    lineHeight: "1em",
    height: "2rem",
    display: "flex",
    alignItems: "flex-end",
    color: (theme: Theme) => theme.palette.primary.contrastText,
    whiteSpace: "normal",

    [theme.breakpoints.up("xl")]: {
      fontSize: "1.2rem",
    },
  }),
  planText: (theme: Theme) => ({
    fontWeight: "bold",
    fontSize: "1.3rem",
    lineHeight: "1em",
    height: "2rem",
    display: "flex",
    alignItems: "flex-end",
    color: (theme: Theme) => theme.palette.primary.main,
    whiteSpace: "normal",

    [theme.breakpoints.up("xl")]: {
      fontSize: "1.2rem",
    },
  }),
  hintContainer: {
    background: (theme: Theme) => theme.palette.complementary.grayBlue,
    padding: "1.5rem",
    borderRadius: "1rem",
  },
  hintText: {
    fontSize: "1rem",
    lineHeight: "1em",
    color: (theme: Theme) => theme.palette.main.gray,
    whiteSpace: "break-spaces",
  },
};
