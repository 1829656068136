import { Theme } from "@mui/material";

export const css = {
  wrapper: (isCarousel: boolean, theme: Theme) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",

    [theme.breakpoints.up("xl")]: {
      padding: isCarousel ? "0" : "0rem 1rem",
    },
  }),
  carouselContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "relative",
  },
  carousel: {
    display: "flex",
    width: "100%",
  },
  button: (isPrevBtnDisabled?: boolean, isNextBtnDisabled?: boolean) => ({
    position: "absolute",
    top: "50%",
    left: "-1rem",
    transform: "translateY(-50%)",
    padding: { sm: "1.5rem 1rem", xl: "1rem 0.7rem" },
    borderRadius: "8px",
    background: (theme: Theme) => theme.palette.complementary.grayBlue,
    zIndex: 1,
    display: "flex",
    cursor: isPrevBtnDisabled || isNextBtnDisabled ? "not-allowed" : "pointer",
    "&:hover": {
      background: (theme: Theme) =>
        isPrevBtnDisabled || isNextBtnDisabled
          ? theme.palette.complementary.grayBlue
          : theme.palette.primary.main,
    },
    "&.nextBtn": {
      right: "-1rem",
      left: "auto",
    },
  }),
  arrow: {
    height: "0.75rem",
    width: "0.375rem",
  },
  carouselItem: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
    width: "100%",
  },
};
