import { Box, Grid } from "@mui/material";
import React, { useMemo } from "react";
import { StackedProgressBar } from "SRC/components/StackedProgressBar/StackedProgressBar";
import { ECalculationMethod } from "SRC/constants/globals";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import { IStructureElements } from "SRC/types/analiticsNew";

import { css } from "./StructureElements.styled";

export const StructureElements = () => {
  const { items: stats } = useStats();
  const data = useMemo(() => stats[0]?.data || {}, [stats]);

  const { calculationMethod }: IFilters = useFilters();

  const structureData = useMemo<IStructureElements>(
    () => ({
      departmentProjects: {
        notDone:
          calculationMethod === ECalculationMethod.EFFECT
            ? data["Мероприятий не выполнено ВП, %"]
            : data["Мероприятий не выполнено ВП 2, %"],
        notDoneValue: data["Мероприятий не выполнено ВП"],
        fact: data["Выполнение мероприятий ВП, факт"],
        done: data["Выполнение мероприятий ВП, %"] || 0,
        plan: data["Выполнение мероприятий ВП, план"] || 0,
        total: data["Ведомственных проектов, кол-во"] || 0,
      },
      federalProjects: {
        notDone:
          calculationMethod === ECalculationMethod.EFFECT
            ? data["Мероприятий не выполнено ФП, %"]
            : data["Мероприятий не выполнено ФП 2, %"],
        notDoneValue: data["Мероприятий не выполнено ФП"],
        fact: data["Выполнение мероприятий ФП, факт"],
        done:
          calculationMethod === ECalculationMethod.EFFECT
            ? data["Количество выполненных мероприятий ФП, %"] || 0
            : data["Количество выполненных мероприятий ФП 2, %"] || 0,
        plan: data["Выполнение мероприятий ФП, план"] || 0,
        total: data["Федеральных проектов, кол-во"] || 0,
      },
      complexesProcessMeasures: {
        notDone:
          calculationMethod === ECalculationMethod.EFFECT
            ? data["Мероприятий не выполнено КПМ, %"]
            : data["Мероприятий не выполнено КПМ 2, %"],
        notDoneValue: data["Мероприятий не выполнено КПМ"],
        fact: data["Выполнение мероприятий КПМ, факт"],
        done: data["Выполнение мероприятий КПМ, %"] || 0,
        plan: data["Выполнение мероприятий КПМ, план"] || 0,
        total: data["Комплексы процессных мероприятий, кол-во"] || 0,
      },
      events: {
        fact: data["Мероприятий выполнено"] || 0,
        done: data["Мероприятий выполнено, %"] || 0,
        notDone: data["Мероприятий не выполнено, %"] || 0,
        notDoneValue: data["Мероприятий не выполнено"] || 0,
        total: data["Мероприятий всего"] || 0,
      },
      totalProjects: {
        plan:
          data["Выполнение мероприятий ВП, план"] +
          data["Выполнение мероприятий ФП, план"] +
          data["Выполнение мероприятий КПМ, план"],
        fact:
          data["Выполнение мероприятий ВП, факт"] +
          data["Выполнение мероприятий ФП, факт"] +
          data["Выполнение мероприятий КПМ, факт"],
        total: data["Структурные элементы, всего"] || 0,
      },
    }),
    [data, calculationMethod]
  );

  return (
    <Box sx={css.container}>
      <Grid container>
        <Grid item xs={3}>
          <Box sx={css.textContainer}>
            <p className="grayBlueText">Всего</p>
            <p className="whiteText bigText">
              {structureData?.totalProjects.total}
            </p>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box sx={css.progressbarHead}>
            <p>Мероприятия</p>
            <Box sx={css.legend}>
              <Box sx={css.legend}>
                <Box sx={css.dot} className="green" />
                <p className="legendText">Выполнено</p>
              </Box>
              <Box sx={css.legend}>
                <Box sx={css.dot} className="red" />
                <p className="legendText">Не выполнено</p>
              </Box>
            </Box>
          </Box>
          <StackedProgressBar
            firstPercent={structureData.events.done}
            firstValue={structureData.events.fact}
            secondPercent={structureData.events.notDone}
            secondValue={structureData.events.notDoneValue}
            total={structureData.events.total}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <Box sx={css.textContainer}>
            <p className="grayBlueText">ФП</p>
            <p className="whiteText">{structureData?.federalProjects.total}</p>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <StackedProgressBar
            firstPercent={structureData.federalProjects.done}
            firstValue={structureData.federalProjects.fact}
            secondValue={structureData.federalProjects.notDoneValue}
            secondPercent={structureData.federalProjects.notDone}
            total={structureData.federalProjects.plan}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <Box sx={css.textContainer}>
            <p className="grayBlueText">ВП</p>
            <p className="whiteText">
              {structureData?.departmentProjects.total}
            </p>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <StackedProgressBar
            firstPercent={structureData.departmentProjects.done}
            firstValue={structureData.departmentProjects.fact}
            secondValue={structureData.departmentProjects.notDoneValue}
            secondPercent={structureData.departmentProjects.notDone}
            total={structureData.departmentProjects.plan}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <Box sx={css.textContainer}>
            <p className="grayBlueText">КПМ</p>
            <p className="whiteText">
              {structureData?.complexesProcessMeasures.total}
            </p>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <StackedProgressBar
            firstPercent={structureData.complexesProcessMeasures.done}
            firstValue={structureData.complexesProcessMeasures.fact}
            secondValue={structureData.complexesProcessMeasures.notDoneValue}
            secondPercent={structureData.complexesProcessMeasures.notDone}
            total={structureData.complexesProcessMeasures.plan}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
