import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IWorstProgram2 } from "SRC/pages/Summary/interfaces";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export interface IProgramsTopWorst2 {
  fetching: boolean;
  fetched: boolean;
  items: IWorstProgram2[];
  load(period: IPeriod): void;
  error: SerializedError | null;
}

export const useTopWorstGP2 = (): IProgramsTopWorst2 => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: programsTopWorst2 = [],
    fetched,
  } = useAppSelector((state) => state.summary.topWorstGp2);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (period) => {
      if (!fetching) {
        dispatch(actions.topWorstGp2.get(period)).then((action) => {
          if (actions.topWorstGp2.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: [...programsTopWorst2].sort((a, b) => Number(b.sum) - Number(a.sum)),
    load,
    error,
  };
};
