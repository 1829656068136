import { Box } from "@mui/material";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import { WIPTag } from "SRC/components/WIP/WIP";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import theme from "SRC/theme";

import { css } from "./Risks.styled";

interface IDataRisk {
  plan: number;
  fact: number;
  risks: number;
  percent: number;
}

interface IRisksProps {
  dataTop: IDataRisk;
  dataBottom: IDataRisk;
  topName: string;
}

export const Risks = ({ dataTop, dataBottom, topName }: IRisksProps) => {
  return (
    <Box sx={css.container}>
      <Box sx={css.itemContainer}>
        <Box sx={css.title}>
          {topName}
          <Box sx={css.unitTitle}>ед.</Box>
        </Box>
        <ProgressBar
          value={roundNumbersToFixed(dataTop?.percent, 0) || 0}
          circleLabel={dataTop?.fact}
          bottomLeftLabel={0}
          bottomRightLabel={dataTop?.plan}
          withLabel
          progressColor={
            dataTop?.percent < 100
              ? theme.palette.pale.red
              : theme.palette.pale.blue
          }
        />
      </Box>
      <Box sx={css.itemContainer}>
        <Box sx={css.title}>
          Нормативные правовые акты
          <Box sx={css.unitTitle}>ед.</Box>
          <WIPTag />
        </Box>
        <ProgressBar
          value={roundNumbersToFixed(dataBottom?.percent, 0) || 0}
          circleLabel={dataBottom?.fact}
          bottomLeftLabel={0}
          bottomRightLabel={dataBottom?.plan}
          withLabel
          progressColor={theme.palette.pale.blue}
        />
      </Box>
    </Box>
  );
};
