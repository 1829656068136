import { CircularProgress, Grid, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { Column } from "react-table";
import { CardsAnalitic } from "SRC/components/CardsAnalitic/CardsAnalitic";
import Table from "SRC/components/Table";
import { RightTitle } from "SRC/pages/Main/common/Analitics/Analitics";
import { ProgramList } from "SRC/pages/Main/common/Analitics/ProgramList/ProgramList";
import { getNationalGoalsColumns } from "SRC/pages/Main/common/NationalGoalsTable/tableData";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useGoals } from "SRC/redux/slices/main/hooks/useGoals";
import { usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";

import { INationalGoalsDataItem } from "../ComplexGrid/util/interfaces";
import { css } from "./NationalGoalsTable.styled";

export const NationalGoalsTable = () => {
  const theme = useTheme();
  const { items } = useGoals();
  const { goal, setSelectedGoal, filteredGoals }: IFilters = useFilters();

  const columns: Column[] = useMemo(
    () => getNationalGoalsColumns(theme),
    [theme]
  );

  const data = useMemo<INationalGoalsDataItem[]>(
    () =>
      items
        .filter((goal) => filteredGoals.includes(goal.NT_CODE))
        .map((goal, index) => ({
          id: goal.NT_CODE,
          icon: require(`ASSETS/icons/nationals/icon${index + 1}.gif`).default,
          goalTitle: goal.NAME,
          achievementLevel: goal.data["Уровень достижения"],
          numOfPrograms: goal.data["Количество ГП"],
        })),
    [items, filteredGoals]
  );

  const { fetching } = usePrograms();

  return (
    <Grid container justifyContent="space-between" gap="1rem">
      <Grid item xs={3}>
        {fetching ? (
          <CardsAnalitic style={css.loading} headerLeft="Список госпрограмм">
            <CircularProgress />
          </CardsAnalitic>
        ) : (
          <CardsAnalitic
            style={css.programListContainer}
            headerLeft="Список госпрограмм"
            headerRight={
              <RightTitle isDetail isTitle isActiveButton link="/programs/" />
            }
            isSmall
          >
            <ProgramList />
          </CardsAnalitic>
        )}
      </Grid>
      <Grid item xs={8.8}>
        <Table
          columns={columns}
          data={data}
          sortedColumns={[
            "Уровень достижения Национальной цели",
            "Количество Госпрограмм",
          ]}
          selectedRow={goal}
          setSelectedRow={setSelectedGoal}
          tableHeight={"22rem"}
        />
      </Grid>
    </Grid>
  );
};
