import { useTheme } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Column,
  useExpanded,
  useGlobalFilter,
  useSortBy,
  useTable,
} from "react-table";
import {
  IExportData,
  OpenerButton,
  TableExportButton,
} from "SRC/components/Buttons";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import {
  getDataSumByCodeGP,
  getDataSumByCodeMin,
  getDataSumByCodeVP,
} from "SRC/constants/dataCodes";
import { ECalculationMethod } from "SRC/constants/globals";
import { isSecret } from "SRC/helpers/dataTesters";
import {
  getInfoRowsGovProgram,
  getInfoRowsMinistries,
  getInfoRowsVicePremiers,
} from "SRC/helpers/getInfoRows";
import useTableExportParams from "SRC/hooks/useTableExportParams";
import { GovProgramsFilters } from "SRC/pages/Main/common/ComplexGrid/filterComponents/GovProgramsFilters";
import { MinistriesFilters } from "SRC/pages/Main/common/ComplexGrid/filterComponents/MinistriesFilters";
import { VicePremiersFilters } from "SRC/pages/Main/common/ComplexGrid/filterComponents/VicePremiersFilters";
import { GovProgramsTab } from "SRC/pages/Main/common/ComplexGrid/GovProgramsTab/GovProgramsTab";
import { getGovProgramsColumns } from "SRC/pages/Main/common/ComplexGrid/GovProgramsTab/tableData";
import { MinistriesTab } from "SRC/pages/Main/common/ComplexGrid/MinistriesTab/MinistriesTab";
import { getMinistriesColumns } from "SRC/pages/Main/common/ComplexGrid/MinistriesTab/tableData";
import {
  IGovProgramDataItem,
  IGovProgramDataItemWithoutIcon,
  IMinistriesDataItem,
  IVicePremiersDataItem,
} from "SRC/pages/Main/common/ComplexGrid/util/interfaces";
import { getVicePremiersColumns } from "SRC/pages/Main/common/ComplexGrid/VicePremiersTab/tableData";
import { VicePremiersTab } from "SRC/pages/Main/common/ComplexGrid/VicePremiersTab/VicePremiersTab";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  TMinistry,
  useMinistries,
} from "SRC/redux/slices/main/hooks/useMInistries";
import { TPremier, usePremiers } from "SRC/redux/slices/main/hooks/usePremiers";
import { TProgram, usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import { IProgram } from "SRC/redux/slices/main/slices/programs";
import themeConfig from "SRC/theme";

type TDData =
  | IGovProgramDataItemWithoutIcon
  | IVicePremiersDataItem
  | IMinistriesDataItem;

type TExportData<T> = {
  [key: string]: IExportData<T>;
};

export const ComplexGrid = () => {
  const theme = useTheme<typeof themeConfig>();
  const navigate = useNavigate();

  const [isGovProgramsModalOpen, setGovProgramsModalOpen] = useState(false);
  const [isVicePremiersModalOpen, setVicePremiersModalOpen] = useState(false);
  const [isMinistriesModalOpen, setMinistriesModalOpen] = useState(false);
  const [govProgramsTableFilter, setGovProgramsTableFilter] = useState<
    "Отраслевая" | "Секретная" | "Комплексная" | ""
  >("");

  const {
    program,
    setSelectedProgram,
    filteredPrograms,
    vp,
    setSelectedVP,
    filteredVPs,
    ministry,
    setSelectedMinistry,
    filteredMinistries,
    setSelectedComplexGridTab,
    complexGridTab,
    calculationMethod,
  }: IFilters = useFilters();
  const { items: ministries } = useMinistries();
  const { items: programs } = usePrograms();
  const { items: premiers } = usePremiers();

  const handleTabChange = (newValue: number | string) => {
    setSelectedComplexGridTab(String(newValue));
  };

  const govProgramsTableData: IProgram[] = useMemo(() => {
    if (govProgramsTableFilter) {
      return programs?.filter((item: IProgram) =>
        Number(item[govProgramsTableFilter])
      );
    }
    return programs;
  }, [govProgramsTableFilter, programs]);

  const govProgramsData = useMemo<IGovProgramDataItem[]>(
    () =>
      govProgramsTableData
        .filter((program) => filteredPrograms.includes(program.gp_code))
        .map((item: TProgram) => ({
          id: item.gp_code,
          icon: item.gp_icon_url,
          govProgram: item.gp_short_name,
          indicatorsPlan: getDataSumByCodeGP(item, 1578) || 0,
          indicatorsDone: getDataSumByCodeGP(item, 1579) || 0,
          plan: getDataSumByCodeGP(item, 1584) || 0,
          fact: getDataSumByCodeGP(item, 1585) || 0,
          atRisk: getDataSumByCodeGP(item, 1680) || 0,
          achievementLevel:
            calculationMethod === ECalculationMethod.EFFECT
              ? getDataSumByCodeGP(item, 1753) || 0
              : getDataSumByCodeGP(item, 1796) || 0,
          secretProgram: isSecret(item),
          achievementSum: getDataSumByCodeGP(item, 5246) || 0,
        }))
        .sort((a: IGovProgramDataItem, b: IGovProgramDataItem) => {
          return Number(a.id) - Number(b.id);
        }),
    [govProgramsTableData, filteredPrograms, calculationMethod]
  );

  // @ts-ignore
  const govProgramsColumns: Column[] = useMemo(
    () => getGovProgramsColumns(theme, Boolean(vp || ministry)),
    [theme, vp, ministry]
  );

  const vicePremiersTableData = useMemo<IVicePremiersDataItem[]>(
    () =>
      premiers
        .filter((premier) => filteredVPs.includes(premier.VP_CODE))
        .map((item: TPremier) => ({
          id: item.VP_CODE,
          vicePremierName: item.VP_SHORT_NAME,
          vicePremierPhoto: item.VP_PHOTO_URL,
          numOfPrograms: getDataSumByCodeVP(item, 1742) || 0,
          indicatorsPlan: getDataSumByCodeVP(item, 1699) || 0,
          indicatorsDone: getDataSumByCodeVP(item, 1700) || 0,
          plan: getDataSumByCodeVP(item, 1747) || 0,
          fact: getDataSumByCodeVP(item, 1748) || 0,
          atRisk: getDataSumByCodeVP(item, 1749) || 0,
          achievementLevel:
            calculationMethod === ECalculationMethod.EFFECT
              ? getDataSumByCodeVP(item, 1743) || 0
              : getDataSumByCodeVP(item, 1797) || 0,
          achievementSum: getDataSumByCodeVP(item, 5248) || 0,
          subRows: programs
            .filter((it: TProgram) => it["Вице-премьер, код"] === item.VP_CODE)
            ?.map((el) => {
              return {
                govProgramId: el.gp_code,
                vicePremierName: el.gp_short_name,
                vicePremierPhoto: el.gp_icon_url,
                numOfPrograms: " ",
                indicatorsPlan: getDataSumByCodeGP(el, 1578) || 0,
                indicatorsDone: getDataSumByCodeGP(el, 1579) || 0,
                plan: getDataSumByCodeGP(el, 1584) || 0,
                fact: getDataSumByCodeGP(el, 1679) || 0,
                atRisk: getDataSumByCodeGP(el, 1680) || 0,
                achievementLevel:
                  calculationMethod === ECalculationMethod.EFFECT
                    ? getDataSumByCodeGP(el, 1753) || 0
                    : getDataSumByCodeGP(el, 1796) || 0,
                achievementSum: getDataSumByCodeGP(el, 5246) || 0,
              };
            }),
        })),
    [premiers, programs, filteredVPs, calculationMethod]
  );

  const vicePremiersTableColumns: Column[] = useMemo(
    () => getVicePremiersColumns(theme, navigate, setSelectedVP),
    [theme]
  );

  const ministriesTableData = useMemo<IMinistriesDataItem[]>(
    () =>
      ministries
        .filter((ministry) => filteredMinistries.includes(ministry.code))
        .map((item: TMinistry) => ({
          ministryName: item.NAME,
          numOfPrograms: getDataSumByCodeMin(item, 1782) || 0,
          plan: getDataSumByCodeMin(item, 1785) || 0,
          fact: getDataSumByCodeMin(item, 1786) || 0,
          atRisk: getDataSumByCodeMin(item, 1787) || 0,
          achievementLevel:
            calculationMethod === ECalculationMethod.EFFECT
              ? getDataSumByCodeMin(item, 1788) || 0
              : getDataSumByCodeMin(item, 1845) || 0,
        })),
    [ministries, filteredMinistries, calculationMethod]
  );

  const ministriesTableColumns: Column[] = useMemo(
    () => getMinistriesColumns(theme),
    [theme]
  );

  const {
    getTableProps: getGovProgramsTableProps,
    getTableBodyProps: getGovProgramsTableBodyProps,
    headerGroups: govProgramsTableHeaderGroups,
    footerGroups: govProgramsTableFooterGroups,
    rows: govProgramsTableRows,
    prepareRow: govProgramsTablePrepareRow,
    setSortBy: setGovProgramsTableSortBy,
    state: govProgramsTableState,
    setGlobalFilter: setGovProgramsTableGlobalFilter,
  } = useTable(
    {
      columns: govProgramsColumns,
      data: govProgramsData,
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter: govProgramsTableGlobalFilter } = govProgramsTableState;

  const {
    getTableProps: getVicePremiersTableProps,
    getTableBodyProps: getVicePremiersTableBodyProps,
    headerGroups: vicePremiersTableHeaderGroups,
    rows: vicePremiersTableRows,
    prepareRow: vicePremiersTablePrepareRow,
    setSortBy: setVicePremiersTableSortBy,
    state: vicePremiersTableState,
    setGlobalFilter: setVicePremiersTableGlobalFilter,
  } = useTable(
    {
      columns: vicePremiersTableColumns,
      data: vicePremiersTableData,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded
  );

  const { globalFilter: vicePremiersTableGlobalFilter } =
    vicePremiersTableState;

  const {
    getTableProps: getMinistriesTableProps,
    getTableBodyProps: getMinistriesTableBodyProps,
    headerGroups: ministriesTableHeaderGroups,
    rows: ministriesTableRows,
    prepareRow: ministriesTablePrepareRow,
    setSortBy: setMinistriesTableSortBy,
    state: ministriesTableState,
    setGlobalFilter: setMinistriesTableGlobalFilter,
  } = useTable(
    {
      columns: ministriesTableColumns,
      data: ministriesTableData,
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter: ministriesTableGlobalFilter } = ministriesTableState;

  const govProgramsDataExport: IGovProgramDataItemWithoutIcon[] =
    govProgramsData.map(
      ({ icon, secretProgram, achievementSum, ...item }) => item
    );

  const vicePremiersDataExport = vicePremiersTableData.map(
    ({ id, subRows, achievementSum, ...item }) => item
  );

  const infoRowsGovProgram = useMemo(
    () => getInfoRowsGovProgram(program, programs, govProgramsTableFilter),
    [program, govProgramsTableFilter, programs]
  );

  const infoRowsVicePremiers = useMemo(
    () => getInfoRowsVicePremiers(govProgramsTableFilter),
    [govProgramsTableFilter]
  );

  const infoRowsMinistries = useMemo(
    () => getInfoRowsMinistries(govProgramsTableFilter),
    [govProgramsTableFilter]
  );

  const {
    headings: govProgramsHeadings,
    mergeCells: govProgramsMergeCells,
    wscols: govProgramsWscols,
  } = useTableExportParams(govProgramsDataExport, "govPrograms");
  const {
    headings: vicePremiersHeadings,
    mergeCells: vicePremiersMergeCells,
    wscols: vicePremiersWscols,
  } = useTableExportParams(vicePremiersDataExport, "vicePremiers");
  const {
    headings: ministriesHeadings,
    mergeCells: ministriesMergeCells,
    wscols: ministriesWscols,
  } = useTableExportParams(ministriesTableData, "ministries");

  const tabExportData: IExportData<TDData> = useMemo(() => {
    const exportData: TExportData<TDData> = {
      govPrograms: {
        csvData: govProgramsDataExport,
        fileName: "Государственные программы",
        wscols: govProgramsWscols,
        headings: govProgramsHeadings,
        mergeCells: govProgramsMergeCells,
        infoRows: infoRowsGovProgram,
      },
      vicePremiers: {
        csvData: vicePremiersDataExport,
        fileName: "Вице-премьеры",
        wscols: vicePremiersWscols,
        headings: vicePremiersHeadings,
        mergeCells: vicePremiersMergeCells,
        infoRows: infoRowsVicePremiers,
      },
      ministries: {
        csvData: ministriesTableData,
        fileName: "Министерства",
        wscols: ministriesWscols,
        headings: ministriesHeadings,
        mergeCells: ministriesMergeCells,
        infoRows: infoRowsMinistries,
      },
    };
    return exportData[complexGridTab];
  }, [
    complexGridTab,
    govProgramsData,
    ministriesTableData,
    vicePremiersTableData,
  ]);

  const tabs: ITab<string>[] = [
    {
      value: "govPrograms",
      label: "Госпрограммы",
      component: (
        <GovProgramsTab
          setGlobalFilter={setGovProgramsTableGlobalFilter}
          globalFilter={govProgramsTableGlobalFilter}
          tableFilter={govProgramsTableFilter}
          setFilter={setGovProgramsTableFilter}
          data={govProgramsData}
          columns={govProgramsColumns}
          footerGroups={govProgramsTableFooterGroups}
          getTableBodyProps={getGovProgramsTableBodyProps}
          getTableProps={getGovProgramsTableProps}
          headerGroups={govProgramsTableHeaderGroups}
          prepareRow={govProgramsTablePrepareRow}
          program={program}
          rows={govProgramsTableRows}
          setSelectedProgram={setSelectedProgram}
          setSortBy={setGovProgramsTableSortBy}
          isTableModalOpen={isGovProgramsModalOpen}
          setTableModalOpen={setGovProgramsModalOpen}
          downloadData={govProgramsDataExport}
          infoRows={infoRowsGovProgram}
        />
      ),
    },
    {
      value: "vicePremiers",
      label: "Вице-премьеры",
      component: (
        <VicePremiersTab
          vicePremiersTableColumns={vicePremiersTableColumns}
          vicePremiersTableData={vicePremiersTableData}
          setSortBy={setVicePremiersTableSortBy}
          rows={vicePremiersTableRows}
          prepareRow={vicePremiersTablePrepareRow}
          headerGroups={vicePremiersTableHeaderGroups}
          getTableProps={getVicePremiersTableProps}
          getTableBodyProps={getVicePremiersTableBodyProps}
          globalFilter={vicePremiersTableGlobalFilter}
          setGlobalFilter={setVicePremiersTableGlobalFilter}
          setSelectedVP={setSelectedVP}
          vp={vp}
          isTableModalOpen={isVicePremiersModalOpen}
          setTableModalOpen={setVicePremiersModalOpen}
          infoRows={infoRowsVicePremiers}
        />
      ),
    },
    {
      value: "ministries",
      label: "Министерства",
      component: (
        <MinistriesTab
          ministriesTableColumns={ministriesTableColumns}
          ministriesTableData={ministriesTableData}
          setSortBy={setMinistriesTableSortBy}
          rows={ministriesTableRows}
          prepareRow={ministriesTablePrepareRow}
          headerGroups={ministriesTableHeaderGroups}
          getTableProps={getMinistriesTableProps}
          getTableBodyProps={getMinistriesTableBodyProps}
          globalFilter={ministriesTableGlobalFilter}
          setGlobalFilter={setMinistriesTableGlobalFilter}
          setSelectedMinistry={setSelectedMinistry}
          ministries={ministry}
          isTableModalOpen={isMinistriesModalOpen}
          setTableModalOpen={setMinistriesModalOpen}
          infoRows={infoRowsMinistries}
        />
      ),
      disabled: false,
      wip: true,
    },
  ];

  let rightSideFilters;
  switch (complexGridTab) {
    case "govPrograms":
      rightSideFilters = (
        <GovProgramsFilters
          globalFilter={govProgramsTableGlobalFilter}
          setGlobalFilter={setGovProgramsTableGlobalFilter}
          setFilter={setGovProgramsTableFilter}
          tableFilter={govProgramsTableFilter}
        />
      );
      break;
    case "vicePremiers":
      rightSideFilters = (
        <VicePremiersFilters
          globalFilter={vicePremiersTableGlobalFilter}
          setGlobalFilter={setVicePremiersTableGlobalFilter}
        />
      );
      break;
    case "ministries":
      rightSideFilters = (
        <MinistriesFilters
          globalFilter={ministriesTableGlobalFilter}
          setGlobalFilter={setMinistriesTableGlobalFilter}
        />
      );
      break;
  }

  const opened: boolean = useMemo(
    () =>
      complexGridTab === "govPrograms"
        ? isGovProgramsModalOpen
        : isVicePremiersModalOpen,
    [complexGridTab, isGovProgramsModalOpen, isVicePremiersModalOpen]
  );

  const setOpen = useCallback(() => {
    const callback =
      complexGridTab === "govPrograms"
        ? setGovProgramsModalOpen
        : setVicePremiersModalOpen;
    return callback(true);
  }, [complexGridTab, setGovProgramsModalOpen, setVicePremiersModalOpen]);

  return (
    <ExtendedTabs
      value={complexGridTab}
      onChange={handleTabChange}
      tabs={tabs}
      contained
      additional={rightSideFilters}
      actions={[
        <TableExportButton {...tabExportData} />,
        <OpenerButton open={opened} setOpen={setOpen} />,
      ]}
    />
  );
};
