import { Box } from "@mui/material";
import React from "react";
import { Column } from "react-table";
import Table from "SRC/components/Table";
import { IRelatedIndicatorsDataItem } from "SRC/pages/Event/interfaces";

import { css } from "./RelatedIndicators.styled";

interface IProps {
  columns: Column[];
}

export const RelatedIndicators: React.FC<IProps> = ({ columns }) => {
  // const data = useMemo<IRelatedIndicatorsDataItem[]>(
  //   () =>
  //     relatedIndicatorsData?.map((item: IRelatedIndicatorsDataItem) => ({
  //       id: item.id,
  //       indicatorName: item.indicatorName,
  //       achievementLevel: item.achievementLevel,
  //       effect: item.effect,
  //     })),
  //   [relatedIndicatorsData]
  // );

  const data: IRelatedIndicatorsDataItem[] = [];

  return (
    <Box sx={css.wrapper}>
      <Table
        columns={columns}
        data={data}
        sortedColumns={["План", "Факт"]}
        tableHeight={"31rem"}
        noDataCondition={data?.length === 0}
      />
    </Box>
  );
};
