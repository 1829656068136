import { Box, Theme, Typography } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { StatusPoint } from "SRC/components/StatusPoint/StatusPoint";
import {
  expandedGroupingColumn,
  NavigateCell,
  nestingTest,
} from "SRC/components/Table";
import { css } from "SRC/components/Table/Table.styled";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { css as cssSummary } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";
import { IMinistriesTableItem } from "SRC/pages/Summary/interfaces";
import { PROGRAM_TABS } from "SRC/types";
import { createProgramCardUrl, PROGRAM_DETAIL_FULL } from "SRC/urls";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<IMinistriesTableItem>;
  rows?: UseTableRowProps<IMinistriesTableItem>[];
}

const getGeneralInfoMinistriesColumns = (theme: Theme) => {
  return [
    expandedGroupingColumn,
    {
      Header: "",
      accessor: "id",
      width: 50,
      sticky: "left",
      columns: [
        {
          Header: "№",
          width: 50,
          sticky: "left",
          accessor: "id",
          Cell: (tableProps: CustomTableProps) => {
            if (nestingTest(tableProps.row)) return null;

            return (
              <Typography sx={css.tableCellText} className="colorGray">
                {String(tableProps.row.original.id).padStart(2, "0")}
              </Typography>
            );
          },
          Footer: "",
        },
      ],
      Footer: "",
    },
    {
      Header: "",
      accessor: "ministriesIcon",
      width: 65,
      sticky: "left",
      columns: [
        {
          Header: "",
          accessor: "ministriesIcon",
          sticky: "left",
          width: 65,
          Cell: (tableProps: CustomTableProps) => {
            if (nestingTest(tableProps.row))
              return (
                <Typography sx={css.tableCellText} className="colorGray">
                  {String(tableProps.row.original.id).padStart(2, "0")}
                </Typography>
              );

            return (
              <Box sx={css.centered}>
                <img
                  src={tableProps.row.original.ministryIcon}
                  alt="icon"
                  style={cssSummary.iconColumn.image}
                />
              </Box>
            );
          },
          Footer: "",
        },
      ],
      Footer: "",
    },
    {
      Header: "",
      accessor: "name",
      width: 432,
      sticky: "left",
      columns: [
        {
          Header: "Министерство",
          accessor: "name",
          sticky: "left",
          width: 432,
          Cell: (tableProps: CustomTableProps) => (
            <Typography sx={css.tableCellText} align="left">
              {tableProps.row.original.name}
            </Typography>
          ),
          Footer: <Typography sx={css.tableCellText}>Итого</Typography>,
        },
      ],
      Footer: "",
    },
    {
      Header: "",
      accessor: "govPrograms",
      width: 250,
      sticky: "left",
      columns: [
        {
          Header: "Кол-во госпрограмм",
          accessor: "govPrograms",
          sticky: "left",
          width: 250,
          Cell: (tableProps: CustomTableProps) => {
            if (nestingTest(tableProps.row)) return null;

            return (
              <Typography sx={css.tableCellText}>
                {roundNumbersToFixed(tableProps.row.original.govPrograms, 0)}
              </Typography>
            );
          },
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  row.original.numOfPrograms + sum,
                0
              );
            }, [tableProps.rows]);

            return (
              <Typography sx={css.tableCellText(theme)}>{total}</Typography>
            );
          },
        },
      ],
      Footer: "",
    },
    {
      Header: "",
      accessor: "performance",
      width: 260,
      columns: [
        {
          Header: "Уровень достижения, %",
          accessor: "performance",
          width: 260,
          Cell: (tableProps: CustomTableProps) => {
            const achievementLevel = roundNumbersToFixed(
              tableProps.row.original.performance || 0,
              1
            );

            return (
              <Typography
                align="left"
                sx={{
                  ...css.tableCellText(theme),
                  display: "flex",
                  gap: "0.75rem",
                  alignItems: "center",
                }}
              >
                <StatusPoint value={achievementLevel} />
                {achievementLevel}
              </Typography>
            );
          },
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              const average = rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  row.original.achievementLevel + sum,
                0
              );

              return roundNumbersToFixed(average / rowsArray.length, 0) || 0;
            }, [tableProps.rows]);

            return (
              <Typography sx={css.tableCellText(theme)}>{total}</Typography>
            );
          },
        },
      ],
    },
    {
      Header: "Кассовое исполнение",
      accessor: "cashExecution",
      width: 320,
      columns: [
        {
          Header: "План, млрд ₽",
          accessor: "cashPlan",
          width: 170,
          Cell: (tableProps: CustomTableProps) => (
            <Typography sx={css.tableCellText}>
              {roundNumbersToFixed(0, 0)}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return (
                rowsArray.reduce(
                  (sum: number, row: UseTableRowProps<any>) =>
                    row.original.cashPlan + sum,
                  0
                ) || 0
              );
            }, [tableProps.rows]);

            return (
              <Typography sx={css.tableCellText(theme)}>{total}</Typography>
            );
          },
        },
        {
          Header: "Факт, %",
          accessor: "cashFact",
          width: 150,
          Cell: (tableProps: CustomTableProps) => (
            <Typography sx={css.tableCellText}>
              {roundNumbersToFixed(0, 0)}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              const average = rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  row.original.cashFact + sum,
                0
              );

              return roundNumbersToFixed(average / rowsArray.length, 0) || 0;
            }, [tableProps.rows]);

            return (
              <Typography sx={css.tableCellText(theme)}>{total}</Typography>
            );
          },
        },
      ],
    },
    {
      Header: "Показатели ГП",
      accessor: "indicators",
      width: 490,
      columns: [
        {
          Header: "План",
          accessor: "indicatorsPlan",
          width: 150,
          Cell: (tableProps: CustomTableProps) => (
            <Typography sx={css.tableCellText}>
              {roundNumbersToFixed(0, 0)}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return (
                rowsArray.reduce(
                  (sum: number, row: UseTableRowProps<any>) =>
                    row.original.gpPlan + sum,
                  0
                ) || 0
              );
            }, [tableProps.rows]);

            return (
              <Typography sx={css.tableCellText(theme)}>{total}</Typography>
            );
          },
        },
        {
          Header: "Факт",
          accessor: "indicatorsDone",
          width: 150,
          Cell: (tableProps: CustomTableProps) => (
            <Typography sx={css.tableCellText} className="colorGreen">
              {roundNumbersToFixed(0, 0)}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return (
                rowsArray.reduce(
                  (sum: number, row: UseTableRowProps<any>) =>
                    row.original.gpFact + sum,
                  0
                ) || 0
              );
            }, [tableProps.rows]);

            return (
              <Typography sx={css.tableCellText(theme)} className="colorGreen">
                {total}
              </Typography>
            );
          },
        },
        {
          Header: "Не выполнено",
          accessor: "indicatorsFail",
          width: 190,
          Cell: (tableProps: CustomTableProps) => (
            <Typography sx={css.tableCellText} className="colorRed">
              {roundNumbersToFixed(0, 0)}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return (
                rowsArray.reduce(
                  (sum: number, row: UseTableRowProps<any>) =>
                    row.original.gpFail + sum,
                  0
                ) || 0
              );
            }, [tableProps.rows]);

            return (
              <Typography sx={css.tableCellText(theme)} className="colorRed">
                {total}
              </Typography>
            );
          },
        },
      ],
    },
    {
      Header: "Мероприятия",
      accessor: "events",
      columns: [
        {
          Header: "План",
          width: 180,
          accessor: "eventsPlan",
          Cell: (tableProps: CustomTableProps) => (
            <Typography sx={css.tableCellText(theme)} className="colorWhite">
              {tableProps.row.original.eventsPlan}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              return (
                roundNumbersToFixed(
                  tableProps?.rows?.[0]?.original.eventsPlanTotal || 0,
                  0
                ) || 0
              );
            }, [tableProps.rows]);

            return (
              <Typography sx={css.tableCellText(theme)} className="colorWhite">
                {total}
              </Typography>
            );
          },
        },
        {
          Header: "Факт",
          width: 180,
          accessor: "eventsDone",
          Cell: (tableProps: CustomTableProps) => (
            <Typography sx={css.tableCellText(theme)} className="colorGreen">
              {tableProps.row.original.eventsDone}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              return (
                roundNumbersToFixed(
                  tableProps?.rows?.[0]?.original.eventsDoneTotal || 0,
                  0
                ) || 0
              );
            }, [tableProps.rows]);

            return (
              <Typography sx={css.tableCellText(theme)} className="colorGreen">
                {total}
              </Typography>
            );
          },
        },
        {
          Header: "Не выполнено",
          width: 180,
          accessor: "eventsFail",
          Cell: (tableProps: CustomTableProps) => (
            <Typography sx={css.tableCellText(theme)} className="colorRed">
              {tableProps.row.original.eventsFail}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              return (
                roundNumbersToFixed(
                  tableProps?.rows?.[0]?.original.eventsFailTotal || 0,
                  0
                ) || 0
              );
            }, [tableProps.rows]);

            return (
              <Typography sx={css.tableCellText(theme)} className="colorRed">
                {total}
              </Typography>
            );
          },
        },
      ],
      Footer: "",
    },
  ];
};

export { getGeneralInfoMinistriesColumns };
