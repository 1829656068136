import { Box, SxProps } from "@mui/material";
import React, { CSSProperties } from "react";
import useSingleAndDoubleClick from "SRC/hooks/useSingleAndDoubleClick";

import { css } from "./Carousel.styled";
import CarouselButtons from "./CarouselButtons/CarouselButtons";

interface ICarouselProps {
  activeIndex: number;
  visibleItems?: number;
  withoutMargin?: boolean;
}

interface ICarouselItemProps {
  visibleItems?: number;
  style?: CSSProperties;
  onClick?: () => void;
  onDoubleClick?: () => void;
}

export const CarouselItem: React.FC<ICarouselItemProps> = ({
  children,
  visibleItems,
  style,
  onClick,
  onDoubleClick,
}) => {
  const itemWidth = visibleItems ? 100 / visibleItems : 100;

  const click = useSingleAndDoubleClick(onClick, onDoubleClick);

  return (
    <Box
      onClick={click}
      sx={css.carouselItemContainer(itemWidth) as SxProps}
      style={style}
    >
      {children}
    </Box>
  );
};

const Carousel: React.FC<ICarouselProps> = ({
  children,
  activeIndex,
  visibleItems,
  withoutMargin,
}) => {
  const offset = visibleItems ? 100 / visibleItems : 100;
  const scrollable =
    visibleItems && Array.isArray(children) && visibleItems < children.length;

  return (
    <Box sx={css.carouselContainer}>
      <Box
        sx={css.innerContainer}
        style={{
          transform: `translateX(-${!scrollable ? 0 : activeIndex * offset}%)`,
          margin: withoutMargin ? 0 : "0 -1rem",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export { CarouselButtons as CounterButtons };
export default Carousel;
