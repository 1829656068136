import { Theme } from "@mui/material";

export const indicatorCss = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
  },
  plusContainer: {
    position: "absolute",
    right: "-1rem",
    top: "-1rem",
    height: "2rem",
    width: "2rem",
    background: (theme: Theme) => theme.palette.main.darkBlue,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: (theme: Theme) =>
      `2px solid ${theme.palette.complementary.darkBlue}`,
    cursor: "pointer",

    "& img": {
      width: "0.75rem",
      height: "0.75rem",
    },
  },
  boxWithChartContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  boxWithChart: (theme: Theme) => ({
    display: "flex",
    flexDirection: "row",
    background: (theme: Theme) => theme.palette.complementary.darkBlueLight,
    borderRadius: "0.75rem",
    height: "5.75rem",
    position: "relative",
    width: "95%",

    [theme.breakpoints.up("xl")]: {
      height: "5rem",
    },
  }),
  infoItem: {
    width: "33%",
    padding: "0.75rem 0.9rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  chartItem: {
    width: "33%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "0.8rem",
    border: (theme: Theme) =>
      `2px solid ${theme.palette.complementary.darkBlue}`,
    marginLeft: "0.3rem",
    height: "103%",
    marginTop: "-0.05rem",
    padding: 0,
  },
};
