import { Box, SxProps } from "@mui/material";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { isSecret } from "SRC/helpers/dataTesters";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  TProgram,
  usePrograms,
} from "SRC/redux/slices/programsBoard/hooks/usePrograms";
import { PROGRAM_DETAIL, setIdToUrl } from "SRC/urls";

import { css } from "./Tiles.styled";
import TilesItemElement from "./TilesItemElement";

export default function Tiles() {
  const { items } = usePrograms();
  const { program, filteredPrograms }: IFilters = useFilters();

  const navigate = useNavigate();
  const programs = useMemo(
    (): (null | JSX.Element)[] =>
      items.map((item: TProgram) => {
        if (!filteredPrograms.includes(item.gp_code)) return null;
        return (
          <Box sx={css.elementSX as SxProps} key={item.gp_code}>
            <TilesItemElement
              {...item}
              secretProgram={isSecret(item)}
              onClick={() => setIdToUrl(PROGRAM_DETAIL, item.gp_code)}
            />
          </Box>
        );
      }),
    [items, filteredPrograms, program, navigate]
  );

  return <Box sx={css.container}>{programs}</Box>;
}
