import { Theme } from "@mui/material";

export const css = {
  icon: (theme: Theme, value: number) => ({
    width: "0.75rem",
    height: "0.75rem",
    backgroundColor:
      value < 100 ? theme.palette.pale.red : theme.palette.pale.green,
    borderRadius: "50%",
  }),
};
