import { Typography } from "@mui/material";
import { useCashExecution } from "SRC/redux/slices/event/hooks/useCashExecution";

export const CharacterTab = () => {
  const { items: cashExecution } = useCashExecution();

  const text = cashExecution ? cashExecution["Характеристика результата"] : "";

  return (
    <Typography
      component={"p"}
      sx={{
        fontSize: "1.5rem",
        lineHeight: "2rem",
        padding: "2rem 2.5rem",
        minHeight: "27rem",
      }}
    >
      {cashExecution && text}
    </Typography>
  );
};
