import { Box, SxProps, Tooltip, Typography, useTheme } from "@mui/material";
import { SmallPlus } from "ASSETS/svg";
import ReactECharts from "echarts-for-react";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";

import { css } from "../GoalItem.styled";
import { indicatorCss } from "./IndicatorInfo.styled";
import getOption from "./util/option";

interface IProps {
  index: number;
  setChartViewIndex: Dispatch<SetStateAction<any>>;
  plan: number;
  fact: number;
  unit: string;
  indicatorName: string;
  chartPlanData: (number | null)[];
  chartFactData: (number | null)[];
}

const IndicatorInfo = (props: IProps) => {
  const {
    index,
    plan,
    fact,
    unit,
    indicatorName,
    chartPlanData,
    chartFactData,
    setChartViewIndex,
  } = props;

  const theme = useTheme();

  const validPlanData = chartPlanData?.map((it) => {
    if (it === null) {
      return 0;
    }
    return it;
  });
  const planData = useMemo<number[]>(
    () => validPlanData?.filter((item: number) => item),
    [chartPlanData]
  );
  const factData = useMemo<number[]>(
    () =>
      chartFactData
        .map((it) => {
          if (it === null) {
            return 0;
          }
          return it;
        })
        ?.filter((item: number) => item),
    [chartFactData]
  );

  const maxPlan = Math.max.apply(null, planData);
  const maxFact = Math.max.apply(null, factData);
  const maxValue = Math.max(maxPlan, maxFact);

  return (
    <Box sx={indicatorCss.container}>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: css.hintContainer as SxProps,
          },
        }}
        title={
          <Typography sx={css.hintText as SxProps}>{indicatorName}</Typography>
        }
      >
        <Typography sx={css.indicatorName as SxProps}>
          {indicatorName}
        </Typography>
      </Tooltip>
      <Box sx={indicatorCss.boxWithChartContainer}>
        <Typography sx={css.percentSmallText}>{unit}</Typography>
        <Box sx={indicatorCss.boxWithChart as SxProps}>
          <Box
            sx={indicatorCss.plusContainer as SxProps}
            onClick={() => setChartViewIndex(index)}
          >
            <SmallPlus />
          </Box>
          <Box sx={indicatorCss.infoItem}>
            <Typography sx={css.smallText}>Факт</Typography>
            <Typography sx={css.factText as SxProps}>
              {roundNumbersToFixed(fact, 2)}
            </Typography>
          </Box>
          <Box sx={indicatorCss.infoItem}>
            <Typography sx={css.smallText}>План</Typography>
            <Typography sx={css.planText as SxProps}>
              {roundNumbersToFixed(plan, 2)}
            </Typography>
          </Box>
          <Box sx={indicatorCss.chartItem as SxProps}>
            <ReactECharts
              option={getOption(validPlanData, chartFactData, maxValue, theme)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default IndicatorInfo;
