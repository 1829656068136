import { createSlice } from "@reduxjs/toolkit";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";

export interface IEventParams {
  id: number | string;
  period: IPeriod;
}

export interface IProgramFiltersState {
  selectedStatus: string[];
  selectedStructureId: string;
  selectedStructureRow: string;
  selectedStructureCodes: string[];
  selectedEventFeature: string;
  selectedStructureElementType: string;
  selectedStructureElementName: string; // TODO для фильтра Наименование СЭ
}

const initialState: IProgramFiltersState = {
  selectedStatus: [],
  selectedStructureRow: "",
  selectedStructureId: "",
  selectedStructureCodes: [],
  selectedEventFeature: "",
  selectedStructureElementType: "",
  selectedStructureElementName: "", // TODO для фильтра Наименование СЭ
};

const slice = createSlice({
  name: "programFilters",
  initialState,
  reducers: {
    clear: () => initialState,
    selectStatus: (state, action: { payload: string }) => {
      const actionStatus = action.payload;
      const selectedStatuses = [...state.selectedStatus];

      if (selectedStatuses.includes(actionStatus)) {
        state.selectedStatus = selectedStatuses.filter(
          (status) => status !== actionStatus
        );
      } else {
        state.selectedStatus = selectedStatuses.concat(actionStatus);
      }
    },
    clearStatus: (state) => {
      state.selectedStatus = [];
    },
    selectStructureRow: (state, action: { payload: string }) => {
      state.selectedStructureRow = action.payload;
    },
    selectStructureId: (state, action: { payload: string }) => {
      state.selectedStructureId = action.payload;
    },
    selectStructureCodes: (state, action: { payload: string[] }) => {
      state.selectedStructureCodes = action.payload;
    },
    selectEventFeature: (state, action: { payload: string }) => {
      state.selectedEventFeature = action.payload;
    },
    selectStructureElementType: (state, action: { payload: string }) => {
      state.selectedStructureElementType = action.payload;
    },
    selectStructureElementName: (state, action: { payload: string }) => {
      state.selectedStructureElementName = action.payload;
    },
  },
});

export default slice;
