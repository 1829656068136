import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { IDataCheckpoints } from "SRC/pages/Event/interfaces";
import { getControlPoints } from "SRC/redux/slices/event/api/client";
import { IEventParams } from "SRC/redux/slices/event/slices/filters";

export interface IControlPoint {
  code: string;
  DatePart: string;
  PERIOD2: string;
  PeriodStart: string;
  PeriodEnd: string;
  NAME: string;
  PERIOD_CODE: string;
  data: IDataCheckpoints[];
}

export interface IControlPointsState {
  items: IControlPoint | null;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "controlPoints/get",
    async (params: IEventParams) => await getControlPoints(params)
  ),
};

const initialState: IControlPointsState = {
  items: null,
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "controlPoints",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = null;
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IControlPointsState, action) => {
          const data = action.payload.data?.[0]?.data as IControlPoint[];
          state.items = isContainedArray(data) ? data[0] : null;
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
