import { Search } from "@mui/icons-material";
import { FilledInput, FormControl, InputAdornment, Theme } from "@mui/material";
import React from "react";
import { TableInstance } from "react-table";
import { css as cssComplexGrid } from "SRC/pages/Main/common/ComplexGrid/ComplexGrid.styled";

import { css } from "./TableSearch.styled";

interface IProps {
  instance: TableInstance | undefined;
}

export const TableSearch = ({ instance }: IProps) => {
  return (
    <FormControl
      sx={cssComplexGrid.uiSearch}
      variant="filled"
      style={{ width: "100%", maxWidth: "100%" }}
    >
      <FilledInput
        startAdornment={
          <InputAdornment position="start">
            <Search
              sx={{
                color: (theme: Theme) => theme.palette.main.gray,
                width: "1.25rem",
                height: "1.25rem",
                ml: "10px",
              }}
            />
          </InputAdornment>
        }
        className="filledInput"
        sx={css.filledInput}
        placeholder="Поиск"
        value={instance?.state.globalFilter}
        autoFocus={Boolean(instance?.state.globalFilter)}
        onChange={(e) => instance?.setGlobalFilter(e.target.value)}
      />
    </FormControl>
  );
};
