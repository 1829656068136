import {
  getDataSumByCodeGP,
  getDataSumByCodeMin,
  getDataSumByCodeVP,
} from "SRC/constants/dataCodes";
import { ECalculationMethod } from "SRC/constants/globals";
import { isSecret } from "SRC/helpers/dataTesters";
import { TMinistry } from "SRC/redux/slices/summary/hooks/useMinistries";
import { TPremier } from "SRC/redux/slices/summary/hooks/usePremiers";
import { TProgram } from "SRC/redux/slices/summary/hooks/usePrograms";

import {
  IBestProgram,
  IBestProgram2,
  IMinistriesTableItem,
  IPremierTableItem,
  IProgramTableItem,
  IRatingTopData,
  ITopMinistries,
  ITopVP,
  IWorstProgram,
  IWorstProgram2,
} from "./interfaces";

export const createProgramRow = (
  elem: TProgram,
  method: ECalculationMethod
): IProgramTableItem => ({
  id: elem.gp_code,
  icon: elem.gp_icon_url,
  name: elem.gp_short_name,
  govProgram: elem.gp_short_name,
  secretProgram: isSecret(elem),
  structurePlan: getDataSumByCodeGP(elem, 1572) || 0,
  structurePlanTotal: getDataSumByCodeGP(elem, 1575) || 0,
  structureDone: getDataSumByCodeGP(elem, 1573) || 0,
  structureDoneTotal: getDataSumByCodeGP(elem, 1576) || 0,
  structureFail: getDataSumByCodeGP(elem, 1574) || 0,
  structureFailTotal: getDataSumByCodeGP(elem, 1577) || 0,
  atRisk: getDataSumByCodeGP(elem, 1680) || 0,
  vp: elem["Вице-премьер, код"] || "",
  vicePremier: elem["Вице-премьер"] !== -1 ? elem["Вице-премьер"] : "",
  foiv: elem["Министерство"],
  performance:
    method === ECalculationMethod.EFFECT
      ? getDataSumByCodeGP(elem, 1753) || 0
      : getDataSumByCodeGP(elem, 1796) || 0,
  performanceTotal: getDataSumByCodeGP(elem, 5246) || 0,
  populationSatisfaction: getDataSumByCodeGP(elem, 1765) || 0,
  cashPlan: getDataSumByCodeGP(elem, 1590) || 0,
  cashPlanTotal: getDataSumByCodeGP(elem, 1593) || 0,
  cashFact: getDataSumByCodeGP(elem, 1591) || 0,
  cashFactTotal: getDataSumByCodeGP(elem, 1594) || 0,
  goalsPlan: getDataSumByCodeGP(elem, 1755) || 0,
  // goalsPlanTotal: getDataSumByCodeGP(elem, 1755) || 0,
  goalsDone: getDataSumByCodeGP(elem, 1756) || 0,
  // goalsDoneTotal: getDataSumByCodeGP(elem, 1756) || 0,
  goalsFail: getDataSumByCodeGP(elem, 1757) || 0,
  // goalsFailTotal: getDataSumByCodeGP(elem, 1757) || 0,
  indicatorsPlan: getDataSumByCodeGP(elem, 1578) || 0,
  indicatorsPlanTotal: getDataSumByCodeGP(elem, 1581) || 0,
  indicatorsDone: getDataSumByCodeGP(elem, 1579) || 0,
  indicatorsDoneTotal: getDataSumByCodeGP(elem, 1582) || 0,
  indicatorsFail: getDataSumByCodeGP(elem, 1580) || 0,
  indicatorsFailTotal: getDataSumByCodeGP(elem, 1583) || 0,
  eventsPlan: getDataSumByCodeGP(elem, 1584) || 0,
  eventsPlanTotal: getDataSumByCodeGP(elem, 1587) || 0,
  eventsDone: getDataSumByCodeGP(elem, 1585) || 0,
  eventsDoneTotal: getDataSumByCodeGP(elem, 1588) || 0,
  eventsFail: getDataSumByCodeGP(elem, 1586) || 0,
  eventsFailTotal: getDataSumByCodeGP(elem, 1589) || 0,
  checkpointsPlan: getDataSumByCodeGP(elem, 5207) || 0,
  checkpointsPlanTotal: getDataSumByCodeGP(elem, 5250) || 0,
  checkpointsDone: getDataSumByCodeGP(elem, 5208) || 0,
  checkpointsDoneTotal: getDataSumByCodeGP(elem, 5251) || 0,
  checkpointsFail: getDataSumByCodeGP(elem, 5209) || 0,
  checkpointsFailTotal: getDataSumByCodeGP(elem, 5252) || 0,
});

export const createProgramsTableData = (
  data: TProgram[],
  method: ECalculationMethod
): IProgramTableItem[] =>
  data.map((elem: TProgram) => createProgramRow(elem, method));

export const createPremierRow = (
  elem: TPremier,
  method: ECalculationMethod,
  index: number
): IPremierTableItem => ({
  id: index,
  code: elem.VP_CODE,
  name: elem.VP_SHORT_NAME,
  vicePremier: elem.VP_SHORT_NAME,
  vicePremierPhoto: elem.VP_PHOTO_URL,
  structurePlan: getDataSumByCodeVP(elem, 1693) || 0,
  structurePlanTotal: getDataSumByCodeVP(elem, 1696) || 0,
  structureDone: getDataSumByCodeVP(elem, 1694) || 0,
  structureDoneTotal: getDataSumByCodeVP(elem, 1697) || 0,
  structureFail: getDataSumByCodeVP(elem, 1695) || 0,
  structureFailTotal: getDataSumByCodeVP(elem, 1698) || 0,
  govPrograms: getDataSumByCodeVP(elem, 1742) || 0,
  performance:
    method === ECalculationMethod.EFFECT
      ? getDataSumByCodeVP(elem, 1759) || 0
      : getDataSumByCodeVP(elem, 1797) || 0,
  performanceTotal: getDataSumByCodeVP(elem, 5248) || 0,
  populationSatisfaction: getDataSumByCodeVP(elem, 1764) || 0,
  cashPlan: getDataSumByCodeVP(elem, 1711) || 0,
  cashPlanTotal: getDataSumByCodeVP(elem, 1714) || 0,
  cashFact: getDataSumByCodeVP(elem, 1712) || 0,
  cashFactTotal: getDataSumByCodeVP(elem, 1715) || 0,
  goalsPlan: getDataSumByCodeVP(elem, 1761) || 0,
  // goalsPlanTotal: getDataSumByCodeVP(elem, 1761) || 0,
  goalsDone: getDataSumByCodeVP(elem, 1762) || 0,
  // goalsDoneTotal: getDataSumByCodeVP(elem, 1762) || 0,
  goalsFail: getDataSumByCodeVP(elem, 1763) || 0,
  // goalsFailTotal: getDataSumByCodeVP(elem, 1763) || 0,
  indicatorsPlan: getDataSumByCodeVP(elem, 1699) || 0,
  indicatorsPlanTotal: getDataSumByCodeVP(elem, 1702) || 0,
  indicatorsDone: getDataSumByCodeVP(elem, 1700) || 0,
  indicatorsDoneTotal: getDataSumByCodeVP(elem, 1703) || 0,
  indicatorsFail: getDataSumByCodeVP(elem, 1701) || 0,
  indicatorsFailTotal: getDataSumByCodeVP(elem, 1704) || 0,
  eventsPlan: getDataSumByCodeVP(elem, 1705) || 0,
  eventsPlanTotal: getDataSumByCodeVP(elem, 1708) || 0,
  eventsDone: getDataSumByCodeVP(elem, 1706) || 0,
  eventsDoneTotal: getDataSumByCodeVP(elem, 1709) || 0,
  eventsFail: getDataSumByCodeVP(elem, 1707) || 0,
  eventsFailTotal: getDataSumByCodeVP(elem, 1710) || 0,
  checkpointsPlan: getDataSumByCodeGP(elem, 5207) || 0,
  checkpointsPlanTotal: getDataSumByCodeVP(elem, 5256) || 0,
  checkpointsDone: getDataSumByCodeGP(elem, 5208) || 0,
  checkpointsDoneTotal: getDataSumByCodeVP(elem, 5257) || 0,
  checkpointsFail: getDataSumByCodeGP(elem, 5209) || 0,
  checkpointsFailTotal: getDataSumByCodeVP(elem, 5258) || 0,
});

export const createPremiersTableData = (
  data: TPremier[],
  method: ECalculationMethod
): IPremierTableItem[] =>
  data.map((elem: TPremier, index: number) =>
    createPremierRow(elem, method, index + 1)
  );

export const createTopMinistriesData = (
  data: ITopMinistries[]
): IRatingTopData[] =>
  data.map((item: ITopMinistries) => ({
    name: item?.NAME,
    sum: item?.data[0].sum,
  }));

export const createBestGP1Data = (data: IBestProgram[]): IRatingTopData[] =>
  data.map((item: IBestProgram) => ({
    url: item?.gp_icon_url,
    name: item?.gp_name,
    code: item?.gp_code,
    sum: item?.sum,
    secret: isSecret(item),
  }));

export const createBestGP2Data = (data: IBestProgram2[]): IRatingTopData[] =>
  data.map((item: IBestProgram2) => ({
    url: item?.gp_icon_url,
    name: item?.gp_name,
    code: item?.gp_code,
    sum: item?.data.find((el) => el.code === "1796")?.sum || 0,
    secret: isSecret(item),
  }));

export const createWorstGP1Data = (data: IWorstProgram[]): IRatingTopData[] =>
  data.map((item: IWorstProgram) => ({
    url: item?.gp_icon_url,
    name: item?.gp_name,
    code: item?.gp_code,
    sum: item?.sum,
    secret: isSecret(item),
  }));

export const createWorstGP2Data = (data: IWorstProgram2[]): IRatingTopData[] =>
  data.map((item: IWorstProgram2) => ({
    url: item?.gp_icon_url,
    name: item?.gp_name,
    code: item?.gp_code,
    sum: item?.data.find((el) => el.code === "1796")?.sum || 0,
    secret: isSecret(item),
  }));

export const createBestVP1Data = (data: ITopVP[]): IRatingTopData[] =>
  data.map((item: ITopVP) => ({
    url: item?.VP_PHOTO_URL,
    name: item?.VP_NAME,
    sum: item?.sum,
  }));

export const createBestVP2Data = (data: ITopVP[]): IRatingTopData[] =>
  data.map((item: ITopVP) => ({
    url: item?.VP_PHOTO_URL,
    name: item?.VP_NAME,
    sum: item?.sum,
  }));

export const createWorstVP1Data = (data: ITopVP[]): IRatingTopData[] =>
  data.map((item: ITopVP) => ({
    url: item?.VP_PHOTO_URL,
    name: item?.VP_NAME,
    sum: item?.sum,
  }));

export const createWorstVP2Data = (data: ITopVP[]): IRatingTopData[] =>
  data.map((item: ITopVP) => ({
    url: item?.VP_PHOTO_URL,
    name: item?.VP_NAME,
    sum: item?.sum,
  }));

export const sortRatingDataBySum = (data: IRatingTopData[]) => {
  return data.sort(({ sum: sumA }, { sum: sumB }) => {
    return sumA > sumB ? -1 : 1;
  });
};

export const createMinistriesTableData = (
  data: TMinistry[],
  method: ECalculationMethod
): IMinistriesTableItem[] =>
  data.map((elem: TMinistry, index: number) => ({
    id: index + 1,
    name: elem.NAME,
    govPrograms: getDataSumByCodeMin(elem, 1782) || 0,
    eventsPlan: getDataSumByCodeMin(elem, 1785) || 0,
    eventsDone: getDataSumByCodeMin(elem, 1786) || 0,
    eventsFail: getDataSumByCodeMin(elem, 1787) || 0,
    performance:
      method === ECalculationMethod.EFFECT
        ? getDataSumByCodeMin(elem, 1788) || 0
        : getDataSumByCodeMin(elem, 1845) || 0,
  }));
