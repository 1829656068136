import { CircularProgress, SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import ProgressDoughnutFinancing from "SRC/components/Charts/ProgressDoughnutFinancing/ProgressDoughnutFinancing";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { Overlay } from "SRC/pages/App.styled";
import { useStatistics } from "SRC/redux/slices/gosprogram/hooks/useStatistics";

import { css } from "./FinancingInfo.styled";

interface IFinancingData {
  fact: number;
  plan: number;
  budget: {
    total: number;
    done: number;
  };
  extrabudget: {
    total: number;
    done: number;
  };
  units: string;
}

export const FinancingInfo = () => {
  const { items: statistics, fetching } = useStatistics();

  const financingData: IFinancingData = useMemo(
    () => ({
      fact: roundNumbersToFixed(statistics["Финансирование факт"], 1),
      plan: roundNumbersToFixed(statistics["Финансирование план"], 1),
      budget: {
        total: roundNumbersToFixed(statistics["Финансирование бюджет план"], 1),
        done: roundNumbersToFixed(statistics["Финансирование бюджет факт"], 1),
      },
      extrabudget: {
        total: roundNumbersToFixed(
          statistics["Финансирование внебюджет план"],
          0
        ),
        done: roundNumbersToFixed(
          statistics["Финансирование внебюджет факт"],
          0
        ),
      },
      units: "млрд.руб.",
    }),
    [statistics]
  );

  if (fetching)
    return (
      <Box sx={css.container as SxProps}>
        <Box sx={css.container as SxProps}>
          <Overlay>
            <CircularProgress />
          </Overlay>
        </Box>
      </Box>
    );

  return (
    <Box sx={css.container as SxProps}>
      <Box sx={css.title}>Финансирование</Box>
      <Box sx={css.content}>
        <Box sx={css.contentLeft}>
          <Box sx={css.doughnut}>
            <ProgressDoughnutFinancing
              value={financingData.fact}
              total={financingData.plan}
              withDarkBgr={true}
            />
          </Box>
          <Box sx={css.doughnutInfo}>
            <Box sx={css.infoTop}>
              <Box sx={css.point("fact")} />
              {financingData.fact} <span>млрд ₽ /</span>{" "}
              {roundNumbersToFixed(
                (100 * financingData.fact) / financingData.plan,
                0
              )}{" "}
              <span>%</span>
            </Box>
            <Box sx={css.infoBottom}>
              <Box sx={css.infoName}>
                <Box sx={css.point("fact")} />
                <Box>Факт</Box>
              </Box>
              <Box sx={css.infoName}>
                <Box sx={css.point("plan")} />
                <Box>План</Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={css.contentRight as SxProps}>
          <Box sx={css.rightItem}>
            <Box sx={css.itemTitle as SxProps}>
              Бюджетных средств<span>{financingData.units}</span>
            </Box>
            <Box sx={css.itemInfoContainer}>
              <Box sx={css.itemInfo}>
                <Box sx={css.itemName}>Факт</Box>
                <Box sx={css.itemValue}>{financingData.budget.done}</Box>
              </Box>
              <Box sx={css.itemInfo}>
                <Box sx={css.itemName}>План</Box>
                <Box sx={css.itemValue}>{financingData.budget.total}</Box>
              </Box>
            </Box>
          </Box>
          <Box sx={css.rightItem}>
            <Box sx={css.itemTitle as SxProps}>
              Внебюджетных средств<span>{financingData.units}</span>
            </Box>
            <Box sx={css.itemInfoContainer}>
              <Box sx={css.itemInfo}>
                <Box sx={css.itemName}>Факт</Box>
                <Box sx={css.itemValue}>{financingData.extrabudget.done}</Box>
              </Box>
              <Box sx={css.itemInfo}>
                <Box sx={css.itemName}>План</Box>
                <Box sx={css.itemValue}>{financingData.extrabudget.total}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
