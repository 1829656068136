import AddIcon from "@mui/icons-material/Add";
import { Box, Grid, SxProps } from "@mui/material";
import { SecretProgram } from "ASSETS/svg";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { css } from "SRC/components/Charts/Equalizer/Equalizer.styled";
import { getDataSumByCodeGP } from "SRC/constants/dataCodes";
import { ECalculationMethod } from "SRC/constants/globals";
import { isSecret } from "SRC/helpers/dataTesters";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { setFocus } from "SRC/helpers/ui-helpers";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { TProgram, usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";

interface ITooltipProps {
  coords: [number, number];
  selected: string;
  onBlur(): void;
}

const gpCodeByCalcM = {
  [ECalculationMethod.EFFECT]: 1753,
  [ECalculationMethod.RESULT]: 1796,
};

const pure = (prev: ITooltipProps, next: ITooltipProps) => {
  return prev.selected === next.selected;
};

const Tooltip = React.memo(({ coords, selected, onBlur }: ITooltipProps) => {
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const [hovered, setHovered] = useState<Boolean>(false);
  const { getItemByCode, items } = usePrograms();
  const { calculationMethod }: IFilters = useFilters();
  const data: TProgram | undefined = useMemo(
    () => getItemByCode(selected),
    [selected, items]
  );

  useEffect(() => {
    if (selected && tooltipRef?.current) {
      setFocus(tooltipRef);
    } else {
      setHovered(false);
    }
  }, [tooltipRef, selected]);

  const onHandleBlur = useCallback(() => {
    if (!hovered) {
      onBlur();
    }
  }, [hovered, onBlur]);

  const testCM = useMemo(
    () => getDataSumByCodeGP(data, gpCodeByCalcM[calculationMethod] || 0) || 0,
    [calculationMethod, data]
  );

  if (!data || !selected) return null;

  return (
    <Box
      sx={css.tooltipContainer(coords) as SxProps}
      ref={tooltipRef}
      onBlur={onHandleBlur}
      onMouseLeave={onBlur}
      onMouseOver={() => setHovered(true)}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        sx={css.tooltipHeader}
      >
        <Grid item sx={css.tooltipHeaderLeft}>
          <Box> {data.gp_code}</Box>
          {isSecret(data) && (
            <Box sx={css.secretProgram}>
              <SecretProgram />
            </Box>
          )}
        </Grid>
        <Grid item sx={css.tooltipHeaderRight(testCM)}>
          {roundNumbersToFixed(testCM, 1)}{" "}
          <Box component="span" sx={css.tooltipHeaderRightPercent}>
            %
          </Box>
        </Grid>
      </Grid>
      <Box sx={css.tooltipText}>{data.gp_name}</Box>
      <Box sx={css.tooltipIconWrapper as SxProps}>
        <img
          src={data.gp_icon_url}
          alt={data.gp_name}
          style={css.tooltipImg as CSSProperties}
        />
      </Box>
      <Box sx={css.tooltipBtn as SxProps}>
        <Link to={`program/${data.gp_code}`}>
          <AddIcon sx={css.tooltipBtnIcon} />
        </Link>
      </Box>
    </Box>
  );
}, pure);

export default Tooltip;
