import {
  olapRequestData2,
  olapRequestData3,
  olapRequestData4,
  olapRequestData5,
} from "CORE/api/core";
import { MODEL_UUID, SECTION_NAMES } from "CORE/scheme/olap";
import { getGpIndicators, getVpIndicators } from "SRC/constants/dataCodes";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";
import { IStatParams } from "SRC/redux/slices/main/slices/stats";

const GET_PROGRAMS = "get-programs";
const GET_STATS = "get-stats";
const GET_GOALS = "get-goals";
const GET_PREMIERS = "get-premiers";
const GET_MINISTRIES = "get-ministries";
const GET_SPHERES = "get-spheres";
const GET_SUM_SPHERES = "get-sum-spheres";
const GET_METHODS = "get-methods";

export const getPrograms = (period: IPeriod) => {
  return olapRequestData4(
    [
      {
        requestId: "29968ac9-ab0e-468b-b55c-aebb90030864",
        type: "data2",
        params: {
          modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1562",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1565",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "gp_short_name",
                "Вице-премьер",
                "gp_name",
                "Министерство",
              ],
            },
          ],
          indicators: {
            id: "1571",
            items: getGpIndicators(
              1578,
              1579,
              1580,
              1584,
              1585,
              1586,
              1679,
              1680,
              1590,
              1753,
              1754,
              1755,
              1756,
              1757,
              1769,
              1796,
              5246
            ),
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1562",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1565",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1565",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_PROGRAMS
  );
};

export const getStats = (params: IStatParams) => {
  return olapRequestData2(
    {
      dimensions: [],
      indicators: {},
      section_name: SECTION_NAMES.STATS,
      modelUuid: MODEL_UUID,
      filters: {
        period_code: params.period.filterValue,
        gp_code: params.gp_code,
        vp_code: params.vp_code,
        sphere: params.sphere,
      },
    },
    GET_STATS
  );
};

export const getGoals = (period: IPeriod) => {
  return olapRequestData2(
    {
      dimensions: [],
      indicators: {},
      section_name: SECTION_NAMES.GOALS,
      modelUuid: MODEL_UUID,
      filters: {
        period_code: period.filterValue,
      },
    },
    GET_GOALS
  );
};

export const getPremiers = (period: IPeriod) => {
  return olapRequestData4(
    [
      {
        requestId: "46c4caf5-da1a-48ba-898b-79a6d6196bd6",
        type: "data2",
        params: {
          modelUuid: "f8cfb9f5-ce0b-4b19-86c5-fd5a55d97a77",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1683",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1686",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "VP_PHOTO_URL",
                "VP_NAME",
              ],
            },
          ],
          indicators: {
            id: "1692",
            items: getVpIndicators(
              1742,
              1743,
              1744,
              1747,
              1748,
              1749,
              1767,
              1768,
              1769,
              1797,
              1699,
              1700,
              5248
            ),
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1683",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1686",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1686",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1683",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_PREMIERS
  );
};

// Министерства
export const getMinistries = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "44306cc1-9221-4740-a6cb-c8a92b413f45",
        type: "data2",
        params: {
          modelUuid: "485e7c43-6639-4b8e-9979-996461bea181",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1770",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "GERB_ICON",
              ],
            },
            {
              id: "1774",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "DatePart",
                "PeriodStart",
                "PeriodEnd",
                "PERIOD2",
              ],
            },
          ],
          indicators: {
            id: "1781",
            items: [
              {
                id: "1782",
                aggregationFunction: "sum",
              },
              {
                id: "1783",
                aggregationFunction: "sum",
              },
              {
                id: "1784",
                aggregationFunction: "sum",
              },
              {
                id: "1785",
                aggregationFunction: "sum",
              },
              {
                id: "1786",
                aggregationFunction: "sum",
              },
              {
                id: "1787",
                aggregationFunction: "sum",
              },
              {
                id: "1788",
                aggregationFunction: "sum",
              },
              {
                id: "1845",
                aggregationFunction: "sum",
              },
            ],
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1770",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1774",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1770",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1774",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_MINISTRIES
  );
};

export const getSpheres = (period: IPeriod) => {
  return olapRequestData4(
    [
      {
        requestId: "dbd3e35e-b26a-4ed3-8082-b802f8a91450",
        type: "data2",
        params: {
          modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1562",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1835",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1571",
            items: [
              {
                id: "1753",
                aggregationFunction: "sum",
              },
              {
                id: "1796",
                aggregationFunction: "sum",
              },
              {
                id: "1837",
                aggregationFunction: "sum",
              },
            ],
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1562",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1835",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1835",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_SPHERES
  );
};

export const getSumSpheres = (period: IPeriod) => {
  return olapRequestData5(
    [
      {
        requestId: "7d93addc-4f47-4340-8695-08c07c1f2a74",
        type: "data2",
        params: {
          modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1562",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1571",
            items: getGpIndicators(1753, 1796, 1837, 1843, 1844),
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 2,
                    id: "1565",
                    attributeCode: "Секретная",
                  },
                  {
                    type: "TEXT",
                    version: 2,
                    values: ["0"],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_SUM_SPHERES
  );
};

export const getMethods = () => {
  return olapRequestData5(
    [
      {
        requestId: "cd9f0aca-4c83-415d-863a-c14bccc1de94",
        type: "data2",
        params: {
          modelUuid: "2d1cc9ce-87da-40b3-80b6-37679e0357fd",
          dimensions: [],
          indicators: {},
          section_name: "Инфо методов расчёта УД",
          filters: {},
        },
      },
    ],
    GET_METHODS
  );
};
