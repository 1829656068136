import { Box, CircularProgress, SxProps } from "@mui/material";
import { WhiteArrowLeft, WhiteArrowRight } from "ASSETS/svg/arrow";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardsAnalitic } from "SRC/components/CardsAnalitic/CardsAnalitic";
import Carousel, { CarouselItem } from "SRC/components/CarouselNew/Carousel";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { Financing } from "SRC/pages/Main/common/Analitics/Financing/Financing";
import { Population } from "SRC/pages/Main/common/Analitics/Population/Population";
import { ProgramList } from "SRC/pages/Main/common/Analitics/ProgramList/ProgramList";
import { RisksTabs } from "SRC/pages/Main/common/Analitics/Risks/common/RisksTabs";
import { StructureElements } from "SRC/pages/Main/common/Analitics/StructureElements/StructureElements";
import { useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import { IFinancingData } from "SRC/types/analitics";

import { css } from "./Analitics.styled";

interface IRightTitleProps {
  isTitle?: boolean;
  isDoubleTitle?: boolean;
  isDetail?: boolean;
  link?: string;
  isActiveButton?: boolean | undefined;
}

export const RightTitle = ({
  isTitle,
  isDoubleTitle,
  isDetail,
  link,
  isActiveButton,
}: IRightTitleProps) => {
  const { items: stats } = useStats();
  const { filteredPrograms } = useFilters();
  const dataStats = useMemo(() => stats[0]?.data || {}, [stats]);

  const financingData = useMemo<IFinancingData>(
    () => ({
      extrabudgetary: {
        plan: dataStats["Финансирование внебюджет план"],
        fact: dataStats["Финансирование внебюджет факт"],
      },
      budget: {
        plan: dataStats["Финансирование бюджет план"],
        fact: dataStats["Финансирование бюджет факт"],
      },
      totalFinancing: {
        plan: dataStats["Финансирование план"],
        fact: dataStats["Финансирование факт"],
      },
    }),
    [dataStats]
  );
  const navigate = useNavigate();
  const handleLink = () => {
    if (link) {
      navigate(`${link}`);
    }
  };
  return (
    <Box sx={css.titleRightContainer}>
      {isDoubleTitle ? (
        <Box sx={css.titleRight}>
          <Box sx={css.title}>
            {`${roundNumbersToFixed(
              financingData.totalFinancing.fact,
              1
            )} / ${roundNumbersToFixed(financingData.totalFinancing.plan, 1)}`}
          </Box>
          <Box sx={css.unit}>млрд ₽</Box>
        </Box>
      ) : isTitle ? (
        <Box sx={css.title}>{filteredPrograms.length}</Box>
      ) : null}
      {isDetail ? (
        <Box
          sx={css.detailBtn(isActiveButton) as SxProps}
          onClick={() => handleLink()}
        >
          <WhiteArrowRight />
        </Box>
      ) : null}
    </Box>
  );
};

const analyticsCards = [
  {
    name: "Список госпрограмм",
    component: <ProgramList />,
    rightTitle: (
      <RightTitle isDetail isTitle isActiveButton link="/programs/" />
    ),
    style: css.programListContainer,
  },
  {
    name: "Структурные элементы",
    component: <StructureElements />,
  },
  {
    name: "Финансирование",
    component: <Financing />,
    rightTitle: <RightTitle isDoubleTitle />,
    inDevelopment: true,
  },
  {
    name: "Риски",
    component: <RisksTabs />,
  },
  {
    name: "Опросы населения",
    component: <Population />,
    rightTitle: <RightTitle isDetail />,
    inDevelopment: true,
  },
];

export const Analitics = (): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPrevBtnDisabled, setPrevBtnDisabled] = useState(false);
  const [isNextBtnDisabled, setNextBtnDisabled] = useState(false);
  const { fetching } = useStats();

  useEffect(() => {
    setPrevBtnDisabled(activeIndex === 0);
    setNextBtnDisabled(activeIndex === 1);
  }, [activeIndex, analyticsCards.length]);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = analyticsCards.length - 1;
    } else if (newIndex >= analyticsCards.length) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  const onPrevClick = () => {
    if (!isPrevBtnDisabled) {
      updateIndex(activeIndex - 1);
    }
  };
  const onNextClick = () => {
    if (!isNextBtnDisabled) {
      updateIndex(activeIndex + 1);
    }
  };

  const analyticsCardItems = useMemo(
    (): JSX.Element[] =>
      analyticsCards.map((item: any, i: number) => (
        <CarouselItem
          key={i}
          visibleItems={4}
          style={{
            padding: "0 0.5rem",
            pointerEvents: item.name === "Опросы населения" ? "none" : "unset",
            opacity: item.name === "Опросы населения" ? 0.4 : 1,
          }}
        >
          {fetching && i ? (
            <CardsAnalitic style={css.defaultItem} headerLeft={item.name}>
              <CircularProgress />
            </CardsAnalitic>
          ) : item.name !== "Риски" ? (
            <CardsAnalitic
              headerLeft={item.name}
              headerRight={item.rightTitle}
              style={item.style || {}}
              inDevelopment={item.inDevelopment}
            >
              {item.component}
            </CardsAnalitic>
          ) : (
            <CardsAnalitic style={item.style || {}}>
              {item.component}
            </CardsAnalitic>
          )}
        </CarouselItem>
      )),
    [analyticsCards, fetching]
  );

  return (
    <Box sx={css.container}>
      <Box sx={css.carouselContainer}>
        <Box
          onClick={onPrevClick}
          sx={css.button(isPrevBtnDisabled) as SxProps}
        >
          <WhiteArrowLeft style={css.arrow} />
        </Box>
        <Box sx={css.carousel}>
          <Carousel visibleItems={1} activeIndex={activeIndex}>
            {analyticsCardItems}
          </Carousel>
        </Box>
        <Box
          onClick={onNextClick}
          sx={css.button(isNextBtnDisabled) as SxProps}
          className="nextBtn"
        >
          <WhiteArrowRight style={css.arrow} />
        </Box>
      </Box>
    </Box>
  );
};
