import { Theme } from "@mui/material";

export const css = {
  progressBar: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  bar: {
    width: "100%",
  },
  nameAndPercent: {
    display: "flex",
    flexDirection: "column",
    flex: "0 0 25%",
  },
  name: (isMain: boolean) => ({
    fontSize: isMain ? "1.125rem" : "1rem",
    color: (theme: Theme) => theme.palette.text.primary,
    "&.mainProgressBar": {
      fontSize: "1.125rem",
    },
  }),
  percent: (isMain: boolean) => ({
    fontSize: isMain ? "1.5rem" : "1.125rem",
  }),
};
