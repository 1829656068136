import { Theme } from "@mui/material";

export const css = {
  modalBox: (theme: Theme) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 4rem)",
    borderRadius: "0.75rem",
    border: `1px solid ${theme.palette.divider}`,
    overflow: "hidden",
    padding: "2rem",
    height: "calc(100% - 2rem)",
    boxSizing: "border-box",
    background: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      maxWidth: theme.breakpoints.values.md,
      minWidth: "unset",
      width: "calc(100% - 5rem)",
    },
  }),

  modalHeader: {
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: "1rem",
  },

  tableName: {
    fontSize: "2.5rem",
    color: (theme: Theme) => theme.palette.primary.contrastText,
    fontWeight: "bold",
  },

  closeButton: {
    cursor: "pointer",
  },
};

{
  /*
display: flex;
flex: 1;
flex-direction: column;
overflow-y: hidden;
overflow-x: visible;
padding: 0 1rem;
*/
}
