import { getGpIndicators } from "SRC/constants/dataCodes";
import { olapRequestData3, olapRequestData4 } from "SRC/core/api/core";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";

const GET_PROGRAMS = "get-programs";
const GET_SUMMARY = "get-summary";

export const getPrograms = (period: IPeriod) => {
  return olapRequestData4(
    [
      {
        requestId: "29968ac9-ab0e-468b-b55c-aebb90030864",
        type: "data2",
        params: {
          modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1562",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1565",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "gp_short_name",
                "Вице-премьер",
                "gp_name",
                "Министерство",
              ],
            },
          ],
          indicators: {
            id: "1571",
            items: getGpIndicators(
              1578,
              1579,
              1580,
              1584,
              1585,
              1586,
              1679,
              1680,
              1590,
              1753,
              1754,
              1755,
              1756,
              1757,
              1769,
              1796,
              1843
            ),
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1562",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1565",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1565",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_PROGRAMS
  );
};

export const getSummary = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "7d93addc-4f47-4340-8695-08c07c1f2a74",
        type: "data2",
        params: {
          modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1562",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1571",
            items: getGpIndicators(1753, 1796, 1837, 1843, 1844),
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 2,
                    id: "1565",
                    attributeCode: "Секретная",
                  },
                  {
                    type: "TEXT",
                    version: 2,
                    values: ["0"],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_SUMMARY
  );
};
