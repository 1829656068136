import { combineReducers } from "@reduxjs/toolkit";
import programFiltersSlice, {
  IProgramFiltersState,
} from "SRC/redux/slices/gosprogram/slices/filters";
import goalConnect, {
  IGoalConnectState,
} from "SRC/redux/slices/gosprogram/slices/goalConnect";
import {
  IProgramState,
  slice as programSlice,
} from "SRC/redux/slices/gosprogram/slices/program";
import resultCharacteristicSlice, {
  IResultCharacteristicState,
} from "SRC/redux/slices/gosprogram/slices/resultCharacteristic";
import statisticsSlice, {
  IStatisticsState,
} from "SRC/redux/slices/gosprogram/slices/statistics";
import {
  IStructureElementsGPState,
  IStructureElementsIndicatorsState,
  IStructureElementsState,
  structureElementsGPSlice,
  structureElementsIndicatorsSlice,
  structureElementsSlice,
} from "SRC/redux/slices/gosprogram/slices/structureElements";
import headerPrograms, {
  ISummaryState,
} from "SRC/redux/slices/programsBoard/slices/summary";

import eventsSlice, { IEventsState } from "./slices/events";
import indicatorsSlice, { IIndicatorsState } from "./slices/indicators";
import programGoalsSlice, { IProgramGoalsState } from "./slices/programGoals";
import tableTypeSlice, { ITableTypeState } from "./slices/tableType";

export interface IGosprogramReducer {
  programGoals: IProgramGoalsState;
  events: IEventsState;
  indicators: IIndicatorsState;
  statistics: IStatisticsState;
  tableType: ITableTypeState;
  filters: IProgramFiltersState;
  resultCharacteristic: IResultCharacteristicState;
  goalConnects: IGoalConnectState;
  headerPrograms: ISummaryState;
  program: IProgramState;
  structureElements: IStructureElementsState;
  structureElementsGP: IStructureElementsGPState;
  structureElementsIndicators: IStructureElementsIndicatorsState;
}

const reducer = combineReducers<IGosprogramReducer>({
  programGoals: programGoalsSlice.reducer,
  events: eventsSlice.reducer,
  indicators: indicatorsSlice.reducer,
  statistics: statisticsSlice.reducer,
  tableType: tableTypeSlice.reducer,
  filters: programFiltersSlice.reducer,
  resultCharacteristic: resultCharacteristicSlice.reducer,
  goalConnects: goalConnect.reducer,
  headerPrograms: headerPrograms.reducer,
  program: programSlice.reducer,
  structureElements: structureElementsSlice.reducer,
  structureElementsGP: structureElementsGPSlice.reducer,
  structureElementsIndicators: structureElementsIndicatorsSlice.reducer,
});

export default reducer;
