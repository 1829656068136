import { Box, useTheme } from "@mui/material";
import React, { useLayoutEffect, useMemo, useState } from "react";
import MethodSwitcher from "SRC/components/MethodSwitcher/MethodSwitcher";
import SecretSwitcher from "SRC/components/SecretSwitcher/SecretSwitcher";
import { IOption } from "SRC/components/SelectWithSearch/SelectWithSearch";
import SelectWithSearchMinistry from "SRC/components/SelectWithSearch/SelectWithSearchMinistry";
import SelectWithSearchVP from "SRC/components/SelectWithSearch/SelectWithSearchVP";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  IMinistries,
  useMinistries,
} from "SRC/redux/slices/main/hooks/useMInistries";
import {
  IPremiers,
  usePremiers,
} from "SRC/redux/slices/main/hooks/usePremiers";
import themeConfig from "SRC/theme";

import { css } from "./MainHeaderFilter.styled";

const MainHeaderFilter = () => {
  const theme = useTheme<typeof themeConfig>();

  const [scrolled, setScrolled] = useState<boolean>(false);
  const {
    vp,
    ministry,
    setSelectedVP,
    setSelectedMinistry,
    filteredVPs,
    filteredMinistries,
    setCalculationMethod,
    calculationMethod,
  }: IFilters = useFilters();

  const { items: vpItems }: IPremiers = usePremiers();
  const { items: minItems }: IMinistries = useMinistries();

  const premiers: IOption[] = useMemo(
    () =>
      vpItems
        .filter((item) => filteredVPs.includes(item.VP_CODE))
        .map((item) => ({
          value: item.VP_CODE,
          label: item.VP_SHORT_NAME,
        })),
    [vpItems, filteredVPs]
  );

  const ministries: IOption[] = useMemo(() => {
    return minItems
      .filter((ministry) => filteredMinistries.includes(ministry.code))
      .map((item) => ({
        value: item.code,
        label: item.NAME,
      }));
  }, [minItems, filteredMinistries]);
  const onScroll = () => {
    setScrolled(Boolean(window.scrollY));
  };

  useLayoutEffect(() => {
    window.addEventListener("scroll", onScroll);
    onScroll();
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const selectStyle = useMemo(() => css.selector(theme), [theme]);
  return (
    <Box sx={css.wrapper(scrolled)}>
      <Box sx={css.selectors}>
        <SelectWithSearchMinistry
          value={ministry}
          label="Министерство"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedMinistry}
          options={ministries}
        />
        <SelectWithSearchVP
          value={vp}
          label="Вице-премьер"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedVP}
          options={premiers}
        />
      </Box>
      <MethodSwitcher
        value={calculationMethod}
        onChange={setCalculationMethod}
      />
      <SecretSwitcher />
    </Box>
  );
};

export default MainHeaderFilter;
