import { MutableRefObject } from "react";

export const setFocus = (
  htmlElement: MutableRefObject<
    HTMLDivElement | HTMLSpanElement | HTMLInputElement | null
  >
): void => {
  setTimeout((): void => {
    const element: HTMLDivElement | HTMLSpanElement | undefined | null =
      htmlElement?.current;

    if (element) {
      element.tabIndex = -1;
      element.style.outline = "none";
      element.focus({ preventScroll: true });
    }
  });
};
