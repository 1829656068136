import { Theme, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import { Layout } from "SRC/layouts";
import { useSummary } from "SRC/redux/slices/summary/hooks/useSummary";

import { TSortBy } from "./interfaces";
import { CenterLayout, LeftLayout, RightLayout } from "./layouts";

export const Summary = () => {
  const theme = useTheme<Theme>();
  const isLargeSize = useMediaQuery(theme.breakpoints.up("lg"));

  const [sortBy, setSortBy] = useState<TSortBy>(null);
  useSummary();

  const leftTableRef = useRef<HTMLElement>();
  const cashTableRef = useRef<HTMLElement>();
  const goalTableRef = useRef<HTMLElement>();
  const indicatorsTableRef = useRef<HTMLElement>();
  const eventsTableRef = useRef<HTMLElement>();
  const satisfactionTableRef = useRef<HTMLElement>();

  const handleScrollLeft = (scroll: any) => {
    if (
      cashTableRef.current &&
      goalTableRef.current &&
      indicatorsTableRef.current &&
      eventsTableRef.current &&
      satisfactionTableRef.current
    ) {
      cashTableRef.current.scrollTop = scroll.target.scrollTop;
      goalTableRef.current.scrollTop = scroll.target.scrollTop;
      indicatorsTableRef.current.scrollTop = scroll.target.scrollTop;
      eventsTableRef.current.scrollTop = scroll.target.scrollTop;
      satisfactionTableRef.current.scrollTop = scroll.target.scrollTop;
    }
  };

  const handleScrollCenter = (scroll: any) => {
    if (leftTableRef.current) {
      leftTableRef.current.scrollTop = scroll.target.scrollTop;
    }
  };

  return (
    <Layout
      left={
        <LeftLayout
          isLargeSize={isLargeSize}
          forwardRef={leftTableRef}
          onScroll={handleScrollLeft}
          setSortBy={setSortBy}
        />
      }
      center={
        isLargeSize && (
          <CenterLayout
            cashTableRef={cashTableRef}
            goalTableRef={goalTableRef}
            indicatorsTableRef={indicatorsTableRef}
            eventsTableRef={eventsTableRef}
            satisfactionTableRef={satisfactionTableRef}
            onScroll={handleScrollCenter}
            setSortBy={setSortBy}
            sortBy={sortBy}
          />
        )
      }
      right={isLargeSize && <RightLayout />}
    />
  );
};
