import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { Overlay } from "SRC/pages/App.styled";
import { useEventData } from "SRC/redux/slices/event/hooks/useEventData";

import { css } from "./Description.styled";

interface ICol {
  label: string;
  value?: number | string;
  flex?: boolean;
}

interface IRealization {
  total: number;
  done: number;
  notDone: number;
  noData: number;
}

export const Description = () => {
  const { items: data, fetching: loading } = useEventData();

  const realization: IRealization = useMemo(
    () => ({
      total: 0,
      done: 0,
      notDone: 0,
      noData: 0,
    }),
    []
  );

  const cols: JSX.Element[] = useMemo(
    () =>
      [
        {
          label: "Ответственный",
          value: data?.["Ответственный исполнитель"] || "–",
          flex: true,
        },
        {
          label: "Федеральный проект",
          value: data?.["Наименование проекта"] || "–",
          flex: true,
        },
        {
          label: "Уровень мероприятия",
          value: "–",
        },
        {
          label: "Единица измерения",
          value: data?.["Ед. измерения результата"] || "–",
        },
      ].map((item: ICol) => (
        <Stack
          sx={css.headerEvent}
          key={item.label}
          className={item.flex ? "fullSize" : ""}
        >
          <Typography sx={css.text} color="main.gray">
            {item.label}
          </Typography>
          <Typography sx={css.text} className="value" color="text.onAccent">
            {item.value}
          </Typography>
        </Stack>
      )),
    [data]
  );

  if (loading)
    return (
      <Overlay>
        <CircularProgress />
      </Overlay>
    );

  return (
    <Grid container sx={css.container}>
      {cols}
      <Stack sx={css.headerEvent} className="fit">
        <Box sx={css.event} className="alignCenter smallGap">
          <Typography sx={css.text} color="main.gray">
            Реализация в субъектах
          </Typography>
          <Typography sx={css.text} className="value" color="text.onAccent">
            {realization.total || 0}
          </Typography>
        </Box>
        <Box sx={css.event}>
          <Box sx={css.event} className="smallGap">
            <Typography sx={css.text} color="main.gray">
              Выполнено
            </Typography>
            <Typography sx={css.text} className="value" color="success.main">
              {realization.done || 0}
            </Typography>
          </Box>
          <Box sx={css.event} className="smallGap">
            <Typography sx={css.text} color="main.gray">
              Не выполнено
            </Typography>
            <Typography sx={css.text} className="value" color="error.main">
              {realization.notDone || 0}
            </Typography>
          </Box>
          <Box sx={css.event} className="smallGap">
            <Typography sx={css.text} color="main.gray">
              Не предоставлена отчетность
            </Typography>
            <Typography sx={css.text} className="value" color="main.gray">
              {realization.noData || 0}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Grid>
  );
};
