import { Box } from "@mui/material";
import React, { MutableRefObject, useMemo } from "react";
import { EventsTable } from "SRC/pages/Summary/common/DasboardTables/EventsTable/EventsTable";
import { GoalsTable } from "SRC/pages/Summary/common/DasboardTables/GoalsTable/GoalsTable";
import { IndicatorsTable } from "SRC/pages/Summary/common/DasboardTables/IndicatorsTable/IndicatorsTable";
import { SatisfactionTable } from "SRC/pages/Summary/common/DasboardTables/SatisfactionTable/SatisfactionTable";
import { ITableDataItem, TSortBy } from "SRC/pages/Summary/interfaces";
import { css } from "SRC/pages/Summary/layouts/center-layout/center-layout.styled";
import { useTableData } from "SRC/pages/Summary/useTableData";
import { useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { usePremiers } from "SRC/redux/slices/summary/hooks/usePremiers";
import { usePrograms } from "SRC/redux/slices/summary/hooks/usePrograms";
import { EGridTabs } from "SRC/redux/slices/summary/slices/filters";

import { CashTable } from "../../common/DasboardTables/CashTable/CashTable";

interface ICenterLayout {
  cashTableRef: MutableRefObject<HTMLElement | undefined>;
  goalTableRef: MutableRefObject<HTMLElement | undefined>;
  indicatorsTableRef: MutableRefObject<HTMLElement | undefined>;
  eventsTableRef: MutableRefObject<HTMLElement | undefined>;
  satisfactionTableRef: MutableRefObject<HTMLElement | undefined>;
  onScroll: (scroll: any) => void;
  setSortBy: (sortBy: any) => void;
  sortBy: TSortBy;
}

export const CenterLayout = ({
  cashTableRef,
  goalTableRef,
  indicatorsTableRef,
  eventsTableRef,
  satisfactionTableRef,
  onScroll,
  setSortBy,
  sortBy,
}: ICenterLayout) => {
  const { programsData, premiersData } = useTableData();
  const { complexGridTab } = useFilters();
  const { fetching: fetchPR } = usePrograms();
  const { fetching: fetchVP } = usePremiers();

  const tableData = useMemo<ITableDataItem[]>(
    () =>
      (complexGridTab === EGridTabs.PROGRAMS
        ? programsData
        : premiersData
      ).sort((a, b) => {
        return sortBy?.desc
          ? String(b[sortBy?.id as keyof ITableDataItem]).localeCompare(
              String(a[sortBy?.id as keyof ITableDataItem])
            )
          : String(a[sortBy?.id as keyof ITableDataItem]).localeCompare(
              String(b[sortBy?.id as keyof ITableDataItem])
            );
      }),
    [complexGridTab, programsData, premiersData, sortBy]
  );

  const loading = complexGridTab === EGridTabs.PROGRAMS ? fetchPR : fetchVP;

  return (
    <Box sx={css.container}>
      <CashTable
        forwardRef={cashTableRef}
        onScroll={onScroll}
        tableData={tableData}
        setSortBy={setSortBy}
        loading={loading}
      />
      <GoalsTable
        forwardRef={goalTableRef}
        onScroll={onScroll}
        tableData={tableData}
        setSortBy={setSortBy}
        loading={loading}
      />
      <IndicatorsTable
        forwardRef={indicatorsTableRef}
        onScroll={onScroll}
        tableData={tableData}
        setSortBy={setSortBy}
        loading={loading}
      />
      <EventsTable
        forwardRef={eventsTableRef}
        onScroll={onScroll}
        tableData={tableData}
        setSortBy={setSortBy}
        loading={loading}
      />
      <SatisfactionTable
        forwardRef={satisfactionTableRef}
        onScroll={onScroll}
        tableData={tableData}
        setSortBy={setSortBy}
        loading={loading}
      />
    </Box>
  );
};
