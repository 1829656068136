import {
  Box,
  Grid,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import smallArrowLeft from "ASSETS/svg/arrow/smallArrowLeft.svg";
import ReactECharts from "echarts-for-react";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Arrow } from "SRC/components/Carousel/CarouselButtons/CarouselButtons.styled";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { IIndicatorsInfo } from "SRC/pages/Program/common/GoalsTab/interfaces";

import { css } from "../GoalItem.styled";
import { chartCss } from "./ChartView.styled";
import getOptions from "./util/options";

interface IChartViewProps {
  indicatorsInfo?: IIndicatorsInfo;
  plan: number;
  fact: number;
  indicatorName: string;
  unit: string;
  chartPlanData: (number | null)[];
  chartFactData: (number | null)[];
  setChartViewIndex: Dispatch<SetStateAction<null>>;
}

const ChartView = ({
  plan,
  fact,
  indicatorName,
  unit,
  chartPlanData,
  chartFactData,
  setChartViewIndex,
}: IChartViewProps) => {
  const theme = useTheme();

  const onBackClick = () => setChartViewIndex(null);

  const validPlanData = chartPlanData?.map((it) => {
    if (it === null) {
      return 0;
    }
    return it;
  });
  const planData = useMemo<number[]>(
    () => validPlanData?.filter((item: number) => item),
    [chartPlanData]
  );
  const factData = useMemo<number[]>(
    () =>
      chartFactData
        .map((it) => {
          if (it === null) {
            return 0;
          }
          return it;
        })
        ?.filter((item: number) => item),
    [chartFactData]
  );

  const maxPlan = Math.max.apply(null, planData);
  const maxFact = Math.max.apply(null, factData);
  const maxValue = Math.max(maxPlan, maxFact);

  return (
    <Box style={{ width: "100%" }}>
      <Grid sx={chartCss.gridContainer} container>
        <Grid item sm={9}>
          <Box sx={chartCss.topLeftContainer}>
            <Box sx={chartCss.button} onClick={onBackClick}>
              <Arrow src={smallArrowLeft} alt="left" />
            </Box>
            <Typography sx={css.indicatorLabelText}>Показатель</Typography>
          </Box>
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: css.hintContainer as SxProps,
              },
            }}
            title={
              <Typography sx={css.hintText as SxProps}>
                {indicatorName}
              </Typography>
            }
          >
            <Typography
              sx={css.indicatorName as SxProps}
              className="chartViewName"
            >
              {indicatorName}
            </Typography>
          </Tooltip>
          <Typography sx={css.percentSmallText} style={{ marginBottom: 0 }}>
            {unit}
          </Typography>
        </Grid>
        <Grid sx={chartCss.gridItem} item sm={3}>
          <Box sx={chartCss.innerGridItem as SxProps}>
            <Box sx={chartCss.circle} />
            <Typography sx={css.smallText}>Факт</Typography>
            <Typography sx={css.factText as SxProps}>
              {roundNumbersToFixed(fact, 2)}
            </Typography>
          </Box>
          <Box sx={chartCss.innerGridItem as SxProps}>
            <Box sx={chartCss.blueCircle} />
            <Typography sx={css.smallText}>План</Typography>
            <Typography sx={css.planText as SxProps}>
              {roundNumbersToFixed(plan, 2)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <ReactECharts
        style={chartCss.chartStyle}
        option={getOptions(validPlanData, chartFactData, maxValue, unit, theme)}
      />
    </Box>
  );
};

export default ChartView;
