import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getSpheres, getSumSpheres } from "SRC/redux/slices/main/api/client";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";
import { ICodeItem } from "SRC/types";

export interface ISphere {
  data: ICodeItem[];
  code: string;
  PERIOD_CODE?: string;
}

export interface ISpheresState {
  items: ISphere[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "spheres/get",
    async (period: IPeriod) => await getSpheres(period)
  ),
};

export const extraActions2 = {
  get: createAsyncThunk(
    "sumSpheres/get",
    async (period: IPeriod) => await getSumSpheres(period)
  ),
};

const initialState: ISpheresState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

export const slice = createSlice({
  name: "spheres",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: ISpheresState, action) => {
        const { data } = action.payload;
        // TODO [пофиксить]: проблема с интерфейсами запросов
        // @ts-ignore
        state.items = (isContainedArray(data) ? data : []) as ISphere[];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const slice2 = createSlice({
  name: "sumSpheres",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions2.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions2.get.fulfilled, (state: ISpheresState, action) => {
        const { data } = action.payload;
        // TODO [пофиксить]: проблема с интерфейсами запросов
        // @ts-ignore
        state.items = (isContainedArray(data) ? data : []) as ISphere[];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions2.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export const actions2 = {
  ...slice2.actions,
  ...extraActions2,
};
