import React, { useState } from "react";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import { Indicators } from "SRC/pages/Program/common/GoalsTab/common/Indicators/Indicators";
import { NationalGoalsConnect } from "SRC/pages/Program/common/GoalsTab/common/NationalGoalsConnect/NationalGoalsConnect";

import { css } from "./GoalsTab.styled";

const tabs: ITab<number>[] = [
  {
    value: 0,
    label: "Связи с НЦ",
    component: <NationalGoalsConnect />,
  },
  {
    value: 1,
    label: "Показатели",
    component: <Indicators />,
  },
];

const GoalsTab = () => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const handleTabChange = (value: number) => setActiveTab(value);

  return (
    <ExtendedTabs
      value={activeTab}
      onChange={handleTabChange}
      tabs={tabs}
      borderStyle="none"
      contained
      style={css.tabs}
    />
  );
};

export default GoalsTab;
