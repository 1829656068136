import {
  olapRequestData2,
  olapRequestData3,
  olapRequestData4,
} from "CORE/api/core";
import { MODEL_UUID, SECTION_NAMES } from "CORE/scheme/olap";
import { getGpIndicators } from "SRC/constants/dataCodes";
import { IParams } from "SRC/redux/slices/gosprogram/slices/events";
import { IEventParams } from "SRC/redux/slices/gosprogram/slices/filters";
import { IGoalsParams } from "SRC/redux/slices/gosprogram/slices/programGoals";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";

const GET_PROGRAM = "get-program";
const GET_PROGRAM_GOALS = "get-program-goals";
const GET_PROGRAM_EVENTS = "get-program-events";
const GET_PROGRAM_INDICATORS = "get-program-indicators";
const GET_STATISTICS = "get-statistics";
const GET_RESULT_CHARACTERISTIC = "get-result-characteristic";
const GET_GOAL_CONNECT = "get-goal-connect";

export const getProgram = (period: IPeriod, gp_code: string) => {
  return olapRequestData4(
    [
      {
        requestId: "29968ac9-ab0e-468b-b55c-aebb90030864",
        type: "data2",
        params: {
          modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1562",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1565",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "gp_short_name",
                "Вице-премьер",
                "gp_name",
                "Министерство",
              ],
            },
          ],
          indicators: {
            id: "1571",
            items: getGpIndicators(
              1578,
              1579,
              1580,
              1584,
              1585,
              1586,
              1679,
              1680,
              1590,
              1753,
              1754,
              1755,
              1756,
              1757,
              1769,
              1796
            ),
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 2,
                    id: "1565",
                    attributeCode: "gp_code",
                  },
                  {
                    type: "TEXT",
                    version: 2,
                    values: [gp_code],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_PROGRAM
  );
};

export const getProgramGoals = (params: IGoalsParams) => {
  return olapRequestData2(
    {
      dimensions: [],
      indicators: {},
      section_name: SECTION_NAMES.PROGRAMGOALS,
      modelUuid: MODEL_UUID,
      filters: {
        period_code: params.period_code,
        gp_code: params.gp_code,
      },
    },
    GET_PROGRAM_GOALS
  );
};

export const getProgramEvents = (params: IParams) => {
  return olapRequestData2(
    {
      dimensions: [],
      indicators: {},
      section_name: SECTION_NAMES.PROGRAMEVENTS,
      modelUuid: MODEL_UUID,
      filters: {
        period_code: params.period_code || null,
        gp_code: params.id,
        fp_code: params.fp_code,
        dp_code: params.dp_code,
        cpm_code: params.cpm_code,
      },
    },
    GET_PROGRAM_EVENTS
  );
};

export const getProgramIndicators = (params: IParams) => {
  return olapRequestData2(
    {
      dimensions: [],
      indicators: {},
      section_name: SECTION_NAMES.PROGRAM_INDICATORS,
      modelUuid: MODEL_UUID,
      filters: {
        period_code: params.period_code || null,
        gp_code: params.id,
        fp_code: params.fp_code,
        dp_code: params.dp_code,
        cpm_code: params.cpm_code,
      },
    },
    GET_PROGRAM_INDICATORS
  );
};

export const getStatistics = ({ id, period }: IEventParams) => {
  return olapRequestData2(
    {
      dimensions: [],
      indicators: {},
      section_name: SECTION_NAMES.STATS,
      modelUuid: MODEL_UUID,
      filters: {
        period_code: period.filterValue,
        gp_code: id,
      },
    },
    GET_STATISTICS
  );
};

export const getResultCharacteristic = (params: IEventParams) => {
  return olapRequestData3(
    [
      {
        requestId: "4dcc7514-b4c3-4015-87c0-bc6bdd0c1fd3",
        type: "dimension_data",
        params: {
          modelUuid: "f0c00f6b-d2fa-4a9a-9f57-258a0e88806d",
          dimensionId: "1344",
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          dataFilter: {
            type: "EQ",
            version: 1,
            operands: [
              {
                type: "DIM",
                version: 1,
                id: "1344",
              },
              {
                type: "CONST",
                version: 1,
                values: [params.period],
              },
            ],
          },
        },
      },
      {
        requestId: "a80a0421-1fb0-49e4-90e8-a27c9346eeb5",
        type: "dimension_data",
        params: {
          modelUuid: "f0c00f6b-d2fa-4a9a-9f57-258a0e88806d",
          dimensionId: "1348",
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          dataFilter: {
            type: "EQ",
            version: 1,
            operands: [
              {
                type: "DIM",
                version: 1,
                id: "1348",
              },
              {
                type: "CONST",
                version: 1,
                values: ["10103"],
              },
            ],
          },
        },
      },
    ],
    GET_RESULT_CHARACTERISTIC
  );
};

export const getGoalConnect = (id: string | number) => {
  return olapRequestData3(
    [
      {
        requestId: "cd9f0aca-4c83-415d-863a-c14bccc1de94",
        type: "data2",
        params: {
          modelUuid: "2d1cc9ce-87da-40b3-80b6-37679e0357fd",
          dimensions: [],
          indicators: {},
          section_name: "2.0.3 Связь показателей ГП и НЦ",
          dataRequestCaching: false,
          filters: {
            gp_code: id,
          },
        },
      },
    ],
    GET_GOAL_CONNECT
  );
};
