import {
  Box,
  CircularProgress,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { SecretProgram } from "ASSETS/svg";
import React from "react";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { Overlay } from "SRC/pages/App.styled";
import { IRatingTopData } from "SRC/pages/Summary/interfaces";

import { css } from "./RatingTop.styled";

interface IRatingTopProps {
  data: IRatingTopData;
  withDarkBgr?: boolean | undefined;
  fetching: boolean;
}

export const RatingTop = ({ data, fetching }: IRatingTopProps) => {
  const percentData = roundNumbersToFixed(data.sum || 0, 1);
  const theme = useTheme();
  if (fetching)
    return (
      <Box sx={css.container as SxProps}>
        <Overlay>
          <CircularProgress />
        </Overlay>
      </Box>
    );
  return (
    <Box sx={css.container as SxProps}>
      <Box sx={css.icon as SxProps}>
        <img src={data.url} aria-hidden alt="icon" />
        {data.secret && (
          <Box sx={css.secretProgram}>
            <SecretProgram />
          </Box>
        )}
      </Box>
      <Box sx={css.infoContainer as SxProps}>
        <Box sx={css.title as SxProps}>
          {data.code && <span> {data.code || ""}.</span>}
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: css.hintContainer as SxProps,
              },
            }}
            title={
              <Typography sx={css.hintText as SxProps}>{data.name}</Typography>
            }
          >
            <Box sx={css.indicatorName as SxProps}>{data.name}</Box>
          </Tooltip>
        </Box>
        <Box sx={css.percentData(theme, percentData) as SxProps}>
          <Box>{percentData} </Box>
          <span> %</span>
        </Box>
      </Box>
    </Box>
  );
};
