import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flex: "0 0 53%",
    flexWrap: "nowrap",
    borderRadius: "1rem",
    padding: "1.5rem 1.5rem",
    alignItems: "center",
    backgroundColor: (theme: Theme) =>
      theme.palette.complementary.darkBlueLight,
  },
  effectiveHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  effectiveContent: {
    display: "flex",
    width: "100%",
    gap: "3rem",
    justifyContent: "space-between",
  },
  progress: {
    display: "flex",
    flexDirection: "column",
    gap: "1.25rem",
    flex: "0 0 30%",
  },
  title: {
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
  },
  lines: {
    display: "flex",
    flexDirection: "column",
  },
  chartContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  chart: {
    width: "15rem",
    height: "15rem",
    margin: "auto",
    display: "flex",
    "@media(max-width: 1200px)": {
      width: "10rem",
      height: "10rem",
    },
  },
  achievement: {
    display: "flex",
    flex: "0 0 27%",
    flexDirection: "column",
    alignItems: "left",
    gap: "2rem",
    "& .item": {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
  },
  titleAchievement: {
    fontSize: "1.375rem",
  },
  progressAchievement: {
    display: "flex",
    flexDirection: "column",
  },
  value: {
    fontSize: "3.5rem",
    "& span": {
      fontSize: "2rem",
    },
    "& span.unit": {
      color: (theme: Theme) => theme.palette.complementary.unitsGray,
      fontSize: "0.875rem",
      ml: "0.3rem",
    },
  },
  progressBarStyle: {
    background: (theme: Theme) => theme.palette.background.progressDarkBlue,
  },
};
