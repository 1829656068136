import { useMemo } from "react";
import { IEventItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import { IndicatorItem } from "SRC/pages/Program/common/IndicatorsTab/interface";
import { IStatus } from "SRC/pages/Program/common/ProgramSummary/interfaces";
import { isStatusMatch } from "SRC/pages/Program/utils";
import { useStructureElements } from "SRC/redux/slices/gosprogram/hooks/structureElements";
import { useEvents } from "SRC/redux/slices/gosprogram/hooks/useEvents";
import {
  IFilters,
  useFilters,
} from "SRC/redux/slices/gosprogram/hooks/useFilters";
import { useIndicators } from "SRC/redux/slices/gosprogram/hooks/useIndicators";
import {
  EVENT_STATUS,
  IIndicatorStatuses,
  TEventStatus,
  TIndicatorStatus,
} from "SRC/types";

import { createEventsTableData, createIndicatorsTableData } from "../utils";

interface IUseTableData {
  eventsData: IEventItem[];
  eventsStatuses: IStatus[];
  eventsTableData: IEventItem[];
  eventsExports: IEventItem[];
  containedEventFeatures: string[];
  indicatorsData: IndicatorItem[];
  indicatorsStatuses: IStatus[];
  indicatorsExports: IndicatorItem[];
}

export const useEventsTableData = (): IUseTableData => {
  const { items: events = [] } = useEvents();
  const { items: indicators = [] } = useIndicators();
  const { items: structures } = useStructureElements();
  const {
    selectedStatus,
    selectedEventFeature,
    selectedStructureRow,
    selectedStructureElementType,
    selectedStructureElementName,
  }: IFilters = useFilters();

  const structureCodes = useMemo<string[] | undefined>(
    () =>
      structures.find((item) => item.code === selectedStructureRow)?.eventsCode,
    [selectedStructureRow, structures]
  );

  const eventsTableData = useMemo(() => {
    return createEventsTableData(events);
  }, [events]);

  const eventsData = useMemo(() => {
    return eventsTableData
      .filter(
        (item) => !structureCodes || structureCodes.includes(String(item?.id))
      )
      .filter((item) => {
        return !selectedStatus.length || isStatusMatch(selectedStatus, item);
      })
      .filter(
        (item) =>
          !selectedEventFeature || item.features?.includes(selectedEventFeature)
      )
      .filter((item) =>
        selectedStructureElementType === "Федеральные проекты"
          ? item.structureElement === "ФП"
          : selectedStructureElementType === "Ведомственные проекты"
          ? item.structureElement === "ВП"
          : selectedStructureElementType === "Комплексы процессных мероприятий"
          ? item.structureElement === "КПМ"
          : true
      )
      .filter((item) => {
        if (
          selectedStatus.includes(EVENT_STATUS.AT_RISK) &&
          !selectedEventFeature
        ) {
          return item.atRisk === 1;
        }

        return true;
      })
      .sort((a, b) => Number(a.id) - Number(b.id));
  }, [
    eventsTableData,
    structureCodes,
    selectedStatus,
    selectedStructureElementType,
    selectedEventFeature,
  ]);

  const containedEventFeatures: string[] = useMemo(
    () =>
      Array.from(
        new Set(
          eventsTableData
            .map((e) => e.features)
            .flat()
            .filter(Boolean) as string[]
        ).values()
      ),
    [eventsTableData]
  );

  const eventsStatuses: IStatus[] = useMemo(() => {
    const eventTotalByStatus = eventsData.reduce(
      (acc: Partial<Record<TEventStatus, IStatus>>, { status }) => ({
        ...acc,
        ...Object.entries(status).reduce(
          (itemAcc: Partial<Record<TEventStatus, IStatus>>, [key, value]) => ({
            ...itemAcc,
            [key as TEventStatus]: {
              status: key,
              amount: Number(value) + (acc[key as TEventStatus]?.amount || 0),
            },
          }),
          {}
        ),
      }),
      {}
    );

    return Object.values(eventTotalByStatus);
  }, [eventsData]);

  const eventsExports = useMemo(
    () => eventsData.map(({ index, atRisk, features, ...item }) => item),
    [eventsData]
  );

  const indicatorsTableData = useMemo(() => {
    return createIndicatorsTableData(indicators);
  }, [indicators]);

  const indicatorsData = useMemo(() => {
    return indicatorsTableData
      .filter(
        (item) => !structureCodes || structureCodes.includes(String(item?.id))
      )
      .filter(
        (item) => !selectedStatus.length || selectedStatus.includes(item.status)
      )
      .filter(
        (item) =>
          !selectedStructureElementName ||
          item.structureElementName === selectedStructureElementName
      )
      .filter((item) =>
        selectedStructureElementType === "Федеральные проекты"
          ? item.structureElement === "ФП"
          : selectedStructureElementType === "Ведомственные проекты"
          ? item.structureElement === "ВП"
          : selectedStructureElementType === "Комплексы процессных мероприятий"
          ? item.structureElement === "КПМ"
          : true
      )
      .sort((a, b) => Number(a.id) - Number(b.id));
  }, [
    indicatorsTableData,
    structureCodes,
    selectedStatus,
    selectedStructureElementType,
    selectedStructureElementName,
  ]);

  const indicatorsStatuses: IStatus[] = useMemo(() => {
    const sorted: IIndicatorStatuses = indicatorsData.reduce(
      (acc: IIndicatorStatuses, item) => ({
        ...acc,
        [item.status]:
          ((acc[item.status as TIndicatorStatus] as number) || 0) + 1,
      }),
      {
        [EVENT_STATUS.DONE]: 0,
        [EVENT_STATUS.NOT_DONE]: 0,
        [EVENT_STATUS.NOT_STARTED]: 0,
      }
    );

    return Object.entries(sorted)
      .filter(([key]) => Boolean(key))
      .map(([key, value]) => ({
        status: key,
        amount: value,
      }));
  }, [indicatorsData]);

  const indicatorsExports = useMemo(
    () => indicatorsData.map(({ index, ...item }) => item),
    [indicatorsData]
  );

  return {
    eventsData,
    eventsStatuses,
    eventsTableData,
    eventsExports,
    containedEventFeatures,
    indicatorsData,
    indicatorsStatuses,
    indicatorsExports,
  };
};
