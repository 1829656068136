import {
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
  Theme,
} from "@mui/material";

interface IProps {
  titlePosition?: string;
  bg?: string;
  status?: string;
  activeStep?: number;
}

export const BoxStepper = styled(Stepper)({
  position: "absolute",
  width: "100%",
  top: "0.85rem",
  transition: "0.5s ease-out",
  "& .MuiStep-root": {
    flex: "0 0 40%",
  },
  "& .MuiStepper-root": {
    position: "relative",
  },
  "& .MuiStepLabel-root": {
    position: "relative",
    top: "2.1rem",
  },
  "& .MuiStepConnector-root": {
    left: "-50%",
    right: "50%",
  },
  "& .MuiStepLabel-labelContainer": {
    "& span": {
      position: "absolute",
      right: 0,
      left: "2rem",
      fontSize: "1rem",
      marginTop: "0rem",
    },
  },
});

export const BoxConnector = styled(StepConnector)(({ status }: IProps) => ({
  top: "1px",
  left: "0rem",
  right: "0rem",
  position: "unset",
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: (theme: Theme) => theme.palette.error.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: (theme: Theme) => theme.palette.success.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: "0.5rem",
    border: 0,
    backgroundColor: "none",
    borderRadius: 1,
    position: "relative",
    top: "0.15rem",
    left: "0.18rem",
    margin: "0 0.4rem",
    background: (theme: Theme) => theme.palette.error.main,
  },
}));

export const StepLabelCheckpoint = styled(StepLabel)(({ status }: IProps) => ({
  marginTop: 0,
  "& .MuiStepLabel-iconContainer .colorStep": {
    background: (theme: Theme) =>
      status === "R"
        ? theme.palette.error.main
        : status === "G"
        ? theme.palette.success.main
        : status === "Gr"
        ? theme.palette.main.gray
        : theme.palette.background.default,
  },
}));

export const css = {
  button: {
    position: "absolute",
    top: "2.5rem",
    bottom: 0,
    background: (theme: Theme) => theme.palette.background.default,
    maxHeight: "4rem",
    minWidth: "4rem",
    borderRadius: "50%",
    border: (theme: Theme) => `3px solid ${theme.palette.background.paper}`,
    "&:hover": {
      background: (theme: Theme) => theme.palette.background.default,
    },
    zIndex: 1,
  },
  arrowLeft: (isPrevBtnDisabled: boolean) => ({
    width: "1.5rem",
    opacity: isPrevBtnDisabled ? 0.4 : 1,
  }),
  arrowRight: (isNextBthDisabled: boolean) => ({
    width: "1.5rem",
    transform: "rotate(180deg)",
    opacity: isNextBthDisabled ? 0.4 : 1,
  }),
  wrapper: (theme: Theme) => ({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: "0.5rem",
    padding: "1.5rem",
    zIndex: 0,
    position: "relative",
    // minHeight: "20rem",
    [theme.breakpoints.up("xl")]: { padding: "1.5rem" },
  }),
  title: {
    fontSize: "1.25rem",
    color: (theme: Theme) => theme.palette.text.primary,
  },
  stepperContainer: (theme: Theme, openTitle: boolean) => ({
    position: "relative",
    overflow: "hidden",
    height: "100%",
    minHeight: openTitle ? "18rem" : "10rem",
    "@media(min-width: 1500px)": {
      minHeight: openTitle ? "17rem" : "10rem",
    },
  }),
  connectorWrapper: {
    left: "-50%",
    right: "50%",
    top: "3.3rem",
    height: "0.85rem",
    position: "absolute",
  },
  checkpointStepperLine: (theme: Theme) => ({
    width: "100%",
    background: theme.palette.background.default,
    height: "1rem",
    zIndex: -1,
    position: "absolute",
    left: 0,
    borderRadius: "0.25rem",
    top: "4rem",
  }),
  colorStepWrapper: (statusPoint: string) => ({
    zIndex: 1,
    background: (theme: Theme) => theme.palette.background.default,
    position: "relative",
    width: "3rem",
    height: "3rem",
    borderRadius: "50%",
    left: "0.125rem",
  }),
  colorStep: (statusPoint: string) => ({
    margin: "0.7rem 0px",
    zIndex: 1,
    left: 0,
    position: "relative",
    width: "2rem",
    height: "2rem",
    display: "flex",
    borderRadius: "50%",
    border: (theme: Theme) =>
      statusPoint === "R"
        ? `0.4rem solid ${theme.palette.error.main}`
        : statusPoint === "G"
        ? `0.4rem solid ${theme.palette.success.main}`
        : statusPoint === "Gr"
        ? `0.4rem solid ${theme.palette.main.gray}`
        : `0.4rem solid ${theme.palette.background.default}`,
    justifyContent: "center",
    alignItems: "center",
    background: (theme: Theme) => theme.palette.primary.contrastText,
  }),
  expandText: {
    position: "absolute",
    bottom: 0,
    right: 0,
    color: (theme: Theme) => theme.palette.main.gray,
    cursor: "pointer",
    lineHeight: "1.6rem",
  },
  expandIcon: {
    width: "0.8rem",
    height: "0.8rem",
    cursor: "pointer",
    marginLeft: "0.5rem",
  },
  stepLabelDate: (theme: Theme) => ({
    position: "absolute",
    right: 0,
    left: "0.5rem",
    fontSize: "1.125rem",
    textAlign: "center",
    marginBottom: "0.625rem",
    color: theme.palette.success.main,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
  }),
  dateFinish: (status: string) => ({
    minWidth: "6rem",
    display: "flex",
    justifyContent: "flex-end",
    color: (theme: Theme) =>
      status === "R"
        ? theme.palette.error.main
        : status === "G"
        ? theme.palette.success.main
        : status === "Gr"
        ? theme.palette.main.gray
        : theme.palette.background.default,
  }),
  datePlan: {
    color: (theme: Theme) => theme.palette.text.secondary,
  },
  deviation: (color: string | undefined) => ({
    marginLeft: "1rem",
    fontSize: "1rem",
    alignSelf: "center",
    color: (theme: Theme) =>
      color === "G"
        ? theme.palette.success.main
        : color === "R"
        ? theme.palette.error.main
        : color === "Gr"
        ? theme.palette.main.gray
        : theme.palette.background.default,
  }),
  stepperConnector: (status: string, theme: Theme) => ({
    position: "absolute",
    backgroundColor: (theme: Theme) =>
      status === "R"
        ? theme.palette.error.main
        : status === "G"
        ? theme.palette.success.main
        : status === "Gr"
        ? theme.palette.main.gray
        : theme.palette.background.default,
    width: "100%",
    height: "0.7rem",
    left: "-50%",
  }),
  legendContainer: {
    fontSize: "1rem",
    gap: "2rem",
    color: (theme: Theme) => theme.palette.main.gray,
  },
  legendItemContainer: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "baseline",

    "& p.white": {
      color: (theme: Theme) => theme.palette.text.onAccent,
    },
  },
  styledPoint: {
    width: "0.7rem",
    height: "0.7rem",
    borderRadius: "50%",

    "&.green": {
      background: (theme: Theme) => theme.palette.success.main,
    },
    "&.red": {
      background: (theme: Theme) => theme.palette.error.main,
    },
    "&.gray": {
      background: (theme: Theme) => theme.palette.main.gray,
    },
    "&.dark": {
      background: (theme: Theme) => theme.palette.background.default,
    },
  },
};
