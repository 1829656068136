import {
  CriticalIcon,
  DoneIcon,
  FailIcon,
  InProgressIcon,
  NotStartedIcon,
  OutOfDateIcon,
  WarningTriangle,
} from "ASSETS/svg";
import React from "react";
import { EVENT_STATUS } from "SRC/types";

export enum EDateType {
  YEAR = "year",
  MONTH = "month",
  QUARTER = "quarter",
}

export enum ECalculationMethod {
  EFFECT = "effect",
  RESULT = "result",
}

export const statusIcons: Record<string, JSX.Element> = {
  [EVENT_STATUS.DONE]: <DoneIcon />,
  [EVENT_STATUS.IN_PROGRESS]: <InProgressIcon />,
  [EVENT_STATUS.NOT_STARTED]: <NotStartedIcon />,
  [EVENT_STATUS.OUT_DATE]: <OutOfDateIcon />,
  [EVENT_STATUS.NOT_ACHIEVED]: <FailIcon />,
  [EVENT_STATUS.NOT_DONE]: <FailIcon />,
  [EVENT_STATUS.AT_RISK]: <WarningTriangle />,
  [EVENT_STATUS.CRITICAL]: <CriticalIcon />,
};

export enum EFinanceUnit {
  BILLION = "млрд",
  MILLION = "млн",
  THOUSAND = "тыс",
}
