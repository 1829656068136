import { Box, Theme, Typography } from "@mui/material";
import { SecretProgram, WarningPinkTriangle } from "ASSETS/svg";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import {
  css,
  css as tableCss,
} from "SRC/components/Table/BaseTableNew/BaseTable.styled";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const getGovProgramsColumns = (theme: Theme, isFiltered: boolean) => {
  return [
    {
      Header: "№",
      accessor: "id",
      Cell: (tableProps: CustomTableProps) => (
        <>
          <Box sx={css.idCell}>
            <Box sx={css.iconContainer}>
              {tableProps.row.original.secretProgram === 1 && (
                <Box sx={css.secretProgram}>
                  <SecretProgram />
                </Box>
              )}
              {tableProps.row.original.atRisk > 0 && (
                <Box sx={{ height: "fit-content" }}>
                  <WarningPinkTriangle
                    style={{
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                </Box>
              )}
            </Box>

            <Typography
              sx={css.tableCellText}
              align="left"
              className="whiteCellText with-right-border idCell"
            >
              {tableProps.row.original.id}
            </Typography>
          </Box>
        </>
      ),
      Footer: " ",
    },
    {
      Header: " ",
      accessor: "icon",
      Cell: (tableProps: CustomTableProps) => (
        <Box sx={css.flexCenter} style={{ marginLeft: "1.25rem" }}>
          <img
            style={css.tableImage(true)}
            src={tableProps.row.original.icon}
            alt="govProgram"
          />
        </Box>
      ),
      Footer: " ",
    },
    {
      Header: "Госпрограмма",
      accessor: "govProgram",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          sx={css.tableCellText}
          className="with-right-border withSmallMarginLeft whiteText"
        >
          {tableProps.row.original.govProgram}
        </Typography>
      ),
      Footer: (
        <Typography
          sx={css.tableCellText}
          className="with-right-border tableFooterText footerTextWhite"
          align="left"
        >
          Итого
        </Typography>
      ),
    },
    {
      Header: "Показатели",
      Footer: " ",
      columns: [
        {
          Header: "План",
          accessor: "indicatorsPlan",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="left"
              sx={css.tableCellText(theme)}
              className="whiteText withMarginLeft"
            >
              {tableProps.row.original.indicatorsPlan}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const rowsArray = Array(tableProps.rows || []).flat();
            const indicatorsPlan = React.useMemo(
              () =>
                rowsArray.reduce(
                  (sum: number, row: UseTableRowProps<any>) =>
                    row.original.indicatorsPlan + sum,
                  0
                ),
              [rowsArray]
            );

            return (
              <Typography
                align="left"
                sx={css.tableCellText}
                className="tableFooterText whiteText withMarginLeft"
              >
                {indicatorsPlan}
              </Typography>
            );
          },
        },
        {
          Header: "Выполнено",
          accessor: "indicatorsDone",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="left"
              sx={css.tableCellText(theme)}
              className="whiteText withMarginLeft"
            >
              {tableProps.row.original.indicatorsDone}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const rowsArray = Array(tableProps.rows || []).flat();
            const indicatorsDone = React.useMemo(
              () =>
                rowsArray.reduce(
                  (sum: number, row: UseTableRowProps<any>) =>
                    row.original.indicatorsDone + sum,
                  0
                ),
              [rowsArray]
            );

            return (
              <Typography
                align="left"
                sx={css.tableCellText}
                className="tableFooterText whiteText withMarginLeft"
              >
                {indicatorsDone}
              </Typography>
            );
          },
        },
      ],
    },
    {
      Header: "Мероприятия",
      Footer: " ",
      columns: [
        {
          Header: "План",
          accessor: "plan",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="left"
              sx={css.tableCellText(theme)}
              className="whiteText withMarginLeft"
            >
              {tableProps.row.original.plan}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const rowsArray = Array(tableProps.rows || []).flat();
            const plan = React.useMemo(
              () =>
                rowsArray.reduce(
                  (sum: number, row: UseTableRowProps<any>) =>
                    row.original.plan + sum,
                  0
                ),
              [rowsArray]
            );

            return (
              <Typography
                align="left"
                sx={css.tableCellText}
                className="tableFooterText whiteText withMarginLeft"
              >
                {plan}
              </Typography>
            );
          },
        },
        {
          Header: "Факт",
          accessor: "fact",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="left"
              sx={css.tableCellText(theme)}
              className="whiteText withMarginLeft"
            >
              {tableProps.row.original.fact}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const rowsArray = Array(tableProps.rows || []).flat();
            const fact = React.useMemo(
              () =>
                rowsArray.reduce(
                  (sum: number, row: UseTableRowProps<any>) =>
                    row.original.fact + sum,
                  0
                ),
              [rowsArray]
            );

            return (
              <Typography
                align="left"
                sx={css.tableCellText}
                className="tableFooterText whiteText withMarginLeft"
              >
                {fact}
              </Typography>
            );
          },
        },
        {
          Header: "Под риском",
          accessor: "atRisk",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="left"
              sx={tableCss.tableCellText(
                theme,
                "",
                false,
                tableProps.row.original.atRisk
              )}
              className="with-right-border whiteText withMarginLeft"
            >
              {tableProps.row.original.atRisk}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const rowsArray = Array(tableProps.rows || []).flat();
            const total = React.useMemo(
              () =>
                rowsArray.reduce(
                  (sum: number, row: UseTableRowProps<any>) =>
                    row.original.atRisk + sum,
                  0
                ),
              [rowsArray]
            );

            return (
              <Typography
                align="left"
                sx={tableCss.tableCellText(theme, "", false, total)}
                className="tableFooterText with-right-border whiteText withMarginLeft"
              >
                {total}
              </Typography>
            );
          },
        },
      ],
    },
    {
      Header: "Уровень достижения",
      accessor: "achievementLevel",
      Cell: (tableProps: CustomTableProps) => (
        <Box sx={{ padding: "8px 1rem" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <Typography
              align="center"
              sx={css.tableCellText(theme)}
              className="with-dotted-border whiteText"
            >
              {`${roundNumbersToFixed(
                tableProps.row.original.achievementLevel || 0,
                1
              )}`}
            </Typography>
            <Typography sx={css.tableCellText(theme)} className="grayCellText">
              %
            </Typography>
          </Box>
          <ProgressBar value={tableProps.row.original.achievementLevel || 0} />
        </Box>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const rowsArray = Array(tableProps.rows || []).flat();
        const average = React.useMemo(() => {
          let achievementAverage: number;

          if (isFiltered) {
            achievementAverage =
              rowsArray.reduce((acc: number, item: UseTableRowProps<any>) => {
                return acc + item.original.achievementLevel || 0;
              }, 0) / rowsArray.length;
          } else {
            achievementAverage = rowsArray?.[0]?.original.achievementSum || 0;
          }

          return roundNumbersToFixed(achievementAverage, 0);
        }, [rowsArray]);

        return (
          <Box sx={{ padding: "5px 1rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
              }}
            >
              <Typography
                align="center"
                sx={css.tableCellText(theme)}
                className="with-dotted-border whiteText"
              >
                {average}
              </Typography>
              <Typography
                sx={css.tableCellText(theme)}
                className="grayCellText"
              >
                %
              </Typography>
            </Box>
            <ProgressBar value={average} />
          </Box>
        );
      },
    },
  ];
};

export { getGovProgramsColumns };
