import { ProgramTabValue } from "SRC/types";

export const MAIN = "/";
export const GOVPROGRAMS = "/programs";
export const SUMMARY = "/summary";
export const PROGRAM = "/program";
export const PROGRAM_DETAIL = "/program/:id";
export const PROGRAM_DETAIL_FULL = "/program/:id/:tab";
export const EVENT = "/event/:id";

export interface IRoute {
  title: string;
  path: string;
  nav?: boolean;
}

export const routeList: IRoute[] = [
  { title: "Все госпрограммы", path: MAIN, nav: true },
  { title: "Сводная таблица", path: SUMMARY, nav: true },
  { title: "Госпрограммы", path: GOVPROGRAMS },
  { title: "Карточка госпрограммы", path: PROGRAM_DETAIL },
  { title: "Карточка госпрограммы", path: PROGRAM_DETAIL_FULL },
  { title: "Мероприятие", path: EVENT },
];

export const navList: IRoute[] = routeList.filter((r) => r.nav);

export const setIdToUrl = (url: string, id: string | number) =>
  url.replace(":id", String(id));

/**
 * Функция для передачи в url параметров для открытия карточки ГП
 *
 * @param url - базовый url карточки ГП
 * @param id - идентификатор ГП
 * @param tab - вкладка в карточке ГП
 * @param filter? - фильтр для ГП
 */
export const createProgramCardUrl = (
  url: string,
  id: number | string,
  tab: ProgramTabValue,
  filter: string = ""
) => {
  const filterParam = filter ? `?filter=${filter}` : "";

  return setIdToUrl(url, id)
    .replace(":tab", String(tab))
    .concat("", filterParam);
};
