import { Theme } from "@mui/material";

export const css = {
  container: (theme: Theme) => ({
    position: "relative",
    display: "flex",
    gap: "0.5rem",
    width: "100%",
    height: "6.125rem",
    background: theme.palette.background.paper,
    borderRadius: "1rem",
    padding: "0.75rem",
  }),
  icon: {
    gap: "0.25rem",
    flexDirection: "column",
    borderRadius: "1.5rem",
    alignItems: "center",
    "& img": {
      height: "2.5rem",
      width: "auto",
      borderRadius: "50%",
    },
  },
  infoContainer: (theme: Theme) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& span": {
      color: theme.palette.complementary.lightWhite,
    },
  }),
  title: {
    display: "flex",
    columnGap: "0.5rem",
    // display: "-webkit-box",
    // "-webkit-line-clamp": "2",
    // "-webkit-box-orient": "vertical",
    // overflow: "hidden",
    // fontSize: "0.875rem",
    // fontWeight: 400,
    // hyphens: "none",
  },
  titleContainer: (theme: Theme) => ({
    display: "flex",
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      left: "0",
      justifyContent: "initial",
      flex: "1",
    },
  }),
  percentData: (theme: Theme, percent: number) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    gap: "0.25rem",
    height: "1.5rem",
    fontSize: "1.25rem",
    fontWeight: 400,
    color: percent >= 100 && theme.palette.pale.green,
    "& span": {
      fontSize: "0.875rem",
    },
  }),
  doughnut: (theme: Theme) => ({
    width: "9rem",
    [theme.breakpoints.down("md")]: {
      width: "6rem",
    },
    [theme.breakpoints.up("lg")]: {
      height: "13rem",
      width: "13rem",
      margin: "0 auto",
    },
  }),
  secretProgram: {
    "& svg": {
      width: "1rem",
      height: "1rem",
    },
  },
  hintContainer: {
    background: (theme: Theme) => theme.palette.complementary.grayBlue,
    padding: "1.5rem",
    borderRadius: "1rem",
  },
  hintText: {
    fontSize: "1rem",
    lineHeight: "1em",
    color: (theme: Theme) => theme.palette.main.gray,
    whiteSpace: "break-spaces",
  },
  indicatorName: (theme: Theme) => ({
    fontSize: "1.13rem",
    lineHeight: "1.4rem",
    color: (theme: Theme) => theme.palette.primary.contrastText,
    whiteSpace: "normal",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    // marginLeft: "0.5rem",
    "&.chartViewName": {
      marginTop: 10,
      marginBottom: 10,
      marginRight: 10,
    },

    [theme.breakpoints.up("xl")]: {
      fontSize: "1rem",
      lineHeight: "1.2rem",
    },
  }),
};
