import { Box, Theme, Typography } from "@mui/material";
import { WarningPinkTriangle } from "ASSETS/svg";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import {
  css,
  css as tableCss,
} from "SRC/components/Table/BaseTableNew/BaseTable.styled";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
}

const getMinistriesColumns = (theme: Theme) => {
  return [
    {
      Header: "Министерство",
      accessor: "ministryName",
      Cell: (tableProps: CustomTableProps) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          {tableProps.row.original.atRisk > 0 && (
            <Box sx={css.iconContainer}>
              <Box sx={{ height: "fit-content" }}>
                <WarningPinkTriangle
                  style={{
                    width: "1rem",
                    height: "1rem",
                  }}
                />
              </Box>
            </Box>
          )}
          <Typography
            sx={css.tableCellText}
            className="with-right-border withMarginLeft whiteText"
          >
            {tableProps.row.original.ministryName}
          </Typography>
        </div>
      ),
    },
    {
      Header: "Госпрограммы",
      accessor: "numOfPrograms",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          align="left"
          sx={css.tableCellText(theme)}
          className="with-right-border whiteText withMarginLeft"
        >
          {tableProps.row.original.numOfPrograms}
        </Typography>
      ),
    },
    {
      Header: "Мероприятия",
      columns: [
        {
          Header: "План",
          accessor: "plan",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="left"
              sx={css.tableCellText(theme)}
              className="whiteText withMarginLeft"
            >
              {tableProps.row.original.plan}
            </Typography>
          ),
        },
        {
          Header: "Факт",
          accessor: "fact",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="left"
              sx={css.tableCellText(theme)}
              className="whiteText withMarginLeft"
            >
              {tableProps.row.original.fact}
            </Typography>
          ),
        },
        {
          Header: "Под риском",
          accessor: "atRisk",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="left"
              sx={tableCss.tableCellText(
                theme,
                "",
                false,
                tableProps.row.original.atRisk
              )}
              className="with-right-border whiteText withMarginLeft"
            >
              {tableProps.row.original.atRisk}
            </Typography>
          ),
        },
      ],
    },
    {
      Header: "Уровень достижения",
      accessor: "achievementLevel",
      Cell: (tableProps: CustomTableProps) => (
        <Box sx={{ padding: "8px 1rem" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <Typography
              align="center"
              sx={css.tableCellText(theme)}
              className="with-dotted-border whiteText"
            >
              {`${roundNumbersToFixed(
                tableProps.row?.original.achievementLevel || 0,
                0
              )}`}
            </Typography>
            <Typography sx={css.tableCellText(theme)} className="grayCellText">
              %
            </Typography>
          </Box>
          <ProgressBar value={tableProps.row?.original.achievementLevel || 0} />
        </Box>
      ),
    },
  ];
};

export { getMinistriesColumns };
