import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { LayoutNew } from "SRC/layouts";
import { ComplexGrid } from "SRC/pages/Main/common/ComplexGrid/ComplexGrid";
import { NationalGoalsTable } from "SRC/pages/Main/common/NationalGoalsTable/NationalGoalsTable";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useMain } from "SRC/redux/slices/main/hooks/useMain";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import themeConfig from "SRC/theme";

import { CenterLayout } from "./layouts";

export const Main = () => {
  const theme = useTheme<typeof themeConfig>();
  const isLargeSize = useMediaQuery(theme.breakpoints.up("lg"));
  const { loading, init } = useMain();
  const { load: updateStats } = useStats();
  const { program, vp, period, sphere }: IFilters = useFilters();

  useEffect(
    (): void =>
      init({
        period,
        gp_code: program || null,
        vp_code: vp || null,
        sphere: sphere || null,
      }),
    []
  );

  useEffect(() => {
    if (!loading) {
      init({
        period,
        gp_code: program || null,
        vp_code: vp || null,
        sphere: sphere || null,
      });
    }
  }, [period]);

  useEffect(() => {
    updateStats({
      gp_code: program || null,
      vp_code: vp || null,
      period,
      sphere: sphere || null,
    });
  }, [program, vp, sphere]);

  return (
    <LayoutNew
      left={isLargeSize && <ComplexGrid />}
      center={<CenterLayout isLargeSize={isLargeSize} />}
      right={isLargeSize && <NationalGoalsTable />}
    />
  );
};
