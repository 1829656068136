import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    gap: "1rem",
  },
  generalEffect: {
    display: "flex",
    maxWidth: "16rem",
    flexDirection: "column",
    background: (theme: Theme) => theme.palette.background.paper,
    padding: "1rem 1.5rem 2.5rem",
    borderRadius: "1rem",
    gap: "1rem",
    flex: "1 0 15%",
    alignItems: "center",
  },
  chart: {
    background: (theme: Theme) => theme.palette.charts.darkBlueBackground,
    borderRadius: "50%",
    display: "flex",
    height: "11.25rem",
    width: "11.25rem",
    padding: "0.3rem",
  },
  title: {
    fontWeight: 400,
    fontSize: "1.25rem",
  },
  num: {
    fontSize: "1.125rem",
    fontWeight: 500,
  },
  effects: {
    display: "flex",
  },
  effectsContainer: {
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
    flex: 1,
  },
  effectItem: (active: boolean) => ({
    display: "flex",
    background: (theme: Theme) => theme.palette.background.paper,
    flexDirection: "column",
    borderRadius: "1rem",
    padding: "1rem 1.5rem",
    justifyContent: "space-between",
    width: "calc(100% * (1/4) - 1rem)",
    opacity: active ? 1 : 0.2,
    transition: "0.1s ease-in",
    flexGrow: "1",
    cursor: "pointer",
  }),
  itemTop: {
    display: "flex",
    flexDirection: "row",
    gap: "0.25rem",
    justifyContent: "space-between",
  },
  itemBottom: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  barContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  barTitle: {
    fontSize: "1rem",
    fontWeight: 300,
  },
  barUnit: {
    display: "flex",
    fontWeight: 700,
    fontSize: "1.25rem",
    alignItems: "baseline",
    gap: "0.3rem",

    "& > span": {
      fontSize: "1rem",
    },
  },
};
