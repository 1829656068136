import { Theme } from "@mui/material";

export const css = {
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    columnGap: "1.5rem",
    rowGap: "1rem",
  },
  elementSX: (theme: Theme) => ({
    flex: 1,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    minWidth: `calc(${100 / 5}% - 1.5rem)`,
    maxWidth: `${100 / 5}%`,

    [theme.breakpoints.up("md")]: {
      minWidth: `calc(${100 / 7}% - 1.5rem)`,
      maxWidth: `${100 / 7}%`,
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: `calc(${100 / 10}% - 1.5rem)`,
      maxWidth: `${100 / 10}%`,
    },
  }),
  item: (active: boolean) => ({
    minHeight: "3.3rem",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    opacity: active ? 1 : 0.2,
    width: "100%",
    borderRadius: "0.5rem",
    cursor: "pointer",
  }),
  icon: (theme: Theme) => ({
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "-1rem",
    display: "flex",
    flexDirection: "column",
    flex: "0 0 auto",
    backgroundColor: theme.palette.main.darkBlue,
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: "1rem",
    alignItems: "center",

    "& img": {
      height: "1.5rem",
      width: "auto",
    },
  }),
  title: (value: number, active: boolean) => (theme: Theme) => ({
    justifyContent: "space-between",
    flex: 1,
    height: "100%",
    overflow: "hidden",
    fontSize: "1rem",
    lineHeight: "1em",
    maxHeight: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0.3rem 0.8rem 0.3rem 1.3rem",
    borderRadius: "0.5rem 0 0 0.5rem",
    background: `linear-gradient(
    0deg,
    ${theme.palette.complementary.darkBlue} -20%,
    ${
      !active
        ? theme.palette.complementary.grayBlue
        : value < 100
        ? theme.palette.complementary.whiteTransparent
        : theme.palette.complementary.darkGreenTransparent
    } ${value}%,
    ${theme.palette.complementary.darkBlueLight} ${value}%,
    ${theme.palette.complementary.darkBlueLight} 100%
  )`,
  }),
  indicator: (value: number, active: boolean) => (theme: Theme) => ({
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "3rem",
    borderLeft: `2px solid ${theme.palette.background.default}`,
    "& > span": {
      color:
        value > 99 ? theme.palette.text.primary : theme.palette.text.secondary,
      fontSize: "0.9rem",
    },
    "& > p": {
      fontSize: "1.6rem",
      fontWeight: "700",
    },
    background: `linear-gradient(
    0deg,
    transparent -20%,
    ${
      !active
        ? theme.palette.complementary.grayBlue
        : value < 100
        ? theme.palette.complementary.whiteTransparent
        : theme.palette.complementary.greenTransparent
    } ${value}%,
    ${theme.palette.background.default} ${value}%,
    ${theme.palette.background.default} 100%
  )`,
  }),
  rightArrowContainer: {
    flex: "0 0 auto",
    display: "flex",
    height: "100%",
    width: "2rem",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0 0.5rem 0.5rem 0",
    borderLeft: (theme: Theme) =>
      `2px solid ${theme.palette.background.default}`,
    background: (theme: Theme) => theme.palette.complementary.darkGrayBlue,
  },
  shortName: (theme: Theme) => ({
    textShadow: `1px 1px 2px ${theme.palette.complementary.darkGrayBlue}`,
    flex: "0 0 85%",
  }),
  secretContainer: {
    flex: "0 0 15%",
    textAlign: "center",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "5.5rem",
    display: "flex",
    height: "fit-content",
    gap: "0.5rem",
    "& svg": {
      width: "1.5rem",
      minWidth: "1.5rem",
    },
  },
};
