import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { ECalculationMethod } from "SRC/constants/globals";
import {
  IMinistries,
  useMinistries,
} from "SRC/redux/slices/summary/hooks/useMinistries";
import {
  IPremiers,
  usePremiers,
} from "SRC/redux/slices/summary/hooks/usePremiers";
import {
  IPrograms,
  usePrograms,
} from "SRC/redux/slices/summary/hooks/usePrograms";
import slice, {
  EGridTabs,
  IFiltersState,
} from "SRC/redux/slices/summary/slices/filters";

import { useAppSelector } from "../../../hooks";

export { EGridTabs } from "SRC/redux/slices/summary/slices/filters";

export interface IFilters extends IFiltersState {
  clear(): void;
  setSelectedVP(code: string): void;
  setSelectedMinistry(code: string): void;
  setSelectedProgram(code: string): void;
  filteredVPs: (string | number)[];
  filteredMinistries: (string | number)[];
  filteredPrograms: (string | number)[];
  filteredType: (string | number)[];
  setSelectedComplexGridTab(tab: string): void;
  setCalculationMethod(method: ECalculationMethod): void;
}

export const useFilters = (): IFilters => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.summary.filters);
  const { vp, program, ministry } = filters;
  const { items: premiers }: IPremiers = usePremiers();
  const { items: programs }: IPrograms = usePrograms();
  const { items: ministries }: IMinistries = useMinistries();

  const filteredVPs = useMemo(
    () => premiers.map((premier) => premier.VP_CODE),
    [premiers]
  );

  const filteredPrograms = useMemo(
    () =>
      programs
        .filter((program) => !vp || program["Вице-премьер, код"] === vp)
        .filter((program) => !ministry || program["Министерство"] === ministry)
        .map((program) => program.gp_code),
    [programs, vp, ministry]
  );

  const filteredType = useMemo(
    () =>
      programs
        .filter((program) => filteredPrograms.includes(program.gp_code))
        .map((program) => program.gp_code),
    [programs, filteredPrograms]
  );

  const filteredMinistries: string[] = useMemo(
    () =>
      ministries
        .filter((item) => !ministry || item.NAME === ministry)
        .map((item) => item.NAME),
    [ministries, program]
  );

  const setSelectedProgram = (id: string) =>
    dispatch(slice.actions.selectProgram(id));

  const setSelectedVP = (id: string) => {
    dispatch(slice.actions.selectVP(id));
    if (id) {
      setSelectedProgram("");
    }
  };

  const setSelectedMinistry = (id: string) => {
    dispatch(slice.actions.selectMinistry(id));
    if (id) {
      setSelectedProgram("");
      // dispatch(slice.actions.selectMinistry(""));
    }
  };

  const clear = useCallback(() => dispatch(slice.actions.clear()), [dispatch]);

  const setSelectedComplexGridTab = (tab: EGridTabs) => {
    dispatch(slice.actions.selectProgram(""));
    dispatch(slice.actions.selectMinistry(""));
    dispatch(slice.actions.selectVP(""));
    dispatch(slice.actions.setComplexGridTab(tab));
  };

  const setCalculationMethod = (method: ECalculationMethod) => {
    dispatch(slice.actions.setCalculationMethod(method));
  };

  return {
    ...filters,
    setSelectedMinistry,
    setSelectedVP,
    setSelectedProgram,
    clear,
    filteredVPs,
    filteredMinistries,
    filteredPrograms,
    filteredType,
    setSelectedComplexGridTab,
    setCalculationMethod,
  };
};
