import { Box, Theme, Typography } from "@mui/material";
import { WarningPinkTriangle } from "ASSETS/svg";
import { SmallWhiteArrowDown, SmallWhiteArrowUp } from "ASSETS/svg/arrow";
import React, { CSSProperties } from "react";
import { NavigateFunction } from "react-router-dom";
import { TableProps, UseExpandedRowProps, UseTableRowProps } from "react-table";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import { WarningIconContainer } from "SRC/components/Table/assets/icons";
import {
  css,
  css as tableCss,
} from "SRC/components/Table/BaseTableNew/BaseTable.styled";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { PROGRAM_DETAIL, setIdToUrl } from "SRC/urls";

import { css as vicePremiersCss } from "./VicePremiersTab.styled";

type CustomRowProps = UseTableRowProps<any> & UseExpandedRowProps<any>;

interface CustomTableProps extends TableProps {
  row: CustomRowProps;
}

const getVicePremiersColumns = (
  theme: Theme,
  navigate: NavigateFunction,
  setSelectedVP: (code: string) => void
) => {
  return [
    {
      Header: "Вице-премьер",
      accessor: "vicePremierName",
      Cell: (tableProps: CustomTableProps) => (
        <Box sx={vicePremiersCss.vicePremierName(tableProps.row.depth === 1)}>
          <Box
            sx={vicePremiersCss.vicePremierNameInner}
            onClick={() => {
              if (tableProps.row.depth === 0) {
                setSelectedVP(tableProps.row.original.id);
              }
            }}
            onDoubleClick={() => {
              if (tableProps.row.depth === 1) {
                navigate(
                  setIdToUrl(
                    PROGRAM_DETAIL,
                    tableProps.row.original.govProgramId
                  )
                );
              }
            }}
          >
            {tableProps.row.original.atRisk > 0 && (
              <WarningIconContainer
                style={vicePremiersCss.warning(tableProps.row.depth === 1)}
              >
                <WarningPinkTriangle
                  style={{
                    width: "1rem",
                    height: "1rem",
                  }}
                />
              </WarningIconContainer>
            )}
            {tableProps.row.original.govProgramId && (
              <Typography
                sx={css.tableCellText}
                style={vicePremiersCss.subRowId as CSSProperties}
                className="with-right-border whiteText"
              >
                {tableProps.row.original.govProgramId}
              </Typography>
            )}
            <img
              style={css.tableImage(tableProps.row.depth === 1)}
              src={tableProps.row.original.vicePremierPhoto}
              alt="vicePremier"
            />
            <Typography
              sx={css.tableCellText}
              className={
                tableProps.row.depth === 1
                  ? "smallText withMarginLeft whiteText"
                  : "withMarginLeft whiteText"
              }
            >
              {tableProps.row.original.vicePremierName}
            </Typography>
          </Box>
          {tableProps.row.canExpand ? (
            <Box
              sx={vicePremiersCss.expandArrow}
              {...tableProps.row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${tableProps.row.depth * 2}rem`,
                },
              })}
            >
              {tableProps.row.isExpanded ? (
                <Typography
                  sx={css.tableCellText}
                  className={
                    tableProps.row.depth === 0
                      ? "with-right-border whiteText smallLineHeight"
                      : "whiteText smallLineHeight"
                  }
                >
                  <SmallWhiteArrowUp />
                </Typography>
              ) : (
                <Typography
                  sx={css.tableCellText}
                  className={
                    tableProps.row.depth === 0
                      ? "with-right-border whiteText smallLineHeight"
                      : "whiteText smallLineHeight"
                  }
                >
                  <SmallWhiteArrowDown />
                </Typography>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                width: "2rem",
                height: "2rem",
              }}
            >
              <Typography
                sx={css.tableCellText}
                className={
                  tableProps.row.depth === 0
                    ? "with-right-border whiteText"
                    : "whiteText"
                }
              />
            </Box>
          )}
        </Box>
      ),
    },
    {
      Header: "Госпрограммы",
      accessor: "numOfPrograms",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          sx={css.tableCellText}
          className="with-right-border whiteText withMarginLeft"
          align="left"
        >
          {tableProps.row.original.numOfPrograms}
        </Typography>
      ),
    },
    {
      Header: "Показатели",
      columns: [
        {
          Header: "План",
          accessor: "indicatorsPlan",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="left"
              sx={css.tableCellText(theme)}
              className="whiteText withMarginLeft"
            >
              {tableProps.row.original.indicatorsPlan}
            </Typography>
          ),
        },
        {
          Header: "Выполнено",
          accessor: "indicatorsDone",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="left"
              sx={css.tableCellText(theme)}
              className="whiteText withMarginLeft"
            >
              {tableProps.row.original.indicatorsDone}
            </Typography>
          ),
        },
      ],
    },
    {
      Header: "Мероприятия",
      columns: [
        {
          Header: "План",
          accessor: "plan",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="left"
              sx={css.tableCellText(theme)}
              className="whiteText withMarginLeft"
            >
              {tableProps.row.original.plan}
            </Typography>
          ),
        },
        {
          Header: "Факт",
          accessor: "fact",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="left"
              sx={css.tableCellText(theme)}
              className="whiteText withMarginLeft"
            >
              {tableProps.row.original.fact}
            </Typography>
          ),
        },
        {
          Header: "Под риском",
          accessor: "atRisk",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="left"
              sx={tableCss.tableCellText(
                theme,
                "",
                false,
                tableProps.row.original.atRisk
              )}
              className="with-right-border whiteText withMarginLeft"
            >
              {tableProps.row.original.atRisk}
            </Typography>
          ),
        },
      ],
    },
    {
      Header: "Уровень достижения",
      accessor: "achievementLevel",
      Cell: (tableProps: CustomTableProps) => (
        <Box sx={{ padding: "8px 1rem" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <Typography
              align="center"
              sx={css.tableCellText(theme)}
              className="whiteText"
            >
              {`${roundNumbersToFixed(
                tableProps.row?.original.achievementLevel || 0,
                1
              )}`}
            </Typography>
            <Typography sx={css.tableCellText(theme)} className="grayCellText">
              %
            </Typography>
          </Box>
          <ProgressBar value={tableProps.row.original.achievementLevel || 0} />
        </Box>
      ),
    },
  ];
};

export { getVicePremiersColumns };
