import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IEventParams } from "SRC/redux/slices/gosprogram/slices/filters";
import { IProgramGoal } from "SRC/types";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export interface IProgramGoals {
  fetching: boolean;
  fetched: boolean;
  items: IProgramGoal[];
  load(params: IEventParams): void;
  getItemByCode(code: string): IProgramGoal | undefined;
  error: SerializedError | null;
}

export const useProgramGoals = (): IProgramGoals => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: programGoals = [],
    fetched,
  } = useAppSelector((state) => state.gosprogram.programGoals);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (params) => {
      if (!fetching) {
        dispatch(actions.programGoals.get(params)).then((action) => {
          if (actions.programGoals.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  const getItemByCode = (code: string): IProgramGoal | undefined =>
    programGoals.find((programGoal) => programGoal["Номер Цели"] === code);

  return {
    fetching,
    fetched,
    items: programGoals,
    getItemByCode,
    load,
    error,
  };
};
