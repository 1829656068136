import { Theme } from "@mui/material";
import { graphic } from "echarts";
import { EFinanceUnit } from "SRC/constants/globals";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";

const months = [
  "январь",
  "февраль",
  "март",
  "апрель",
  "май",
  "июнь",
  "июль",
  "август",
  "сентябрь",
  "октябрь",
  "ноябрь",
  "декабрь",
];

export const getOption = (
  planData: number[],
  factData: number[],
  unit: EFinanceUnit,
  theme: Theme
) => {
  return {
    grid: {
      containLabel: true,
      show: true,
      borderWidth: 0,
      right: "3%",
      left: "3%",
      bottom: "0",
    },
    responsive: true,
    xAxis: {
      type: "category",
      show: true,
      boundaryGap: false,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: theme.palette.charts.splitLine,
          width: 1,
        },
      },
      axisLabel: {
        formatter: function (value: string, index: number) {
          return value.slice(0, 3);
        },
        fontSize: 12,
        color: theme.palette.main.gray,
      },
      data: months,
    },
    yAxis: {
      type: "value",
      show: true,
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: theme.palette.complementary.darkGrayBlue,
      borderColor: theme.palette.complementary.grayBorder,
      textStyle: {
        color: "#fff",
      },
    },
    series: [
      {
        data: planData,
        type: "line",
        smooth: false,
        symbolSize: 1,
        tooltip: {
          valueFormatter: (value: number) =>
            value
              ? roundNumbersToFixed(value, 2) + ` ${unit}.руб`
              : `0.00 ${unit}.руб`,
        },
        lineStyle: {
          width: 3,
        },
        itemStyle: {
          color: theme.palette.primary.contrastText,
        },
        label: {
          show: false,
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: theme.palette.charts.whiteTransparent,
            },
            {
              offset: 1,
              color: theme.palette.charts.blueAreaTransparent,
            },
          ]),
        },
      },
      {
        data: factData,
        type: "line",
        smooth: false,
        lineStyle: {
          width: 3,
        },
        tooltip: {
          valueFormatter: (value: number) =>
            value
              ? roundNumbersToFixed(value, 2) + ` ${unit}.руб`
              : `0.00 ${unit}.руб`,
        },
        label: {
          show: false,
        },
        symbolSize: 1,
        itemStyle: {
          color: theme.palette.main.blue,
        },
      },
    ],
  };
};

export default getOption;
