import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { ITopVP } from "SRC/pages/Summary/interfaces";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export interface IVpTopBest2 {
  fetching: boolean;
  fetched: boolean;
  items: ITopVP[];
  load(period: IPeriod): void;
  error: SerializedError | null;
}

export const useTopBestVP2 = (): IVpTopBest2 => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: programsTopBest2 = [],
    fetched,
  } = useAppSelector((state) => state.summary.topBestVp2);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (period) => {
      if (!fetching) {
        dispatch(actions.topBestVp2.get(period)).then((action) => {
          if (actions.topBestVp2.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: [...programsTopBest2].sort((a, b) => Number(b.sum) - Number(a.sum)),
    load,
    error,
  };
};
