import { Box, Stack } from "@mui/material";
import React from "react";

import { css } from "./Layout.styles";

interface ILayout {
  head?: React.ReactNode;
  description?: React.ReactNode;
  summary?: React.ReactNode;
  content?: React.ReactNode;
  foot?: React.ReactNode;
}

const Layout: React.FC<ILayout> = ({
  head = null,
  description = null,
  summary = null,
  content = null,
  foot = null,
}) => (
  <Stack sx={css.container}>
    <Box sx={css.row}>{head}</Box>
    <Box sx={css.row}>
      <Box sx={css.field}>{description}</Box>
    </Box>
    <Box sx={css.content}>
      <Box sx={css.col}>
        <Box sx={css.field}>{summary}</Box>
      </Box>
      <Box sx={css.full}>
        <Box sx={css.field}>{content}</Box>
      </Box>
    </Box>
    <Box sx={css.row}>
      <Box sx={css.field}>{foot}</Box>
    </Box>
  </Stack>
);

export default Layout;
