import { Box, SxProps, Typography } from "@mui/material";
import { WhiteArrowLeft } from "ASSETS/svg/arrow";
import { WhiteArrowRight } from "ASSETS/svg/arrow";
import { Close } from "ASSETS/svg/close";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import Carousel, { CarouselItem } from "SRC/components/Carousel/Carousel";
import { useObjectPhotos } from "SRC/redux/slices/event/hooks/useObjectPhotos";

import { css } from "../ObjectTab.styled";

interface ICodeItem {
  sum: string;
  code: string;
}

interface IPhoto {
  code: string;
  url: string;
  date: string;
}

const photoCodes: [string, string][] = [
  ["1457", "1850"],
  ["1458", "1851"],
  ["1459", "1852"],
];

interface IPhotosModalProps {
  handlePhotoModalClose: () => void;
}

interface IImageItem {
  item: IPhoto;
  isSmall?: boolean;
}

const ImageItem: React.FC<IImageItem> = ({ item, isSmall }) => (
  <Box sx={isSmall ? css.imageSmallItemContainer : css.imageBigItemContainer}>
    <Box sx={css.image}>
      <img src={item.url} alt="" style={{ width: "100%", height: "100%" }} />
    </Box>
    <Box sx={css.date}>{moment(item.date).format("DD.MM.YYYY")}</Box>
  </Box>
);

export const PhotosModal = ({ handlePhotoModalClose }: IPhotosModalProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { items: objectPhotos } = useObjectPhotos();

  const data: ICodeItem[] = useMemo(
    () => objectPhotos?.[0]?.data?.[0]?.data?.[0]?.data || [],
    [objectPhotos]
  );

  const photos: IPhoto[] = useMemo(
    () =>
      photoCodes.reduce(
        (acc: IPhoto[], [url, date]) => [
          ...acc,
          {
            code: url,
            url: data.find((item) => item.code === url)?.sum || "",
            date: data.find((item) => item.code === date)?.sum || "",
          },
        ],
        [] as IPhoto[]
      ),
    [data]
  );

  useEffect(() => {
    setActiveIndex(0);
  }, []);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = photos.length - 1;
    } else if (newIndex >= photos.length) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  const isPrevBtnDisabled = activeIndex === 0;
  const isNextBtnDisabled = photos.length - activeIndex === 1;

  const onPrevClick = () => {
    if (!isPrevBtnDisabled) {
      updateIndex(activeIndex - 1);
    }
  };
  const onNextClick = () => {
    if (!isNextBtnDisabled) {
      updateIndex(activeIndex + 1);
    }
  };

  const photoItems = useMemo(
    (): JSX.Element[] =>
      photos.map((item: IPhoto) => (
        <CarouselItem key={item.code} visibleItems={1}>
          <ImageItem item={item} />
        </CarouselItem>
      )),
    [photos]
  );

  const smallPhotoItems = useMemo(
    (): JSX.Element[] =>
      photos.map((item: IPhoto) => (
        <CarouselItem key={item.code} visibleItems={3}>
          <ImageItem item={item} isSmall />
        </CarouselItem>
      )),
    [photos]
  );

  return (
    <>
      <Box sx={css.modalHeader}>
        <Typography sx={css.title as SxProps}>Фотогалерея</Typography>
        <Box sx={{ cursor: "pointer" }} onClick={handlePhotoModalClose}>
          <Close />
        </Box>
      </Box>
      <Box sx={css.container}>
        {photos.length > 1 && (
          <Box
            onClick={onPrevClick}
            sx={css.button(isPrevBtnDisabled)}
            style={{ transform: "translateX(-15%)" }}
          >
            <WhiteArrowLeft style={css.arrow} />
          </Box>
        )}
        <Box sx={css.carouselContainer}>
          <Carousel activeIndex={activeIndex} visibleItems={1}>
            {photoItems}
          </Carousel>
        </Box>
        {photos.length > 1 && (
          <Box
            onClick={onNextClick}
            sx={css.button(isNextBtnDisabled)}
            style={{ transform: "translateX(-60%)" }}
          >
            <WhiteArrowRight style={css.arrow} />
          </Box>
        )}
      </Box>
      <Box sx={css.secondContainer}>
        {photos.length > 3 && (
          <Box
            onClick={onPrevClick}
            sx={css.button(isPrevBtnDisabled)}
            style={{ transform: "translateX(-15%)" }}
          >
            <WhiteArrowLeft style={css.arrow} />
          </Box>
        )}
        <Box sx={css.carouselContainer}>
          <Carousel activeIndex={activeIndex} visibleItems={3}>
            {smallPhotoItems}
          </Carousel>
        </Box>
        {photos.length > 3 && (
          <Box
            onClick={onNextClick}
            sx={css.button(isNextBtnDisabled)}
            style={{ transform: "translateX(-60%)" }}
          >
            <WhiteArrowRight style={css.arrow} />
          </Box>
        )}
      </Box>
    </>
  );
};
