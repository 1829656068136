import { combineReducers } from "@reduxjs/toolkit";
import ministriesSlice, {
  IMinistriesState,
} from "SRC/redux/slices/summary/slices/ministries";
import {
  IPremiersState,
  slice as premiersSlice,
} from "SRC/redux/slices/summary/slices/premiers";
import {
  IProgramsState,
  slice as programsSlice,
} from "SRC/redux/slices/summary/slices/programs";
import topBestGpSlice, {
  IProgramsTopBestState,
} from "SRC/redux/slices/summary/slices/topBestGp";
import topBestGp2Slice, {
  IProgramsTopBest2State,
} from "SRC/redux/slices/summary/slices/topBestGp2";
import topBestMinistriesSlice, {
  IMinistriesTopBestState,
} from "SRC/redux/slices/summary/slices/topBestMinistries";
import topBestVpSlice, {
  IVpTopBestState,
} from "SRC/redux/slices/summary/slices/topBestVp";
import topBestVp2Slice, {
  IVpTopBest2State,
} from "SRC/redux/slices/summary/slices/topBestVp2";
import topWorstGpSlice, {
  IProgramsTopWorstState,
} from "SRC/redux/slices/summary/slices/topWorstGp";
import topWorstGp2Slice, {
  IProgramsTopWorst2State,
} from "SRC/redux/slices/summary/slices/topWorstGp2";
import topWorstMinistriesSlice, {
  IMinistriesTopWorstState,
} from "SRC/redux/slices/summary/slices/topWorstMinistries";
import topWorstMinistries2Slice, {
  IMinistriesTopWorst2State,
} from "SRC/redux/slices/summary/slices/topWorstMinistries2";
import topWorstVpSlice, {
  IVpTopWorstState,
} from "SRC/redux/slices/summary/slices/topWorstVp";
import topWorstVp2Slice, {
  IVpTopWorst2State,
} from "SRC/redux/slices/summary/slices/topWorstVp2";

import filtersSlice, { IFiltersState } from "./slices/filters";
import topBestMinistries2Slice, {
  IMinistriesTopBest2State,
} from "./slices/topBestMinistries2";

export interface ISummaryReducer {
  filters: IFiltersState;
  programs: IProgramsState;
  premiers: IPremiersState;
  ministries: IMinistriesState;
  topBestGP: IProgramsTopBestState;
  topWorstGP: IProgramsTopWorstState;
  topBestVP: IVpTopBestState;
  topWorstVP: IVpTopWorstState;
  topBestVp2: IVpTopBest2State;
  topWorstVP2: IVpTopWorst2State;
  topWorstGp2: IProgramsTopWorst2State;
  topBestGp2: IProgramsTopBest2State;
  topBestMinistries: IMinistriesTopBestState;
  topWorstMinistries: IMinistriesTopWorstState;
  topBestMinistries2: IMinistriesTopBest2State;
  topWorstMinistries2: IMinistriesTopWorst2State;
}

const reducer = combineReducers<ISummaryReducer>({
  filters: filtersSlice.reducer,
  programs: programsSlice.reducer,
  premiers: premiersSlice.reducer,
  ministries: ministriesSlice.reducer,
  topBestGP: topBestGpSlice.reducer,
  topWorstGP: topWorstGpSlice.reducer,
  topBestVP: topBestVpSlice.reducer,
  topWorstVP: topWorstVpSlice.reducer,
  topBestVp2: topBestVp2Slice.reducer,
  topWorstVP2: topWorstVp2Slice.reducer,
  topWorstGp2: topWorstGp2Slice.reducer,
  topBestGp2: topBestGp2Slice.reducer,
  topBestMinistries: topBestMinistriesSlice.reducer,
  topWorstMinistries: topWorstMinistriesSlice.reducer,
  topBestMinistries2: topBestMinistries2Slice.reducer,
  topWorstMinistries2: topWorstMinistries2Slice.reducer,
});

export default reducer;
