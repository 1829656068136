import { Theme } from "@mui/material";
import { graphic } from "echarts";

const months = [
  "январь",
  "февраль",
  "март",
  "апрель",
  "май",
  "июнь",
  "июль",
  "август",
  "сентябрь",
  "октябрь",
  "ноябрь",
  "декабрь",
];

const getOptions = (
  planData: number[],
  factData: (number | null)[],
  maxValue: number | null,
  unit: string,
  theme: Theme
) => {
  return {
    grid: {
      width: "92%",
      height: "85%",
      left: "4%",
      bottom: "25%",
    },
    xAxis: {
      type: "category",
      show: true,
      boundaryGap: false,
      offset: 0,
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: theme.palette.charts.splitLine,
        },
        interval: 0,
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: theme.palette.charts.splitLine,
        },
      },
      axisLabel: {
        formatter: function (value: string, index: number) {
          return value.slice(0, 3);
        },
        show: true,
        fontSize: 12,
        color: theme.palette.main.gray,
      },
      data: months,
    },
    yAxis: {
      type: "value",
      show: false,
      max: (maxValue || 0) * 1.5,
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
    },
    tooltip: {
      show: true,
    },
    series: [
      {
        data: planData,
        type: "line",
        smooth: true,
        symbolSize: 3,
        tooltip: {
          valueFormatter: (value: number) => `${value || 0} ${unit}`,
        },
        lineStyle: {
          width: 3,
        },
        itemStyle: {
          color: theme.palette.main.blue,
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: theme.palette.charts.blueArea,
            },
            {
              offset: 1,
              color: theme.palette.charts.blueAreaTransparent,
            },
          ]),
        },
      },
      {
        data: factData,
        type: "line",
        smooth: true,
        lineStyle: {
          width: 3,
        },
        tooltip: {
          valueFormatter: (value: number) => `${value || 0} ${unit}`,
        },
        symbolSize: 1,
        itemStyle: {
          color: theme.palette.primary.contrastText,
        },
      },
    ],
  };
};

export default getOptions;
