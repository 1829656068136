import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import {
  IExportData,
  OpenerButton,
  TableExportButton,
} from "SRC/components/Buttons";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import { getInfoRowsEvents } from "SRC/helpers/getInfoRows";
import useTableExportParams from "SRC/hooks/useTableExportParams";
import { IEventItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import { EventsTab } from "SRC/pages/Program/common/EventsTab/EventsTab";
import GoalsTab from "SRC/pages/Program/common/GoalsTab/GoalsTab";
import { IndicatorsTab } from "SRC/pages/Program/common/IndicatorsTab/IndicatorsTab";
import {
  useEventsTableData,
  useSEIndicatorsTableData,
} from "SRC/pages/Program/tableData";
import { getCurrentProgram } from "SRC/pages/Program/utils";
import { IFilters, useFilters } from "SRC/redux/slices/gosprogram/hooks";
import { useStructureElements } from "SRC/redux/slices/gosprogram/hooks/structureElements";
import { useProgram } from "SRC/redux/slices/gosprogram/hooks/useProgram";
import { useProgramGoals } from "SRC/redux/slices/gosprogram/hooks/useProgramGoals";
import { EVENT_STATUS, PROGRAM_TABS } from "SRC/types";

export const ProgramSummary = () => {
  const { tab: currentTab } = useParams();
  const { items: programGoals, fetched } = useProgramGoals();
  const { items: program } = useProgram();

  const currentProgram = useMemo(() => {
    return getCurrentProgram(program);
  }, [program]);

  const [activeTab, setActiveTab] = useState<PROGRAM_TABS>(PROGRAM_TABS.EVENTS);
  const { getItemByCode } = useStructureElements();
  const { eventsTableData, eventsExports } = useEventsTableData();
  const { indicatorsTableData } = useSEIndicatorsTableData();

  const {
    selectedStructureRow,
    selectedStructureElementName, // TODO под фильтр Наименование СЭ
  }: IFilters = useFilters();

  const [openTableModal, setOpenTableModal] = useState(false);

  const types: (keyof typeof currentProgram)[] = useMemo(
    () => ["Секретная", "Отраслевая", "Комплексная"],
    []
  );

  const infoRowsEvents = useMemo(
    () =>
      getInfoRowsEvents(
        currentProgram?.gp_name,
        types.find((name) => Number(currentProgram?.[name])) || " ",
        getItemByCode(selectedStructureRow)?.name
      ),
    [types, currentProgram, getItemByCode, selectedStructureRow]
  );

  const tabs: ITab<PROGRAM_TABS>[] = useMemo(() => {
    // TODO: временное решение, ожидается исправление статусов в данных
    const eventsTotal = eventsTableData.filter(({ status }) => {
      return status[EVENT_STATUS.DONE] || status[EVENT_STATUS.NOT_DONE];
    }).length;

    return [
      {
        value: PROGRAM_TABS.INDICATORS,
        label: "Показатели ГП",
        numberOfItems: fetched ? programGoals.length : undefined,
        component: <GoalsTab />,
      },
      {
        value: PROGRAM_TABS.EVENTS,
        label: "Мероприятия",
        numberOfItems: eventsTotal,
        component: (
          <EventsTab
            setTableModalOpen={setOpenTableModal}
            isTableModalOpen={openTableModal}
            infoRows={infoRowsEvents}
          />
        ),
      },
      {
        value: PROGRAM_TABS.STRUCTURE,
        wip: true,
        label: "Показатели СЭ",
        //numberOfItems: indicatorsTableData.length,
        component: (
          <IndicatorsTab
            setTableModalOpen={setOpenTableModal}
            isTableModalOpen={openTableModal}
            infoRows={infoRowsEvents}
          />
        ),
      },
    ];
  }, [
    programGoals,
    fetched,
    eventsTableData,
    openTableModal,
    infoRowsEvents,
    indicatorsTableData,
  ]);

  const handleChangeTab = (newValue: PROGRAM_TABS) => setActiveTab(newValue);

  const exportParams = useTableExportParams(eventsExports || [], "events");

  useEffect(() => {
    const tab = currentTab as undefined | PROGRAM_TABS;
    if (tab?.toString().length) setActiveTab(tab);
  }, [currentTab]);

  const tabExportData: IExportData<IEventItem> = useMemo(
    () => ({
      csvData: eventsExports,
      fileName: "Мероприятия",
      infoRows: infoRowsEvents,
      ...exportParams,
    }),
    [infoRowsEvents, eventsExports, exportParams]
  );

  return (
    <ExtendedTabs
      value={activeTab}
      onChange={handleChangeTab}
      tabs={tabs}
      actions={
        activeTab ===
        (programGoals?.length === 0
          ? PROGRAM_TABS.INDICATORS
          : PROGRAM_TABS.EVENTS)
          ? [
              <TableExportButton key={Math.random()} {...tabExportData} />,
              <OpenerButton
                open={openTableModal}
                setOpen={() => setOpenTableModal(true)}
              />,
            ]
          : []
      }
      style={{ fontSize: "2rem", fontWeight: 700 }}
    />
  );
};
