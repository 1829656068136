import { Box, SxProps, Typography } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { css } from "SRC/components/Table/Table.styled";
import { statusIcons } from "SRC/constants/globals";
import { IEventStatuses } from "SRC/types";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>[];
}

const renderStatusIcons = (statuses: IEventStatuses) =>
  Object.entries(statuses).map(
    ([status, isSet]) => !!isSet && statusIcons[status]
  );

const getEventColumns = () => [
  {
    Header: "",
    width: 0,
    accessor: "status-icon",
    Cell: (tableProps: CustomTableProps) => (
      <Box sx={css.statusIcon as SxProps}>
        {renderStatusIcons(tableProps.row.original.status)}
      </Box>
    ),
  },
  {
    Header: "№",
    width: 50,
    accessor: "index",
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText} className="colorWhite">
        {tableProps.row.original.index}
      </Typography>
    ),
  },
  {
    Header: "ID мер-ия",
    accessor: "id",
    width: 120,
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText} className="colorGray">
        {tableProps.row.original.id}
      </Typography>
    ),
  },
  {
    Header: "Тип CЭ",
    width: 100,
    accessor: "structureElement",
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText} className="colorGray">
        {tableProps.row.original.structureElement}
      </Typography>
    ),
  },
  {
    Header: "Наименование СЭ",
    width: "auto",
    accessor: "structureElementName",
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText} className="eventName colorWhite">
        {tableProps.row.original.structureElementName}
      </Typography>
    ),
  },
  {
    Header: "Наименование мероприятия",
    accessor: "name",
    width: "auto",
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText} className="eventName colorWhite">
        {tableProps.row.original.name}
      </Typography>
    ),
  },
  {
    Header: "Ед.изм.",
    accessor: "units",
    width: 100,
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText} className="colorGray">
        {tableProps.row.original.units}
      </Typography>
    ),
  },
  {
    Header: "План",
    accessor: "plan",
    width: 100,
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText} className="colorWhite bigText">
        {tableProps.row.original.plan}
      </Typography>
    ),
  },
  {
    Header: "Факт",
    accessor: "fact",
    width: 100,
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText} className="colorWhite bigText">
        {tableProps.row.original.fact}
      </Typography>
    ),
  },
  {
    Header: "Выполнение",
    accessor: "done",
    width: 180,
    Cell: (tableProps: CustomTableProps) => (
      <>
        <Typography sx={css.tableCellText} className="colorWhite bigText">
          {tableProps.row.original.done}
        </Typography>
      </>
    ),
  },
  {
    Header: "Влияние",
    accessor: "effect",
    Cell: (tableProps: CustomTableProps) => (
      <Typography sx={css.tableCellText} className="colorWhite bigText">
        {tableProps.row.original.effect}
      </Typography>
    ),
  },
];

export { getEventColumns };
