import { styled, Theme, Typography } from "@mui/material";

interface IProps {
  isSmall?: boolean;
  length?: number | undefined;
  withBigRows?: boolean;
  hasBorder?: "left" | "right";
  multilineHeader?: boolean;
  withHeadBgr?: boolean;
  withClickableRow?: boolean;
  tableHeight?: string | number;
  statusLabel?: string;
  statusColored?: boolean;
  isSelected?: boolean;
  defaultSelection?: boolean;
  wrapSort?: boolean;
}

export const IncreasedText = styled(Typography)({
  display: "inherit",
  flex: 1,
  fontSize: "1.1em",
  fontWeight: "inherit",
});

export const DecreasedText = styled(Typography)({
  display: "inherit",
  flex: 1,
  fontSize: "0.9em",
  fontWeight: "inherit",
});

export const css = {
  statusIcon: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.3rem",
    width: 0,
    maxWidth: 0,
    height: "100%",
    padding: "0.3rem 0",

    "> *": {
      position: "relative",
      left: "-0.5rem",
      width: "1.2rem",
      minWidth: "1.2rem",
      height: "1.2rem",
      minHeight: "1.2rem",
    },
  },

  centered: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
  },

  centeredRow: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
  },

  contained: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    maxHeight: "100%",
  },

  tableCellText: (
    theme: Theme,
    statusLabel?: string,
    statusColored?: boolean,
    atRisk?: number | undefined,
    withBigRows?: boolean
  ) => ({
    flex: 1,
    fontSize: "1.1rem",
    lineHeight: "1.2em",
    fontWeight: theme.typography.fontWeightRegular,
    padding: "0.5rem 0",

    [theme.breakpoints.up("lg")]: {
      fontWeight: theme.typography.fontWeightBold,
    },

    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
    },

    "&.with-right-border": {
      "&:after": {
        height: "3rem",
        content: "''",
        borderLeft: `1px solid ${theme.palette.complementary.lightViolet}`,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: "100%",
      },
    },
    "&.with-dotted-border": {
      "&:after": {
        height: "0.3rem",
        content: "''",
        borderLeft: `4px solid ${theme.palette.main.darkBlue}`,
        position: "absolute",
        top: "50%",
        borderRadius: "50%",
        transform: "translateY(-50%)",
        left: "100%",
      },
    },
    "&.with-left-border": {
      "&:after": {
        height: "3rem",
        content: "''",
        borderLeft: `1px solid ${theme.palette.complementary.lightViolet}`,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: "0%",
      },
    },
    "&.colorWhite": {
      color: theme.palette.text.onAccent,
    },
    "&.colorRed": {
      color: theme.palette.error.main,
    },
    "&.colorGreen": {
      color: theme.palette.success.main,
    },
    "&.colorGray": {
      color: theme.palette.main.gray,
    },
    "&.bigText": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem",
      color:
        statusLabel === "completed"
          ? theme.palette.success.main
          : statusColored
          ? theme.palette.error.main
          : "",

      [theme.breakpoints.up("md")]: {
        fontSize: "1.5rem",
        lineHeight: "1.5em",
      },
    },

    // TODO [Добить стили]: что мог забыть? ===========================================
    /*
    "&.tableFooterText": {
      fontSize: "1.2rem",
      lineHeight: "1em",

      [theme.breakpoints.up("md")]: {
        fontSize: "1.6rem",
        lineHeight: "1em",
      },
    },
    "&.footerTextGray": {
      marginLeft: "1rem",
      color: theme.palette.main.gray,
    },
    "&.footerTextWhite": {
      marginLeft: "1rem",
      color: theme.palette.text.onAccent,
    },
    "&.whiteText": {
      color: theme.palette.text.onAccent,
    },
    "&.redText": {
      color: theme.palette.error.main,
    },
    "&.cellContainer": {
      padding: "1.25rem",
      maxWidth: "43.8rem",
      fontWeight: "bold",
    },
    "&.cellProgress": {
      color: `${theme.palette.main.yellow}`,
      padding: "1.25rem",
      maxWidth: "43.8rem",
      fontWeight: "bold",
    },
    "&.cellDone": {
      color: `${theme.palette.success.main}`,
      padding: "1.25rem",
      maxWidth: "43.8rem",
      fontWeight: "bold",
    },
    "&.grayCellText": {
      marginLeft: "0.3rem",
      color: theme.palette.main.gray,
    },
    "&.grayText": {
      color: theme.palette.main.gray,
      padding: 4,
      paddingRight: 2,
    },
    "&.whiteCellText": {
      color: theme.palette.text.onAccent,
      padding: 4,
      paddingRight: 2,
    },
    "&.withMarginLeft": {
      marginLeft: "1.25rem",
    },
    "&.withSmallMarginLeft": {
      marginLeft: "1rem",
    },
    "&.subjectName, &.regionName": {
      marginLeft: "1.25rem",
      fontSize: "1.25rem",
      fontWeight: 400,
      display: "flex",
    },
    "&.displayFlex": {
      display: "flex",
    },
    "&.columnDirection": {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    "&.justifyCenter": {
      display: "flex",
      justifyContent: "center",
    },
    "&.verticalPadding": {
      padding: "1rem 0",
    },
    "&.atRisk": {
      color:
        atRisk && atRisk > 0
          ? theme.palette.error.main
          : theme.palette.main.gray,
    },
    "&.cashColumn-fact-cellText": {
      color: theme.palette.text.onAccent,
    },
    "&.withBigRows": {
      [theme.breakpoints.up("lg")]: withBigRows && {
        fontSize: "2rem",
        lineHeight: "1.8rem",
      },
    },
    "&.marginBottom": {
      color: theme.palette.main.gray,
      marginBottom: 2,
    },
    "&.eventName": {
      marginLeft: "1.25rem",
      padding: "1.25rem 1.25rem 1.25rem 0",
      maxWidth: "43.8rem",
      fontWeight: "normal",
    },
    "&.isSmallOnLarge": {
      [theme.breakpoints.up("xl")]: { fontSize: "1rem" },
    },
    "&.fontLight": {
      fontWeight: "normal",
    },
    "&.idCell": {
      padding: "0 0.5rem",
    },
    "&.smallText": {
      fontSize: "1rem",
      lineHeight: "1rem",
    },
    "&.smallLineHeight": {
      lineHeight: "0.2rem",
    },
  */
  }),

  /*
  tableImage: (isSmall?: boolean) => ({
    width: isSmall ? "2rem" : "3rem",

    "&.rounded": {
      borderRadius: "50%",
    },
  }),
  tableImageContainer: (
    theme: Theme,
    withBigRows?: boolean,
    isSmall?: boolean
  ) => ({
    display: "flex",
    alignItems: "center",с
    justifyContent: "center",
    padding: isSmall ? "1rem" : "1.2rem",

    [theme.breakpoints.up("lg")]: withBigRows && {
      padding: "1rem 1.5rem",
    },
  }),
  secretProgram: {
    "& svg": {
      width: "1rem",
      height: "1rem",
    },
  },
  iconContainer: {
    display: "flex",
    gap: "0.1rem",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    transform: "translateY(-43%)",
    left: "-0.5rem",
  },
  idCell: {
    padding: "0 0 0 10px",
    display: "flex",
    flexDirection: "column",
  },
  emptyTextContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    padding: "1rem",
  },
  loader: {
    position: "absolute",
    width: "100vw",
    maxWidth: "100%",
    height: "100px",
    zIndex: 999,
  },
  */
};

export const TableCellText = styled(Typography)<IProps>(
  ({ theme, hasBorder }: IProps & { theme: Theme }) => ({
    fontWeight: "bold",
    fontSize: "1.1rem",
    lineHeight: "1.2em",

    "&:after": hasBorder && {
      height: "3rem",
      content: "''",
      borderLeft: `3px solid ${theme.palette.main.darkBlue}`,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: hasBorder === "left" ? "0%" : "100%",
    },

    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
      lineHeight: "1.3em",
    },
  })
);

export const TableCellBigText = styled(Typography)<IProps>(
  ({
    theme,
    withBigRows,
    statusLabel,
    statusColored,
    hasBorder,
  }: IProps & { theme: Theme }) => ({
    flex: 1,
    fontSize: "1.2rem",
    lineHeight: "1.2rem",
    fontWeight: "bold",
    color:
      statusLabel === "completed"
        ? theme.palette.success.main
        : statusColored
        ? theme.palette.error.main
        : "",

    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
      lineHeight: "1.5em",
    },

    [theme.breakpoints.up("lg")]: withBigRows && {
      fontSize: "2rem",
      lineHeight: "1.8rem",
    },

    "&:after": hasBorder && {
      height: "4rem",
      content: "''",
      borderLeft: `3px solid ${theme.palette.main.darkBlue}`,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: hasBorder === "left" ? "0%" : "100%",
    },
    "&.resetMargin": {
      margin: 0,
    },
  })
);

export const MediaIconContainer = styled("div")<IProps>(
  ({ theme }: IProps & { theme: Theme }) => ({
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    left: "-6%",
    transform: "translateY(50%)",
    bottom: "50%",
  })
);

export const TableFooterText = styled(TableCellText)(({ theme }) => ({
  flex: 1,
  fontSize: "1.2rem",
  lineHeight: "1em",

  [theme.breakpoints.up("md")]: {
    fontSize: "1.6rem",
    lineHeight: "1em",
  },
}));
