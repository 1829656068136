import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { AtRiskProgram, AtRiskProgram2 } from "ASSETS/svg";
import React, { useMemo } from "react";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";

import { css } from "./LineProgressEvents.styled";

interface ILineProgressProps {
  nameValue: string;
  unit: string;
  value: number;
  percentage: number;
  total: number;
}

export const LineProgressEvents = ({
  nameValue,
  unit,
  value,
  percentage,
  total,
}: ILineProgressProps) => {
  const theme = useTheme();
  return (
    <Box sx={css.container}>
      <Box sx={css.nameContainer}>
        <Box sx={css.name}>{nameValue}</Box>
        <Box sx={css.unit}>{unit}</Box>
      </Box>
      <ProgressBar
        value={percentage}
        progressColor={theme.palette.pale.red}
        withLabel={true}
        bottomRightLabel={total}
        circleLabel={value}
        style={{
          marginTop: "1.25rem",
          background: theme.palette.background.progressDarkBlue,
        }}
      />
    </Box>
  );
};
