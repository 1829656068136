import { combineReducers } from "@reduxjs/toolkit";

import auth, { IAuthReducer } from "./slices/auth/reducer";
import event, { IEventReducer } from "./slices/event/reducer";
import gosprogram, { IGosprogramReducer } from "./slices/gosprogram/reducer";
import main, { IMainReducer } from "./slices/main/reducer";
import programsBoard, { IProgramsBoard } from "./slices/programsBoard/reducer";
import summary, { ISummaryReducer } from "./slices/summary/reducer";

export interface IState {
  auth: IAuthReducer;
  main: IMainReducer;
  gosprogram: IGosprogramReducer;
  summary: ISummaryReducer;
  programsBoard: IProgramsBoard;
  event: IEventReducer;
}

const reducer = combineReducers<IState>({
  auth,
  main,
  gosprogram,
  summary,
  programsBoard,
  event,
});

export default reducer;
