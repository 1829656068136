import { Box, Theme, Typography } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { StatusPoint } from "SRC/components/StatusPoint/StatusPoint";
import {
  expandedGroupingColumn,
  NavigateCell,
  nestingTest,
} from "SRC/components/Table";
import { css } from "SRC/components/Table/Table.styled";
import { WIPTag } from "SRC/components/WIP";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { IPremierTableItem } from "SRC/pages/Summary/interfaces";
import { PROGRAM_TABS } from "SRC/types";
import { createProgramCardUrl, PROGRAM_DETAIL_FULL } from "SRC/urls";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<IPremierTableItem>;
  rows?: UseTableRowProps<IPremierTableItem>[];
}

const getGeneralInfoPremiersColumns = (
  theme: Theme
  // total?: IPremierTableItem
) => [
  expandedGroupingColumn,
  {
    Header: "",
    sticky: "left",
    accessor: "id",
    columns: [
      {
        Header: "№",
        width: 50,
        sticky: "left",
        accessor: "id",
        Cell: (tableProps: CustomTableProps) => {
          if (nestingTest(tableProps.row)) return null;

          return (
            <Typography sx={css.tableCellText} className="colorGray">
              {String(tableProps.row.original.id).padStart(2, "0")}
            </Typography>
          );
        },
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "vicePremierPhoto",
    sticky: "left",
    columns: [
      {
        Header: "",
        sticky: "left",
        accessor: "vicePremierPhoto",
        width: 65,
        Cell: (tableProps: CustomTableProps) => {
          if (nestingTest(tableProps.row))
            return (
              <Typography sx={css.tableCellText} className="colorGray">
                {String(tableProps.row.original.id).padStart(2, "0")}
              </Typography>
            );

          return (
            <Box sx={css.centered}>
              <img
                style={css.contained}
                src={tableProps.row.original.vicePremierPhoto}
                alt="photo"
              />
            </Box>
          );
        },
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "name",
    sticky: "left",
    columns: [
      {
        Header: "Вице - премьер",
        accessor: "name",
        width: 232,
        sticky: "left",
        Cell: (tableProps: CustomTableProps) => (
          <Typography sx={css.tableCellText}>
            {tableProps.row.original.name}
          </Typography>
        ),
        Footer: <Typography sx={css.tableCellText}>Итого</Typography>,
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "govPrograms",
    sticky: "left",
    columns: [
      {
        Header: "Кол-во госпрограмм",
        sticky: "left",
        width: 120,
        accessor: "govPrograms",
        Cell: (tableProps: CustomTableProps) => (
          <Typography sx={css.tableCellText} align="left">
            {tableProps.row.original.govPrograms}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return Array(tableProps.rows || [])
              .flat()
              .reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  (row.original.govPrograms || 0) + sum,
                0
              );
          }, [tableProps.rows]);

          return (
            <Typography sx={css.tableCellText(theme)} className="colorWhite">
              {total}
            </Typography>
          );
        },
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    width: 260,
    accessor: "performance",
    columns: [
      {
        Header: "Уровень достижения, %",
        width: 260,
        accessor: "performance",
        Cell: (tableProps: CustomTableProps) => {
          const performance = roundNumbersToFixed(
            tableProps.row.original.performance || 0,
            2
          );

          return (
            <Typography
              align="left"
              sx={{
                ...css.tableCellText(theme),
                display: "flex",
                gap: "0.75rem",
                alignItems: "center",
              }}
            >
              <StatusPoint value={performance} />
              {performance}
            </Typography>
          );
        },
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return (
              roundNumbersToFixed(
                tableProps?.rows?.[0]?.original.performanceTotal || 0,
                2
              ) || 0
            );
          }, [tableProps.rows]);

          return (
            <Typography
              align="left"
              sx={{
                ...css.tableCellText(theme),
                display: "flex",
                gap: "0.75rem",
                alignItems: "center",
              }}
            >
              <StatusPoint value={total} />
              {total}
            </Typography>
          );
        },
      },
    ],
    Footer: "",
  },
  {
    Header: "Кассовое исполнение",
    accessor: "cash",
    columns: [
      {
        Header: "План, млрд ₽",
        width: 170,
        accessor: "cashPlan",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme, tableProps.row.original.statusLabel)}
          >
            {tableProps.row.original.cashPlan}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return (
              roundNumbersToFixed(
                tableProps?.rows?.[0]?.original.cashPlanTotal || 0,
                2
              ) || 0
            );
          }, [tableProps.rows]);

          return <Typography sx={css.tableCellText(theme)}>{total}</Typography>;
        },
      },
      {
        Header: "Факт, %",
        width: 150,
        accessor: "cashFact",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme, tableProps.row.original.statusLabel)}
          >
            {tableProps.row.original.cashFact}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return (
              roundNumbersToFixed(
                tableProps?.rows?.[0]?.original.cashFactTotal || 0,
                2
              ) || 0
            );
          }, [tableProps.rows]);

          return (
            <Typography sx={css.tableCellText(theme)} className="colorWhite">
              {total}
            </Typography>
          );
        },
      },
    ],
    Footer: "",
  },
  {
    Header: "Показатели ГП",
    accessor: "indicators",
    columns: [
      {
        Header: "План",
        width: 150,
        accessor: "indicatorsPlan",
        Cell: (tableProps: CustomTableProps) => (
          <Typography sx={css.tableCellText(theme)} className="colorWhite">
            {tableProps.row.original.indicatorsPlan}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.indicatorsPlanTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography sx={css.tableCellText(theme)} className="colorWhite">
              {total}
            </Typography>
          );
        },
      },
      {
        Header: (
          <div>
            Факт
            <WIPTag sx={{ display: "inline-block", marginLeft: "0.5rem" }} />
          </div>
        ),
        width: 150,
        accessor: "indicatorsDone",
        Cell: (tableProps: CustomTableProps) => (
          <Typography sx={css.tableCellText(theme)} className="colorGreen">
            {tableProps.row.original.indicatorsDone}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.indicatorsDoneTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography sx={css.tableCellText(theme)} className="colorGreen">
              {total}
            </Typography>
          );
        },
      },
      {
        Header: "Не выполнено",
        width: 190,
        accessor: "indicatorsFail",
        Cell: (tableProps: CustomTableProps) => {
          if (!nestingTest(tableProps.row))
            return (
              <Typography sx={css.tableCellText(theme)} className="colorRed">
                {tableProps.row.original.indicatorsFail}
              </Typography>
            );

          return (
            <NavigateCell
              url={createProgramCardUrl(
                PROGRAM_DETAIL_FULL,
                tableProps.row.original.id,
                PROGRAM_TABS.INDICATORS,
                "1"
              )}
            >
              <Typography sx={css.tableCellText(theme)} className="colorRed">
                {tableProps.row.original.indicatorsFail}
              </Typography>
            </NavigateCell>
          );
        },
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.indicatorsFailTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography sx={css.tableCellText(theme)} className="colorRed">
              {total}
            </Typography>
          );
        },
      },
    ],
    Footer: "",
  },
  {
    Header: "Стуктурные элементы",
    accessor: "structure",
    columns: [
      {
        Header: "План",
        width: 150,
        accessor: "structurePlan",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme)}
            className="colorWhite"
            align="left"
          >
            {tableProps.row.original.structurePlan}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.structurePlanTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorWhite"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
      {
        Header: (
          <div>
            Факт
            <WIPTag sx={{ display: "inline-block", marginLeft: "0.5rem" }} />
          </div>
        ),
        width: 180,
        accessor: "structureDone",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme)}
            className="colorGreen"
            align="left"
          >
            {tableProps.row.original.structureDone}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.structureDoneTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorGreen"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
      {
        Header: "Не выполнено",
        width: 180,
        accessor: "structureFail",
        Cell: (tableProps: CustomTableProps) => {
          if (!nestingTest(tableProps.row))
            return (
              <Typography
                sx={css.tableCellText(theme)}
                className="colorRed"
                align="left"
              >
                {tableProps.row.original.structureFail}
              </Typography>
            );

          return (
            <NavigateCell
              url={createProgramCardUrl(
                PROGRAM_DETAIL_FULL,
                tableProps.row.original.id,
                PROGRAM_TABS.STRUCTURE,
                "1"
              )}
            >
              <Typography
                sx={css.tableCellText(theme)}
                className="colorRed"
                align="left"
              >
                {tableProps.row.original.structureFail}
              </Typography>
            </NavigateCell>
          );
        },
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.structureFailTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorRed"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
    ],
    Footer: "",
  },
  {
    Header: "Мероприятия",
    accessor: "events",
    columns: [
      {
        Header: "План",
        width: 180,
        accessor: "eventsPlan",
        Cell: (tableProps: CustomTableProps) => (
          <Typography sx={css.tableCellText(theme)} className="colorWhite">
            {tableProps.row.original.eventsPlan}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.eventsPlanTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography sx={css.tableCellText(theme)} className="colorWhite">
              {total}
            </Typography>
          );
        },
      },
      {
        Header: "Факт",
        width: 180,
        accessor: "eventsDone",
        Cell: (tableProps: CustomTableProps) => (
          <Typography sx={css.tableCellText(theme)} className="colorGreen">
            {tableProps.row.original.eventsDone}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.eventsDoneTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography sx={css.tableCellText(theme)} className="colorGreen">
              {total}
            </Typography>
          );
        },
      },
      {
        Header: "Не выполнено",
        width: 180,
        accessor: "eventsFail",
        Cell: (tableProps: CustomTableProps) => {
          if (!nestingTest(tableProps.row))
            return (
              <Typography sx={css.tableCellText(theme)} className="colorRed">
                {tableProps.row.original.eventsFail}
              </Typography>
            );

          return (
            <NavigateCell
              url={createProgramCardUrl(
                PROGRAM_DETAIL_FULL,
                tableProps.row.original.id,
                PROGRAM_TABS.EVENTS,
                "1"
              )}
            >
              <Typography sx={css.tableCellText(theme)} className="colorRed">
                {tableProps.row.original.eventsFail}
              </Typography>
            </NavigateCell>
          );
        },
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return tableProps?.rows?.[0]?.original.eventsFailTotal || 0;
          }, [tableProps.rows]);

          return (
            <Typography sx={css.tableCellText(theme)} className="colorRed">
              {total}
            </Typography>
          );
        },
      },
    ],
    Footer: "",
  },
  {
    Header: "Контрольные точки",
    accessor: "checkpoints",
    columns: [
      {
        Header: (
          <div>
            План
            <WIPTag sx={{ display: "inline-block", marginLeft: "0.5rem" }} />
          </div>
        ),
        width: 180,
        accessor: "checkpointsPlan",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme)}
            className="colorWhite"
            align="left"
          >
            {tableProps.row.original.checkpointsPlan}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return (
              roundNumbersToFixed(
                tableProps?.rows?.[0]?.original.checkpointsPlanTotal || 0,
                0
              ) || 0
            );
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorWhite"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
      {
        Header: (
          <div>
            Факт
            <WIPTag sx={{ display: "inline-block", marginLeft: "0.5rem" }} />
          </div>
        ),
        width: 180,
        accessor: "checkpointsDone",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme)}
            className="colorGreen"
            align="left"
          >
            {tableProps.row.original.checkpointsDone}
          </Typography>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return (
              roundNumbersToFixed(
                tableProps?.rows?.[0]?.original.checkpointsDoneTotal || 0,
                0
              ) || 0
            );
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorGreen"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
      {
        Header: (
          <div>
            Не выполнено
            <WIPTag sx={{ display: "inline-block", marginLeft: "0.5rem" }} />
          </div>
        ),
        // TODO: Выставить 180 после подключения данных
        width: 220,
        accessor: "checkpointsFail",
        Cell: (tableProps: CustomTableProps) => (
          <NavigateCell
            url={createProgramCardUrl(
              PROGRAM_DETAIL_FULL,
              tableProps.row.original.id,
              PROGRAM_TABS.EVENTS,
              "1"
            )}
          >
            <Typography
              sx={css.tableCellText(theme)}
              className="colorRed"
              align="left"
            >
              {tableProps.row.original.checkpointsFail}
            </Typography>
          </NavigateCell>
        ),
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return (
              roundNumbersToFixed(
                tableProps?.rows?.[0]?.original.checkpointsFailTotal || 0,
                0
              ) || 0
            );
          }, [tableProps.rows]);

          return (
            <Typography
              sx={css.tableCellText(theme)}
              className="colorRed"
              align="left"
            >
              {total}
            </Typography>
          );
        },
      },
    ],
    Footer: "",
  },
];

export { getGeneralInfoPremiersColumns };
