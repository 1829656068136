import { Box, SxProps } from "@mui/material";
import { SecretProgram, WarningPinkTriangle } from "ASSETS/svg";
import { WhiteArrowRight } from "ASSETS/svg/arrow";
import React, { BaseSyntheticEvent } from "react";
import { useNavigate } from "react-router";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { TProgram } from "SRC/redux/slices/main/hooks/usePrograms";
import { PROGRAM_DETAIL, setIdToUrl } from "SRC/urls";

import { css } from "./TilesItemElement.styled";

interface ITilesItemElement extends TProgram {
  active: boolean;
  secretProgram: boolean;
  onClick(): void;
  achievement?: number;
}

export default function TilesItemElement({
  gp_icon_url,
  gp_short_name,
  gp_code,
  active,
  secretProgram,
  onClick,
  data,
  achievement,
}: ITilesItemElement) {
  const navigate = useNavigate();

  const onNavigate = (e: BaseSyntheticEvent): void => {
    e.stopPropagation();
    if (active) navigate(setIdToUrl(PROGRAM_DETAIL, gp_code));
  };

  const atRisk = data.find((el) => el.code === "1586")?.sum || 0;

  return (
    <Box sx={css.item(active)} onClick={onClick}>
      <Box sx={css.icon as SxProps}>
        <span>{gp_code}</span>
        <img src={gp_icon_url} aria-hidden alt="Title Item Image." />
      </Box>
      <Box sx={css.title(achievement || 0, active)}>
        <Box sx={css.shortName}>{gp_short_name}</Box>
        <Box sx={css.secretContainer as SxProps}>
          {atRisk ? (
            <WarningPinkTriangle
              style={{ height: "1.5rem", alignSelf: "flex-end" }}
            />
          ) : null}
          {secretProgram ? <SecretProgram /> : null}
        </Box>
      </Box>
      <Box sx={css.indicator(achievement || 0, active) as SxProps}>
        <span>%</span>
        <p>{(achievement && roundNumbersToFixed(achievement, 0)) || 0}</p>
      </Box>
      <Box sx={css.rightArrowContainer as SxProps} onClick={onNavigate}>
        <WhiteArrowRight />
      </Box>
    </Box>
  );
}
