import { actions as headerPrograms } from "../programsBoard/slices/summary";
import { actions as events } from "./slices/events";
import { actions as goalConnect } from "./slices/goalConnect";
import { actions as indicators } from "./slices/indicators";
import { actions as program } from "./slices/program";
import { actions as programGoals } from "./slices/programGoals";
import { actions as resultCharacteristic } from "./slices/resultCharacteristic";
import { actions as statistics } from "./slices/statistics";
import {
  structureElementsActions,
  structureElementsGPActions,
  structureElementsIndicatorsActions,
} from "./slices/structureElements";
import { actions as tableType } from "./slices/tableType";

export interface IGosprogramActions {
  programGoals: typeof programGoals;
  events: typeof events;
  indicators: typeof indicators;
  statistics: typeof statistics;
  tableType: typeof tableType;
  resultCharacteristic: typeof resultCharacteristic;
  goalConnect: typeof goalConnect;
  headerPrograms: typeof headerPrograms;
  program: typeof program;
  structureElements: typeof structureElementsActions;
  structureElementsGP: typeof structureElementsGPActions;
  structureElementsIndicators: typeof structureElementsIndicatorsActions;
}

const actions: IGosprogramActions = {
  programGoals,
  events,
  indicators,
  statistics,
  tableType,
  resultCharacteristic,
  goalConnect,
  headerPrograms,
  program,
  structureElements: structureElementsActions,
  structureElementsGP: structureElementsGPActions,
  structureElementsIndicators: structureElementsIndicatorsActions,
};

export default actions;
