import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";
import { getSummary } from "SRC/redux/slices/programsBoard/api/client";
import { ICodeItem } from "SRC/types";

export interface ISummary {
  data: ICodeItem[];
  code: string;
  PERIOD_CODE?: string;
}

export interface ISummaryState {
  items: ISummary;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "summary/get",
    async (period: IPeriod) => await getSummary(period)
  ),
};

const initialState: ISummaryState = {
  items: {} as ISummary,
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "summary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = {} as ISummary;
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: ISummaryState, action) => {
        const data = action.payload.data?.[0]?.data as ISummary[];
        state.items = isContainedArray(data) ? data[0] : ({} as ISummary);
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
