import { Box, SxProps } from "@mui/material";
import React from "react";
import { statusIcons } from "SRC/constants/globals";

import { css } from "./TableStatusCardNew.styled";

interface IProps {
  status: string;
  amount: number;
  onClick?(): void;
  selected: boolean;
}

const TableStatusCardNew = ({ status, amount, onClick, selected }: IProps) => (
  <Box sx={css.cardContainer(selected, !amount) as SxProps} onClick={onClick}>
    <Box sx={css.statusIcon as SxProps}>{statusIcons[status]}</Box>
    <Box sx={css.statusText as SxProps}>{status}</Box>
    <Box sx={css.statusAmount}>{amount}</Box>
  </Box>
);

export default TableStatusCardNew;
