import { Box, FormControlLabel, Switch, SxProps } from "@mui/material";
import React, { useCallback } from "react";
import { useFilters } from "SRC/redux/slices/main/hooks/useFilters";

import { css } from "./SecretSwitcher.styled";

const SecretSwitcher = () => {
  const { withSecret, toggleSecret } = useFilters();

  const handleChange = useCallback(() => {
    toggleSecret(!withSecret);
  }, [toggleSecret, withSecret]);

  return (
    <Box sx={css.switch as SxProps}>
      <FormControlLabel
        classes={{ label: "controlLabel" }}
        value="start"
        control={
          <Switch
            checked={withSecret}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Секретные"
        labelPlacement="start"
      />
    </Box>
  );
};

export default SecretSwitcher;
