import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { ISubjectItem } from "SRC/pages/Event/interfaces";
import { getSubjects } from "SRC/redux/slices/event/api/client";
import { IEventParams } from "SRC/redux/slices/event/slices/filters";

export interface ISubjectsState {
  items: ISubjectItem[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "subjects/get",
    async (params: IEventParams) => await getSubjects(params)
  ),
};

const initialState: ISubjectsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "subjects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: ISubjectsState, action) => {
        const data = action.payload.data?.[0]?.data?.[0]?.data?.[0]
          ?.data as ISubjectItem[];
        state.items = isContainedArray(data) ? data : [];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
