import { olapRequestData2 } from "CORE/api/core";
import { MODEL_UUID, SECTION_NAMES } from "CORE/scheme/olap";
import { IGoalsParams } from "SRC/redux/slices/gosprogram/slices/programGoals";

const GET_STRUCTURE_ELEMENTS = "get-structure-elements";

export const getStructureElements = (params: IGoalsParams) => {
  return olapRequestData2(
    {
      dimensions: [],
      indicators: {},
      section_name: SECTION_NAMES.STRUCTURE,
      modelUuid: MODEL_UUID,
      filters: {
        period_code: params.period_code,
        gp_code: params.gp_code,
      },
    },
    GET_STRUCTURE_ELEMENTS
  );
};
