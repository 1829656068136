import { Box, Theme, Typography } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { Percent } from "SRC/components/elements/Percent/Percent";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import { css } from "SRC/components/Table/Table.styled";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";

import { css as nationalCss } from "./NationalGoalsTable.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
}

const getNationalGoalsColumns = (theme: Theme) => {
  return [
    {
      Header: " ",
      accessor: "icon",
      width: 60,
      Cell: (tableProps: CustomTableProps) => (
        <Box sx={css.centered}>
          <img
            style={{ ...css.contained, borderRadius: "0.5rem" }}
            src={tableProps.row.original.icon}
            alt="govProgram"
          />
        </Box>
      ),
    },
    {
      Header: "Наименование цели",
      accessor: "goalTitle",
      width: "auto",
      Cell: (tableProps: CustomTableProps) => (
        <Typography align="left" sx={css.tableCellText}>
          {tableProps.row.original.goalTitle}
        </Typography>
      ),
    },
    {
      Header: "Уровень достижения Национальной цели",
      accessor: "achievementLevel",
      width: "30%",
      Cell: (tableProps: CustomTableProps) => (
        <Box sx={nationalCss.achievementContainer}>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <Typography
              align="center"
              sx={css.tableCellText(theme)}
              className="whiteText"
            >
              {`${roundNumbersToFixed(
                tableProps.row.original.achievementLevel || 0,
                1
              )}`}
              <Percent theme={theme} />
            </Typography>
          </Box>
          <ProgressBar value={tableProps.row.original.achievementLevel || 0} />
        </Box>
      ),
    },
    {
      Header: "Количество Госпрограмм",
      accessor: "numOfPrograms",
      width: "20%",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          align="center"
          sx={css.tableCellText(theme, "", false, undefined)}
          className="bigText"
        >
          {tableProps.row.original.numOfPrograms}
        </Typography>
      ),
    },
  ];
};

export { getNationalGoalsColumns };
