import { Theme } from "@mui/material";

export const css = {
  cardContainer: (isSelected: boolean, isDisabled: boolean) => ({
    position: "relative",
    display: "flex",
    gap: "2.5rem",
    justifyContent: "space-between",
    height: "100%",
    minHeight: "4rem",
    minWidth: "10rem",
    padding: "1rem 1.5rem",
    alignItems: "center",
    borderRadius: "1rem",
    border: (theme: Theme) =>
      `1px solid ${theme.palette.complementary.darkBlue}`,
    cursor: isDisabled ? "default" : "pointer",
    background: isSelected
      ? (theme: Theme) => theme.palette.complementary.darkBlue
      : (theme: Theme) => theme.palette.main.darkBlue,
  }),
  statusIcon: {
    width: "1.5rem",
    height: "1.5rem",
    position: "absolute",
    top: "0.4rem",
    left: "-0.8rem",
    transform: "translateY(50%)",

    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  statusText: {
    fontSize: "1rem",
    lineHeight: "1rem",
    fontWeight: "normal",
    color: (theme: Theme) => theme.palette.main.gray,
    textAlign: "center",
  },
  statusAmount: {
    fontSize: "2rem",
    lineHeight: "2rem",
    fontWeight: "bold",
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
};
