import { Theme } from "@mui/material";

export const css = {
  vicePremierName: (isSubRow: boolean) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    paddingLeft: isSubRow ? "4rem" : "2rem",
  }),
  vicePremierNameInner: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
  },
  subRowId: {
    paddingRight: "1rem",
    marginRight: "1rem",
    position: "relative",
  },
  expandArrow: {
    width: "2rem",
    height: "2rem",
    background: (theme: Theme) => theme.palette.background.opacityWhite,
    borderRadius: "0.2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "1rem",
  },
  warning: (isSubRow: boolean) => ({
    left: isSubRow ? "-3.5rem" : "-2.5rem",
  }),
};
