import { Box, Theme } from "@mui/material";
import { AtRiskProgram, DspProgram, SecretProgram } from "ASSETS/svg";
import React, { Fragment } from "react";
import MethodSwitcher from "SRC/components/MethodSwitcher/MethodSwitcher";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import SecretSwitcher from "SRC/components/SecretSwitcher/SecretSwitcher";
import { getDataSumByCodeGP } from "SRC/constants/dataCodes";
import { ECalculationMethod } from "SRC/constants/globals";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import Tiles from "SRC/pages/ProgramsBoard/common/Tiles/Tiles";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { usePrograms } from "SRC/redux/slices/programsBoard/hooks/usePrograms";
import { useSummary } from "SRC/redux/slices/programsBoard/hooks/useSummary";

import { css } from "./center-layout.styled";

interface ICenterLayoutProps {
  theme: Theme;
  isLargeSize: boolean;
}
export const CenterLayout = ({ theme }: ICenterLayoutProps) => {
  const { setCalculationMethod, calculationMethod }: IFilters = useFilters();
  const { items } = useSummary();
  const {
    stats: { total, secret, risk },
  } = usePrograms();

  return (
    <Fragment>
      <Box sx={css.headerContainer}>
        <Box sx={css.header}>
          <Box sx={css.title}>Карточки госпрограмм</Box>
          <Box sx={css.switcherContainer}>
            <MethodSwitcher
              value={calculationMethod}
              onChange={setCalculationMethod}
            />
            <SecretSwitcher />
          </Box>
        </Box>
        <Box sx={css.header}>
          <Box sx={css.progress}>
            <Box sx={css.progressHeader}>
              <Box sx={css.barName}>
                Уровень достижения по открытым госпрограммам
              </Box>
              <Box sx={css.barValue}>
                {calculationMethod === ECalculationMethod.EFFECT
                  ? roundNumbersToFixed(getDataSumByCodeGP(items, 1753) || 0, 0)
                  : roundNumbersToFixed(
                      getDataSumByCodeGP(items, 1796) || 0,
                      0
                    )}
                <span>%</span>
              </Box>
            </Box>
            <ProgressBar
              value={
                calculationMethod === ECalculationMethod.EFFECT
                  ? roundNumbersToFixed(getDataSumByCodeGP(items, 1753) || 0, 0)
                  : roundNumbersToFixed(getDataSumByCodeGP(items, 1796) || 0, 0)
              }
              progressColor={theme.palette.pale.green}
              isDarkBg
            />
          </Box>
          <Box sx={css.statuses}>
            <Box sx={css.status}>
              <Box sx={css.statusName}>Всего</Box>
              <Box sx={css.statusCount}>{total}</Box>
            </Box>
            <Box sx={css.status}>
              <Box sx={css.statusIcon}>
                <SecretProgram />
              </Box>
              <Box sx={css.statusName}>Секретные</Box>
              <Box sx={css.statusCount}>{secret}</Box>
            </Box>
            <Box sx={css.status}>
              <Box sx={css.statusIcon}>
                <AtRiskProgram />
              </Box>
              <Box sx={css.statusName}>Под риском</Box>
              <Box sx={css.statusCount}>{risk}</Box>
            </Box>
            <Box sx={css.status} style={{ display: "none" }}>
              <Box sx={css.statusIcon}>
                <DspProgram />
              </Box>
              <Box sx={css.statusName}>ДСП</Box>
              <Box sx={css.statusCount}>0</Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Tiles />
    </Fragment>
  );
};
