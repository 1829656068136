import { Theme } from "@mui/material";

const imageItemContainer = {
  background: (theme: Theme) => theme.palette.background.opacityWhite,
  boxShadow: (theme: Theme) =>
    `0px 3px 5px ${theme.palette.background.defaultTransparent}`,
  backdropFilter: "blur(64px)",
  borderRadius: "1.5rem",
  padding: "2rem",
  margin: "auto",
  position: "relative",
};

export const css = {
  wrapper: {
    fontSize: "1rem",
  },
  modalContainer: {
    maxHeight: "80%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    borderRadius: "0.75rem",
    background: (theme: Theme) => theme.palette.background.default,
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  modalHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    margin: "auto",
  },
  secondContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    margin: "auto",
  },
  button: (hasNextItem?: boolean) => ({
    padding: "1.5rem 1rem",
    borderRadius: "8px",
    background: (theme: Theme) =>
      !hasNextItem
        ? theme.palette.primary.main
        : theme.palette.complementary.grayBlue,
    zIndex: 1,
    display: "flex",
    transform: "translateX(50%)",
    cursor: hasNextItem ? "not-allowed" : "pointer",
    "&:hover": {
      background: (theme: Theme) =>
        hasNextItem
          ? theme.palette.complementary.grayBlue
          : theme.palette.complementary.blueHover,
    },
  }),
  arrow: {
    height: "0.75rem",
    width: "0.375rem",
  },
  carouselContainer: {
    marginLeft: "-2rem",
    display: "flex",
    width: "100%",
  },
  imageBigItemContainer: {
    ...imageItemContainer,
    height: "20rem",
    width: "95%",
  },
  imageSmallItemContainer: {
    ...imageItemContainer,
    height: "16rem",
    width: "90%",
  },
  image: {
    height: "100%",
    width: "100%",
    borderRadius: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  date: {
    position: "absolute",
    left: "2rem",
    bottom: 0,
    lineHeight: "2rem",
  },
  videoBigItemContainer: {
    ...imageItemContainer,
    height: "60%",
    width: "70%",
    margin: "3rem auto",
  },
  flexContainer: (active: boolean) => ({
    display: "flex",
    alignItems: "center",
    padding: "0.5rem 1rem",
    background: (theme: Theme) =>
      active
        ? theme.palette.background.paperBorderGray
        : theme.palette.background.paper,
    fontSize: "1rem",
    gap: "0.5rem",
    borderRadius: "0.5rem",
    border: (theme: Theme) =>
      active ? `1px solid ${theme.palette.primary.contrastText}` : "none",
    cursor: "pointer",

    "& span.grayText": {
      color: (theme: Theme) => theme.palette.main.gray,
    },
  }),
  styledDot: {
    width: "0.8rem",
    height: "0.8rem",
    borderRadius: "50%",

    "&.green": {
      background: (theme: Theme) => theme.palette.success.main,
    },
    "&.red": {
      background: (theme: Theme) => theme.palette.error.main,
    },
    "&.blue": {
      background: (theme: Theme) => theme.palette.background.default,
    },
  },
};
