import { combineReducers } from "@reduxjs/toolkit";

import { IProgramsState, slice as programs } from "./slices/programs";
import { default as summary, ISummaryState } from "./slices/summary";

export interface IProgramsBoard {
  programs: IProgramsState;
  summary: ISummaryState;
}

const reducer = combineReducers<IProgramsBoard>({
  programs: programs.reducer,
  summary: summary.reducer,
});

export default reducer;
