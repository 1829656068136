export enum EVENT_STATUS {
  IN_PROGRESS = "В реализации",
  DONE = "Выполнено",
  AT_RISK = "Под риском",
  NOT_STARTED = "Не начато",
  NOT_ACHIEVED = "Не достигнуто",
  NOT_DONE = "Не выполнено",
  CRITICAL = "ЧС",
  OUT_DATE = "Просрочено",
}

export enum EVENT_CODE {
  IN_PROGRESS = "14",
  DONE = "16",
  AT_RISK = "17",
  NOT_STARTED = "18",
  NOT_DONE = "51",
  CRITICAL = "117",
}

// Важен порядок, он соответствует порядку отображения кнопок в карточке ГП
export const EVENT_STATUSES_DEFAULT: IEventStatuses = {
  [EVENT_STATUS.DONE]: 0,
  [EVENT_STATUS.NOT_DONE]: 0,
  [EVENT_STATUS.IN_PROGRESS]: 0,
  [EVENT_STATUS.NOT_STARTED]: 0,
  [EVENT_STATUS.AT_RISK]: 0,
  [EVENT_STATUS.CRITICAL]: 0,
};

export interface IEventStatuses {
  [EVENT_STATUS.IN_PROGRESS]: number;
  [EVENT_STATUS.DONE]: number;
  [EVENT_STATUS.AT_RISK]: number;
  [EVENT_STATUS.NOT_STARTED]: number;
  [EVENT_STATUS.NOT_DONE]: number;
  [EVENT_STATUS.CRITICAL]: number;
}

export type TEventStatus = keyof IEventStatuses;

export interface IIndicatorStatuses {
  [EVENT_STATUS.DONE]: number;
  [EVENT_STATUS.NOT_DONE]: number;
  [EVENT_STATUS.NOT_STARTED]: number;
}

export type TIndicatorStatus = keyof IIndicatorStatuses;

// TODO: свести всё к EVENT_STATUSES_DEFAULT после доработки бэка
export type ISEIndicatorStatuses = {
  [EVENT_STATUS.DONE]: number;
  [EVENT_STATUS.NOT_ACHIEVED]: number;
  [EVENT_STATUS.NOT_STARTED]: number;
};

// Важен порядок, он соответствует порядку отображения кнопок в карточке ГП
export const INDICATOR_STATUSES_DEFAULT = {
  [EVENT_STATUS.DONE]: {
    status: EVENT_STATUS.DONE,
    amount: 0,
  },
  [EVENT_STATUS.NOT_ACHIEVED]: {
    status: EVENT_STATUS.NOT_ACHIEVED,
    amount: 0,
  },
  [EVENT_STATUS.NOT_STARTED]: {
    status: EVENT_STATUS.NOT_STARTED,
    amount: 0,
  },
};

export type TSEIndicatorStatus = keyof ISEIndicatorStatuses;
