import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Card } from "SRC/layouts";
import { useEvent } from "SRC/redux/slices/event/hooks/useEvent";
import { useFilters } from "SRC/redux/slices/event/hooks/useFilters";

import { Description } from "./common/Description/Description";
import { EventTabs } from "./common/EventTabs/EventTabs";
import { Footer } from "./common/Footer/Footer";
import { Header } from "./common/Header/Header";
import { Sidebar } from "./common/Sidebar/Sidebar";

interface IProps {
  modalId?: string | number | null;
  close?(): void;
}

export const Event: React.FC<IProps> = ({ modalId, close }) => {
  const { id } = useParams();
  const { setSelectedEventId, clear } = useFilters();
  useEvent();

  useEffect(() => {
    if (modalId) {
      setSelectedEventId(modalId);
    } else if (id) {
      setSelectedEventId(id);
    } else {
      clear();
    }
  }, [id, modalId]);

  useEffect(() => () => clear(), []);

  return (
    <Card
      head={<Header isModal={!!modalId} close={close} />}
      description={<Description />}
      summary={<Sidebar />}
      content={<EventTabs />}
      foot={<Footer />}
    />
  );
};
