import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
  },
  numProgram: (theme: Theme) => ({
    fontWeight: "bold",
    fontSize: "3.25rem",
    lineHeight: "3.1rem",
    color: theme.palette.complementary.grayBlue,

    [theme.breakpoints.up("xl")]: {
      fontSize: "2.5rem",
      lineHeight: "2.5rem",
    },
  }),
  title: {
    display: "flex",
    alignItems: "center",
    gap: "1.5rem",
    flex: 1,
  },
  mainTitle: (theme: Theme) => ({
    display: "flex",
    lineHeight: "2.75rem",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "2rem",
    color: theme.palette.primary.contrastText,
    "&.centered": {
      justifyContent: "center",
    },

    [theme.breakpoints.up("xl")]: {
      fontSize: "1.75rem",
    },
  }),
  programType: (theme: Theme) => ({
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    color: theme.palette.complementary.grayBlue,

    [theme.breakpoints.up("xl")]: {
      fontSize: "1rem",
      lineHeight: "1.25rem",
    },
  }),
  switcher: {
    display: "flex",
    alignItems: "center",
    gap: "2rem",
    "& svg": {
      cursor: "pointer",
      width: "2rem",
      height: "2rem",
      alignItems: "baseline",
    },
  },
  buttons: {
    display: "flex",
    alignItems: "top",
    gap: "2rem",
  },
  curator: {
    display: "flex",
    gap: "2rem",
    flexWrap: "nowrap",
    borderRadius: "1rem",
    padding: "1rem",
    alignItems: "center",
    backgroundColor: (theme: Theme) =>
      theme.palette.complementary.darkBlueLight,
  },
  curatorContainer: {
    display: "flex",
    alignItems: "center",
  },
  curatorPhoto: (theme: Theme) => ({
    display: "flex",
    "& img": {
      width: "2rem",
      height: "2rem",
      borderRadius: "50%",
      border: `2px solid ${theme.palette.main.gray}`,
    },
  }),
  curatorNameContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "0.5rem",
    justifyContent: "space-between",
  },
  curatorText: (theme: Theme) => ({
    fontSize: "0.875rem",
    lineHeight: "1.125rem",
    color: theme.palette.main.gray,
  }),
  curatorName: (theme: Theme) => ({
    fontWeight: "bold",
    lineHeight: "1rem",
    fontSize: "1rem",
    color: theme.palette.primary.contrastText,
  }),
};
