import { olapRequestData3, olapRequestData4 } from "CORE/api/core";
import {
  getGpIndicators,
  getMinistriesIndicators,
  getVpIndicators,
} from "SRC/constants/dataCodes";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";

const vpIndicators = getVpIndicators(
  1572,
  1573,
  1574,
  1693,
  1694,
  1695,
  1696,
  1697,
  1698,
  1699,
  1700,
  1701,
  1702,
  1703,
  1704,
  1705,
  1706,
  1707,
  1708,
  1709,
  1710,
  1711,
  1712,
  1713,
  1714,
  1715,
  1716,
  1717,
  1718,
  1719,
  1720,
  1721,
  1722,
  1723,
  1724,
  1725,
  1726,
  1727,
  1728,
  1742,
  1743,
  1744,
  1745,
  1746,
  1747,
  1748,
  1749,
  1758,
  1759,
  1760,
  1761,
  1762,
  1763,
  1764,
  1765,
  1766,
  1767,
  1768,
  1797,
  1840,
  1841,
  5207,
  5208,
  5209,
  5248,
  5256,
  5257,
  5258
);

const GET_ALL_PROGRAMS_INFO = "get-all-programs-info";
const GET_ALL_PREMIERS_INFO = "get-all-premiers-info";
const GET_PREMIERS_TOTALS = "get-premiers-totals";
const GET_MINISTRIES = "get-ministries";
const GET_TOP_BEST_GP = "get-top-best-gp";
const GET_TOP_BEST_GP_2 = `${GET_TOP_BEST_GP}-2`;
const GET_TOP_WORST_GP = "get-top-worst-gp";
const GET_TOP_WORST_GP_2 = `${GET_TOP_WORST_GP}-2`;
const GET_TOP_BEST_VP = "get-top-best-vp";
const GET_TOP_BEST_VP_2 = `${GET_TOP_BEST_VP}-2`;
const GET_TOP_WORST_VP = "get-top-worst-vp";
const GET_TOP_WORST_VP_2 = `${GET_TOP_WORST_VP}-2`;
const GET_TOP_BEST_MINISTRIES = "get-top-best-ministries";
const GET_TOP_BEST_MINISTRIES_2 = `${GET_TOP_BEST_MINISTRIES}-2`;
const GET_TOP_WORST_MINISTRIES = "get-top-worst-ministries";
const GET_TOP_WORST_MINISTRIES_2 = `${GET_TOP_WORST_MINISTRIES}-2`;

export const getAllProgramsInfo = (period: IPeriod) => {
  return olapRequestData4(
    [
      {
        requestId: "9999c038-5b70-4081-a47f-49018e1ce85f",
        type: "data2",
        params: {
          modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1562",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "PERIOD_CODE",
              ],
            },
            {
              id: "1565",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "gp_code",
                "gp_short_name",
                "gp_icon_url",
                "gp_name",
                "Вице-премьер",
                "Министерство",
                "Вице-премьер, код",
                "Вице-премьер, фото",
                "Секретная",
                "Комплексная",
                "Отраслевая",
                "Сфера",
              ],
            },
          ],
          indicators: {
            id: "1571",
            items: [
              {
                id: "1547",
                aggregationFunction: "sum",
              },
              {
                id: "1548",
                stringAggregation: true,
                separator: ";",
              },
              // 1572 - СЭ, план
              {
                id: "1572",
                aggregationFunction: "sum",
              },
              // 1573 - СЭ, выполнено
              {
                id: "1573",
                aggregationFunction: "sum",
              },
              // 1574 - СЭ, невыполнено
              {
                id: "1574",
                aggregationFunction: "sum",
              },
              {
                id: "1575",
                aggregationFunction: "sum",
              },
              {
                id: "1576",
                aggregationFunction: "sum",
              },
              {
                id: "1577",
                aggregationFunction: "sum",
              },
              {
                id: "1578",
                aggregationFunction: "sum",
              },
              {
                id: "1579",
                aggregationFunction: "sum",
              },
              {
                id: "1580",
                aggregationFunction: "sum",
              },
              {
                id: "1581",
                aggregationFunction: "sum",
              },
              {
                id: "1582",
                aggregationFunction: "sum",
              },
              {
                id: "1583",
                aggregationFunction: "sum",
              },
              {
                id: "1584",
                aggregationFunction: "sum",
              },
              {
                id: "1585",
                aggregationFunction: "sum",
              },
              {
                id: "1586",
                aggregationFunction: "sum",
              },
              {
                id: "1587",
                aggregationFunction: "sum",
              },
              {
                id: "1588",
                aggregationFunction: "sum",
              },
              {
                id: "1589",
                aggregationFunction: "sum",
              },
              {
                id: "1590",
                aggregationFunction: "sum",
              },
              {
                id: "1591",
                aggregationFunction: "sum",
              },
              {
                id: "1592",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1593",
                aggregationFunction: "sum",
              },
              {
                id: "1594",
                aggregationFunction: "sum",
              },
              {
                id: "1595",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1596",
                aggregationFunction: "sum",
              },
              {
                id: "1597",
                aggregationFunction: "sum",
              },
              {
                id: "1598",
                aggregationFunction: "sum",
              },
              {
                id: "1599",
                aggregationFunction: "sum",
              },
              {
                id: "1600",
                aggregationFunction: "sum",
              },
              {
                id: "1601",
                aggregationFunction: "sum",
              },
              {
                id: "1602",
                aggregationFunction: "sum",
              },
              {
                id: "1603",
                aggregationFunction: "sum",
              },
              {
                id: "1604",
                aggregationFunction: "sum",
              },
              {
                id: "1605",
                aggregationFunction: "sum",
              },
              {
                id: "1652",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1653",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1678",
                aggregationFunction: "sum",
              },
              {
                id: "1679",
                aggregationFunction: "sum",
              },
              {
                id: "1680",
                aggregationFunction: "sum",
              },
              {
                id: "1681",
                aggregationFunction: "sum",
              },
              {
                id: "1682",
                aggregationFunction: "sum",
              },
              {
                id: "1730",
                aggregationFunction: "sum",
              },
              {
                id: "1731",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1753",
                aggregationFunction: "sum",
              },
              {
                id: "1754",
                aggregationFunction: "sum",
              },
              {
                id: "1755",
                aggregationFunction: "sum",
              },
              {
                id: "1756",
                aggregationFunction: "sum",
              },
              {
                id: "1757",
                aggregationFunction: "sum",
              },
              {
                id: "1769",
                stringAggregation: true,
                separator: ";",
              },
              {
                id: "1796",
                aggregationFunction: "sum",
              },
              {
                id: "1838",
                aggregationFunction: "sum",
              },
              {
                id: "1839",
                aggregationFunction: "sum",
              },
              {
                id: "5207",
                aggregationFunction: "sum",
              },
              {
                id: "5208",
                aggregationFunction: "sum",
              },
              {
                id: "5209",
                aggregationFunction: "sum",
              },
              {
                id: "5246",
                aggregationFunction: "sum",
              },
              {
                id: "5250",
                aggregationFunction: "sum",
              },
              {
                id: "5251",
                aggregationFunction: "sum",
              },
              {
                id: "5252",
                aggregationFunction: "sum",
              },
            ],
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1562",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1565",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1565",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_ALL_PROGRAMS_INFO
  );
};

export const getAllPremiersInfo = (period: IPeriod) => {
  return olapRequestData4(
    [
      {
        requestId: "4145f492-0dff-4658-ab3d-7a2f010acf5d",
        type: "data2",
        params: {
          modelUuid: "f8cfb9f5-ce0b-4b19-86c5-fd5a55d97a77",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1683",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "PERIOD_CODE",
              ],
            },
            {
              id: "1686",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "VP_CODE",
                "VP_SHORT_NAME",
                "VP_PHOTO_URL",
                "VP_NAME",
              ],
            },
          ],
          indicators: {
            id: "1692",
            items: vpIndicators,
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1683",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1686",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1686",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1683",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_ALL_PREMIERS_INFO
  );
};

export const getPremiersTotals = (period: IPeriod) => {
  return olapRequestData4(
    [
      {
        requestId: "2747e9d9-cc01-4720-a28f-d6e7d2e8fa2d",
        type: "data2",
        params: {
          modelUuid: "f8cfb9f5-ce0b-4b19-86c5-fd5a55d97a77",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1683",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "PERIOD_CODE",
              ],
            },
          ],
          indicators: {
            id: "1692",
            items: vpIndicators,
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "EQ",
            version: 1,
            operands: [
              {
                type: "DIM",
                version: 1,
                id: "1683",
              },
              {
                type: "CONST",
                version: 1,
                values: [period.apiV2],
              },
            ],
          },
        },
      },
    ],
    GET_PREMIERS_TOTALS
  );
};

// Министерства
export const getMinistries = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "cacd90dd-8e2f-4d48-af95-ca583e569a9d",
        type: "data2",
        params: {
          modelUuid: "485e7c43-6639-4b8e-9979-996461bea181",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1770",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "GERB_ICON",
              ],
            },
            {
              id: "1774",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1781",
            items: getMinistriesIndicators(1782, 1788, 1845, 1848, 1849),
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1770",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1774",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_MINISTRIES
  );
};

export const getTopBestGP = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "175baed8-7166-4997-9cfc-9f60b4b1cade",
        type: "data2",
        params: {
          modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1562",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1565",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1571",
            items: getGpIndicators(1547),
            limit: {
              id: "1547",
              order: "desc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1562",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1565",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1565",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_BEST_GP
  );
};

export const getTopBestGP2 = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "d326eec8-d68c-49de-9e06-5f463a5b940f",
        type: "data2",
        params: {
          modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1565",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1571",
            items: [
              {
                id: "1796",
                aggregationFunction: "sum",
              },
              {
                id: "1834",
                aggregationFunction: "sum",
              },
            ],
            limit: {
              id: "1834",
              order: "asc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1565",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_BEST_GP_2
  );
};

export const getTopWorstGP = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "d174a454-9d82-44ab-908e-b0cd825bb701",
        type: "data2",
        params: {
          modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1565",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1571",
            items: [
              {
                id: "1796",
                aggregationFunction: "sum",
              },
              {
                id: "1834",
                aggregationFunction: "sum",
              },
            ],
            limit: {
              id: "1834",
              order: "desc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 2,
                    id: "1565",
                    attributeCode: "Секретная",
                  },
                  {
                    type: "TEXT",
                    version: 2,
                    values: ["0"],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_WORST_GP
  );
};

export const getTopWorstGP2 = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "d174a454-9d82-44ab-908e-b0cd825bb701",
        type: "data2",
        params: {
          modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1565",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1571",
            items: [
              {
                id: "1796",
                aggregationFunction: "sum",
              },
              {
                id: "1834",
                aggregationFunction: "sum",
              },
            ],
            limit: {
              id: "1834",
              order: "desc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 2,
                    id: "1565",
                    attributeCode: "Секретная",
                  },
                  {
                    type: "TEXT",
                    version: 2,
                    values: ["0"],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_WORST_GP_2
  );
};

export const getTopBestVP = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "88d37d5e-416e-4210-b0ce-483704c9492c",
        type: "data2",
        params: {
          modelUuid: "f8cfb9f5-ce0b-4b19-86c5-fd5a55d97a77",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1683",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1686",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1692",
            items: getVpIndicators(1759),
            limit: {
              id: "1759",
              order: "desc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1686",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1683",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_BEST_VP
  );
};

export const getTopBestVP2 = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "46ec34b2-5f5d-40ae-80cd-61d8bfdc1b58",
        type: "data2",
        params: {
          modelUuid: "f8cfb9f5-ce0b-4b19-86c5-fd5a55d97a77",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1683",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1686",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1692",
            items: [
              {
                id: "1797",
                aggregationFunction: "sum",
              },
            ],
            limit: {
              id: "1797",
              order: "desc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1686",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1683",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_BEST_VP_2
  );
};

export const getTopWorstVP = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "6ca79fe9-c269-4a40-a180-f3eaa966147a",
        type: "data2",
        params: {
          modelUuid: "f8cfb9f5-ce0b-4b19-86c5-fd5a55d97a77",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1683",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1686",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1692",
            items: getVpIndicators(1759),
            limit: {
              id: "1759",
              order: "asc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1686",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1683",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_WORST_VP
  );
};

export const getTopWorstVP2 = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "9a2477f6-4bff-4b0b-8482-d22ead261763",
        type: "data2",
        params: {
          modelUuid: "f8cfb9f5-ce0b-4b19-86c5-fd5a55d97a77",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1683",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1686",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1692",
            items: [
              {
                id: "1797",
                aggregationFunction: "sum",
              },
            ],
            limit: {
              id: "1797",
              order: "asc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1686",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1683",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_WORST_VP_2
  );
};

export const getTopBestMinistries = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "99716b9a-1bd2-445a-a4eb-33331e134900",
        type: "data2",
        params: {
          modelUuid: "485e7c43-6639-4b8e-9979-996461bea181",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1770",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1774",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1781",
            items: [
              {
                id: "1788",
                aggregationFunction: "sum",
              },
            ],
            limit: {
              id: "1788",
              order: "desc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1770",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1774",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_BEST_MINISTRIES
  );
};

export const getTopBestMinistries2 = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "9be7a071-97b7-463d-9a32-09e07581499f",
        type: "data2",
        params: {
          modelUuid: "485e7c43-6639-4b8e-9979-996461bea181",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1770",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1774",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1781",
            items: [
              {
                id: "1845",
                aggregationFunction: "sum",
              },
            ],
            limit: {
              id: "1845",
              order: "desc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1770",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1774",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_BEST_MINISTRIES_2
  );
};

export const getTopWorstMinistries = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "cad97218-83f5-47ec-b8cc-120ef4a63ff2",
        type: "data2",
        params: {
          modelUuid: "485e7c43-6639-4b8e-9979-996461bea181",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1770",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1774",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1781",
            items: [
              {
                id: "1788",
                aggregationFunction: "sum",
              },
            ],
            limit: {
              id: "1788",
              order: "asc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1770",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1774",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_WORST_MINISTRIES
  );
};

export const getTopWorstMinistries2 = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "786503ff-3d21-41a5-b88e-c0bfaa8f3deb",
        type: "data2",
        params: {
          modelUuid: "485e7c43-6639-4b8e-9979-996461bea181",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1770",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1774",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1781",
            items: [
              {
                id: "1845",
                aggregationFunction: "sum",
              },
            ],
            limit: {
              id: "1845",
              order: "asc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1770",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1774",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_WORST_MINISTRIES_2
  );
};
