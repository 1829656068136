import { actions as programs } from "./slices/programs";
import { actions as summary } from "./slices/summary";

export interface IProgramsBoardActions {
  programs: typeof programs;
  summary: typeof summary;
}

const actions: IProgramsBoardActions = {
  programs,
  summary,
};

export default actions;
