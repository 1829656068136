import { Theme } from "@mui/material";
import { DarkChartBgr, LightChartBgr } from "ASSETS/svg/backgrounds";

export const css = {
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "end",
    position: "relative",
  },
  graph: {
    width: "100%",
    height: "100%",
  },
  textContainer: (withDarkBgr: boolean | undefined) => ({
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    top: "1rem",
    gap: "0.5rem",
    background: withDarkBgr
      ? `url(${DarkChartBgr}) no-repeat`
      : `url(${LightChartBgr}) no-repeat`,
    backgroundSize: "contain",
    backgroundPosition: "center",
  }),
  symbol: {
    position: "relative",
    bottom: "0.8rem",
    fontSize: "0.875rem",
    lineHeight: "1rem",
    color: (theme: Theme) => theme.palette.complementary.lightWhite,
    display: "flex",
    textAlign: "center",
  },
  text: {
    position: "relative",
    bottom: "0.8rem",
    fontSize: "2.5rem",
    lineHeight: "2.5rem",
    fontWeight: "bold",
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
  textSmall: (theme: Theme) => ({
    fontSize: "2rem",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  }),
};
