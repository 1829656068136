import {
  Box,
  Button,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { IconPhotoTable, IconPlayTable } from "ASSETS/svg";
import { ObjectDoubleArrow } from "ASSETS/svg/arrow";
import moment from "moment";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import {
  css as tableCss,
  MediaIconContainer,
} from "SRC/components/Table/Table.styled";
import { WIPTag } from "SRC/components/WIP";
import { css } from "SRC/pages/Event/common/EventTabs/ObjectTab/util/objectTableData.styled";
import theme from "SRC/theme";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const EventTabsTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme: Theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    width: "2em!important",
    height: "3rem!important",
    marginRight: "-0.8rem!important",
    top: "0.8rem!important",
    "&::before": {
      background: "red",
      border: `1px solid ${theme.palette.background.paperBorderGray}`,
      borderLeft: "none",
      backgroundColor: theme.palette.complementary.darkGrayBlue,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.complementary.darkGrayBlue,
    border: `1px solid ${theme.palette.background.paperBorderGray}`,
    boxShadow: "0px 12px 24px rgba(8, 9, 13, 0.3)",
    borderRadius: "4px",
    padding: "1rem",
  },
}));

const periodDate = (date: string) =>
  moment(date).isValid() ? moment(date).format("DD.MM.YYYY") : null;

type onClickType = (id: string) => void;

const getObjectColumns = (
  onPhotoClick: onClickType,
  onVideoClick: onClickType
) => {
  return [
    {
      Header: "Название объекта",
      accessor: "nameSubject",
      width: "30%",
      Cell: (tableProps: CustomTableProps) => (
        <Box sx={css.boxContainer}>
          <MediaIconContainer>
            <Box sx={css.boxIcon}>
              {tableProps.row.original.hasPhoto && (
                <Button
                  sx={css.buttonStyle}
                  onClick={() => onPhotoClick(tableProps.row.original.id)}
                >
                  <IconPhotoTable />
                </Button>
              )}
              {tableProps.row.original.hasVideo && (
                <Button
                  sx={css.buttonStyle}
                  onClick={() => onVideoClick(tableProps.row.original.id)}
                >
                  <IconPlayTable />
                </Button>
              )}
            </Box>
          </MediaIconContainer>
          <Typography
            sx={tableCss.tableCellText}
            className="with-right-border subjectName"
          >
            {tableProps.row.original.nameObject}
          </Typography>
        </Box>
      ),
    },
    {
      Header: "Регион/город",
      accessor: "nameRegion",
      width: "15%",
      Cell: (tableProps: CustomTableProps) => (
        <Box
          sx={tableCss.tableCellText}
          className="with-right-border columnDirection verticalPadding"
        >
          <Typography sx={tableCss.tableCellText} className="regionName">
            {tableProps.row.original.nameRegion}
          </Typography>
        </Box>
      ),
    },
    {
      Header: "Мощность",
      accessor: "countObjects",
      width: "15%",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          sx={tableCss.tableCellText}
          align="center"
          className="with-right-border justifyCenter verticalPadding"
        >
          {tableProps.row.original.countObjects}
        </Typography>
      ),
    },
    {
      Header: (
        <div style={{ display: "flex" }}>
          <div>Строительная готовность</div>
          <WIPTag
            sx={{
              display: "inline-block",
              marginLeft: "0.5rem",
              flexWrap: "nowrap",
            }}
          />
        </div>
      ),
      accessor: "ready",
      width: "20%",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          sx={tableCss.tableCellText}
          className="with-right-border justifyCenter verticalPadding"
        >
          {tableProps.row.original.ready + " %"}
        </Typography>
      ),
    },
    {
      Header: "Период реализации",
      accessor: "datePlan",
      width: "15%",
      Cell: (tableProps: CustomTableProps) => (
        <Box
          sx={tableCss.tableCellText}
          className="withMarginLeft verticalPadding"
        >
          <Box sx={css.datePlanContainer}>
            <Box sx={css.dates}>
              <Typography sx={tableCss.tableCellText}>
                {periodDate(tableProps.row.original.datePlan[0])}
              </Typography>
              <Typography sx={tableCss.tableCellText}>
                {periodDate(tableProps.row.original.datePlan[1])}
              </Typography>
            </Box>
            <Box>
              <EventTabsTooltip
                title={
                  <Box sx={css.tooltipTitle}>
                    Переход в карточку объекта (АРМ Строительство)
                  </Box>
                }
                placement={"left"}
                arrow
              >
                <Button>
                  <ObjectDoubleArrow />
                </Button>
              </EventTabsTooltip>
            </Box>
          </Box>
        </Box>
      ),
    },
  ];
};

export { getObjectColumns };
