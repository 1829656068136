import { createTheme } from "@mui/material";
import * as React from "react";

declare module "@mui/material/styles" {
  interface mainPalette {
    gray: string;
    red: string;
    blue: string;
    darkBlue: string;
    yellow: string;
  }
  interface compPalette {
    vividRed: string;
    darkRed: string;
    darkRedTransparent: string;
    whiteTransparent: string;
    redTransparent: string;
    blue: string;
    blueHover: string;
    darkBlue: string;
    darkBlueTransparent: string;
    darkBlueLight: string;
    darkBlueHover: string;
    grayBlue: string;
    grayBorder: string;
    darkGrayBlue: string;
    grayGreen: string;
    darkViolet: string;
    lightViolet: string;
    darkGreenTransparent: string;
    greenTransparent: string;
    lightShadow: string;
    lightWhite: string;
    unitsGray: string;
    linearDarkBlue: string;
    linearDarkBlue2: string;
  }
  interface palePalette {
    red: string;
    green: string;
    blue: string;
    gray: string;
    yellow: string;
  }
  interface chartsPalette {
    splitLine: string;
    grayBlue: string;
    white: string;
    blueArea: string;
    blueAreaTransparent: string;
    lightBlue: string;
    green: string;
    red: string;
    opacityGray: string;
    grayTransparent: string;
    greenTransparent: string;
    redTransparent: string;
    whiteTransparent: string;
    blueAreaOpacity: string;
    darkBlueBackground: string;
  }
  interface labelPalette {
    violet: string;
    blue: string;
    pink: string;
  }
  interface Palette {
    charts: chartsPalette;
    main: mainPalette;
    complementary: compPalette;
    pale: palePalette;
    labelTab: labelPalette;
  }
  interface PaletteOptions {
    main: mainPalette;
    complementary: compPalette;
    charts: chartsPalette;
    pale: palePalette;
    labelTab: labelPalette;
  }
}
declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    header: string;
    default: string;
    defaultTransparent: string;
    paperGray: string;
    paperDarkBlue: string;
    paperDarkGray: string;
    opacityWhite: string;
    paperBorderGray: string;
    buttonDarkBlue: string;
    progressDarkBlue: string;
  }

  interface TypeText {
    onAccent: string;
  }
}

declare module "@mui/material/styles/createTypography" {
  interface Typography {
    fontWeightSemiBold: React.CSSProperties["fontWeight"];
  }

  interface TypographyOptions {
    fontWeightSemiBold?: React.CSSProperties["fontWeight"];
  }
}

declare module "@mui/material/styles/createMixins" {
  interface Mixins {
    borderValue: (width?: string) => string;
    border: (width?: string) => {
      border: string;
    };
  }
}

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 1000,
      md: 1900,
      lg: 3800,
      xl: 7600,
    },
  },
  palette: {
    mode: "dark",
    main: {
      red: "#e93438",
      darkBlue: "#212735",
      blue: "#4a79d8",
      gray: "#a0b0d0",
      yellow: "#E7DE4D",
    },
    complementary: {
      vividRed: "#D22F33",
      darkRed: "#921D35",
      darkRedTransparent: "rgba(157,52,63,0.7)",
      whiteTransparent: "rgba(255,255,255,0.15)",
      redTransparent: "rgba(204, 12, 49, 0.75)",
      blue: "#063dad",
      blueHover: "#3168D7",
      darkBlue: "#2E364A",
      darkBlueTransparent: "rgba(46, 54, 74, 0.6)",
      darkBlueLight: "#262D3E",
      darkBlueHover: "#31394e",
      grayBlue: "#58637B",
      grayBorder: "#4D5567",
      darkGrayBlue: "#363E53",
      grayGreen: "#30404b",
      darkViolet: "#3a3648",
      lightViolet: "#393B5D",
      darkGreenTransparent: "rgba(64,151,88,0.7)",
      greenTransparent: "rgba(76, 217, 97, 0.65)",
      lightShadow: "rgba(110, 121, 148, 0.2)",
      lightWhite: "rgba(255, 255, 255, 0.5)",
      unitsGray: "#8391A0",
      linearDarkBlue: "rgba(4, 4, 5, 0.25)",
      linearDarkBlue2: "rgba(14, 16, 20, 0.35)",
    },
    pale: {
      red: "#FF6682",
      green: "#45E595",
      blue: "#79A7EB",
      gray: "#A0B0D1",
      yellow: "#FFAC54",
    },
    primary: {
      main: "#4a79d8",
      contrastText: "#fff",
    },
    error: {
      main: "#cc0c31",
      light: "#FF6682",
    },
    success: {
      main: "#4cd961",
      light: "#45E595",
    },
    background: {
      default: "#212735",
      defaultTransparent: "rgba(33, 39, 53, 0.5)",
      header: "#cc0c31",
      paper: "#2e364a",
      paperGray: "#282D40",
      paperDarkBlue: "#272e3e",
      paperDarkGray: "#252b3b",
      opacityWhite: "rgba(255, 255, 255, 0.1)",
      paperBorderGray: "#53647C",
      buttonDarkBlue: "rgba(9, 26, 43, 0.28)",
      progressDarkBlue: "#131529",
    },
    text: {
      primary: "#a0b0d0",
      secondary: "#58637B",
      disabled: "#3f4859",
      onAccent: "#ffffff",
    },
    divider: "#58637b",
    charts: {
      grayBlue: "#58637B",
      white: "#f7f7f7",
      splitLine: "rgba(160, 176, 208, 0.1)",
      blueArea: "rgba(74, 121, 216, 0.9)",
      blueAreaTransparent: "rgba(74, 121, 216, 0)",
      blueAreaOpacity: "rgba(74, 121, 216, 0.1)",
      lightBlue: "#32769F",
      green: "rgba(76, 217, 97, 1)",
      red: "rgba(204, 12, 49, 1)",
      opacityGray: "rgba(50, 118, 159, 0.05)",
      grayTransparent: "rgba(160, 176, 208, 0.1)",
      greenTransparent: "rgba(76, 217, 97, 0.7)",
      whiteTransparent: "rgba(255,255,255,0.7)",
      redTransparent: "rgba(233, 52, 56, 0.7)",
      darkBlueBackground: "rgba(13, 19, 33, 0.35)",
    },
    labelTab: {
      violet: "#6A32DB",
      blue: "#48ABE8",
      pink: "#D6387F",
    },
  },
  spacing: () => "0.5rem",
  typography: {
    fontFamily: `'Oswald', 'FS Elliot Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    htmlFontSize: 28,
    h1: {
      fontSize: "2rem",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.33rem",
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
      fontSize: "1rem",
      lineHeight: 1.4,
    },
    h5: {
      fontWeight: "bold",
      fontSize: "0.8rem",
      lineHeight: 1.4,
    },
    h6: {
      fontWeight: "bold",
      fontSize: "0.66rem",
      lineHeight: 1.4,
    },
    caption: {
      fontSize: "0.66rem",
    },
    subtitle1: {
      fontSize: "0.66rem",
      lineHeight: 1.4,
    },
    subtitle2: {
      fontSize: "0.5rem",
    },
  },
  mixins: {
    borderValue: (width?: string) =>
      `${width || "1px"} solid ${theme.palette.divider}`,
    border: (width?: string) => ({
      border: theme.mixins.borderValue(width),
    }),
  },
  shape: {
    borderRadius: 8,
  },
});

theme = createTheme(theme, {
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: "1rem",
          lineHeight: "1.5em",
          padding: "0.5rem 0.9rem",
          borderRadius: "0.5rem",
          backgroundColor: "#2E364A",
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          lineHeight: "1.4em",
          borderRadius: "0.5rem",
          backgroundColor: "#2E364A",
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          color: "#FFFFFF",
        },
        padding: {
          padding: "0.5rem",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: "0.25rem",
          fontSize: "1rem",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "0.5rem",
          backgroundColor: "#2E364A",
          backgroundImage: "none",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&:after": {
            content: "none",
          },

          "&:before": {
            content: "none",
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.contrastText,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        clearIndicator: {
          fontSize: "1em !important",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          display: "flex",
          minHeight: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          maxWidth: "100%",
          minWidth: "fit-content",
          flex: "0 0 auto",
          minHeight: 0,
          padding: "0.25rem 1rem",
          fontWeight: "inherit",
          fontSize: "inherit",
          textTransform: "none",
          lineHeight: "1.6em",
          zIndex: 1,
          color: theme.palette.text.secondary,

          "&.Mui-selected": {
            color: theme.palette.text.onAccent,
          },
        },
      },
    },
  },
});

export default theme;
