import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React, { CSSProperties } from "react";
import { OpenInFull } from "SRC/assets/svg/index";
import TableExportButton from "SRC/components/Buttons/TableExportButton";
import { Range } from "xlsx";

import { css } from "./TableActionsButtons.styled";

interface ITableActionsButtonsProps<DD> {
  setOpen?: (value: boolean) => void;
  open?: boolean;
  downloadData: DD[];
  fileName: string;
  wscols: Record<string, number>[];
  headings: Record<string, string | object>[];
  mergeCells?: Range[] | undefined;
  buttonsStyle?: CSSProperties;
  infoRows?: Record<string, string>[][];
}

export const TableActionsButtons = <T extends object>({
  setOpen,
  open,
  downloadData,
  fileName,
  wscols,
  headings,
  mergeCells,
  buttonsStyle,
  infoRows,
}: ITableActionsButtonsProps<T>) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box sx={{ ...css.buttonsContainer, ...buttonsStyle }}>
      <Button sx={css.button}>
        <TableExportButton
          csvData={downloadData}
          fileName={fileName}
          wscols={wscols}
          headings={headings}
          mergeCells={mergeCells}
          infoRows={infoRows}
        />
      </Button>
      {!match && !open && (
        <Button sx={css.button} onClick={() => setOpen?.(true)}>
          <OpenInFull />
        </Button>
      )}
    </Box>
  );
};
