import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  SxProps,
} from "@mui/material";
import { WarningTriangle } from "ASSETS/svg";
import React from "react";
import { useNavigate } from "react-router";
import { ForwardArrowRight } from "SRC/assets/svg/arrow";
import { Close } from "SRC/assets/svg/close";
import SelectWithSearch from "SRC/components/SelectWithSearch/SelectWithSearch";
import { WIP } from "SRC/components/WIP";
import { Overlay } from "SRC/pages/App.styled";
import { useEventData } from "SRC/redux/slices/event/hooks/useEventData";

import { css } from "./Header.styled";

interface IHeader {
  isModal: boolean;
  close?(): void;
}

const staticOption = {
  value: "",
  label: "Российская федерация",
};

export const Header: React.FC<IHeader> = ({ isModal, close }) => {
  const navigate = useNavigate();
  const { items: data, fetching: loading } = useEventData();
  const atRisk = Boolean(data?.data.find((el) => el.code === "1471")?.sum || 0);

  if (loading)
    return (
      <Overlay>
        <CircularProgress />
      </Overlay>
    );

  return (
    <Grid container sx={css.container}>
      <Stack sx={css.titles}>
        <Box sx={css.subtitles(!isModal)}>
          {!isModal && (
            <Button onClick={() => navigate(-1)} sx={css.back as SxProps}>
              <ForwardArrowRight style={{ transform: "rotate(180deg)" }} />
            </Button>
          )}
          <Box component="h2" sx={css.title}>
            {data?.NAME || "Название мероприятия"}
          </Box>
          {atRisk && (
            <WarningTriangle
              style={{
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
          )}
        </Box>
        <Box sx={css.subtitles()}>
          <Box component="span" sx={css.type as SxProps}>
            {data?.["Тип мероприятия"] || "Тип"}
          </Box>
          <Box component="h3" sx={css.title}>
            {data?.["Характеристика результата"] || "Характеристика"}
          </Box>
        </Box>
      </Stack>
      <WIP bordered>
        <Box sx={css.actions}>
          <SelectWithSearch
            options={[staticOption]}
            value={""}
            label=""
            hasCloseIcon={false}
            style={{ selectStyle: { width: "15rem" } }}
          />
          {/* TODO: сделать свитчер */}
          <Box sx={css.buttonsContainer as SxProps}>
            <Button sx={css.calculationButton as SxProps}>
              Квартальный расчет
            </Button>
            <Button sx={css.calculationButton as SxProps} variant="contained">
              Оперативный расчет
            </Button>
          </Box>
          {isModal && (
            <Button onClick={() => close?.()} sx={css.close}>
              <Close />
            </Button>
          )}
        </Box>
      </WIP>
    </Grid>
  );
};
