export const css = {
  container: {
    maxWidth: "100%",
    display: "flex",
    flex: "0 0 auto",
  },
  crumb: {
    fontSize: "1rem",
  },
};
