import { Box, CircularProgress, SxProps, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { RadialPolarChart } from "SRC/components/Charts";
import MethodSwitcher from "SRC/components/MethodSwitcher/MethodSwitcher";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import { ECalculationMethod } from "SRC/constants/globals";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { Overlay } from "SRC/pages/App.styled";
import { LineProgress } from "SRC/pages/Program/common/GovProgramInfoWidget/common/LineProgress/LineProgress";
import { useStatistics } from "SRC/redux/slices/gosprogram/hooks/useStatistics";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";

import { css } from "./EffectiveInfo.styled";

interface IStructureElement {
  name: string;
  amount: number;
  total: number;
  done: number;
  percentage: number;
}

interface IEffectiveData {
  achievementLevel: {
    percent: number;
    units: string;
  };
  indicators: {
    total: number;
    done: number;
    percentage: number;
    units: string;
  };
  chartData: {
    categories: string[];
    values: number[];
  };
  structureElements: {
    elements: IStructureElement[];
    total: number;
    done: number;
    units: string;
    percentage: number;
    name: string;
  };
}

export const EffectiveInfo = () => {
  const theme = useTheme();
  const { setCalculationMethod, calculationMethod }: IFilters = useFilters();

  const { items: statistics, fetching } = useStatistics();
  const {
    chartData,
    structureElements,
    achievementLevel,
    indicators,
  }: IEffectiveData = useMemo(
    () => ({
      achievementLevel: {
        percent:
          calculationMethod === ECalculationMethod.EFFECT
            ? statistics["Уровень достижения"]
            : statistics["Уровень достижения 2"],
        units: "%",
      },
      indicators: {
        total: statistics["Количество показателей"],
        done: statistics["Количество показателей, выполнено"],
        percentage:
          calculationMethod === ECalculationMethod.EFFECT
            ? statistics["Количество показателей, выполнено %"] || 0
            : statistics["Количество показателей 2, выполнено %"] || 0,
        units: "ед.",
      },
      chartData: {
        categories: ["КПМ", "Показатели", "ФП", "ВП"],
        values: [
          roundNumbersToFixed(
            calculationMethod === ECalculationMethod.EFFECT
              ? statistics["Выполнение мероприятий КПМ, %"] || 0
              : statistics["Выполнение мероприятий КПМ 2, %"] || 0,
            0
          ),
          roundNumbersToFixed(
            calculationMethod === ECalculationMethod.EFFECT
              ? statistics["Количество показателей, выполнено %"] || 0
              : statistics["Количество показателей 2, выполнено %"] || 0,
            0
          ),
          roundNumbersToFixed(
            calculationMethod === ECalculationMethod.EFFECT
              ? statistics["Количество выполненных мероприятий ФП, %"] || 0
              : statistics["Количество выполненных мероприятий ФП 2, %"] || 0,
            0
          ),
          roundNumbersToFixed(
            calculationMethod === ECalculationMethod.EFFECT
              ? statistics["Выполнение мероприятий ВП, %"] || 0
              : statistics["Выполнение мероприятий ВП 2, %"] || 0,
            0
          ),
        ],
      },
      structureElements: {
        elements: [
          {
            name: "ФП",
            amount: statistics["Федеральных проектов, кол-во"],
            total: statistics["Выполнение мероприятий ФП, план"],
            done: statistics["Выполнение мероприятий ФП, факт"],
            percentage: roundNumbersToFixed(
              calculationMethod === ECalculationMethod.EFFECT
                ? statistics["Количество выполненных мероприятий ФП, %"] || 0
                : statistics["Количество выполненных мероприятий ФП 2, %"] || 0,
              0
            ),
          },
          {
            name: "ВП",
            amount: statistics["Ведомственных проектов, кол-во"],
            total: statistics["Выполнение мероприятий ВП, план"],
            done: statistics["Выполнение мероприятий ВП, факт"],
            percentage: roundNumbersToFixed(
              calculationMethod === ECalculationMethod.EFFECT
                ? statistics["Выполнение мероприятий ВП, %"] || 0
                : statistics["Выполнение мероприятий ВП 2, %"] || 0,
              0
            ),
          },
          {
            name: "КПМ",
            amount: statistics["Комплексы процессных мероприятий, кол-во"],
            total: statistics["Выполнение мероприятий КПМ, план"],
            done: statistics["Выполнение мероприятий КПМ, факт"],
            percentage: roundNumbersToFixed(
              calculationMethod === ECalculationMethod.EFFECT
                ? statistics["Выполнение мероприятий КПМ, %"] || 0
                : statistics["Выполнение мероприятий КПМ 2, %"] || 0,
              0
            ),
          },
        ],
        name: "Всего",
        total: statistics["Структурные элементы, всего"],
        done: statistics["Мероприятий выполнено"],
        units: "ед.",
        percentage: roundNumbersToFixed(
          calculationMethod === ECalculationMethod.EFFECT
            ? statistics["Структурные элементы, %"] || 0
            : statistics["Структурные элементы 2, %"] || 0,
          0
        ),
      },
    }),
    [statistics, calculationMethod]
  );
  const percentHundredths = useMemo(() => {
    return String(achievementLevel.percent).split(".");
  }, [achievementLevel.percent]);

  if (fetching)
    return (
      <Box sx={css.container as SxProps}>
        <Box sx={css.container as SxProps}>
          <Overlay>
            <CircularProgress />
          </Overlay>
        </Box>
      </Box>
    );

  return (
    <Box sx={css.container as SxProps}>
      <Box sx={css.effectiveHeader}>
        <Box sx={css.title}>Уровень достижения</Box>
        <MethodSwitcher
          value={calculationMethod}
          onChange={setCalculationMethod}
        />
      </Box>
      <Box sx={css.effectiveContent}>
        <Box sx={css.achievement as SxProps}>
          <Box>
            <Box sx={css.progress}>
              <Box sx={css.value}>
                {percentHundredths[0]}
                {percentHundredths[1]?.[0] ? (
                  <span>,{percentHundredths[1][0]}</span>
                ) : null}
                <span className="unit">{achievementLevel.units}</span>
              </Box>
              <ProgressBar
                value={achievementLevel.percent}
                progressColor={
                  achievementLevel.percent < 100
                    ? theme.palette.pale.red
                    : theme.palette.pale.green
                }
                style={css.progressBarStyle as SxProps}
              />
            </Box>
          </Box>
          <Box>
            <Box sx={css.titleAchievement}>Показатели уровня ГП</Box>
            <Box sx={css.progressAchievement}>
              <Box sx={css.value}>
                {indicators.done}
                <span>/{indicators.total}</span>
                <span className="unit">{indicators.units}</span>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={css.progress}>
          <Box sx={css.titleAchievement}>Уровень достижения СЭ</Box>
          <Box sx={css.lines}>
            <LineProgress
              nameValue={structureElements.name}
              value={structureElements.total}
              percent={structureElements.percentage}
              isMain
            />
            {structureElements.elements.map((elem: IStructureElement) => (
              <LineProgress
                key={elem.name}
                nameValue={elem.name}
                value={elem.amount}
                percent={elem.percentage}
              />
            ))}
          </Box>
        </Box>
        <Box sx={css.chartContainer}>
          <Box sx={css.chart}>
            <RadialPolarChart
              categories={chartData.categories}
              values={chartData.values}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
