import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import ReactECharts, { EChartsInstance } from "echarts-for-react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { getDataSumByCodeGP } from "SRC/constants/dataCodes";
import { ECalculationMethod } from "SRC/constants/globals";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { TProgram, usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import themeConfig from "SRC/theme";

import Tooltip from "./common/Tooltip";
import { css } from "./Equalizer.styled";
import { getOption } from "./options";

interface IChartEvent {
  name: string;
  event: { offsetX: number; offsetY: number };
  value: number;
}

const Equalizer = () => {
  const theme = useTheme<typeof themeConfig>();
  const { items } = usePrograms();
  const {
    program,
    filteredPrograms,
    setSelectedProgram,
    calculationMethod,
  }: IFilters = useFilters();

  const chartRef = useRef<EChartsInstance | null>(null);
  const [hovered, setHovered] = useState<string>("");
  const [coords, setCoords] = useState<[number, number]>([0, 0]);

  const [codes, values]: [string[], number[]] = useMemo(
    () =>
      items.reduce(
        (acc, item: TProgram) => {
          return [
            [...acc[0], String(item.gp_code)],
            [
              ...acc[1],
              roundNumbersToFixed(
                calculationMethod === ECalculationMethod.EFFECT
                  ? getDataSumByCodeGP(item, 1753) || 0
                  : getDataSumByCodeGP(item, 1796) || 0,
                0
              ),
            ],
          ];
        },
        [[], []] as [string[], number[]]
      ),
    [items, calculationMethod]
  );

  const onHover = useCallback(
    (e: IChartEvent) => {
      if (hovered !== e.name && chartRef?.current) {
        const {
          ele: { clientHeight, clientWidth },
        } = chartRef.current;
        const {
          event: { offsetX, offsetY },
          name,
        } = e;

        setCoords([
          offsetX < clientWidth / 2 ? offsetX + 15 : offsetX - 310,
          offsetY < clientHeight / 2 ? offsetY : offsetY - clientHeight / 2,
        ]);
        setHovered((state) => (state === name ? state : name));
      }
    },
    [hovered, filteredPrograms]
  );

  const chartEvents = useMemo(
    () => ({
      click: (e: { name: string }) => {
        setSelectedProgram(program !== e.name ? e.name : "");
      },
      mouseover: (e: IChartEvent) => {
        if (!filteredPrograms.includes(e.name)) return;
        onHover(e);
      },
    }),
    [items, filteredPrograms, program]
  );

  const onBlur = useCallback(() => {
    setHovered("");
    setCoords([0, 0]);
  }, []);

  const options = useMemo(
    () =>
      getOption(theme, codes, values, filteredPrograms as string[], program),
    [theme, codes, values, program, filteredPrograms]
  );

  return (
    <Box sx={css.container}>
      <ReactECharts
        option={options}
        style={css.graph}
        onEvents={chartEvents}
        ref={chartRef}
      />
      <Tooltip coords={coords} selected={hovered} onBlur={onBlur} />
    </Box>
  );
};

export default Equalizer;
