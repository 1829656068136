import { Theme } from "@mui/material";

const subtag = {
  position: "absolute",
  left: 0,
  maxWidth: "3rem !important",
  minWidth: 0,
};

const icon = {
  svg: {
    width: "1rem",
    height: "1rem",
  },
};

export const css = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    gap: "2rem",
    paddingTop: "1rem",
  },
  titles: {
    flex: 1,
    overflow: "hidden",
    gap: "1rem",
  },
  subtitles: (hasPadding = true) => ({
    display: "flex",
    position: "relative",
    alignItems: "center",
    flex: 1,
    gap: "0.5rem",
    overflow: "hidden",
    paddingLeft: hasPadding ? "3.5rem" : 0,
  }),
  title: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
  type: {
    backgroundColor: (theme: Theme) =>
      theme.palette.complementary.whiteTransparent,
    padding: "2px 8px",
    borderRadius: "4px",
    flex: "0 0 auto",
    ...subtag,
  },
  actions: {
    display: "flex",
    flexWrap: "nowrap",
    flex: "0 0 auto",
    gap: "1rem",
  },
  buttonsContainer: (theme: Theme) => ({
    background: theme.palette.background.paper,
    borderRadius: "0.5rem",
    padding: "0.25rem",
    flex: "0 0 auto",
    width: "fit-content",
    position: "relative",
    height: "2.5rem",
  }),
  calculationButton: {
    color: "text.onAccent",
    borderRadius: "0.5rem",
    height: "2rem",
    padding: "0.5rem 1rem 0.5rem 1rem !important",
    flex: "0 0 auto",
    fontSize: "1rem",
    textTransform: "none",
    fontWeight: "normal",
  },
  close: {
    maxWidth: "2.5rem !important",
    minWidth: "2.5rem !important",
    height: "2.5rem",
    ...icon,
  },
  back: {
    height: "100%",
    paddingLeft: 0,
    justifyContent: "start",
    ...subtag,
    ...icon,
  },
};
