import { useMemo } from "react";
import { ECalculationMethod } from "SRC/constants/globals";
import {
  IFilters,
  useFilters,
} from "SRC/redux/slices/summary/hooks/useFilters";
import { useTopBestGP } from "SRC/redux/slices/summary/hooks/useTopBestGP";
import { useTopBestGP2 } from "SRC/redux/slices/summary/hooks/useTopBestGP2";
import { useTopBestMinistries } from "SRC/redux/slices/summary/hooks/useTopBestMinistries";
import { useTopBestMinistries2 } from "SRC/redux/slices/summary/hooks/useTopBestMinistries2";
import { useTopBestVP } from "SRC/redux/slices/summary/hooks/useTopBestVP";
import { useTopBestVP2 } from "SRC/redux/slices/summary/hooks/useTopBestVP2";
import { useTopWorstGP } from "SRC/redux/slices/summary/hooks/useTopWorstGP";
import { useTopWorstGP2 } from "SRC/redux/slices/summary/hooks/useTopWorstGP2";
import { useTopWorstMinistries } from "SRC/redux/slices/summary/hooks/useTopWorstMinistries";
import { useTopWorstMinistries2 } from "SRC/redux/slices/summary/hooks/useTopWorstMinistries2";
import { useTopWorstVP } from "SRC/redux/slices/summary/hooks/useTopWorstVP";
import { useTopWorstVP2 } from "SRC/redux/slices/summary/hooks/useTopWorstVP2";

import { IRatingTopData } from "./interfaces";
import {
  createBestGP1Data,
  createBestGP2Data,
  createBestVP1Data,
  createBestVP2Data,
  createTopMinistriesData,
  createWorstGP1Data,
  createWorstGP2Data,
  createWorstVP1Data,
  createWorstVP2Data,
  sortRatingDataBySum,
} from "./utils";

interface IUseRatingData {
  bestGPData: IRatingTopData[];
  worstGPData: IRatingTopData[];
  bestVPData: IRatingTopData[];
  worstVPData: IRatingTopData[];
  bestMinistriesData: IRatingTopData[];
  worstMinistriesData: IRatingTopData[];
  bestGPFetching: boolean;
  worstGPFetching: boolean;
  bestVPFetching: boolean;
  worstVPFetching: boolean;
  bestMinistriesFetching: boolean;
  worstMinistriesFetching: boolean;
}

export const useRatingData = (): IUseRatingData => {
  const { calculationMethod }: IFilters = useFilters();
  const { items: itemsWorstGP, fetching: worstGP1Fetching } = useTopWorstGP();
  const { items: itemsWorstGP2, fetching: worstGP2Fetching } = useTopWorstGP2();
  const { items: itemsBestGP, fetching: bestGP1Fetching } = useTopBestGP();
  const { items: itemsBestGP2, fetching: bestGP2Fetching } = useTopBestGP2();

  const { items: itemsWorstVP, fetching: worstVP1Fetching } = useTopWorstVP();
  const { items: itemsWorstVP2, fetching: worstVP2Fetching } = useTopWorstVP2();
  const { items: itemsBestVP, fetching: bestVP1Fetching } = useTopBestVP();
  const { items: itemsBestVP2, fetching: bestVP2Fetching } = useTopBestVP2();

  const { items: itemsWorstMinistries, fetching: worstMinistries1Fetching } =
    useTopWorstMinistries();
  const { items: itemsWorstMinistries2, fetching: worstMinistries2Fetching } =
    useTopWorstMinistries2();
  const { items: itemsBestMinistries, fetching: bestMinistries1Fetching } =
    useTopBestMinistries();
  const { items: itemsBestMinistries2, fetching: bestMinistries2Fetching } =
    useTopBestMinistries2();

  // Получение лидирующих Министерств по эффективности
  const dataBestMinistries1 = useMemo(
    () => createTopMinistriesData(itemsBestMinistries),
    [itemsWorstMinistries]
  );
  // Получение лидирующих Министерств по результативности
  const dataBestMinistries2 = useMemo(
    () => createTopMinistriesData(itemsBestMinistries2),
    [itemsWorstMinistries2]
  );

  // Получение отстающих Министерств по эффективности
  const dataWorstMinistries1 = useMemo(
    () => createTopMinistriesData(itemsWorstMinistries),
    [itemsWorstMinistries]
  );
  // Получение отстающих Министерств по результативности
  const dataWorstMinistries2 = useMemo(
    () => createTopMinistriesData(itemsWorstMinistries2),
    [itemsWorstMinistries2]
  );

  // Получение лидирующих ГП по эффективности
  const dataBestGP1 = useMemo(
    () => createBestGP1Data(itemsBestGP),
    [itemsBestGP]
  );
  // Получение лидирующих ГП по результативности
  const dataBestGP2 = useMemo(
    () => createBestGP2Data(itemsBestGP2),
    [itemsBestGP2]
  );
  // Получение отстающих ГП по эффективности
  const dataWorstGP1 = useMemo(
    () => createWorstGP1Data(itemsWorstGP),
    [itemsWorstGP]
  );
  // Получение отстающих ГП по результативности
  const dataWorstGP2 = useMemo(
    () => createWorstGP2Data(itemsWorstGP2),
    [itemsWorstGP2]
  );

  // Лидирующие Министерства в зависимости от метода расчёта
  const bestMinistriesData = useMemo(() => {
    const ratingData =
      calculationMethod === ECalculationMethod.EFFECT
        ? dataBestMinistries1
        : dataBestMinistries2;

    return sortRatingDataBySum(ratingData);
  }, [calculationMethod, dataBestMinistries1, dataBestMinistries2]);

  // Лидирующие Министерства в зависимости от метода расчёта
  const worstMinistriesData = useMemo(() => {
    const ratingData =
      calculationMethod === ECalculationMethod.EFFECT
        ? dataWorstMinistries1
        : dataWorstMinistries2;

    return sortRatingDataBySum(ratingData);
  }, [calculationMethod, dataBestMinistries1, dataBestMinistries2]);

  // Получены ли данные о лидирующих Министерствах в зависимости от метода расчёта
  const bestMinistriesFetching = useMemo(() => {
    return calculationMethod === ECalculationMethod.EFFECT
      ? bestMinistries1Fetching
      : bestMinistries2Fetching;
  }, [bestMinistries2Fetching, bestMinistries1Fetching, calculationMethod]);

  // Получены ли данные об отстающих Министерствах в зависимости от метода расчёта
  const worstMinistriesFetching = useMemo(() => {
    return calculationMethod === ECalculationMethod.EFFECT
      ? worstMinistries1Fetching
      : worstMinistries2Fetching;
  }, [worstMinistries1Fetching, worstMinistries2Fetching, calculationMethod]);

  // Лидирующие ГП в зависимости от метода расчёта
  const bestGPData = useMemo(() => {
    const ratingData =
      calculationMethod === ECalculationMethod.EFFECT
        ? dataBestGP1
        : dataBestGP2;

    return sortRatingDataBySum(ratingData);
  }, [calculationMethod, dataBestGP1, dataBestGP2]);

  // Отстающие ГП в зависимости от метода расчёта
  const worstGPData = useMemo(() => {
    const ratingData =
      calculationMethod === ECalculationMethod.EFFECT
        ? dataWorstGP1
        : dataWorstGP2;

    return sortRatingDataBySum(ratingData);
  }, [calculationMethod, dataWorstGP1, dataWorstGP2]);

  // Получены ли данные о лидирующих ГП в зависимости от метода расчёта
  const bestGPFetching = useMemo(() => {
    return calculationMethod === ECalculationMethod.EFFECT
      ? bestGP1Fetching
      : bestGP2Fetching;
  }, [bestGP1Fetching, bestGP2Fetching, calculationMethod]);

  // Получены ли данные об отстающих ГП в зависимости от метода расчёта
  const worstGPFetching =
    calculationMethod === ECalculationMethod.EFFECT
      ? worstGP1Fetching
      : worstGP2Fetching;

  // Получение лидирующих ВП по эффективности
  const dataBestVP1 = useMemo(
    () => createBestVP1Data(itemsBestVP),
    [itemsBestVP]
  );

  // Получение лидирующих ВП по результативности
  const dataBestVP2 = useMemo(
    () => createBestVP2Data(itemsBestVP2),
    [itemsBestVP2]
  );

  // Получение отстающих ВП по эффективности
  const dataWorstVP1 = useMemo(
    () => createWorstVP1Data(itemsWorstVP),
    [itemsWorstVP]
  );

  // Получение отстающих ВП по результативности
  const dataWorstVP2 = useMemo(
    () => createWorstVP2Data(itemsWorstVP2),
    [itemsWorstVP2]
  );

  // Лидирующие ВП в зависимости от метода расчёта
  const bestVPData = useMemo(() => {
    return calculationMethod === ECalculationMethod.EFFECT
      ? dataBestVP1
      : dataBestVP2;
  }, [calculationMethod, dataBestVP1, dataBestVP2]);

  // Отстающие ВП в зависимости от метода расчёта
  const worstVPData = useMemo(() => {
    return calculationMethod === ECalculationMethod.EFFECT
      ? dataWorstVP1
      : dataWorstVP2;
  }, [calculationMethod, dataWorstVP1, dataWorstVP2]);

  // Получены ли данные о лидирующих ВП в зависимости от метода расчёта
  const bestVPFetching = useMemo(() => {
    return calculationMethod === ECalculationMethod.EFFECT
      ? bestVP1Fetching
      : bestVP2Fetching;
  }, [bestVP1Fetching, bestVP2Fetching, calculationMethod]);

  // Получены ли данные об отстающих ВП в зависимости от метода расчёта
  const worstVPFetching = useMemo(() => {
    return calculationMethod === ECalculationMethod.EFFECT
      ? worstVP1Fetching
      : worstVP2Fetching;
  }, [calculationMethod, worstVP1Fetching, worstVP2Fetching]);

  return {
    bestGPData,
    worstGPData,
    bestVPData,
    worstVPData,
    bestMinistriesData,
    worstMinistriesData,
    bestGPFetching,
    worstGPFetching,
    bestVPFetching,
    worstVPFetching,
    bestMinistriesFetching,
    worstMinistriesFetching,
  };
};
