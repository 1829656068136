import { Theme } from "@mui/material";

export const css = {
  container: {
    height: "100%",
    width: "100%%",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  labelContainer: (bottomOffset: number, leftOffset: string) => ({
    position: "absolute",
    left: leftOffset,
    bottom: `calc(${bottomOffset}%)`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
  planText: {
    fontSize: "1rem",
    lineHeight: "0.8rem",
    fontWeight: "bold",
  },
  divider: {
    width: "100%",
    height: "1px",
    background: (theme: Theme) => theme.palette.primary.contrastText,
    margin: "0.5rem 0",
  },
  factText: (success: boolean) => ({
    fontSize: "1.2rem",
    lineHeight: "1rem",
    color: (theme: Theme) =>
      success ? theme.palette.success.main : theme.palette.error.main,
    fontWeight: "bold",
  }),
};
