import { styled, Theme, Typography } from "@mui/material";

interface IProps {
  isSmall?: boolean;
  length?: number | undefined;
  withBigRows?: boolean;
  hasBorder?: "left" | "right";
  multilineHeader?: boolean;
  withHeadBgr?: boolean;
  withClickableRow?: boolean;
  tableHeight?: string | number;
  statusLabel?: string;
  statusColored?: boolean;
  isSelected?: boolean;
  defaultSelection?: boolean;
  wrapSort?: boolean;
}

export const css = {
  wrapper: (
    tableHeight: string | number = "auto",
    scrollHide: boolean | undefined,
    scrollX: boolean | undefined,
    theme: Theme
  ) => ({
    position: "relative",
    flex: "1 1 auto",
    height: tableHeight,
    overflow: "hidden",
    maxHeight: tableHeight,
    margin: "0 -1rem",
    padding: scrollX ? "0rem 2rem" : "0rem 1rem",
    display: "flex",
    minHeight: 0,
    flexDirection: "column",
    width: scrollX ? "max-content" : "unset",
    "& ::-webkit-scrollbar": {
      display: scrollHide ? "none" : "",
    },
  }),
  resetSelection: (theme: Theme) => ({
    position: "absolute",
    top: "50%",
    right: 5,
    transform: "translate(50%, -50%)",
    cursor: "pointer",
    border: `1px solid ${theme.palette.background.paper}`,
    borderRadius: "50%",
    background: theme.palette.primary.main,
    width: "2.4rem",
    height: "2.4rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  closeIcon: {
    width: "1.5rem",
    height: "1.5rem",
  },
  container: (multilineHeader: boolean, singleTable: boolean | undefined) => ({
    overflow: "auto",
    height: "100%",
    minHeight: 0,
    margin: "0 -1rem",
    padding: "0 1rem",
    table: {
      width: "100%",
      borderSpacing: multilineHeader ? "0 0" : "0px 0.5rem",
      borderCollapse: multilineHeader ? "collapse" : "separate",

      "& td, th": {
        position: "relative",
        minWidth: "1rem",
      },

      "& td:first-of-type": {
        borderRadius: "1.5rem 0 0 1.5rem",
      },
      "& td:last-child": {
        borderRadius: singleTable ? "1.5rem" : "0 1.5rem 1.5rem 0",
      },
      "& tbody > tr:hover": {
        background:
          "linear-gradient(90deg, rgba(46, 54, 74, 0) 0%, rgba(160, 176, 208, 0.4) 100%), rgba(46, 54, 74, 0.4) !important",
      },
    },
    thead: (theme: Theme) => ({
      height: "6rem",
      backgroundColor: theme.palette.background.paper,

      [theme.breakpoints.up("lg")]: {
        backgroundColor: "transparent",
      },
      //SPUTNIK
      "& tr:first-of-type, tr:last-child": {
        height: "3rem",
      },
      //
      "& tr:first-of-type > th:first-of-type": {
        borderRadius: "1rem 0 0 0",
      },
      "& tr:first-of-type > th:last-child": {
        borderRadius: "0 1rem 0 0",
      },
      "& tr:last-child > th:first-of-type": {
        borderRadius: multilineHeader ? "0 0 0 1.5rem" : "1rem 0 0 1rem",
      },
      "& tr:last-child > th:last-child": {
        borderRadius: multilineHeader ? "0 0 1.5rem 0" : "0 1rem 1rem 0",
      },
      " tr:last-child th": {
        color: multilineHeader
          ? theme.palette.complementary.grayBlue
          : theme.palette.main.gray,
      },
      " tr:first-of-type th": {
        color: theme.palette.main.gray,
      },
      position: "sticky",
      top: "0.5rem",
      zIndex: 1,
    }),
    tfoot: (theme: Theme) => ({
      height: "6rem",
      backgroundColor: theme.palette.complementary.darkGrayBlue,
      borderTop: multilineHeader
        ? `10px solid ${theme.palette.main.darkBlue}`
        : "none",
      //SPUTNIK
      "& tr:first-of-type, tr:last-child": {
        height: "6rem",
      },
      ////
      "& tr:last-child": {
        display: multilineHeader ? "none" : "auto",
      },

      "& th:first-of-type": {
        borderRadius: "1.5rem 0 0 1rem",
      },
      "& th:last-child": {
        borderRadius: singleTable ? "1.5rem" : "0 1.5rem 1rem 0",
      },
      position: "sticky",
      bottom: 0,
      zIndex: 1,

      [theme.breakpoints.up("xl")]: {
        height: "5rem",
      },
    }),
  }),
  headerBack: {
    width: "calc(100% - 2rem)",
    height: "5.5rem",
    top: "-0.5rem",
    position: "absolute",
    background: (theme: Theme) => theme.palette.main.darkBlue,
    // zIndex: 1,
    // boxShadow: (theme: Theme) =>
    //   `0px 10px 5px 5px ${theme.palette.main.darkBlue}`,
    zIndex: 1,
  },
  headerBackHideRiskIcon: {
    width: "calc(100% - 2rem)",
    height: "5.5rem",
    top: "-0.5rem",
    left: "-0.5rem",
    position: "absolute",
    background: "transparent",
    zIndex: 1,

    "&.hideHeaderIcon": {
      background: (theme: Theme) => theme.palette.main.darkBlue,
      width: "2rem",
      height: "6rem",
    },
    "&.auxBlock": {
      background: "transparent",
      width: "100%",
      // height: "5.5rem"
    },
  },
  footerBack: (theme: Theme) => ({
    width: "calc(100% - 2rem)",
    height: "5.5rem",
    bottom: "-0.5rem",
    left: "-1rem",
    position: "absolute",
    background: theme.palette.main.darkBlue,
    zIndex: 0,
    // boxShadow: `0px -10px 5px 5px ${theme.palette.main.darkBlue}`,

    [theme.breakpoints.up("xl")]: {
      height: "5rem",
    },
  }),
  footerBackHideRiskIcon: {
    width: "calc(100% - 2rem)",
    height: "5.5rem",
    top: "2rem",
    left: "-1rem",
    position: "absolute",
    background: "transparent",
    zIndex: 1,

    "&.hideFooterIcon": {
      background: (theme: Theme) => theme.palette.main.darkBlue,
      width: "2rem",
      height: "5rem",
      top: "-2rem",
    },
  },
  tableHeadCell: {
    lineHeight: "1.2rem",
    color: (theme: Theme) => theme.palette.main.gray,
    fontSize: "1.2rem",
    position: "relative",
  },
  inDevelopmentContainer: {
    width: "fit-content",
    position: "absolute",
    top: "0.5rem",
    right: "0.5rem",
  },
  tableHeadInnerContainer: (
    length: number | undefined,
    withBigRows: boolean | undefined,
    wrapSort?: boolean
  ) => ({
    display: "flex",
    flexDirection: wrapSort ? "column" : "row",
    gap: wrapSort ? "10px" : "0",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.2rem",
    lineHeight: "1rem",
    maxWidth:
      length && length > 30 && withBigRows
        ? "16.5rem"
        : length && length > 30
        ? "13.75rem"
        : length && length > 20
        ? "11rem"
        : "auto",
    margin: length && length > 20 ? "auto" : "unset",
  }),
  tableHeadSortContainer: {
    display: "flex",
    gap: "0.1rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  tableRow: (
    theme: Theme,
    isSmall: boolean | undefined,
    withBigRows: boolean | undefined,
    multilineHeader: boolean,
    withClickableRow: undefined | boolean,
    isSelected: boolean,
    defaultSelection: boolean
  ) => ({
    height: isSmall ? "4rem" : withBigRows ? "7rem" : "5rem",
    background: isSelected
      ? "linear-gradient(90deg, rgba(46, 54, 74, 0) 0%, rgba(160, 176, 208, 0.4) 100%), rgba(46, 54, 74, 0.4)"
      : `linear-gradient(270deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.paperGray} 100%)`,
    borderTop: multilineHeader
      ? `10px solid ${theme.palette.main.darkBlue}`
      : "none",
    cursor: !withClickableRow ? "default" : "pointer",
    opacity: defaultSelection || isSelected ? 1 : 0.5,

    "@media (minWidth: 3800px)": {
      height: withBigRows ? "8rem" : "6rem",
    },
  }),
  tableData: {
    maxWidth: "25rem",
  },
  tableCellText: (
    theme: Theme,
    statusLabel?: string,
    statusColored?: boolean,
    atRisk?: number | undefined,
    withBigRows?: boolean
  ) => ({
    fontWeight: "bold",
    fontSize: "1.1rem",
    lineHeight: "1.2em",

    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
      lineHeight: "1.3em",
    },

    "&.with-right-border": {
      "&:after": {
        height: "4rem",
        content: "''",
        borderLeft: `3px solid ${theme.palette.main.darkBlue}`,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: "100%",
      },
    },
    "&.with-dotted-border": {
      "&:after": {
        height: "0.3rem",
        content: "''",
        borderLeft: `4px solid ${theme.palette.main.darkBlue}`,
        position: "absolute",
        top: "50%",
        borderRadius: "50%",
        transform: "translateY(-50%)",
        left: "100%",
      },
    },
    "&.with-left-border": {
      "&:after": {
        height: "4rem",
        content: "''",
        borderLeft: `3px solid ${theme.palette.main.darkBlue}`,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: "0%",
      },
    },

    "&.tableFooterText": {
      fontSize: "1.2rem",
      lineHeight: "1em",

      [theme.breakpoints.up("md")]: {
        fontSize: "1.6rem",
        lineHeight: "1em",
      },
    },
    "&.footerTextGray": {
      marginLeft: "1rem",
      color: theme.palette.main.gray,
    },
    "&.whiteText": {
      color: theme.palette.text.onAccent,
    },
    "&.redText": {
      color: theme.palette.error.main,
    },
    "&.cellContainer": {
      padding: "1.25rem",
      maxWidth: "43.8rem",
      fontWeight: "bold",
    },
    "&.cellProgress": {
      color: `${theme.palette.main.yellow}`,
      padding: "1.25rem",
      maxWidth: "43.8rem",
      fontWeight: "bold",
    },
    "&.cellDone": {
      color: `${theme.palette.success.main}`,
      padding: "1.25rem",
      maxWidth: "43.8rem",
      fontWeight: "bold",
    },
    "&.grayText": {
      color: theme.palette.main.gray,
      padding: 4,
      paddingRight: 2,
    },
    "&.withMarginLeft": {
      marginLeft: "1.25rem",
    },
    "&.subjectName, &.regionName": {
      marginLeft: "1.25rem",
      fontSize: "1.25rem",
      fontWeight: 400,
      display: "flex",
    },
    "&.displayFlex": {
      display: "flex",
    },
    "&.columnDirection": {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    "&.justifyCenter": {
      display: "flex",
      justifyContent: "center",
    },
    "&.verticalPadding": {
      padding: "1rem 0",
    },

    "&.bigText": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem",
      fontWeight: "bold",
      margin: "auto",
      color:
        statusLabel === "completed"
          ? theme.palette.success.main
          : statusColored
          ? theme.palette.error.main
          : "",

      [theme.breakpoints.up("md")]: {
        fontSize: "1.5rem",
        lineHeight: "1.5em",
      },
    },
    "&.atRisk": {
      color:
        atRisk && atRisk > 0
          ? theme.palette.error.main
          : theme.palette.main.gray,
    },
    "&.cashColumn-fact-cellText": {
      color: theme.palette.text.onAccent,
    },
    "&.withBigRows": {
      [theme.breakpoints.up("lg")]: withBigRows && {
        fontSize: "2rem",
        lineHeight: "1.8rem",
      },
    },
    "&.marginBottom": {
      color: theme.palette.main.gray,
      marginBottom: 2,
    },
    "&.cellWrapper": {
      marginLeft: "1.25rem",
      marginTop: "2rem",
      fontWeight: theme.typography.fontWeightRegular,
      [theme.breakpoints.up("lg")]: {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    "&.eventName": {
      marginLeft: "1.25rem",
      padding: "1.25rem 1.25rem 1.25rem 0",
      maxWidth: "43.8rem",
      fontWeight: "normal",
    },
    "&.isSmallOnLarge": {
      [theme.breakpoints.up("xl")]: { fontSize: "1rem" },
    },
    "&.fontLight": {
      fontWeight: "normal",
    },
  }),
  tableImage: (isSmall?: boolean) => ({
    width: isSmall ? "2rem" : "3rem",

    "&.rounded": {
      borderRadius: "50%",
    },
  }),
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableImageContainer: (
    theme: Theme,
    withBigRows?: boolean,
    isSmall?: boolean
  ) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: isSmall ? "1rem" : "1.2rem",

    [theme.breakpoints.up("lg")]: withBigRows && {
      padding: "1rem 1.5rem",
    },
  }),
  secretProgram: {
    "& svg": {
      width: "1.5rem",
      height: "1.5rem",
    },
  },
  iconContainer: {
    display: "flex",
    gap: "0.5rem",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    transform: "translateY(-43%)",
    left: "-0.6rem",
  },
  idCell: {
    padding: "0 20px",
    display: "flex",
    flexDirection: "column",
  },
  emptyTextContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    padding: "1rem",
  },
  emptyText: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: (theme: Theme) => theme.palette.main.gray,
  },
  loader: {
    position: "absolute",
    width: "100vw",
    maxWidth: "100%",
    height: "100px",
    zIndex: 999,
  },
};

export const MediaIconContainer = styled("div")<IProps>(
  ({ theme }: IProps & { theme: Theme }) => ({
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    left: "-1.5rem",
    transform: "translateY(50%)",
    bottom: "50%",

    [theme.breakpoints.up("md")]: {
      left: "-1rem",
    },
  })
);

export const TableCellText = styled(Typography)<IProps>(
  ({ theme, hasBorder }: IProps & { theme: Theme }) => ({
    fontWeight: "bold",
    fontSize: "1.1rem",
    lineHeight: "1.2em",

    "&:after": hasBorder && {
      height: "4rem",
      content: "''",
      borderLeft: `3px solid ${theme.palette.main.darkBlue}`,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: hasBorder === "left" ? "0%" : "100%",
    },

    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
      lineHeight: "1.3em",
    },
  })
);

export const TableCellBigText = styled(Typography)<IProps>(
  ({
    theme,
    withBigRows,
    statusLabel,
    statusColored,
    hasBorder,
  }: IProps & { theme: Theme }) => ({
    fontSize: "1.2rem",
    lineHeight: "1.2rem",
    fontWeight: "bold",
    color:
      statusLabel === "completed"
        ? theme.palette.success.main
        : statusColored
        ? theme.palette.error.main
        : "",

    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
      lineHeight: "1.5em",
    },

    [theme.breakpoints.up("lg")]: withBigRows && {
      fontSize: "2rem",
      lineHeight: "1.8rem",
    },

    "&:after": hasBorder && {
      height: "4rem",
      content: "''",
      borderLeft: `3px solid ${theme.palette.main.darkBlue}`,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: hasBorder === "left" ? "0%" : "100%",
    },
    "&.resetMargin": {
      margin: 0,
    },
  })
);

export const TableImage = styled("img")<IProps>(
  ({ isSmall }: IProps & { theme: Theme }) => ({
    width: isSmall ? "2rem" : "3rem",
    borderRadius: isSmall ? "0.5rem" : "1rem",
  })
);

export const TableFooterText = styled(TableCellText)(({ theme }) => ({
  fontSize: "1.2rem",
  lineHeight: "1em",

  [theme.breakpoints.up("md")]: {
    fontSize: "1.6rem",
    lineHeight: "1em",
  },
}));
