import { Theme } from "@mui/material";

export const css = {
  mainPage: {
    fontSize: "1rem",
    fontWeight: 400,
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
  currentPage: {
    fontSize: "1rem",
    color: (theme: Theme) => theme.palette.text.primary,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1.25rem",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  switcherContainer: {
    display: "flex",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 500,
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
  progress: {
    width: "100%",
    maxWidth: "31.25rem",
    display: "flex",
    flexDirection: "column",
    gap: "0.6rem",
  },
  progressHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barName: {
    fontSize: "1.25rem",
    fontWeight: 300,
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
  barValue: {
    fontSize: "1.5rem",
    fontWeight: 400,
    color: (theme: Theme) => theme.palette.primary.contrastText,
    "& span": {
      fontSize: "1.2rem",
    },
  },
  statuses: {
    display: "flex",
    flex: "0 0 25%",
    backgroundColor: (theme: Theme) => theme.palette.background.paper,
    justifyContent: "space-between",
    padding: "0.625rem 0.875rem",
    borderRadius: "0.5rem",
    gap: "2.5rem",
  },
  status: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  statusIcon: {
    display: "flex",
    maxWidth: "1.25rem",
  },
  statusName: {
    color: (theme: Theme) => theme.palette.text.primary,
    fontSize: "1rem",
  },
  statusCount: {
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
  link: {
    textDecoration: "none",
  },
};
