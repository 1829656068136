import { Box, CircularProgress, SxProps } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { getDataSumByCodeGP } from "SRC/constants/dataCodes";
import { ECalculationMethod } from "SRC/constants/globals";
import { isSecret } from "SRC/helpers/dataTesters";
import { Overlay } from "SRC/pages/App.styled";
import { css } from "SRC/pages/Main/common/Analitics/ProgramList/ProgramList.styled";
import TilesItemElement from "SRC/pages/Main/common/GovPrograms/TilesItemElement";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { TProgram, usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";

export const ProgramList = () => {
  const { items, fetching } = usePrograms();
  const {
    program,
    filteredPrograms,
    setSelectedProgram,
    setSelectedGoal,
    period,
    calculationMethod,
    sphere,
  }: IFilters = useFilters();

  const toggleSelection = useCallback(
    (code: typeof program) => () => {
      if (!filteredPrograms.includes(code)) {
        setSelectedGoal("");
      }
      setSelectedProgram(program === code ? "" : code);
    },
    [program, setSelectedProgram, setSelectedGoal, filteredPrograms]
  );

  const programs = useMemo(
    (): JSX.Element[] =>
      items
        .filter((item: TProgram) => filteredPrograms.includes(item.gp_code))
        .sort((a: TProgram, b: TProgram) =>
          calculationMethod === ECalculationMethod.EFFECT
            ? Number(getDataSumByCodeGP(b, 1753)) -
              Number(getDataSumByCodeGP(a, 1753))
            : Number(getDataSumByCodeGP(b, 1796)) -
              Number(getDataSumByCodeGP(a, 1796))
        )
        .map((item: TProgram) => (
          <Box sx={css.elementSX as SxProps} key={item.gp_code}>
            <TilesItemElement
              {...item}
              secretProgram={isSecret(item)}
              active={
                program
                  ? program === item.gp_code
                  : filteredPrograms.includes(item.gp_code)
              }
              achievement={
                calculationMethod === ECalculationMethod.EFFECT
                  ? getDataSumByCodeGP(item, 1753) || 0
                  : getDataSumByCodeGP(item, 1796) || 0
              }
              onClick={toggleSelection(item.gp_code as typeof program)}
            />
          </Box>
        )),
    [
      items,
      filteredPrograms,
      program,
      toggleSelection,
      period,
      calculationMethod,
      sphere,
    ]
  );

  if (fetching)
    return (
      <Overlay>
        <CircularProgress />
      </Overlay>
    );

  return <Box sx={css.container}>{programs}</Box>;
};
