import { Box, SxProps } from "@mui/material";
import React, { CSSProperties, FC } from "react";

import { css } from "./index.styled";

interface IProps {
  value: number;
  progressColor?: string;
  style?: CSSProperties | SxProps;
  circleLabel?: number;
  percentLabel?: string | number;
  bottomLeftLabel?: number;
  bottomRightLabel?: number;
  withLabel?: boolean;
  isDarkBg?: boolean;
  percentOnCircle?: boolean;
}

export const ProgressBar: FC<IProps> = ({
  value = 0,
  progressColor,
  style,
  circleLabel,
  bottomLeftLabel,
  bottomRightLabel,
  withLabel,
  isDarkBg,
  percentOnCircle = true,
}) => {
  const val = value > 100 ? 100 : value;

  return (
    <Box sx={css.progressBar}>
      <Box
        sx={
          {
            ...css.uiProgressBarStyle(
              val,
              progressColor,
              circleLabel,
              isDarkBg
            ),
            ...style,
          } as SxProps
        }
      >
        <Box sx={css.progressLine(val, progressColor) as SxProps}>
          {withLabel && (
            <Box sx={css.circleLabel(circleLabel, progressColor) as SxProps}>
              {percentOnCircle && (
                <Box sx={css.percentLabel as SxProps}>
                  {val}
                  <span>%</span>
                </Box>
              )}
              {circleLabel}
            </Box>
          )}
        </Box>
      </Box>
      {withLabel && (
        <Box sx={css.bottomLabelContainer}>
          <Box sx={css.bottomLabel}>{bottomLeftLabel}</Box>
          <Box sx={css.bottomLabel}>{bottomRightLabel}</Box>
        </Box>
      )}
    </Box>
  );
};
